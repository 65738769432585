import { makeStyles } from "@material-ui/core";
import { baseColors } from "../constants/colors/base-colors";
import { mediaQuery } from "../constants/media";

export const useTableStyles = makeStyles(() => ({
  container: {
    width: "100%",
    overflowX: "scroll",
    display: "flex",
    color: "black",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
    "& .MuiTableContainer-root": {
      overflowX: "initial",
    },
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeader": {
      "& .MuiTableCell-root": {
        padding: 0,
        display: "flex",
        borderBottom: "none",
        "& h4": {
          marginRight: "10px",
        },
        "& .MuiSvgIcon-root": {
          cursor: "pointer",
        },
        "& .MuiBox-root": {
          cursor: "pointer",
        },
      },
      transition: "1s",
      "&:hover": {
        opacity: 0.5,
      },
      "& .MuiDataGrid-menuIcon": {
        width: "auto",
        visibility: "visible",
      },
      "& .MuiDataGrid-iconButtonContainer": {
        width: "auto",
        visibility: "visible",
        "& .MuiDataGrid-sortIcon": {
          opacity: "1 !important",
        },
      },
    },
    "& .MuiDataGrid-columnHeadersInner >*:first-child": {
      display: "none",
    },
    "& .MuiDataGrid-row > [data-field='__detail_panel_toggle__']": {
      display: "none",
    },
    "& .MuiTableCell-root .MuiTableRow-root >*:first-child": {
      paddingLeft: 50,
    },

    "& .MuiDataGrid-virtualScrollerContent": {
      width: "100% !important",
      "& .MuiDataGrid-detailPanel >.MuiTableRow-root": {
        paddingLeft: 45,
      },
      "& .MuiDataGrid-row, & .MuiDataGrid-cell": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-cell": {
        whiteSpace: "normal !important",
        marginLeft: '3px',
      },
    },
  },
  containerNoDrag: {
    "& .MuiDataGrid-virtualScrollerContent": {
      width: "100% !important",
      "& .MuiDataGrid-detailPanel >.MuiTableRow-root": {
        paddingLeft: 0,
      },
    },
  },
  paginationContainer: {
    justifyContent: "flex-end",
    [mediaQuery.maxWidth1200]: {
      justifyContent: "flex-start",
    },
    "& .MuiTableCell-root": {
      borderBottom: `1px solid ${baseColors.black}`,
    },
    "& ul li.Mui-selected": {
      backgroundColor: `${baseColors.red} !important`,
      color: baseColors.white,
      border: "2px solid red",
    },
    "& .MuiTablePagination-select:focus": {
      background: "transparent",
    },
    [mediaQuery.maxWidth600]: {
      "& .MuiToolbar-root": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      },
      "& .MuiTablePagination-spacer": {
        display: "none",
      },
    },
  },
  emptySpan: {
    color: baseColors.red,
    height: "200px",
  },
  emptySpanCarousel: {
    color: baseColors.red,
    height: "50px",
  },
  dragIconClassName: {
    width: 19,
    display: "block",
    margin: "0 auto",
    cursor: "grab",
  },
  columnSeparator: {
    display: "none !important",
  },
  columnHeaders: {
    borderBottom: "1px solid #000 !important",
    "& .MuiDataGrid-columnHeader--sortable": {
      marginLeft: '5px',
    },
    "& .MuiDataGrid-columnHeadersInner": {
      "& .MuiDataGrid-columnHeader:last-child": {
        display: "none",
      },
      "& .MuiDataGrid-columnHeader:nth-child(2) > .MuiDataGrid-columnSeparator": {
        display: 'none !important'
      },
    },
  },
  virtualScroller: {
    marginTop: "106px !important",
  },
  rowlastVisible: {
    "& .MuiDataGrid-cell": {
      borderBottomColor: "rgba(224, 224, 224, 1) !important",
    },
  },
  footerContainer: {
    display: "none !important",
  },
  footerContainerNoDrag: {
    marginTop: "50px !important",
    borderTop: "none !important",
    "& .MuiTablePagination-root": {
      borderBottom: "1px solid #000 !important",
    },
  },
  rowCount: {
    display: "none !important",
  },
}));
