import * as Yup from "yup";
import { COLOR_REGEX } from "../../constants";

export const AddWidgetsStorySchema = Yup.object().shape({
  storyAuthorName: Yup.string().max(50),
  storyAuthorNameColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  storyButtonText: Yup.string().min(3, "min_length_3").max(50, "max_length_50"),
  storyButtonStyle: Yup.string().required("Required"),
  feedAssetsButtonImage: Yup.string().required("Required"),
  // thumbnailLockerUrl: Yup.string(),
  storySwipe: Yup.array().when(
    "storyDescription",
    (storyDescription, schema) => {
      return schema.test({
        test: (storySwipe: Array<Record<string, any>>) => {
          let isValid = true;

          if (storySwipe && storySwipe.length) {
            storySwipe.forEach((item: Record<string, any>) => {
              if (item.isHaveError) isValid = false;
            });
          }

          return isValid;
        },
      });
    }
  ),
});
