import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { isEmpty, orderBy, find, findIndex } from "lodash";
import Avatar from "@mui/material/Avatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { FlexBox, GridBox, RadiusBox } from "../../../../components/wilson-ui/box";
import { FormikColorField } from "../../../../components/wilson-ui/inputs";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
// import { Dropzone } from "../../../../components/wilson-ui/dropzone";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal, UploadModal } from "../modals";
import { useIntl } from "react-intl";
// import { axiosAuthorized } from "../../../../axios";
import image from "../../../../assets/images/successAssers.png";
import { Accordion } from "../accordion";
// import { EMPTY_FUNCTION } from "../../../../constants";
import { useLocation } from "react-router-dom";
import { FormikAutocomplete } from "../../../../components/wilson-ui/selects";
import { getFullWidgetsFeedByString } from "../../../../actions/widgets";
import { sendOverrideImage } from "../../../../actions/widgets";
import WidgetsStore from "../../../../store/widgets/widgets.store";
import {
  updateFeedPriorityForLocationBasedHelper,
  updateFeedWidgetForLocationBasedHelper
} from "../../../widgets/helpers/update-priority-widget";
import { feedWidgetsTableConfig } from "../../../widgets/widgets-table-config";
import { UiTableDrag } from "../../../../components/wilson-ui/table";
import { MaterialSwitch } from "../../../../components/wilson-ui/switch";
import { getTypeWidget } from "../../../../utils/utils";

const NAMES = ["Button Text", "Button Color", "Image"];

interface IProps {
  form: Record<string, any>;
}

const defaultSortState = "";
const defaultSortOrder = null;

export const SuccessAssets: React.FC<IProps> = ({ form }) => {
  const location: Record<string, any> = useLocation();
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);
  const [isModalUpload, setIsModalUpload] = useState<boolean>(false);
  const [feedItem, setFeedItem] = useState<Record<string, any>>({});
  const [feedsOptions, setFeedsOptions] = useState<Array<Record<string, any>>>(
    []
  );
  const [filterString, setFilterString] = useState<any>(null);
  const [threadTimeOut, setThreadTimeOut] = useState(0);

  const [sortState, setSortState] = useState<string>(defaultSortState);
  const [sortOrder, setSortOrder] = useState<any>(defaultSortOrder);
  const [loading, setLoading] = useState(false);

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  useEffect(() => {
    filterString !== null && onChange(filterString);
  }, [filterString]);

  useEffect(() => {
    if (sortState && sortOrder) {
      const sortOptions = orderBy(
        form.values.feedItems,
        [sortState],
        [sortOrder]
      );
      form.setFieldValue("feedItems", sortOptions);
    }
  }, [sortState, sortOrder]);

  const onChange = (value: string) => {
    setFilterString(value);
    callSearch(value);
  };

  const onFocus = () => {
    setFilterString("");
    callSearch("");
  };

  const callSearch = (term: string) => {
    if (threadTimeOut) {
      clearTimeout(threadTimeOut);
    }
    const timer: any = setTimeout(function () {
      sendData(term);
    }, 500);
    setThreadTimeOut(timer);
  };

  const sendData = async (term: string) => {
    const data = await getFullWidgetsFeedByString(term || "", false);
    setFeedsOptions(data);
    setFilterString(null);
  };

  const notify = () => toast.success(intl.formatMessage({ id: "upload_image_success" }));

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const feedsOptionsValue = feedsOptions.filter((item: any) => {
    const widgetIdPath =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    let isUsed = false;
    if (item.id === widgetIdPath) isUsed = true;
    (form.values.feedItems || []).forEach((feed: any) => {
      if (item.id === feed.id) isUsed = true;
    });
    if (!isUsed) return item;
  });

  const setRowPriorityHandler = async (payload: Array<Record<string, any>>) => {
    const widgetIdPath =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    const parseValue = payload.map(wg => ({ ...wg, id: wg.carouselId, childId: wg.id, priority: wg.widgetPriority, widgetPriority: undefined }));
    if(widgetIdPath) {
      await updateFeedPriorityForLocationBasedHelper(parseValue);
    }
    const feedItems = form.values.feedItems.map((item: any) => {
      const check = find(parseValue, (value: any) => value.carouselId === item?.carouselId);
      if(!isEmpty(check)) {
        return {
          ...item,
          priority: check?.priority
        }
      } else return { ...item };
    });
    const real = orderBy((feedItems || []), ['priority'], ['asc']);
    form.setFieldValue("feedItems", real);
  };

  const updateFeedWidget = async (id: string, payload: Record<string, any>) => {
    setLoading(true);
    await updateFeedWidgetForLocationBasedHelper(id, payload);
    setLoading(false);
  };

  const setSortModel = (field: string, sortOrder: string | null) => {
    setSortOrder(sortOrder || null);
    setSortState(field);
  };

  const sendOverrideImageHandler = async (file: Blob | string, type: string) => {
    setLoading(true);
    const data = await sendOverrideImage(file, type);
    if (data) notify();
    const feedItems = form.values.feedItems;
    if (feedItem?.carouselId) {
      await updateFeedWidget(feedItem.carouselId, { overrideImageUrl: data?.url });
    }
    const index = findIndex(feedItems, (item: any) => item.id === feedItem.id);
    if (index >= 0) {
      feedItems[index].overrideImageUrl = data?.url;
      feedItems[index].overrideImageLink = data?.link || '';
      feedItems[index].overrideThumbnailUrl = data?.thumbnailUrl || '';
      feedItems[index].overrideThumbnailLink = data?.thumbnail || '';
      form.setFieldValue("feedItems", feedItems);
    }
    setLoading(false);
    setFeedItem({
      ...feedItem,
      overrideImageLink: data?.link || "",
      overrideThumbnailLink: data?.thumbnail || "",
    });
    setIsModalUpload(!isModalUpload);
  };

  const toggleModalUpload = (feedItem: Record<string, any> | undefined) => {
    setIsModalUpload(!isModalUpload);
    setFeedItem(feedItem || {})
  }

  const removeFeedWidget = (id: string) => {
    const feedItems = form.values.feedItems.filter((feed: any) => feed.id !== id);
    form.setFieldValue("feedItems", feedItems);
  }


  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "success_assets" })}
      nameArray={NAMES}
      image={image}
      maxHeight="375px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  // const sendData = async (file: any) => {
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   try {
  //     const data: Record<string, any> = await axiosAuthorized.post(
  //       process.env.REACT_APP_API_URL + "widgets/feedMedia",
  //       formData
  //     );
  //     form.setFieldValue("successMediaUrl", data.data.feedMedia);
  //     form.setFieldValue("successMediaLink", data.data.feedMedia);
  //   } catch (e) {
  //     EMPTY_FUNCTION();
  //   }
  // };

  const modalUpload = isModalUpload ? (
    <UploadModal
      form={form}
      loading={loading}
      title={intl.formatMessage({ id: "override_image" })}
      filePreview={feedItem?.overrideImageLink}
      submitHandler={sendOverrideImageHandler}
      closeHandler={() => toggleModalUpload(undefined)}
    />
  ) : (
    <></>
  );
  const components = {
    type: ({ row }: any) => getTypeWidget(row.type),
    thumbnail: ({ row }: any) => {
      return (
        <FlexBox items="center" content="center">
          <Avatar
            variant="square"
            src={row.feedMediaLink}
            sx={{ marginRight: 2 }}
          />
          {row.title}
        </FlexBox>
      );
    },
    data: ({ row, col }: any) => (
      <span>
        {row[col] ? moment(row[col]).format("MMMM Do YYYY, HH:mm") : ""}
      </span>
    ),
    toggle: ({ row }: any) => (
        <FlexBox items="center" content="flex-end" width="100%">
          <MaterialSwitch
              value={row.isDropExclusive}
              onChange={(params: Record<string, any>) => updateFeedWidget(row.id, params)}
          />
        </FlexBox>
    ),
    actions: ({ row }: any) => (
      <FlexBox items="center" content="flex-end" width="100%" gap="15px">
        <RadiusBox width="32px" height="32px" onClick={() => toggleModalUpload(row)}>
        {row?.overrideThumbnailLink || row?.overrideImageLink ? (
          <img src={row?.overrideThumbnailLink || row?.overrideImageLink} alt="override image" className={classes.image} />
        ): <AddPhotoAlternateOutlinedIcon className={classes.icon}/>}
        </RadiusBox>
        <RadiusBox width="32px" height="32px" onClick={() => removeFeedWidget(row.id)}>
          <DeleteForeverIcon className={classes.removeIcon} />
        </RadiusBox>
      </FlexBox>
    ),
  };

  const TableDrag = (
    <UiTableDrag
      loading={WidgetsStore.isLoading || loading}
      sortModelInit={
        sortState && sortOrder ? [{ field: sortState, sort: sortOrder }] : []
      }
      isSortType={false}
      sortSubmit={setSortModel}
      components={components}
      componentsTable={components}
      configDrag={feedWidgetsTableConfig()}
      data={form.values.feedItems}
      disablePagination={true}
      length={(form.values.feedItems || []).length}
      setRowPriority={setRowPriorityHandler}
      rowReordering={true}
      searchValue={""}
      minHeight={200}
    />
  );

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "success_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox columns="1fr 1fr" cg="30px" rg="30px">
            <FormikField
              label={intl.formatMessage({ id: "primary_title" })}
              name="successTitle"
              submit={form.handleChange}
              placeholder={intl.formatMessage({
                id: "add_promotion_title",
              })}
              value={form.values.successTitle}
              {...{ form }}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "title_color" })}
              name="successTitleColor"
              value={form.values.successTitleColor}
              {...{ form }}
            />
          </GridBox>

          <GridBox columns="1fr 1fr" cg="30px" rg="30px">
            <FormikField
              label={intl.formatMessage({ id: "description" })}
              name="successDescription"
              submit={form.handleChange}
              placeholder={intl.formatMessage({
                id: "description",
              })}
              value={form.values.successDescription}
              {...{ form }}
              multiline
              rows={2}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "description_color" })}
              name="successDescriptionColor"
              value={form.values.successDescriptionColor}
              {...{ form }}
            />
          </GridBox>
          {/* <GridBox columns="1fr" cg="30px" rg="30px">
            <FormikColorField
              label={intl.formatMessage({ id: "background_color" })}
              name="successBackgroundColor"
              value={form.values.successBackgroundColor}
              {...{ form }}
            />
          </GridBox> */}

          {/* <div>
            <h4>{intl.formatMessage({ id: "primary_image" })}</h4>
            <Dropzone
              {...{ form, sendData }}
              name="successMediaUrl"
              imagePreview={form.values.successMediaLink}
            />
          </div> */}
          <GridBox columns="1fr" cg="30px" rg="30px">
            <FormikAutocomplete
              placeholder="Value"
              optionLabel="title"
              options={feedsOptionsValue}
              label={intl.formatMessage({ id: "add_feed_items" })}
              name="feedItems"
              value={form.values.feedItems}
              {...{ form }}
              limitTags={8}
              textSubmit={setFilterString}
              textValue={filterString}
              addHandler={onChange}
              onFocus={onFocus}
            />
          </GridBox>
          {!isEmpty(form.values.feedItems) ? (
            <div className={classes.feedTable}>{TableDrag}</div>
          ) : null}
        </GridBox>
      </Accordion>
      {modal}
      {modalUpload}
    </div>
  );
};
