import React, { useState } from "react";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { Dropzone } from "../../../../components/wilson-ui/dropzone";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal } from "../modals";
import { useIntl } from "react-intl";
import image from "../../../../assets/images/successAssers.png";
import { axiosAuthorized } from "../../../../axios";
import { EMPTY_FUNCTION } from "../../../../constants";
import { Accordion } from "../accordion";

const NAMES = ["Button Text", "Button Color", "Image"];

interface IProps {
  form: Record<string, any>;
}

export const LockerAssets: React.FC<IProps> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "locker_assets" })}
      nameArray={NAMES}
      image={image}
      maxHeight="375px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  const sendData = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "widgets/thumbnailLocker",
        formData
      );
      form.setFieldValue("thumbnailLockerUrl", data.data.thumbnail);
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "locker_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <FlexBox
          className={classes.accordionBody}
          direction="column"
          gap="30px"
        >
          <Dropzone
            {...{ form, sendData }}
            name="thumbnailLockerUrl"
            imagePreview={form.values.thumbnailLockerLink}
          />
        </FlexBox>
      </Accordion>
      {modal}
    </div>
  );
};
