import React, { useEffect, useRef, useState } from "react";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { SwipeItem } from "../swipe-item";
import { useIntl } from "react-intl";
import { findIndex } from 'lodash';
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";
import { getNewItem } from "./helpers/get-default-item";
import { useSwipeUpUrlStyle } from "./swipe-up-url-styles.hook";
import { URL_REGEX } from "../../../../constants";
import { IModifierHTMLVideoElement } from "../../../../types";
import { hasAudio } from "../../../../utils/utils";

interface IProps {
  form: Record<string, any>;
}

const checkUrl = (url: string) => {
  if (url === "") return true;

  return URL_REGEX.test(url);
};

const checkSwipeField = (field: Record<string, any>) => {
  if (field.swipeUrl && !checkUrl(field.swipeUrl)) field.isHaveError = true;
  field.isHaveError = false;

  return field;
};

const SortableItem = SortableElement(
  ({ index, setSwipeState, items, item, updateSwipeArray, checkSoundVideo, form }: any) => (
    <SwipeItem
      key={index}
      item={item}
      form={form}
      index={findIndex(items, (u: any) => u.id === item.id)}
      swipeState={items}
      setSwipeState={setSwipeState}
      updateSwipeArray={updateSwipeArray}
      checkSoundVideo={checkSoundVideo}
    />
  )
);

const SortableList = SortableContainer(
  ({ items, setSwipeState, updateSwipeArray, checkSoundVideo, form }: any) => {
    const classes = useSwipeUpUrlStyle();
    return (
      <FlexBox direction="column" gap="10px" className={classes.container}>
        {items.map((item: any, index: any) => (
          <SortableItem
            key={index}
            form={form}
            index={index}
            item={item}
            items={items}
            setSwipeState={setSwipeState}
            updateSwipeArray={updateSwipeArray}
            checkSoundVideo={checkSoundVideo}
          />
        ))}
      </FlexBox>
    );
  }
);

export const SwipeUpUrl: React.FC<IProps> = ({ form }) => {
  const classes = useSwipeUpUrlStyle();
  const [swipeState, setSwipeState] = useState<any>(form.values.storySwipe);
  const divListVideosRef = useRef<HTMLDivElement>(null);

  const intl = useIntl();
  const isHaveSwipeUrlError: boolean =
    form?.touched.storySwipe && Boolean(form.errors.storySwipe);

    useEffect(() => {
      if (!swipeState.length || isHaveSwipeUrlError ) {
        addNewSwipe();
      }
      updateSwipeArray();
    }, [swipeState]);

  useEffect(() => {
    if (form.values.isAdd) {
      form.setFieldValue("storySwipe", []);
      setSwipeState([]);
    }
  }, []);

  const addNewSwipe = () => {
    setSwipeState((prevState: any[]) => {
      return [...prevState, getNewItem()].map((item: any, index: number) => {
        return { ...item, priority: index + 1 };
      });
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) =>
    setSwipeState((items: any) =>
      arrayMoveImmutable(items, oldIndex, newIndex)
    );

  const updateSwipeArray = async () => {
    const newState = [];

    for await (const [index, swipeItem] of swipeState.entries()) {
      const checkedItem = checkSwipeField(swipeItem);

      // if item is video, check on sound and add property "hasSound"
      // if(swipeItem.type === 'VIDEO') {
      //   try {
      //     let hasSound = false;
      //     if(divListVideosRef && divListVideosRef.current) {
      //       // create tag(<video />) in DOM for check on sound 
      //       const divList = divListVideosRef.current;
      //       const videoTag = document.createElement("video");
      //       videoTag.src = swipeItem.preview;
      //       videoTag.load();
      //       divList.appendChild(videoTag);

      //       // check on sound
      //       hasSound = await hasAudio(videoTag as IModifierHTMLVideoElement);
      //       newState.push({
      //         ...checkedItem,
      //         priority: index,
      //         hasSound
      //       });

      //       continue;
      //     }
      //   } catch (error) {
      //     console.log('error', error);
      //   }
      // }
    
      // if item is image
      newState.push({
        ...checkedItem,
        priority: index,
      })
    }

    form.setFieldValue("storySwipe", newState);
  };

  const checkSoundVideo = async (preview: string) => {
    try {
      let hasSound = false;
      if (divListVideosRef && divListVideosRef.current) {
        const divList = divListVideosRef.current;
        const videoTag = document.createElement("video");
        videoTag.src = preview;
        videoTag.load();
        divList.appendChild(videoTag);

        // check on sound
        hasSound = await hasAudio(videoTag as IModifierHTMLVideoElement);
        return hasSound;
      }
    } catch (error) {
      console.log('error', error);
      return false;
    }
  };
  return (
    <FlexBox direction="column" gap="15px">
      <FlexBox items="center" content="space-between">
        <h4 style={{ marginBottom: 0 }}>{intl.formatMessage({ id: "story_slides" })}</h4>

        <UiButton
          buttonType="rect"
          className={classes.button}
          onClick={addNewSwipe}
        >
          + {intl.formatMessage({ id: "add" })}
        </UiButton>
      </FlexBox>

      {/* need to checking a video on the sound in updateSwipeArray() */}
      <div ref={divListVideosRef} className={classes.divListVideos} />

      {!swipeState.length || isHaveSwipeUrlError ? (
        <span
          style={{
            color: baseColors.red,
          }}
        >
          {intl.formatMessage({ id: "please_set_one_item" })}
        </span>
      ) : (
        <SortableList
          distance={1}
          form={form}
          items={swipeState}
          {...{ onSortEnd, setSwipeState, updateSwipeArray, checkSoundVideo }}
        />
      )}
    </FlexBox>
  );
};
