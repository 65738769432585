
import { downloadUsersCSV } from "../../../../actions/users";
import { EMPTY_FUNCTION } from "../../../../constants";

export const useUsersDownloadCSV = async (
  length: number,
  startDate: Date | null,
  endDate: Date | null,
  order: string,
  sortByName: string,
  term: string | undefined,
  isInterests: boolean
): Promise<Array<Array<any>> | string | undefined> => {
  try {
    const CSVFile = await downloadUsersCSV(
      length,
      startDate,
      endDate,
      order,
      sortByName,
      term,
      isInterests
    );
    return CSVFile;
  } catch (error) {
    EMPTY_FUNCTION();
  }
};
