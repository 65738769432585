import { makeStyles } from "@material-ui/core";
import { baseColors } from "../constants/colors/base-colors";

export const useCheckBoxesStyles = makeStyles(() => ({
  redSwitch: {
    borderRadius: "300px !important",
    background: baseColors.grey,
    display: "block !important",
    height: "28px !important",
    width: "50px !important",
    "& .Mui-checked": {
      color: baseColors.red + " !important",
      "& ~ .MuiSwitch-track": {
        backgroundColor: baseColors.red + " !important",
      },
    },
    "& .MuiSwitch-switchBase": {
      marginTop: "-5px",
      marginLeft: "-4px",
      color: "#fff !important",
    },
  },
  redSwitchActive: {
    background: `${baseColors.red} !important`,
  },
}));
