import React from "react";
import { TableCell } from "@material-ui/core";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FlexBox } from "../../../box";
import { IRowColumn } from "../interface";
import { observer } from "mobx-react-lite";
import { WidgetTypeEnum } from "../../../../../enum";

interface IProps {
  row: Record<string, any>;
  rowCarousel?: Record<string, any>;
  column: IRowColumn;
  components: Record<string, any>;
  setOpen?: (open: boolean) => void;
  open?: boolean;
}

export const BodyColumn: React.FC<IProps> = observer(
  ({ row, rowCarousel, column, components, setOpen, open }) => {
    const componentName = column.componentName ? column.componentName : "";
    const name = column.name ? column.name : column.field || "";

    const renderCarousel = () => {
      switch (name) {
        case "title":
          return (
            <FlexBox items="center" content="flex-start">
              <IconButton
                aria-label="expand row"
                size="small"
                sx={{ marginRight: 2 }}
                onClick={() => setOpen && setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              {row[name]}
            </FlexBox>
          );
        case "type":
          return components[componentName](row);
        case "edit":
          return (
            <TableCell style={{ padding: "16px 0" }}>
              {components[componentName](row, null)}
            </TableCell>
          );
        default:
          return "";
      }
    };

    if (row.type === WidgetTypeEnum.CAROUSEL)
      return <TableCell style={column.style}>{renderCarousel()}</TableCell>;

    if (column.type === "component")
      return (
        <TableCell style={column.style}>
          {components[componentName](row, name === "edit" ? rowCarousel : name)}
        </TableCell>
      );

    return <TableCell style={column.style}>{row[name]}</TableCell>;
  }
);
