import { makeAutoObservable } from "mobx";
import WidgetsService from "../../services/widgets-service";
import {IWidget} from "./widget.types";
import { WidgetTabValue, DropTransactionStatusEnum } from "../../types";
export interface IWidgets {
  length: number;
  data: Array<IWidget>;
}

class WidgetsStore {
  widgetsData: IWidgets = { length: 0, data: [] };
  dropBagged: IWidgets = { length: 0, data: [] };
  dropMissed: IWidgets = { length: 0, data: [] };
  resultsWidget: IWidgets = { length: 0, data: [] };
  isLoading = false;
  isLoadingResults = false;
  isLoadingExport = false;
  constructor() {
    makeAutoObservable(this);
  }

  async setData(
    tab: string,
    row: number,
    page: number,
    sortState: string,
    sortBy: string | null,
    sortType: Array<string>,
    term: string
  ) {
    this.isLoading = true;
    let data: Record<string, any> = {};
    switch (tab) {
      case WidgetTabValue.promotions:
        data = await WidgetsService.getWidgets(
          row,
          page,
          sortState,
          sortBy,
          sortType,
          term
        );
        break;
      case WidgetTabValue.feedOrder:
        data = await WidgetsService.getWidgetsFeedorder(
          row,
          page,
          sortState,
          sortBy,
          sortType,
          term
        );
        break;
      default:
        data = await WidgetsService.getWidgets(
          row,
          page,
          sortState,
          sortBy,
          sortType,
          term
        );
        break;
    }
    this.setWidgets({ length: data.length, data: data.widgets });
    this.isLoading = false;
  }

  async setDropResults(
    location: any,
    status: string,
    row: number,
    page: number,
    sortState: string | null,
    sortBy: string | null,
    setData: (data: Record<string, any>) => void,
  ) {
    this.isLoadingResults = true;
    const id = location?.pathname.split("/")[location.pathname.split("/").length - 1];
    const data: Record<string, any> = await WidgetsService.getDropsResults(
      id,
      row,
      page,
      sortState,
      sortBy,
      status
    );
    this.isLoadingResults = false;
    setData({ length: data.length, data: data.transactions });
    switch (status) {
      case DropTransactionStatusEnum.SUCCESS:
        this.setDropBagged({ length: data.length, data: data.transactions });
        break;
      default:
        this.setDropMissed({ length: data.length, data: data.transactions });
        break;
    }
    this.isLoadingResults = false;
  }

  async setInstantResults(
    location: any,
    row: number,
    page: number,
    sortState: string | null,
    sortBy: string | null,
    setData: (data: Record<string, any>) => void,
  ) {
    this.isLoadingResults = true;
    const id = location?.pathname.split("/")[location.pathname.split("/").length - 1];
    const data: Record<string, any> = await WidgetsService.getInstantResults(
      id,
      row,
      page,
      sortState,
      sortBy,
    );
    this.isLoadingResults = false;
    setData({ length: data.length, data: data.results });
    this.setResultsWidget({ length: data.length, data: data.results });
    this.isLoadingResults = false;
  }

  async exportInstantResults(
    location: any,
    sortState: string | null,
    sortBy: string | null,
    setDataCSV: (data: Array<Array<any>> | string | undefined) => void,
  ) {
    this.isLoadingExport = true;
    const id = location?.pathname.split("/")[location.pathname.split("/").length - 1];
    const data = await WidgetsService.exportInstantResults(
      id,
      sortState,
      sortBy,
    );
    setDataCSV(data);
    this.isLoadingExport = false;
  }

  changeWidgets(arr: any) {
    this.widgetsData.data = arr;
  }

  getWidgets() {
    return this.widgetsData;
  }

  setWidgets(widgets: IWidgets) {
    this.widgetsData = widgets;
  }

  getDropBagged() {
    return this.dropBagged;
  }

  setDropBagged(bagged: IWidgets) {
    this.dropBagged = bagged;
  }

  getDropMissed() {
    return this.dropMissed;
  }

  setDropMissed(missed: IWidgets) {
    this.dropMissed = missed;
  }

  getResultsWidget() {
    return this.resultsWidget;
  }

  setResultsWidget(results: IWidgets) {
    this.resultsWidget = results;
  }
}

export default new WidgetsStore();
