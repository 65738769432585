import { makeStyles } from "@material-ui/core";
import { baseColors } from "../constants/colors/base-colors";

export const useSelectsStyles = makeStyles(() => ({
  formikSelect: {
    "& .MuiSvgIcon-root": {
      transform: "translateX(-10px) !important",
    },
    "&:hover": {
      "& .MuiInput-root": {
        borderBottom: `1px solid ${baseColors.black}`,
      },
    },
    "& .MuiInput-root": {
      borderBottom: "1px solid #808080",
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSelect-select:focus": {
      background: "transparent !important",
    },
    "& .MuiInputLabel-root": {
      color: baseColors.dark + " !important",
    },
  },
  formikSelectWithoutIcon: {
    "& .MuiSvgIcon-root": {
      display: "none",
    },
  },
  errorSelect: {
    "& .MuiInput-root": {
      borderBottom: `1px solid ${baseColors.red} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${baseColors.red} !important`,
    },
  },
  selectMarginTop: {
    "& .MuiInput-root": {
      marginTop: "0px",
    },
  },
  formikDate: {
    "& .MuiInputAdornment-root": {
      transform: "translateX(-11px) !important",
    },
    "&:hover": {
      "& .MuiInputBase-root": {
        borderBottom: `1px solid ${baseColors.black}`,
      },
    },
    "& .MuiInputBase-root": {
      borderBottom: "1px solid #808080",
    },
    "& .MuiInputBase-root:after": {
      display: "none",
    },
    "& .MuiInputBase-root:before": {
      display: "none",
    },
    "& .MuiButtonBase-root": {
      padding: 0,
    },
  },
  errorFormikDate: {
    "& .MuiInput-root": {
      borderBottom: `1px solid ${baseColors.red} !important`,
    },
  },
  timePicker: {
    "& .MuiButtonBase-root": {
      transform: "translateX(-4px)",
    },
  },
  autocomplete: {
    width: "100% !important",
    "& .MuiButtonBase-root.MuiChip-root": {
      background: baseColors.red,
      color: baseColors.white,
    },
    "& .MuiButtonBase-root": {
      // height: "25px",
    },
  },
  autocompleteBorder: {
    width: "100% !important",
    border: `1px solid ${baseColors.black} !important`,
    borderRadius: '5px',
    padding: '5px 10px',
    "& .MuiButtonBase-root.MuiChip-root": {
      background: baseColors.red,
      color: baseColors.white,
    },
    "& .MuiInputBase-root": {
      // height: "25px",
      border: 'none!important'
    },
    "& .MuiButtonBase-root": {
      // height: "25px",
    },
  },
  autocompleteWhite: {
    "& .MuiInput-root": {
      border: `1px solid ${baseColors.white} !important`,
      color: `${baseColors.white} !important`,
      padding: "5px",
    },
  },
  selectSimple: {},
  selectMultiple: {
    border: `1px solid ${baseColors.red}`,
    color: baseColors.red,
  },
  fieldLabel: {
    fontFamily: "'GT America',  sans-serif",
    fontSize: "14px",
    color: baseColors.black,
    fontWeight: 500,
    display: "block",
    marginBottom: "2px",
  },
  fieldLabelSmall: {
    fontFamily: "'GT America',  sans-serif",
    fontSize: "11px",
    color: baseColors.dark,
    fontWeight: 500,
    display: "block",
    marginBottom: "5px",
  },
  selectMenuItem: {
    "&.Mui-selected": {
      background: `${baseColors.red} !important`,
      color: `${baseColors.white} !important`,
    },
  },
  errorText: {
    fontFamily: "'GT America',  sans-serif",
    fontSize: "12px",
    color: baseColors.red,
    fontWeight: 500,
    display: "block",
    marginTop: "5px",
  }
}));
