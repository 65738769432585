import React from "react";
import { useNavBarListStyles } from "./navbar-list-style.hook";
import { NAV_LIST } from "../../../../constants";
import { NavbarListItem } from "../navbar-list-item";
import { INavbarListItem } from "../../interface";

export const NavbarList: React.FC = () => {
  const classes = useNavBarListStyles();

  return (
    <div>
      <ul className={classes.container}>
        {NAV_LIST().map((item: INavbarListItem, index) => (
          <NavbarListItem key={index} item={item} />
        ))}
      </ul>
    </div>
  );
};
