import * as Yup from "yup";
import moment from "moment-timezone";

export const AddCampaignSchema = Yup.object().shape({
  // type: Yup.string().required("Required"),
  prizeTypes: Yup.array().min(1, "min_length_1"),
  name: Yup.string()
    .min(3, "min_length_3")
    .max(50, "max_length_50")
    .required("Required"),
  // offerCodeUrl: Yup.mixed().when(
  //   ["prizeTypes"],
  //   {
  //     is: ['PROMOTION'],
  //     then: Yup.string().required("Required"),
  //   }
  // ),
  prizes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .min(3, "min_length_3")
        .max(50, "max_length_50")
        .required("Required"),
      // subTitle: Yup.string().required("Required"),
      prizeUrl: Yup.string().required("Required"),
      offerCodeUrl: Yup.string().required("Required"),
      qty: Yup.number().required("Required"),
      mediaUrl: Yup.string().required("Required"),
      typeformUrl: Yup.string().required("Required"),
      richText: Yup.string().required("Required"),
      // redemptionInstructions: Yup.string().required("Required"),
    })
  ),

  status: Yup.string().required("Required"),
  // hits: Yup.number().required("Required"),
  misses: Yup.number().min(0, 'min_0').required("Required"),
  category: Yup.string().required("Required"),
  startDateDate: Yup.date().required("Required"),
  startDateTime: Yup.date().required("Required"),
  expirationDateDate: Yup.date()
    .required("Required")
    .when("startDateDate", (startDateDate, schema) => {
      return schema.test({
        test: (expirationDateDate: string) => {
          const expirationDateDateVal = moment(
            moment(expirationDateDate).format("MM/DD/YYYY")
          ).valueOf();
          const startDateDateVal = moment(
            moment(startDateDate).format("MM/DD/YYYY")
          ).valueOf();
          return (
            !expirationDateDate ||
            (!!expirationDateDate && expirationDateDateVal >= startDateDateVal)
          );
        },
      });
    }),
  expirationDateTime: Yup.date()
    .required("Required")
    .when("startDateTime", (startDateTime, schema) => {
      return schema.test({
        test: (expirationDateTime: string) => {
          const expirationDateDateVal = moment(
            moment(expirationDateTime).format("MM/DD/YYYY HH:mm")
          ).valueOf();
          const startDateDateVal = moment(
            moment(startDateTime).format("MM/DD/YYYY HH:mm")
          ).valueOf();
          return (
            !expirationDateTime ||
            (!!expirationDateTime && expirationDateDateVal >= startDateDateVal)
          );
        },
      });
    }),
});
