import { TextField } from "@mui/material";
import styled from "styled-components";
import { baseColors } from "../constants/colors";

export const DefaultInput = styled(TextField)({
  "& .MuiFormControl-root": {
    borderBottom: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #808080",
  },
  "& .MuiInput-root": {
    borderBottom: "1px solid #808080",
    "&:after": {
      display: "none",
    },
    "&:before": {
      display: "none",
    },
    "&:hover": {
      borderBottom: `1px solid ${baseColors.black}`,
    },
  },
  "& .MuiInput-root.Mui-focused": {
    borderBottom: `1px solid ${baseColors.black}`,
  },
  "& .MuiInputLabel-root": {
    color: baseColors.dark + " !important",
    fontSize: "16px",
    fontFamily: "'GT America Bold', sans-serif",
  },
});
