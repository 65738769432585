import React from "react";
import { InterestAssets } from "../interest-assets";
import { GeneralSettings } from "./general-settings";
import { FormikSelect } from "../../../../components/wilson-ui/selects";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { useIntl } from "react-intl";
import { InterestTypeValue, InterestValueType, InterestTypeOptions } from "../../../../enum";

interface ISettings {
  form: Record<string, any>;
}

export const Settings: React.FC<ISettings> = ({ form }) => {
  const createWidgetClasses = useCreateWidgetsStyles();
  const intl = useIntl();

  const onSelectType = (value: any) => {
    const option = value.target;
    form.setValues({ ...form.values, type: InterestTypeValue[option.value] });
  }

  return (
    <FlexBox
      direction="column"
      gap="70px"
      className={createWidgetClasses.sectionContainer}
    >
      <div style={{ padding: '0 16px' }}>
        <GridBox columns="1fr 1fr" cg="30px" rg="30px">
          <FormikSelect
            {...{ form }}
            name="type"
            value={form.values.type ? InterestValueType[form.values.type]: ''}
            options={InterestTypeOptions}
            label={intl.formatMessage({ id: "type" })}
            placeholder={intl.formatMessage({ id: "select_a_type" })}
            type="outlined"
            showIcon={true}
            handleChange={onSelectType}
          />
        </GridBox>
      </div>
      <GeneralSettings {...{ form }} />
      <InterestAssets {...{ form }} />
    </FlexBox>
  );
};
