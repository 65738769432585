import React from "react";
import { NavLink } from "react-router-dom";
import { INavbarListItem } from "../../interface";

interface IProps {
  item: INavbarListItem;
}

export const NavbarListItem: React.FC<IProps> = ({ item }) => {
  const path = window.location.pathname;
  const itemClass = item.link === path ? "active" : "";

  return (
    <li className={itemClass} key={Math.random().toString().substr(2)}>
      <NavLink to={item.link}>{item.name}</NavLink>
    </li>
  );
};
