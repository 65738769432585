import React from "react";
import { UiModal } from "../../../../components/wilson-ui/modals";
import { useFormik } from "formik";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { useIntl } from "react-intl";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { useAddCarouselStyles } from "./add-carousel-modal-styles.hook";
import { Text } from "../../../../components/wilson-ui/text";
import { FormikColorField } from "../../../../components/wilson-ui/inputs";
import { WidgetStatus } from "../../../../types";
import { FormikSelect } from "../../../../components/wilson-ui/selects";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import {
  createNewCarouseRequest,
  editCarouseRequest,
} from "../../../../actions/carousel";
import { toast } from "react-toastify";
import CarouselStore from "../../../../store/carousel/corousel.store";

interface IProps {
  toggleModal: () => void;
  activeCarousel: Record<string, any> | null;
  page: number;
}

export const AddCarouselModal: React.FC<IProps> = ({
  toggleModal,
  activeCarousel,
  page,
}) => {
  const notify = (text: string) => toast(text);
  const intl = useIntl();
  const formInitialValues = {
    title: activeCarousel?.title || "",
    titleColor: activeCarousel?.titleColor || "",
    backgroundColor: activeCarousel?.backgroundColor || "",
    status: activeCarousel?.status || Object.values(WidgetStatus)[0],
    id: activeCarousel?.id || null,
  };

  const carouselForm = useFormik({
    initialValues: formInitialValues,
    onSubmit: (values) =>
      activeCarousel ? editCarouselHandler(values) : sendFormHandler(values),
  });

  const sendFormHandler = async (values: Record<string, any>) => {
    const isCreated = await createNewCarouseRequest(values);

    if (isCreated) {
      await CarouselStore.getCarouselsDataRequest(page);
      toggleModal();
    } else {
      notify("some error");
    }
  };

  const editCarouselHandler = async (values: Record<string, any>) => {
    const isEdited = await editCarouseRequest(values);

    if (isEdited) {
      await CarouselStore.getCarouselsDataRequest(page);
      CarouselStore.resetCarouselData();
      toggleModal();
    } else {
      notify("some error");
    }
  };

  const classes = useAddCarouselStyles();

  const submitButtonText = !activeCarousel
    ? intl.formatMessage({ id: "add" })
    : intl.formatMessage({ id: "save" });

  const titleText = !activeCarousel
    ? intl.formatMessage({ id: "add_carousel" })
    : intl.formatMessage({ id: "edit_carousel" });

  return (
    <UiModal width="800px" closeHandler={toggleModal}>
      <div className={classes.container}>
        <GridBox cg="30px" rg="30px">
          <Text fontSize={"32px"}>{titleText}</Text>

          <FormikField
            label={intl.formatMessage({ id: "title" })}
            name="title"
            submit={carouselForm.handleChange}
            placeholder={intl.formatMessage({ id: "title" })}
            value={carouselForm.values.title}
            form={carouselForm}
          />
          <GridBox columns="1fr 1fr" cg="30px" rg="30px">
            <FormikColorField
              label={intl.formatMessage({ id: "title_color" })}
              name="titleColor"
              value={carouselForm.values.titleColor}
              form={carouselForm}
            />
            <FormikColorField
              label={intl.formatMessage({ id: "background_color" })}
              name="backgroundColor"
              value={carouselForm.values.backgroundColor}
              form={carouselForm}
            />
          </GridBox>
          <FormikSelect
            form={carouselForm}
            name="status"
            value={carouselForm.values.status}
            options={Object.values(WidgetStatus)}
            placeholder={intl.formatMessage({ id: "set_status" })}
          />

          <FlexBox content="flex-end" cg="20px">
            <UiButton buttonType="white" onClick={() => toggleModal()} style={{ marginRight: 8 }}>
              {intl.formatMessage({ id: "cancel" })}
            </UiButton>
            <UiButton
              buttonType="black"
              onClick={() => carouselForm.submitForm()}
            >
              {submitButtonText}
            </UiButton>
          </FlexBox>
        </GridBox>
      </div>
    </UiModal>
  );
};
