import React, { useState } from "react";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { FormikColorField } from "../../../../components/wilson-ui/inputs";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { Dropzone } from "../../../../components/wilson-ui/dropzone";
import { RadioCheckboxRed } from "../../../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal } from "../modals";
import { useIntl } from "react-intl";
import image from "../../../../assets/images/successAssers.png";
import {
  sendWidgetPromotionCollaborationImage,
  sendWidgetPromotionImage,
  sendWidgetPromotionModalImage,
  sendWidgetPromotionModalBackgroundImage
} from "../../../../actions/widgets";
import { Accordion } from "../accordion";

const NAMES = ["Button Text", "Button Color", "Image"];

interface IProps {
  form: Record<string, any>;
}

export const SuccessAssets: React.FC<IProps> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "success_assets" })}
      nameArray={NAMES}
      image={image}
      maxHeight="375px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  const sendPromotionModalBackgroundImageHandler = async (file: Blob | string) => {
    const imageURL = await sendWidgetPromotionModalBackgroundImage(file);
    form.setFieldValue("promotionsModalBackgroundImage", imageURL);
  };

  const sendPromotionImageHandler = async (file: Blob | string) => {
    const imageURL = await sendWidgetPromotionImage(file);
    form.setFieldValue("successAssetsButtonImage", imageURL);
  };

  const sendDataCollaborationImageHandler = async (file: Blob | string) => {
    const imageURL = await sendWidgetPromotionCollaborationImage(file);
    form.setFieldValue("promotionsCollaborationImage", imageURL);
  };

  const sendPromotionModalImageHandler = async (file: Blob | string) => {
    const imageURL = await sendWidgetPromotionModalImage(file);
    form.setFieldValue("promotionsModalImage", imageURL);
  };

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "success_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox columns="1fr 1fr 1fr 1fr">
            <RadioCheckboxRed
              value={form.values.promotionsModalVisibility}
              label={intl.formatMessage({ id: "promotion_modal_visibility" })}
              labelFirst={true}
              submit={(event, value) =>
                form.setFieldValue("promotionsModalVisibility", value)
              }
              name="promotionsModalVisibility"
            />
          </GridBox>
          <GridBox columns="1fr 1fr 1fr" cg="30px" rg="30px">
            <FormikField
                label={intl.formatMessage({ id: "button_text" })}
                name="successAssetsButtonText"
                submit={form.handleChange}
                placeholder={intl.formatMessage({ id: "add_button_text" })}
                value={form.values.successAssetsButtonText}
                {...{ form }}
            />

            <FormikColorField
                label={intl.formatMessage({ id: "button_color" })}
                name="successAssetsButtonColor"
                value={form.values.successAssetsButtonColor}
                {...{ form }}
            />

            <FormikColorField
                label={intl.formatMessage({ id: "button_text_color" })}
                name="successAssetsButtonTextColor"
                value={form.values.successAssetsButtonTextColor}
                {...{ form }}
            />
          </GridBox>
          <FormikColorField
            label={intl.formatMessage({ id: "promotion_background_color" })}
            name="promotionsBackgroundColor"
            value={form.values.promotionsBackgroundColor}
            {...{ form }}
          />
          <GridBox columns="1fr 1fr" cg="30px" rg="30px">
            <FormikField
              label={intl.formatMessage({ id: "promotion_title" })}
              name="promotionsTitle"
              submit={form.handleChange}
              placeholder={intl.formatMessage({
                id: "add_promotion_title",
              })}
              value={form.values.promotionsTitle}
              {...{ form }}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "promotion_title_color" })}
              name="promotionsTitleColor"
              value={form.values.promotionsTitleColor}
              {...{ form }}
            />
          </GridBox>

          <GridBox columns="1fr 1fr" cg="30px" rg="30px">
            <FormikField
              label={intl.formatMessage({ id: "promotion_description" })}
              name="promotionsDescription"
              submit={form.handleChange}
              placeholder={intl.formatMessage({
                id: "add_promotion_description",
              })}
              value={form.values.promotionsDescription}
              {...{ form }}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "promotion_description_color" })}
              name="promotionsDescriptionColor"
              value={form.values.promotionsDescriptionColor}
              {...{ form }}
            />
          </GridBox>
          {form.values.promotionsModalVisibility ? (
            <>
              <GridBox columns="1fr 1fr" cg="30px" rg="30px">
                <FormikField
                  label={intl.formatMessage({ id: "promotion_modal_title" })}
                  name="promotionsModalTitle"
                  submit={form.handleChange}
                  placeholder={intl.formatMessage({
                    id: "add_promotion_modal_title",
                  })}
                  value={form.values.promotionsModalTitle}
                  {...{ form }}
                />

                <FormikColorField
                  label={intl.formatMessage({
                    id: "promotion_modal_title_color",
                  })}
                  name="promotionsModalTitleColor"
                  value={form.values.promotionsModalTitleColor}
                  {...{ form }}
                />
              </GridBox>
              <FormikColorField
                label={intl.formatMessage({
                  id: "promotion_modal_background_color",
                })}
                name="promotionsModalBackgroundColor"
                value={form.values.promotionsModalBackgroundColor}
                {...{ form }}
              />
              <div>
                <h4>{intl.formatMessage({ id: "promotion_modal_background_image" })}</h4>
                <Dropzone
                  sendData={sendPromotionModalBackgroundImageHandler}
                  {...{ form }}
                  name="promotionsModalBackgroundImage"
                  imagePreview={form.values.promotionsModalBackgroundImgLink}
                />
              </div>
            </>
          ): null}
          <div>
            <h4>{intl.formatMessage({ id: "main_promotion_image" })}</h4>
            <Dropzone
              sendData={sendPromotionImageHandler}
              {...{ form }}
              name="successAssetsButtonImage"
              imagePreview={form.values.successAssetsMediaLink}
            />
          </div>
          <div>
            <h4>{intl.formatMessage({ id: "collaboration_image" })}</h4>
            <Dropzone
              sendData={sendDataCollaborationImageHandler}
              {...{ form }}
              name="promotionsCollaborationImage"
              imagePreview={form.values.promotionCollaborationImgLink}
            />
          </div>
          {form.values.promotionsModalVisibility ? (
            <div>
              <h4>{intl.formatMessage({ id: "modal_box_image" })}</h4>
              <Dropzone
                sendData={sendPromotionModalImageHandler}
                {...{ form }}
                name="promotionsModalImage"
                imagePreview={form.values.promotionModalImgLink}
              />
            </div>
          ): null}
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
