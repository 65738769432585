import { makeStyles } from "@material-ui/core";

export const useTextSortStyles = makeStyles(() => ({
  textSort: {
    "& > div": {
      height: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > span": {
        textTransform: "uppercase",
        fontSize: "10px",
      },
      "& svg": {
        transform: "scale(0.8)",
        marginLeft: "-3px",
      },
    },
  },
}));
