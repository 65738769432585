import { ChangeEvent, Dispatch } from "react";
import { WidgetTypeEnum } from "../../enum";
import { setWidgetFeedMedia, setWidgetStoryImage } from "../../actions/widgets";

type ChangeSwipeItemType = (
  id: string,
  file: string,
  preview: string,
  type: string
) => void;

export const onSelectFile = async (
  event: ChangeEvent<HTMLInputElement>,
  isLoading: boolean,
  setIsLoading: Dispatch<any>,
  changeImage: ChangeSwipeItemType,
  widgetType:
    | WidgetTypeEnum.POST
    | WidgetTypeEnum.STORY
    | WidgetTypeEnum.PROMOTION
    | WidgetTypeEnum.INSTANT_WIN
): Promise<void> => {
  if (isLoading) return;

  try {
    setIsLoading(true);

    if (!event.target.files || event.target.files.length < 1) {
      changeImage(event.target.name, "", "", "");
      return;
    }

    let image: string;
    const type = event.target.files[0].type.split("/")[0];

    if (widgetType === WidgetTypeEnum.STORY) {
      image = await setWidgetStoryImage(event.target.files[0], type);
    } else {
      image = await setWidgetFeedMedia(event.target.files[0], type);
    }

    const objectUrl = URL.createObjectURL(event.target.files[0]);

    changeImage(event.target.name, image, objectUrl, type);
  } catch (e) {
    console.warn(e);
  } finally {
    setIsLoading(false);
  }
};
