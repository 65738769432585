import React from "react";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { Dropzone } from "../../../../components/wilson-ui/dropzone";
import { QuestionIcon } from "../../../../components/icons";
// import { ItemQuestionModal } from "../modals";
import { useIntl } from "react-intl";
// import image from "../../../../assets/images/successAssers.png";
import { axiosAuthorized } from "../../../../axios";
import { EMPTY_FUNCTION } from "../../../../constants";
import { Accordion } from "../accordion";

// const NAMES = ["Button Text", "Button Color", "Image"];

interface IProps {
  form: Record<string, any>;
}

export const InterestAssets: React.FC<IProps> = ({ form }) => {
  // const [isModalTrue, setIsModalTrue] = useState<boolean>(false);

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => false;

  // const modal = isModalTrue ? (
  //   <ItemQuestionModal
  //     title={intl.formatMessage({ id: "interest_assets" })}
  //     nameArray={NAMES}
  //     image={image}
  //     maxHeight="375px"
  //     closeHandler={toggleModal}
  //   />
  // ) : (
  //   <></>
  // );

  const sendData = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "user-interests/mediaIterest",
        formData
      );
      form.setFieldValue("mediaUrl", data.data.mediaUrl);
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "interest_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <FlexBox
          className={classes.accordionBody}
          direction="column"
          gap="30px"
        >
          <Dropzone
            {...{ form, sendData }}
            name="mediaUrl"
            imagePreview={form.values.mediaLink}
          />
        </FlexBox>
      </Accordion>
      {/* {modal} */}
    </div>
  );
};
