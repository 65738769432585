import { makeStyles } from "@material-ui/core";
import { baseColors } from "../constants/colors/base-colors";

export const useRadioToggleStyles = makeStyles(() => ({
  redRadio: {
    color: "#bbbbbb !important",
  },
  redRadioActive: {
    color: baseColors.red + " !important",
    "& ~ span": {
      color: baseColors.black + " !important",
    },
  },
  redRadioLabel: {
    color: "#bbbbbb !important",
    transition: "all .3s ease",
  },
}));
