import { sumBy, isEmpty } from 'lodash';
import { parseDateTime } from "../../../../utils/utils";
import { editCampaign, sendCampaign } from "../../../../actions/campaigns";

export const sendFormHelper = async (
  sendForm: Record<string, any>
): Promise<any> => {
  const sendData: Record<string, any> = {};

  const startDateTime = parseDateTime(sendForm.startDateDate, sendForm.startDateTime);
  sendData.startDate = startDateTime.date;
  sendData.startTime = startDateTime.time;
  if (sendForm.expirationDateDate) {
    const expirationDateTime = parseDateTime(sendForm.expirationDateDate, sendForm.expirationDateTime);

    sendData.expirationDate = expirationDateTime.date;
    sendData.expirationTime = expirationDateTime.time;
  }
  sendData.name = sendForm.name;
  sendData.webViewUrl = sendForm.webViewUrl || '';
  sendData.offerCodeUrl = sendForm?.offerCodeUrl || ' ';
  // sendData.codes = sendForm?.codes || [];
  sendData.qty = 0;
  sendData.status = sendForm.status.toUpperCase();
  sendData.type = sendForm?.type?.toUpperCase() || 'CAMPAIGN';
  sendData.category = sendForm.category;
  sendData.hits = !isEmpty(sendForm.prizes) ? sumBy(sendForm.prizes, (prize: any) => prize.qty): sendForm.hits;
  sendData.misses = sendForm.misses;
  sendData.prizes = sendForm.prizes.map((prize: any, index: number) => ({
    id: prize.noId ? undefined: prize?.id || undefined,
    priority: index + 1,
    type: prize.type,
    status: 'ACTIVE',
    name: prize.name,
    subTitle: prize?.subTitle || '',
    qty: prize?.qty || 0,
    prizeUrl: prize?.prizeUrl || '',
    offerCodeUrl: prize?.offerCodeUrl || undefined,
    codes: prize?.codes || undefined,
    mediaUrl: prize.mediaUrl,
    typeformUrl: prize.typeformUrl,
    richText: prize.richText,
    redemptionInstructions: prize?.redemptionInstructions || '',
  }));
  sendData.id = sendForm.id;

  if (sendData.id) {
    return editCampaign(sendData);
  }
  return sendCampaign(sendData);
};
