import React from "react";
import { PinnedAssets } from "../pinned-assets";
import { LockerAssets } from "../locker-assets";
import { DetailsAssetsInstantWin } from "../details-assets-instant-win";
import { FavoriteThumbnail } from "../favorite-thumbnail";

interface IProps {
  form: Record<string, any>;
}

export const SettingInstantWin: React.FC<IProps> = ({ form }) => {
  return (
    <>
      <PinnedAssets {...{ form }} />
      <DetailsAssetsInstantWin {...{ form }} />
      <LockerAssets {...{ form }} />
      <FavoriteThumbnail {...{ form }} />
    </>
  );
};
