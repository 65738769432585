import React from "react";
import { FlexBox } from "../../wilson-ui/box";
import { useQuestionIconStyles } from "./question-icon-styles.hook";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface IProps {
  submit: () => void;
}

export const QuestionIcon: React.FC<IProps> = ({ submit }) => {
  const classes = useQuestionIconStyles();

  return (
    <FlexBox
      items="center"
      content="center"
      className={classes.container}
      onClick={submit}
    >
      <HelpOutlineIcon />
    </FlexBox>
  );
};
