import React from "react";
import { FlexBox, GridBox } from "../../../../../components/wilson-ui/box";
import { useItemQuestionFooterStyles } from "./items-question-footer-styles.hook";

interface IProps {
  titleArray: Array<string>;
}

export const ItemQuestionFooter: React.FC<IProps> = ({ titleArray }) => {
  const classes = useItemQuestionFooterStyles();

  return (
    <GridBox
      items="center"
      col={titleArray.length}
      className={classes.container}
    >
      {titleArray.map((title, index) => (
        <FlexBox items="center" gap="5px" key={index}>
          <FlexBox className={classes.itemContainer}>{index + 1}</FlexBox>
          <span>{title}</span>
        </FlexBox>
      ))}
    </GridBox>
  );
};
