import React from "react";

interface IProps {
  minHeight?: string;
}

export const LayoutWrap: React.FC<IProps> = ({ minHeight, children }) => {
  const height = minHeight ? minHeight : "200px";

  return <div style={{ minHeight: height }}>{children}</div>;
};
