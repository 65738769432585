import React from "react";
import { TextSort } from "../text-sort";
import { TableCell } from "@material-ui/core";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { DataSort } from "../data-sort";

interface IProps {
  type?: string;
  title?: string;
  name?: string;
  style?: React.CSSProperties;
  isSortType?: boolean;
  sortSubmit?: (title: string) => void;
  sortTypeSubmit?: () => void;
}

export const HeaderColumn: React.FC<IProps> = ({
  type,
  title,
  sortSubmit,
  name,
  isSortType,
  sortTypeSubmit,
  style,
}) => {
  if (type === "text")
    return (
      <TableCell
        style={style}
        onClick={() => sortSubmit && name && sortSubmit(name)}
      >
        <h4>{title}</h4>
        <TextSort />
      </TableCell>
    );

  if (type === "left")
    return (
      <TableCell
        style={style}
        onClick={() => sortSubmit && name && sortSubmit(name)}
      >
        <ArrowUpwardOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
        <h4>{title}</h4>
      </TableCell>
    );

  if (type === "right")
    return (
      <TableCell
        style={style}
        onClick={() => sortSubmit && name && sortSubmit(name)}
      >
        <h4>{title}</h4>
        <ArrowUpwardOutlinedIcon fontSize="small" />
      </TableCell>
    );

  if (type === "filter")
    return (
      <TableCell style={style}>
        <h4>{title}</h4>
        {isSortType && (
          <FilterAltOutlinedIcon
            fontSize="small"
            sx={{ marginRight: 1 }}
            onClick={sortTypeSubmit}
          />
        )}
      </TableCell>
    );

  if (type === "data")
    return (
      <TableCell
        style={style}
        onClick={() => sortSubmit && name && sortSubmit(name)}
      >
        <h4>{title}</h4>
        <DataSort />
      </TableCell>
    );

  return (
    <TableCell style={style}>
      <h4>{title}</h4>
    </TableCell>
  );
};
