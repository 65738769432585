import { editInterest, sendInterest } from "../../../../actions/usersInterests";

export const sendFormHelper = async (
  sendForm: Record<string, any>
): Promise<any> => {
  const sendData: Record<string, any> = {};

  sendData.name = sendForm.name;
  sendData.mediaUrl = sendForm.mediaUrl;
  sendData.status = sendForm.status.toUpperCase();
  sendData.type = sendForm.type.toUpperCase();
  sendData.category = sendForm.category;
  sendData.id = sendForm.id;

  if (sendData.id) {
    return editInterest(sendData);
  }
  return sendInterest(sendData);
};
