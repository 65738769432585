export const channelsWrappers = {
  NBALogoBasketball: {
    name: "NBALogo Basketball",
    background:
      "linear-gradient(0deg, rgba(207, 16, 45, 0.08), rgba(207, 16, 45, 0.08)), rgba(238, 155, 56, 0.08)",
    color: "#CF102D",
  },

  WNBALogoBasketball: {
    name: "WNBALogo Basketball",
    background:
      "linear-gradient(0deg, rgba(255, 107, 0, 0.08), rgba(255, 107, 0, 0.08))",
    color: "#D45900",
  },

  evolutionBasketball: {
    name: "Evolution Basketball",
    background: "rgba(128, 92, 145, 0.5)",
    color: "#5e5811",
  },

  NFLLetteringFootball: {
    name: "NFLLettering Football",
    background: "rgba(176, 162, 95, 0.5)",
    color: "#465918",
  },

  FinalFourMen22Basketball: {
    name: "FinalFourMen22 Basketball",
    background: "rgba(176, 162, 95, 0.5)",
    color: "#465918",
  },

  FinalFourWomen22Basketball: {
    name: "FinalFourWomen22 Basketball",
    background: "rgba(176, 162, 95, 0.5)",
    color: "#465918",
  },

  NCAALogoBasketball: {
    name: "NCAALogo Basketball",
    background: "rgba(176, 162, 95, 0.5)",
    color: "#465918",
  },

  evoNXTBasketball: {
    name: "EvoNXT Basketball",
    background:
      "linear-gradient(0deg, rgba(125, 75, 0, 0.08), rgba(125, 75, 0, 0.08))",
    color: "#7D4B00",
  },

  NBA75Basketball: {
    name: "NBA75 Basketball",
    background: "rgba(70, 176, 21, 0.5)",
    color: "#1a2b11",
  },
  SuperBowl56Football: {
    name: "NFLShield Football",
    background: "rgba(28, 55, 186, 0.5)",
    color: "#532d33",
  },
  NFLShieldFootball: {
    name: "SuperBowl56 Football",
    background: "rgba(290, 30, 186, 0.5)",
    color: "#594332",
  },

  GSTFootball: {
    name: "GST Football",
    background: "rgba(290, 30, 186, 0.5)",
    color: "#594332",
  },
  GSTPrimeFootball: {
    name: "GSTPrime Football",
    background: "rgba(290, 30, 186, 0.5)",
    color: "#594332",
  },
  OmegaFootball: {
    name: "Omega Football",
    background: "rgba(290, 30, 186, 0.5)",
    color: "#594332",
  },
};
