import { makeStyles } from "@material-ui/core";
import { mediaQuery } from "../../../../components/wilson-ui/constants/media";

export const useSubmissionsHeaderStyles = makeStyles(() => ({
  buttons: {
    gap: "10px",
    [mediaQuery.maxWidth550]: {
      flexDirection: "column",
    },
  },
}));
