import React, { useState } from "react";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { FormikColorField } from "../../../../components/wilson-ui/inputs";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { Dropzone } from "../../../../components/wilson-ui/dropzone";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal } from "../modals";
import { useIntl } from "react-intl";
import { axiosAuthorized } from "../../../../axios";
import image from "../../../../assets/images/successAssers.png";
import { Accordion } from "../accordion";
import { EMPTY_FUNCTION } from "../../../../constants";

const NAMES = ["Button Text", "Button Color", "Image"];

interface IProps {
  form: Record<string, any>;
}

export const SuccessAssets: React.FC<IProps> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "success_assets" })}
      nameArray={NAMES}
      image={image}
      maxHeight="375px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  const sendData = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "widgets/feedMedia",
        formData
      );
      form.setFieldValue("successMediaUrl", data.data.feedMedia);
      form.setFieldValue("successMediaLink", data.data.feedMedia);
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "success_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox columns="1fr 1fr" cg="30px" rg="30px">
            <FormikField
              label={intl.formatMessage({ id: "primary_title" })}
              name="successTitle"
              submit={form.handleChange}
              placeholder={intl.formatMessage({
                id: "add_promotion_title",
              })}
              value={form.values.successTitle}
              {...{ form }}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "title_color" })}
              name="successTitleColor"
              value={form.values.successTitleColor}
              {...{ form }}
            />
          </GridBox>

          <GridBox columns="1fr 1fr" cg="30px" rg="30px">
            <FormikField
              label={intl.formatMessage({ id: "description" })}
              name="successDescription"
              submit={form.handleChange}
              placeholder={intl.formatMessage({
                id: "description",
              })}
              value={form.values.successDescription}
              {...{ form }}
              multiline
              rows={2}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "description_color" })}
              name="successDescriptionColor"
              value={form.values.successDescriptionColor}
              {...{ form }}
            />
          </GridBox>
          <GridBox columns="1fr" cg="30px" rg="30px">
            <FormikColorField
              label={intl.formatMessage({ id: "background_color" })}
              name="successBackgroundColor"
              value={form.values.successBackgroundColor}
              {...{ form }}
            />
          </GridBox>

          <div>
            <h4>{intl.formatMessage({ id: "primary_image" })}</h4>
            <Dropzone
              {...{ form, sendData }}
              name="successMediaUrl"
              imagePreview={form.values.successMediaLink}
            />
          </div>
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
