import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../../components/wilson-ui/constants/colors";

export const itemQuestionModalStyles = makeStyles(() => ({
  header: {
    background: baseColors.black,
    color: baseColors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h4": {
      fontFamily: "'GT America Bold', sans-serif",
      fontWeight: 700,
      fontSize: "21px",
      color: baseColors.white,
    },
  },
  content: {
    overflowY: "scroll",
    // padding: "30px",
  },
  tabsRoot: {
    borderBottom: "1px solid #d2d2d2",
    "& .MuiTabs-indicator": {
      backgroundColor: "#202020 !important",
    },
  },
  tabRoot: {
    "&.MuiTab-root": {
      fontSize: "20px !important",
      textTransform: "inherit !important",
    },
    "&.Mui-selected": {
      fontWeight: "bold",
      color: "#202020 !important",
    },
  },
  tabPanel: {
    padding: "24px 0px !important",
  },
}));
