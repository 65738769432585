import React, { useEffect, useState } from "react";

import { useAdditionalSettingsStyles } from "./additional-settings-styles.hook";
import { AdditionalItem } from "../additional-item";
import {
  FormikData,
  FormikSelect,
  FormikTime,
} from "../../../../components/wilson-ui/selects";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { CheckboxRed } from "../../../../components/wilson-ui/checkboxes";
import { AdditionChannelsItem } from "../addition-channels-item/addition-channels-item";
import { useIntl } from "react-intl";
import { WidgetStatus, WidgetStatusForCreateWidget, DropStatusEnum, DropStatusLable } from "../../../../types";
import { getChannels, removeWidget } from "../../../../actions/widgets";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { toast } from "react-toastify";
import { ConfirmModal } from "../../../submissions/components/confirm-modal";
import WidgetsStore from "../../../../store/widgets/widgets.store";
import { useParams, useHistory } from "react-router-dom";

interface IAdditionalSettings {
  form: Record<string, any>;
}

export const AdditionalSettingsDrop: React.FC<IAdditionalSettings> = ({ form }) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isDeleteWidgetModalShow, setIsDeleteWidgetModalShow] = useState(false);
  const toggleModal = () =>
    setIsDeleteWidgetModalShow(!isDeleteWidgetModalShow);
  const [offset, setOffset] = useState<number>(0);
  const [chanelsOptions, setChannels] = useState<Array<Record<string, any>>>(
    []
  );

  const classes = useAdditionalSettingsStyles();
  const intl = useIntl();
  const divRef = React.useRef<HTMLDivElement>(null);
  const fixedBlockClass = offset > 80 ? classes.containerOffsetActive : "";
  // const offsetLeft = divRef.current ? divRef.current.offsetLeft : "auto";

  const logit = () => {
    setOffset(window.scrollY);
  };

  const notifyAllField = () => {
    console.log("form.errors", form.errors);
    // console.log("form.values", form.values);
    const formErrorsArray = Object.values(form.errors);
    const invalidFormatErrors = formErrorsArray.filter(
      (error) => error === "must_correspond"
    );

    const invalidFormatDateErrors = formErrorsArray.filter(
      (error: any) => error && error?.includes("Invalid Date")
    );

    if (invalidFormatDateErrors.length) {
      toast(
        intl.formatMessage({
          id: "please_fill_in_all_date_fields_in_the_correct_format_date",
        })
      );
    }

    if (invalidFormatErrors.length) {
      toast(
        intl.formatMessage({
          id: "please_fill_in_all_fields_in_the_correct_format",
        })
      );
    }
    if (invalidFormatErrors.length !== formErrorsArray.length) {
      return toast(
        intl.formatMessage({ id: "please_fill_in_all_required_fields" })
      );
    }
  };

  const submitHandler = () => {
    if (form.touched && !form.isValid) notifyAllField();
    form.handleSubmit();
  };

  const removeWidgetHandler = async (id: string) => {
    WidgetsStore.isLoading = true;
    await removeWidget(id);
    WidgetsStore.isLoading = false;
    history.push("/widgets");
    toggleModal();
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  useEffect(() => {
    const sendData = async () => {
      const newChannelsArray = await getChannels();
      setChannels(newChannelsArray);
    };
    sendData();
  }, []);

  return (
    <div ref={divRef}>
      <div className={`${fixedBlockClass} ${classes.container}`}>
        <AdditionalItem title={intl.formatMessage({ id: "status" })}>
          <FormikSelect
            {...{ form }}
            name="status"
            value={
              form.values.status === WidgetStatusForCreateWidget.hidden
                ? WidgetStatusForCreateWidget.hidden
                : form.values.status ===
                    WidgetStatusForCreateWidget.onTheBench ||
                  form.values.status === WidgetStatus.onTheBench
                ? WidgetStatusForCreateWidget.onTheBench
                : WidgetStatusForCreateWidget.itsAGameTime
            }
            options={Object.values(WidgetStatusForCreateWidget)}
            placeholder={intl.formatMessage({ id: "set_status" })}
          />
        </AdditionalItem>
      </div>
      <div className={`${fixedBlockClass} ${classes.container}`}>
        <h3>{intl.formatMessage({ id: "feed" })}</h3>
        <AdditionalItem title={intl.formatMessage({ id: "start_expiration_date" })}>
          <FlexBox direction="row" gap="10px">
            <FormikData
              {...{ form }}
              time={form.values.startDateTime}
              value={form.values.startDateDate}
              name="startDateDate"
              nameTime="startDateTime"
            />
            <FormikData
              {...{ form }}
              time={form.values.expirationDateTime}
              value={form.values.expirationDateDate}
              name="expirationDateDate"
              nameTime="expirationDateTime"
            />
          </FlexBox>
        </AdditionalItem>
        <AdditionalItem title={intl.formatMessage({ id: "start_expiration_time" })}>
          <FlexBox direction="row" gap="10px">
            <FormikTime
              {...{ form }}
              date={form.values.startDateDate}
              value={form.values.startDateTime}
              name="startDateTime"
              label={`${intl.formatMessage({
                id: "start_time",
              })}`}
            />
            <FormikTime
              {...{ form }}
              date={form.values.expirationDateDate}
              value={form.values.expirationDateTime}
              name="expirationDateTime"
              label={`${intl.formatMessage({
                id: "expiration_time",
              })}`}
            />
          </FlexBox>
        </AdditionalItem>
        <h3 style={{ marginTop: '30px' }}>{intl.formatMessage({ id: "drop" })}</h3>
        <AdditionalItem title={intl.formatMessage({ id: "live_date" })}>
            <FormikData
              {...{ form }}
              time={form.values.liveDateTime}
              value={form.values.liveDateDate}
              name="liveDateDate"
              nameTime="liveDateTime"
            />
          </AdditionalItem>
          <AdditionalItem title={intl.formatMessage({ id: "live_date" })}>
            <FormikTime
              {...{ form }}
              date={form.values.liveDateDate}
              value={form.values.liveDateTime}
              name="liveDateTime"
              label={`${intl.formatMessage({
                id: "start_time",
              })}`}
            />
        </AdditionalItem>
        <AdditionalItem
          title={intl.formatMessage({ id: "trigger_fullscreen_gate" })}
        >
          <FlexBox direction="column" gap="10px">
            <FormikField
              label={`${intl.formatMessage({
                id: "number_of_hours_before_drop",
              })}`}
              isSmallLabel={true}
              name="hoursBeforeDrop"
              type="number"
              submit={form.handleChange}
              value={form.values.hoursBeforeDrop}
              {...{ form }}
              placeholder={intl.formatMessage({
                id: "number_of_hours",
              })}
            />
          </FlexBox>
        </AdditionalItem>
        <AdditionalItem title={intl.formatMessage({ id: "channels" })}>
          <FlexBox direction="column" gap="10px">
            {chanelsOptions.map(
              (item: Record<string, any>, index: number) => (
                <AdditionChannelsItem key={index} {...{ form }} item={item} />
              )
            )}
          </FlexBox>
        </AdditionalItem>
        <AdditionalItem title={intl.formatMessage({ id: "crawler_settings" })}>
          <FlexBox direction="column" gap="10px">
            <FlexBox items="center" gap="10px" draggable={true}>
              <CheckboxRed 
                onChange={() => form.setFieldValue('dropStatus', DropStatusEnum.BUY_NOW)} 
                checked={form.values.dropStatus === DropStatusEnum.BUY_NOW} 
              />
              <span>{DropStatusLable.BUY_NOW}</span>
            </FlexBox>
            <FlexBox items="center" gap="10px" draggable={true}>
              <CheckboxRed 
                onChange={() => form.setFieldValue('dropStatus', DropStatusEnum.SOLD_OUT)} 
                checked={form.values.dropStatus === DropStatusEnum.SOLD_OUT} 
              />
              <span>{DropStatusLable.SOLD_OUT}</span>
            </FlexBox>
            <FlexBox items="center" gap="10px" draggable={true}>
              <CheckboxRed 
                onChange={() => form.setFieldValue('dropStatus', DropStatusEnum.AUTOMATIC)} 
                checked={form.values.dropStatus === DropStatusEnum.AUTOMATIC} 
              />
              <span>{DropStatusLable.AUTOMATIC}</span>
            </FlexBox>
          </FlexBox>
        </AdditionalItem>
      </div>
      <GridBox columns="1fr 2fr" cg="10px">
        <UiButton
          type="button"
          buttonType="white"
          onClick={toggleModal}
          disabled={form.isSubmitting || !id}
          className={classes.deleteButton}
        >
          {intl.formatMessage({ id: "delete" })}
        </UiButton>
        <UiButton
          buttonType="black"
          onClick={submitHandler}
          disabled={form.isSubmitting}
          className={classes.saveButton}
        >
          {intl.formatMessage({ id: "save" })}
        </UiButton>
      </GridBox>
      {isDeleteWidgetModalShow && (
        <ConfirmModal
          toggleModal={toggleModal}
          submit={() => removeWidgetHandler(id)}
          title="Are you sure?"
        />
      )}
    </div>
  );
};
