import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toJS } from "mobx";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { sendOverrideImage } from "../../../../actions/widgets";
import { updateFeedWidgetHelper, updateCarouselWidgetHelper } from "../../../widgets/helpers/update-priority-widget";
import ROUTING_CONFIG from "../../../../constants/routing-config/routing-config";
import CarouselStore from "../../../../store/carousel/corousel.store";
import { CarouselItem } from "../carousel-item/carousel-item";
import { useCarouselListBodyStyle } from "./carousel-list-body-style";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { sendCarouselData } from "../../../../actions/carousel";
import { Text } from "../../../../components/wilson-ui/text";
import { UploadModal } from "../../../create-widgets/components/modals";

const SortableItem = SortableElement(
  ({
    item,
    updateCarouselItemHandler,
    uploadImageCarouselItemHandler,
    removeCarouselItemHandler,
  }: any) => (
    <CarouselItem
      item={item}
      updateCarouselItemHandler={updateCarouselItemHandler}
      uploadImageCarouselItemHandler={uploadImageCarouselItemHandler}
      removeCarouselItemHandler={removeCarouselItemHandler}
    />
  )
);

const SortableList = SortableContainer(
  ({
    items,
    updateCarouselItemHandler,
    uploadImageCarouselItemHandler,
    removeCarouselItemHandler,
  }: any) => (
    <FlexBox direction="column" gap="10px">
      {items.map((item: any, index: any) => (
        <SortableItem
          key={index}
          index={index}
          item={item}
          updateCarouselItemHandler={updateCarouselItemHandler}
          uploadImageCarouselItemHandler={uploadImageCarouselItemHandler}
          removeCarouselItemHandler={removeCarouselItemHandler}
        />
      ))}
    </FlexBox>
  )
);

const CarouselListBody: FC<RouteComponentProps<{ id: string }>> = observer(
  (props) => {
    const [loading, setLoading] = useState(false);
    const [isModalUpload, setIsModalUpload] = useState<boolean>(false);
    const [feedItem, setFeedItem] = useState<Record<string, any>>({});
    const classes = useCarouselListBodyStyle();
    const carouselArray: any = toJS(CarouselStore.carouselData.data);
    const buttonClass = CarouselStore.isChanged
      ? `${classes.button} ${classes.buttonVisible}`
      : classes.button;
    const history = useHistory();
    const intl = useIntl();

    useEffect(() => {
      if (CarouselStore.carouselData.id !== props.match.params.id)
        carouselDataRequestHandler(props.match.params.id);

      return () => {
        CarouselStore.setIsChanged(false);
      };
    }, []);

    const form = useFormik({
      initialValues: { },
      onSubmit: () => { console.log('') },
    });

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
      const sortCarousel = arrayMoveImmutable(
        carouselArray,
        oldIndex,
        newIndex
      );
      CarouselStore.changeCarousel(sortCarousel);
    };

    const carouselDataRequestHandler = async (carouselId: string) => {
      await CarouselStore.carouselDataRequest(carouselId);
    };

    const sendCarouselHandler = async () => {
      try {
        await sendCarouselData(
          props.match.params.id,
          carouselArray,
          CarouselStore.carouselData.status,
          CarouselStore.carouselData.title,
          CarouselStore.carouselData.titleColor,
          CarouselStore.carouselData.backgroundColor,
        );
        history.push(ROUTING_CONFIG.widgets);
        CarouselStore.setIsChanged(false);
        CarouselStore.resetCarouselData();
      } catch (error) {
        console.warn(error);
      }
    };

    const removeCarouselItemHandler = (id: string) => {
      const newCarousel = carouselArray.filter(
        (item: Record<string, any>) => item.id !== id
      );
      CarouselStore.changeCarousel(newCarousel);
    };

    const toggleModalUpload = (feedItem: Record<string, any> | undefined) => {
      setIsModalUpload(!isModalUpload);
      setFeedItem(feedItem || {})
    }

    const notify = () => toast.success(intl.formatMessage({ id: "upload_image_success" }));

    const sendOverrideImageHandler = async (file: Blob | string, type: string) => {
      setLoading(true);
      const data = await sendOverrideImage(file, type);
      if (data) notify();
      if (feedItem?.id) {
        await updateFeedWidgetHelper(feedItem.id, { overrideImageUrl: data?.url });
      }
      CarouselStore.updateItem({
        childId: feedItem?.childId,
        overrideImageUrl: data?.url,
        overrideImageLink: data?.link || '',
        overrideThumbnailUrl: data?.thumbnailUrl || '',
        overrideThumbnailLink: data?.thumbnail || ''
      })
      setLoading(false);
      setFeedItem({
        ...feedItem,
        overrideImageLink: data?.link || "",
        overrideThumbnailLink: data?.thumbnail || "",
      });
      setIsModalUpload(!isModalUpload);
    };

    const updateCarouselItemHandler = async (id: string, body: { carouselTitle: string }) => {
      setLoading(true);
      let result = null;
      if (id) {
        result = await updateCarouselWidgetHelper(id, body);
      }
      setLoading(false);
      return result;
    }

    const modalUpload = isModalUpload ? (
      <UploadModal
        form={form}
        loading={loading}
        name='overrideImageUrl'
        title={intl.formatMessage({ id: "override_image" })}
        filePreview={feedItem?.overrideImageLink}
        submitHandler={sendOverrideImageHandler}
        closeHandler={() => toggleModalUpload(undefined)}
      />
    ) : (
      <></>
    );

    const carouselContent = carouselArray.length ? (
      <SortableList
        distance={1}
        items={carouselArray}
        onSortEnd={onSortEnd}
        updateCarouselItemHandler={updateCarouselItemHandler}
        uploadImageCarouselItemHandler={toggleModalUpload}
        removeCarouselItemHandler={removeCarouselItemHandler}
      />
    ) : (
      <div className={classes.tableHint}>
        <Text fontSize={"18px"}>
          {intl.formatMessage({ id: "no_child_widgets" })}
        </Text>
      </div>
    );

    return (
      <div className={classes.wrap}>
        {carouselContent}
        <FlexBox className={buttonClass}>
          <UiButton buttonType="black" onClick={sendCarouselHandler}>
            {intl.formatMessage({ id: "save" })}
          </UiButton>
        </FlexBox>
        {modalUpload}
      </div>
    );
  }
);

export default withRouter(CarouselListBody);
