import React from "react";
import { useModalStyle } from "./modal-style.hook";

interface IProps {
  width: string;
  closeHandler: () => void;
  minHeight?: string;
  isWhiteBackground?: boolean;
}

export const UiModal: React.FC<IProps> = ({
  children,
  width,
  closeHandler,
  minHeight,
  isWhiteBackground,
}) => {
  const classes = useModalStyle();
  const minHeightValue = minHeight ? minHeight : '200px'

  const closeSubmit = (e: any) => {
    if (e.target.id !== "ui-modal") return;
    closeHandler();
  };

  return (
    <div className={classes.wrap} id="ui-modal" onClick={closeSubmit}>
      <div
        style={{
          maxWidth: width,
          minHeight: minHeightValue,
          background: isWhiteBackground ? "#ffffff" : "f3f3f3",
        }}
        className={classes.container}
      >
        {children}
      </div>
    </div>
  );
};
