import { makeAutoObservable } from "mobx";
import usersService from "../../services/users.service";

export interface IUsers {
  length: number;
  lengthAll: number;
  data: Array<Record<string, unknown>>;
}

class UsersStore {
  users: IUsers = { length: 0, lengthAll: 0, data: [] };
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async usersRequest(
    row: number,
    page: number,
    order: string,
    sortByName: string,
    term: string
  ) {
    this.isLoading = true;
    const data: Record<string, any> = await usersService.getUsers(
      row,
      page,
      order,
      sortByName,
      term
    );
    this.setUsers({ length: data.length, lengthAll: data.lengthAll, data: data.users });
    this.isLoading = false;
  }

  setUsers(users: IUsers) {
    this.users = users;
  }

  getUsers() {
    return this.users;
  }

  async changeUserWhitelist(id: string, value: boolean) {
    try {
      this.users.data.forEach((user) => {
        if (user.id === id) user.isWhitelisted = value;
      });
      await usersService.setUserWhitelist(id, value);
    } catch (e) {
      console.warn(e);
    }
  }

  // async changeUserField(id: string, url: string, data: Record<string, any>) {
  //   try {
  //     this.users.data.forEach((user) => {
  //       if (user.id === id) user = { ...user, ...data };
  //     });
  //     await usersService.setUserField(id, url, data);
  //   } catch (e) {
  //     console.warn(e);
  //   }
  // }

  async changeUserExplicitSMS(id: string, value: boolean) {
    try {
      this.users.data.forEach((user) => {
        if (user.id === id) user.explicitSms = value;
      });
      await usersService.setUserExplicitSMS(id, value);
    } catch (e) {
      console.warn(e);
    }
  }
}

export default new UsersStore();
