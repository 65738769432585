import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { useUsersInterestsDownloadCSV } from "../../hooks";
import { usePageStyles } from "../../../pages-styles.hook";
import { useHeaderStyles } from "./header-styles.hook";
import { Text } from "../../../../components/wilson-ui/text";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { ROUTING_CONFIG } from "../../../../constants/routing-config/routing-config";

interface IProps {
  rows: number;
  page: number;
  sortByName: string;
  order: string;
  length: number;
}

export const UserInterestsHeader: React.FC<IProps> = ({
  rows,
  page,
  sortByName,
  order
}) => {
  const [currentCSV, setCurrentCSV] = useState([]);
  const pageClasses = usePageStyles();
  const classes = useHeaderStyles();
  const intl = useIntl();
  const history = useHistory();

  const csvInstance: any = useRef();
  useEffect(() => {
    if (
      !isEmpty(currentCSV) &&
      csvInstance?.current &&
      csvInstance?.current?.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCurrentCSV([]);
      });
    }
  }, [currentCSV]);

  const createWidgetHandler = () =>
    history.push(ROUTING_CONFIG.createUserInterest, null);

    const downloadCurrentCSV = async () => {
      useUsersInterestsDownloadCSV(
        rows,
        page,
        order,
        sortByName,
        setCurrentCSV
      );
    };

  return (
    <FlexBox className={pageClasses.container}>
      <Text className={pageClasses.pageTitle}>
        {intl.formatMessage({ id: "interests" })}
      </Text>
      <FlexBox className={classes.buttons}>
        <>
          <UiButton buttonType="white" onClick={downloadCurrentCSV}>
            {intl.formatMessage({ id: "export_current_view" })}
          </UiButton>
          {currentCSV && (
            <CSVLink
              filename="interests-current-list"
              data={currentCSV}
              ref={csvInstance}
            />
          )}
        </>
        <UiButton buttonType="black" onClick={createWidgetHandler}>
          {intl.formatMessage({ id: "create_interest" })}
        </UiButton>
      </FlexBox>
    </FlexBox>
  );
};
