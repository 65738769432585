import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import MDSpinner from "react-md-spinner";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { FlexBox } from "../box";
import { useDropzoneStyles } from "./dropzone-styles.hook";
import { DropzoneEmpty } from "./components/dropzome-empty";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { MAX_FILE_SIZE } from "../../../constants";

interface IProps {
  form: Record<string, any>;
  name: string;
  loading?: boolean;
  sendData: (file: Blob | string, type: string) => void;
  filePreview?: string;
}

export const DropzoneImageMP4: React.FC<IProps> = ({
  form,
  name,
  loading,
  sendData,
  filePreview,
}) => {
  const intl = useIntl();
  const [preview, setPreview] = useState<string | undefined>();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const classes = useDropzoneStyles();
  const notify = (id: string) => toast(intl.formatMessage({ id }));
  const errorClass: string =
    form?.errors[name] && form?.submitCount ? classes.errorWrap : "";

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (loading !== undefined) {
      setIsLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
      setPreview(filePreview);
      setSelectedFileType(checkIsVideo(filePreview)? 'video': 'image');
  }, [filePreview]);

  const checkIsVideo = (fileExt: string | undefined) => {
    if (!fileExt) return false;
    const videoExtensionRegExp = /(mp4)/gi;
    return videoExtensionRegExp.test(fileExt);
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const fileType = acceptedFiles[0].type.split("/")[0];
        const fileExt = acceptedFiles[0].name.match(/((?<=\.)(\d|\w)*)$/gi);
        const size = acceptedFiles[0].size;
        if (isLoading) return;
        if (fileType !== "video" && fileType !== "image") {
          return notify("only_image_and_video_file");
        }
        if (size >= MAX_FILE_SIZE) {
          return notify("up_to_100_mb");
        }

        if (fileType === "video") {
          const isFileExtensionAllowed = checkIsVideo(fileExt);
          if (!isFileExtensionAllowed)
            return notify("only_allowed_video_extension_mp4");
        }
        try {
          setIsLoading(true);
          await sendData(acceptedFiles[0], fileType);
          setSelectedFile(acceptedFiles[0]);
          setSelectedFileType(fileType);
          setIsLoading(false);
        } catch (e) {
          console.warn(e);
        }
      } else return notify("only_image_and_video_file");
    },
    [form[name]]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // accept: "*/mp4",
  });

  const setFileNull = () => {
    setPreview(undefined);
    setSelectedFile(null);
    form.setFieldValue(name, '');
  };

  if (isLoading) {
    return (
      <FlexBox items="center" content="center" className={classes.preview}>
        <MDSpinner />
      </FlexBox>
    );
  }

  if (form.values[name] || preview) {
    return (
      <FlexBox items="center" content="center" className={classes.preview}>
        <DeleteForeverIcon onClick={setFileNull} className={classes.icon} />
        {selectedFileType === 'image' ? <img src={preview} alt="Image" />: null}
        {selectedFileType === 'video' ? (
          <video width="310" height="176" controls>
            <source src={preview} />
          </video>
        ): null}
      </FlexBox>
    );
  }

  return (
    <div {...getRootProps()} className={`${classes.wrap} ${errorClass}`}>
      <input name={name} {...getInputProps()} />
      <FlexBox className={classes.container}>
        <DropzoneEmpty isBoth />
      </FlexBox>
    </div>
  );
};
