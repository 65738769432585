import React from "react";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useFontTypeSelectStyle } from "./font-type-select.hook";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { WidgetFeedTitleFont } from "../../../../enum";

interface FontTypeSelectProps {
  label: string;
  value: WidgetFeedTitleFont;
  submit: (value: WidgetFeedTitleFont) => void;
  form: Record<string, any>;
}

export const FontTypeSelect: React.FC<FontTypeSelectProps> = ({
  label,
  value,
  submit,
  form,
}) => {
  const classes = useFontTypeSelectStyle();
  const isHaveError: boolean =
    form.errors.feedTitleFont && form.submitCount;
  const types = [WidgetFeedTitleFont.GT_AMERICA, WidgetFeedTitleFont.ARGENT_CF];
  const errorClass: string = isHaveError ? ` ${classes.errorBorderField}` : "";

  return (
    <FlexBox items="center" gap="16px">
      <span className={classes.label}>{label}</span>
      <FlexBox items="center">
        {types.map((type) => (
          <UiButton
            key={type}
            buttonType={value === type ? "black" : "white"}
            onClick={() => submit(type)}
            className={`${classes.button} ${classes.size}${errorClass}`}
          >
            {type}
          </UiButton>
        ))}
      </FlexBox>
    </FlexBox>
  );
};
