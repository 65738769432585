import { makeAutoObservable } from "mobx";
import UsersService from "../../services/users.service";

export interface IUserInterests {
  length: number;
  data: Array<Record<string, unknown>>;
}

class UserInterestsStore {
  userInterests: IUserInterests = { length: 0, data: [] };
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async usersRequest(
    row: number,
    page: number,
    order: string,
    sortByName: string,
    term: string,
    type: string
  ) {
    this.isLoading = true;
    const data: Record<string, any> = await UsersService.getUserInterests(
      row,
      page,
      order,
      sortByName,
      term,
      type
    );
    this.setUserInterests({ length: data.count, data: data.interests });
    this.isLoading = false;
  }

  setUserInterests(userInterests: IUserInterests) {
    this.userInterests = userInterests;
  }

  getUserInterests() {
    return this.userInterests;
  }

}

export default new UserInterestsStore();
