import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";
import ImageIcon from "@mui/icons-material/Image";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import MDSpinner from "react-md-spinner";
import { useMediaItemStyle } from "./feed-media-item-styles.hook";
import { IFeedMediaItem } from "../../../../types";
import { onSelectFile } from "../../../../helpers/widgets/select-file.helper";
import { setMediaItemImage, setMediaItemIsFullScreen } from "./helpers/";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { checkIsImage } from "../../../../utils/utils";
import { MAX_FILE_SIZE } from "../../../../constants";
import { WidgetTypeEnum } from "../../../../enum";

interface IProps {
  index: number;
  item: IFeedMediaItem;
  isTouched: boolean;
  isVideo?: boolean;
  isFullScreenState?: boolean;
  mediaItemState: Array<IFeedMediaItem>;
  setMediaItemState: React.Dispatch<any>;
  updateFeedMediaArray: () => void;
}

export const FeedMediaItem: React.FC<IProps> = ({
  index,
  item,
  isTouched,
  isVideo,
  mediaItemState,
  isFullScreenState,
  setMediaItemState,
  updateFeedMediaArray,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useMediaItemStyle();
  const labelClass = item.isHaveError && isTouched
    ? `${classes.label} ${classes.labelError}`
    : classes.label;
  const notify = (id: string) => toast(intl.formatMessage({ id }));
  const assetsUrl = isLoading ? (
    <MDSpinner />
  ) : item.preview ? (
    item.type === 'IMAGE' || checkIsImage(item.preview) ? <img className={classes.image} src={item.preview} /> : (
      <div style={{
        position: 'relative',
        paddingBottom: '56.25%',
        width: '100%'
      }}>
        <video
          style={{
            position: 'absolute',
            top: '0',
            left: 0,
            width: '100%',
            height: '100%'
          }}
          controls>
          <source src={item.preview} />
        </video>
      </div>
    )
  ) : (
    <ImageIcon />
  );

  const setFeedMediaImageHandler = (
    id: string,
    file: string,
    preview: string,
    type: string
  ) => {
    setMediaItemImage(
      id,
      file,
      preview,
      isLoading,
      mediaItemState,
      setMediaItemState,
      type
    );
    updateFeedMediaArray();
  };

  const setFeedMediaIsFullScreenHandler = (
    id: string,
    isFullScreen: boolean,
  ) => {
    setMediaItemIsFullScreen(
      id,
      isFullScreen,
      isLoading,
      mediaItemState,
      setMediaItemState,
    );
    updateFeedMediaArray();
  };


  const test = async (event: any) => {
    const validated = isVideo ? event.target.files[0] && event.target.files[0].type.split("/")[0] !== "image" && event.target.files[0].type.split("/")[0] !== "video": event.target.files[0] && event.target.files[0].type.split("/")[0] !== "image"
    const size = event.target.files[0].size;
    if (validated){
      notify(isVideo ? "only_image_and_video_file": "only_image_file");
    } else if (size >= MAX_FILE_SIZE) {
      return notify("up_to_100_mb");
    } else {
      onSelectFile(event, isLoading, setIsLoading, setFeedMediaImageHandler, WidgetTypeEnum.POST);
    }
  };

  const removeItem = (id: string) => {
    if (isLoading) return;
    const newMediaItemState = mediaItemState.filter((item: any) => item.id !== id);
    setMediaItemState(newMediaItemState);
  };

  return (
    <GridBox className={classes.container} style={{ borderColor: "red" }}>
      <FlexBox className={classes.dragContainer}>
        <DragHandleIcon />
      </FlexBox>
      <div style={{ height: "188px", width: "188px", position: "relative" }}>
        <input
          className={classes.fileInput}
          id={item.id}
          name={item.id}
          type="file"
          onChange={test}
        />
        <label htmlFor={item.id} className={labelClass}>
          {assetsUrl}
        </label>
        {isFullScreenState && item.assetUrl ? (
          <Box
            sx={{
              position: "absolute",
              top: 5,
              right: 5,

              width: 32,
              height: 32,
              bgcolor: "#fff",
              borderRadius: '50%',

              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            onClick={() => setFeedMediaIsFullScreenHandler(item.id, !item.isFullScreen)}
          >
            {!item.isFullScreen ? (
              <FullscreenExitIcon style={{ fontSize: "24px", cursor: "pointer" }} />
            ) : (
              <FullscreenIcon style={{ fontSize: "24px", cursor: "pointer" }} />
            )}
          </Box>
        ) : null}
      </div>
      {index > 0 && (
        <FlexBox content="end" items="center" width={24}>
          <DeleteForeverIcon
            style={{ color: baseColors.red, cursor: "pointer" }}
            onClick={() => removeItem(item.id)}
          />
        </FlexBox>
      )}
    </GridBox>
  );
};
