import * as React from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

interface IProps {
  onChangeDate: (dateRange: DateRange<Dayjs>) => any;
}

export const BasicDateRangePicker: React.FC<IProps> = ({ onChangeDate }) => {
  const [value, setValue] = React.useState<DateRange<Dayjs>>([null, null]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: 'Start Date', end: 'End Date' }}
    >
      <DateRangePicker
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          onChangeDate(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}>
            <TextField {...startProps} sx={{ width: "50%" }} />
            <Box sx={{ mx: 1 }}></Box>
            <TextField {...endProps} sx={{ width: "50%" }} />
          </Box>
        )}
      />
    </LocalizationProvider>
  );
}
