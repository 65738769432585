import React from "react";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box } from "@material-ui/core";
import { Text } from "../../../../components/wilson-ui/text";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { FormikColorField } from "../../../../components/wilson-ui/inputs";
import { usePageStyles } from "../../../pages-styles.hook";
import { SelectSimple } from "../../../../components/wilson-ui/selects";
import { WidgetStatus } from "../../../../types";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import CarouselStore from "../../../../store/carousel/corousel.store";
import { observer } from "mobx-react-lite";

interface IProps {
  isEdit?: boolean;
}
export const CarouselHeader: React.FC<IProps> = observer(({ isEdit }) => {
  const carouselTitle = CarouselStore.carouselData.title;
  const carouselTitleColor = CarouselStore.carouselData.titleColor;
  const carouselBackgroundColor = CarouselStore.carouselData.backgroundColor;
  const carouselStatus = CarouselStore.carouselData.status;
  const pageClasses = usePageStyles();
  const intl = useIntl();
  const carouselForm = useFormik({
    initialValues: {
      title: carouselTitle,
      titleColor: carouselTitleColor,
      backgroundColor: carouselBackgroundColor
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Required"),
    }),
    onSubmit: (values) => console.log(values),
  });
  return (
    <FlexBox
      items="center"
      content="space-between"
      className={pageClasses.container}
    >
      <GridBox columns="5fr 1fr" cg="30px" rg="30px">
        {isEdit ? (
          <GridBox cg="30px" rg="30px">
            <Box className={pageClasses.bigInput}>
              <FormikField
                label={intl.formatMessage({ id: "carousel_title" })}
                name="title"
                variant="standard"
                submit={(event) => {
                  carouselForm.handleChange(event);
                  CarouselStore.setTitle(event.target.value);
                }}
                placeholder={intl.formatMessage({ id: "title" })}
                value={carouselForm.values.title}
                form={carouselForm}
              />
            </Box>
            <GridBox columns="1fr 1fr" cg="30px" rg="30px">
              <Box className={pageClasses.bigLabel}>
                <FormikColorField
                  label={intl.formatMessage({ id: "carousel_title_color" })}
                  name="titleColor"
                  value={carouselForm.values.titleColor}
                  form={carouselForm}
                  onChange={(event) => {
                    carouselForm.handleChange(event);
                    CarouselStore.setTitleColor(event.target.value);
                  }}
                />
              </Box>
              <Box className={pageClasses.bigLabel}>
                <FormikColorField
                  label={intl.formatMessage({ id: "carousel_background_color" })}
                  name="backgroundColor"
                  value={carouselForm.values.backgroundColor}
                  form={carouselForm}
                  onChange={(event) => {
                    carouselForm.handleChange(event);
                    CarouselStore.setBackgroundColor(event.target.value);
                  }}
                />
              </Box>
            </GridBox>
          </GridBox>
        ) : (
          <Text className={pageClasses.pageTitle} fontSize={"32px"}>
            {carouselTitle}
          </Text>
        )}
        <div style={{ width: "200px" }}>
          <SelectSimple
            options={Object.values(WidgetStatus)}
            name={"status"}
            value={carouselStatus}
            submit={(event) => CarouselStore.setStatus(event.target.value)}
          />
        </div>
      </GridBox>
    </FlexBox>
  );
});
