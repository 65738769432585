import { Dispatch } from "react";
import { downloadUsersInterestsCSV } from "../../../../actions/usersInterests";
import { EMPTY_FUNCTION } from "../../../../constants";

export const useUsersInterestsDownloadCSV = async (
  rows: number,
  page: number,
  order: string,
  sortByName: string,
  setCurrentCSV: Dispatch<any>
): Promise<void> => {
  try {
    const CSVFile = await downloadUsersInterestsCSV(rows, page, order || 'ASC', sortByName || 'name');
    setCurrentCSV(CSVFile);
  } catch (error) {
    EMPTY_FUNCTION();
  }
};
