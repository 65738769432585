import React, { useState, useEffect } from "react";
import { MainLayout } from "../../components";
import { CarouselsHeader } from "./components/carousels-header";
import CarouselStore from "../../store/carousel/corousel.store";
import { UiTable } from "../../components/wilson-ui/table";
import { carouselsTableConfig } from "./carousels-page-table-config";
import { observer } from "mobx-react-lite";
import { Spiner } from "../../components/wilson-ui/spiner";
import { FlexBox } from "../../components/wilson-ui/box";
import { UiButton } from "../../components/wilson-ui/buttons";
import { useHistory } from "react-router-dom";
import { AddCarouselModal } from "./components/add-carousel-modal";
import { useIntl } from "react-intl";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useCarouselsPageStyles } from "./carousels-page-styles.hook";
import { removeCarouselRequest } from "../../actions/carousel";
import { arrayMoveImmutable } from "array-move";
import { setWidgetsPriority } from "../../actions/widgets";

interface IProps {
  team?: string;
}

export const CarouselsPage: React.FC<IProps> = observer(() => {
  const intl = useIntl();
  const classes = useCarouselsPageStyles();
  const notify: (text: string) => void = (text: string) => notify(text);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [activeCarousel, setActiveCarousel] = useState<Record<
    string,
    any
  > | null>(null);
  const [isModalShow, setIsModalShow] = useState(false);
  const toggleModal = () => setIsModalShow(!isModalShow);

  useEffect(() => {
    CarouselStore.getCarouselsDataRequest(page);
  }, []);

  const viewCarouselHandler = (id: string) => {
    history.push("/carousel/" + id);
  };

  const setRowPerPageHandler = (val: number) => {
    setPage(1);
    setRowPerPage(val);
  };

  const addCarouselHandler = () => {
    setActiveCarousel(null);
    toggleModal();
  };

  const editCarouselHandler = (carousel: Record<string, any>) => {
    setActiveCarousel(carousel);
    toggleModal();
  };

  const removeCarouselHandler = async (id: string) => {
    const isRemoved = await removeCarouselRequest(id);

    if (isRemoved) {
      CarouselStore.getCarouselsDataRequest(page);
    } else {
      notify("some error");
    }
  };

  const components = {
    actions: (row: Record<string, any>) => (
      <FlexBox content="space-between" items="center">
        <UiButton
          buttonType="black"
          onClick={() => viewCarouselHandler(row.id)}
        >
          {intl.formatMessage({ id: "view" })}
        </UiButton>

        <UiButton buttonType="white" onClick={() => editCarouselHandler(row)}>
          {intl.formatMessage({ id: "edit" })}
        </UiButton>

        <DeleteForeverIcon
          onClick={() => removeCarouselHandler(row.id)}
          className={classes.removeIcon}
        />
      </FlexBox>
    ),
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const sortCarousels = arrayMoveImmutable(
      CarouselStore.carouselsArray.data,
      oldIndex,
      newIndex
    );

    CarouselStore.changeCarousels(sortCarousels);
    setWidgetsPriority(sortCarousels, page, rowPerPage);
  };

  const table = CarouselStore.isLoading ? (
    <Spiner paddingTop="100px" />
  ) : (
    <UiTable
      components={components}
      config={carouselsTableConfig()}
      data={CarouselStore.carouselsArray.data || []}
      setRowPerPage={setRowPerPageHandler}
      rowPerPage={rowPerPage}
      page={page}
      setPage={setPage}
      length={CarouselStore.carouselsArray.length || 0}
      sortable={true}
      onSortEnd={onSortEnd}
    />
  );

  const modal = isModalShow && (
    <AddCarouselModal
      page={page}
      activeCarousel={activeCarousel}
      toggleModal={toggleModal}
    />
  );

  return (
    <MainLayout>
      <CarouselsHeader toggleModal={addCarouselHandler} />
      {table}
      {modal}
    </MainLayout>
  );
});
