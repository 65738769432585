import React, { useEffect, useState } from "react";
import MDSpinner from "react-md-spinner";
import { useIntl } from "react-intl";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useAuthorAvatarStyles } from "./author-avatar-styles.hook";
import { setSelectFile } from "./helpers";
import { toast } from "react-toastify";
import UserIcon from "../../../../assets/icons/user.svg";

interface IProps {
  sendData: (file: any) => void;
  imagePreview?: string;
}

export const AuthorAvatar: React.FC<IProps> = ({ sendData, imagePreview }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [image, setImage] = useState(undefined);

  const notify = () => toast(intl.formatMessage({ id: "only_image_file" }));

  const intl = useIntl();
  const classes = useAuthorAvatarStyles();

  const avatarIcon = isLoading ? (
    <MDSpinner />
  ) : preview ? (
    <img src={preview} alt="avatar" />
  ) : (
    <div className={classes.baseImage}>
      <img src={UserIcon} alt="User" />
    </div>
  );

  const selectFileHandler = (event: any) => {
    if (isLoading) return;

    if (event.target.files[0].type.split("/")[0] !== "image") {
      notify();
    } else {
      setSelectFile(event, setImage, setIsLoading, sendData);
    }
  };

  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  useEffect(() => {
    if (imagePreview) {
      setPreview(imagePreview);
    }
  }, []);

  return (
    <div>
      <FlexBox
        items="center"
        justifyContent="flex-start"
        className={classes.container}
      >
        <div className={classes.imageWrap}>{avatarIcon}</div>
        <input
          accept="image/png, image/gif, image/jpeg"
          className={classes.input}
          id="avatar"
          type="file"
          name="avatar"
          onChange={selectFileHandler}
        />
        <label htmlFor="avatar" className={classes.downloadButton}>
          <span>{intl.formatMessage({ id: "upload_avatar" })}</span>
          <CloudDownloadIcon />
        </label>
      </FlexBox>
    </div>
  );
};
