import React, { Fragment } from "react";
import { useSelectsStyles } from "./selects-styles.hook";
// import { DatePicker } from "@mui/lab";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DefaultInput } from "../inputs";
import { parseDateTime } from "../../../utils/utils";

const minDate = new Date();

interface IProps {
  form: Record<string, any>;
  name: string;
  nameTime: string;
  value: any;
  time?: any;
}

export const FormikData: React.FC<IProps> = ({ form, name, nameTime, value, time }) => {
  const classes = useSelectsStyles();
  const isHaveError: boolean = form?.errors[name] && form?.submitCount;
  const errorClass: string = isHaveError ? classes.errorFormikDate : "";

  return (
    <Fragment>
      <DatePicker
        allowSameDateSelection={true}
        minDate={minDate}
        value={value}
        onChange={(newValue: string | Date | null) => {
          const dateTime = time ? parseDateTime(newValue, time): null;
          return form.setValues({
            ...form.values,
            [name]: dateTime?.date || newValue || value,
            [nameTime]: dateTime?.date || time || null,
          });
        }}
        renderInput={(params: any) => (
          <DefaultInput
            className={`${classes.formikDate} ${errorClass} `}
            fullWidth
            variant="standard"
            {...params}
          />
        )}
      />
    </Fragment>
  );
};
