import React, { useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface IProps {
  value: any;
  // isError?: boolean;
  onChange: (value: any) => void;
}
export const TinyMCEEditor: React.FC<IProps> = ({ value, onChange }) => {
  const editorRef: any = useRef(null);
  const [ data, setData ] = useState('');
  const [threadTimeOut, setThreadTimeOut] = useState(0);

  useEffect(() => {
    setData(value || '');
  }, [value]);

  const onInit = (evt: any, editor: any) => {
    if (editorRef.current) {
      return editorRef.current = editor;
    }
  };

  const onEditorChange = (value: any) => {
    setData(value);
    callOnChange(value);
  }

  const callOnChange = (value: any) => {
    if (threadTimeOut) {
      clearTimeout(threadTimeOut);
    }
    const timer: any = setTimeout(function () {
      onChange(value);
    }, 1000);
    setThreadTimeOut(timer);
  };

  return (
    <Editor
      apiKey={process.env.REACT_APP_API_KEY_TINY_MCE}
      onInit={onInit}
      value={data}
      onEditorChange={onEditorChange}
      init={{
        height: 500,
        skin: "oxide-dark",
        content_css: "dark",
        // skin: "oxide",
        // content_css: "default",
        menubar: false,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'emoticons', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
        ],
        toolbar: 'fontfamily fontsize blocks | ' +
          'bold italic underline strikethrough |' +
          'forecolor backcolor | undo redo | alignleft aligncenter ' +
          'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
          'link unlink image code |' +
          'emoticons removeformat help',
        toolbar_mode: 'sliding',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
    />
  );
}
