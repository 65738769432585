import axios from "axios";

const axiosAuthorized = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosAuthorized.interceptors.request.use((req) => {
  const token = localStorage.getItem("Authorization");

  req.headers = {
    ...req.headers,
    Authorization: "Bearer " + token,
  };

  // req.headers = {
  //   ...req.headers,
  //   Authorization:
  //     "Bearer " +
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA3N2I3MWY4LWVlMTAtNDJjYS1hYjE4LTg5MDFiMmZlMDEyZCIsImlhdCI6MTY0MzI4NDY5OSwiZXhwIjoxNjQ1ODc2Njk5fQ.T7PRieFvXOEtfhvweDNOuz8gYbqGDWmqRXjKdRNfzaE",
  // };

  // req.headers = {
  //   ...req.headers,
  //   Authorization:
  //     "Bearer " +
  //     // dev
  //     // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImY5NGZkZmEwLTUyODgtNDdiYy04OGU1LWIxYTg1NTMzMzM2YyIsImlhdCI6MTY5NjIzODAzNiwiZXhwIjoxNjk4ODMwMDM2fQ.C9sB2SaYty6UInk3zAU5P8TUPjI7kYvJWJAaUTBKVaY"
  //     // pro
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU1YTJjNDFmLTVkODMtNDU1OC05NTg5LTYxZTI1MzI0NGU3NCIsImlhdCI6MTY5Njk1NDYzMCwiZXhwIjoxNjk5NTQ2NjMwfQ.Xyz6k2ho4kObP_hK5eeJ922Uu8iMYeHQwOPUlNozu70",
  // };

  return req;
});

axiosAuthorized.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 403 || error.response.status === 401) {
    localStorage.removeItem("Authorization");
    window.location.reload();
    return Promise.reject(error);
  }
  throw error;
});

export { axiosAuthorized };
