import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { FormikAutocompleteDefault } from "../../../../components/wilson-ui/selects";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useIntl } from "react-intl";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import CarouselStore from "../../../../store/carousel/corousel.store";
import { getFullWidgetsByString } from "../../../../actions/widgets";
import { AddCarouselItemSchema } from "./carousel-form-schema";

export const CarouselForm: FC = () => {
  const [filterString, setFilterString] = useState<any>(null);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [threadTimeOut, setThreadTimeOut] = useState(0);
  const form = useFormik({
    initialValues: {
      title: "",
      widget: null as unknown as Record<string, any>,
    },
    validationSchema: AddCarouselItemSchema,
    onSubmit: () => {
      addItemHandler();
    },
  });

  const intl = useIntl();

  const addItemHandler = () => {
    const id: string = form.values.widget.id;
    const title = form.values.title;
    const widgetTitle = form.values.widget.title;
    const widgetType = form.values.widget.type;
    CarouselStore.addItem({
      childId: id,
      carouselTitle: title,
      title: widgetTitle,
      type: widgetType
    });

    setFilterString("");
    setTagsOptions([]);
    form.resetForm();
    sendData("");
  };

  const onChange = (value: any) => {
    setFilterString(value);
    callSearch(value);
  };

  const callSearch = (term: string) => {
    if (threadTimeOut) {
      clearTimeout(threadTimeOut);
    }
    const timer: any = setTimeout(function () {
      sendData(term);
    }, 500);
    setThreadTimeOut(timer);
  };

  const sendData = async (term: string) => {
    const usedWidgets = CarouselStore.carouselData.data.map(
      (item: Record<string, any>) => item.childId
    );
    const data = await getFullWidgetsByString(term || "");
    const newData = data.filter(
      (item: Record<string, any>) => !usedWidgets.includes(item.id)
    );
    setTagsOptions(newData);
    setFilterString("");
  };

  useEffect(() => {
    if (!open) {
      setTagsOptions([]);
    }
  }, [open]);

  // useEffect(() => {
  //   if (typeof filterString === "string") sendData();
  //   return () => {
  //     setTagsOptions([]);
  //     setFilterString("");
  //   };
  // }, [filterString]);

  return (
    <FlexBox direction="column" gap="30px">
      <div>
        <h3 style={{ marginBottom: "10px" }}>
          {intl.formatMessage({ id: "title" })}
        </h3>
        <FormikField
          variant="outlined"
          name="title"
          submit={form.handleChange}
          value={form.values.title}
          {...{ form }}
        />
      </div>

      <div>
        <h3 style={{ marginBottom: "10px" }}>
          {intl.formatMessage({ id: "feed_widget" })}
        </h3>
        <FormikAutocompleteDefault
          optionLabel="title"
          type="white"
          variant="outlined"
          options={tagsOptions}
          name="widget"
          value={form.values.widget}
          {...{ form }}
          onFocus={onChange}
          textSubmit={onChange}
          textValue={filterString}
          open={open}
          setOpen={setOpen}
        />
      </div>

      <div>
        <UiButton onClick={() => form.submitForm()} buttonType="white">
          {intl.formatMessage({ id: "add" })}
        </UiButton>
      </div>
    </FlexBox>
  );
};
