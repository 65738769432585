import React, { useState, useEffect } from "react";
import { orderBy, isEmpty, findIndex } from "lodash";
import { toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import moment from "moment-timezone";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import {
  FormikColorField,
  DefaultInput,
} from "../../../../components/wilson-ui/inputs";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { prizesTableConfig } from "../../../widgets/widgets-table-config";
import { QuestionIcon } from "../../../../components/icons";
import { UiTableDrag } from "../../../../components/wilson-ui/table";
import { ItemQuestionModal } from "../modals";
import { useIntl } from "react-intl";
import image from "../../../../assets/images/postModalImage.png";
import { Accordion } from "../accordion";
import { FeedMedia } from "../feed-media";
import { BackgroundTypeSelect } from "../background-type-select";
import { BackgroundTypeEnum } from "../../../../enum";
import { COLOR_REGEX } from "../../../../constants";
import {
  updatePrizePriorityHelper,
  updatePrizeHelper,
} from "../../../widgets/helpers/update-priority-widget";
import { ContainerTypeSelect } from "../container-type-select";

const NAMES = ["Title", "Description", "Button Text", "Button Color"];
const types: any = {
  PROMOTION: "Promotion",
  PHYSICAL: "Physical Good",
  DIGITAL: "Digital Good",
};
interface IProps {
  form: Record<string, any>;
}
const defaultSortState = "";
const defaultSortOrder = null;
export const DetailsAssetsInstantWin: React.FC<IProps> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortState, setSortState] = useState<string>(defaultSortState);
  const [sortOrder, setSortOrder] = useState<any>(defaultSortOrder);
  const [isEdit, setIsEdit] = useState<any>({});
  const [currentId, setCurrentId] = useState("");
  const [cardColor, setCardColor] = useState("");

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  useEffect(() => {
    if (sortState && sortOrder) {
      const sortOptions = orderBy(
        form.values.campaign.prizes,
        [sortState],
        [sortOrder]
      );
      form.setFieldValue("campaign", {
        ...form.values.campaign,
        prizes: sortOptions,
      });
    }
  }, [sortState, sortOrder]);

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "post_preview" })}
      nameArray={NAMES}
      image={image}
      maxHeight="400px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  const setSortModel = (field: string, sortOrder: string | null) => {
    setSortOrder(sortOrder || null);
    setSortState(field);
  };

  const setRowPriorityHandler = async (payload: Array<Record<string, any>>) => {
    setLoading(true);
    await updatePrizePriorityHelper(
      payload.map((u) => ({
        ...u,
        priority: u.widgetPriority,
        widgetPriority: undefined,
      }))
    );
    setLoading(false);
  };

  const onSubmit = (id: string, color: string, noId: boolean) => {
    if (noId) return;
    if (!isEdit[id]) {
      setIsEdit({ [id]: true });
      setCurrentId(id);
      setCardColor(color);
    } else {
      if (!cardColor) return;
      if (!COLOR_REGEX.test(cardColor)) {
        toast.error(intl.formatMessage({ id: "must_correspond" }));
        return;
      }
      if (cardColor && cardColor !== color) {
        setLoading(true);
        updatePrizeHelper(id, { cardColor });
        setIsEdit({ [id]: false });
        setCurrentId("");
        setCardColor("");
        const prizes = form.values.campaign.prizes;
        const index = findIndex(prizes, (prize: any) => prize.id === id);
        form.values.campaign.prizes[index].cardColor = cardColor;
        form.setFieldValue("campaign", {
          ...form.values.campaign,
        });
        setLoading(false);
        toast.success(intl.formatMessage({ id: "update_card_color" }));
      }
    }
  };

  // const onBlur = () => {
  //   setCurrentId("");
  //   setCardColor("");
  //   setIsEdit({});
  // };

  const onChange = (e: any) => {
    setCardColor(e.target.value);
  };

  const components = {
    name: ({ row }: any) => {
      return (
        <FlexBox items="center">
          <Avatar variant="square" src={row.mediaLink} sx={{ marginRight: 2 }} />
          {`${intl.formatMessage({
            id: "prize",
          })} # ${row.priority} - ${row.name || ''}`}
        </FlexBox>
      );
    },
    type: ({ row }: any) => (
      <Box sx={{ textTransform: "capitalize" }}>{types[row.type]}</Box>
    ),
    cardColor: ({ row }: any) => (
      <Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: 16,
              height: 16,
              marginRight: 1,
              borderRadius: "50%",
              border:
                (currentId === row.id && cardColor) || row.cardColor
                  ? "1px solid #000"
                  : "",
              alignSelf: "center",
              background:
                currentId === row.id
                  ? cardColor || row.cardColor || ""
                  : row.cardColor || "",
            }}
          />
          {isEdit[row.id] ? (
            <DefaultInput
              fullWidth
              variant="outlined"
              value={cardColor}
              // onBlur={onBlur}
              onChange={onChange}
              placeholder="Card Color"
            />
          ) : (
            <Box
              sx={{
                width: "160px",
                height: "56px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {row.cardColor || ""}
            </Box>
          )}
          <Box
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              background: "#fff",
              border: "1px solid #000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "-5px",
              right: "-5px",
              cursor: "pointer",
            }}
            onClick={() => onSubmit(row.id, row.cardColor, row?.noId)}
          >
            {isEdit[row.id] ? (
              <SaveOutlinedIcon
                fontSize="small"
                sx={{
                  width: "0.8em",
                  height: "0.8em",
                }}
              />
            ) : (
              <ModeEditOutlineOutlinedIcon
                fontSize="small"
                sx={{
                  width: "0.8em",
                  height: "0.8em",
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    ),
    data: () => (
      <span>
        {form.values.campaign.expirationDate
          ? moment(form.values.campaign.expirationDate).format(
              "MMMM Do YYYY, HH:mm"
            )
          : ""}
      </span>
    ),
  };

  const TableDrag = (
    <UiTableDrag
      loading={loading}
      sortModelInit={
        sortState && sortOrder ? [{ field: sortState, sort: sortOrder }] : []
      }
      isSortType={false}
      sortSubmit={setSortModel}
      components={components}
      componentsTable={components}
      configDrag={prizesTableConfig()}
      data={form.values.campaign?.prizes || []}
      disablePagination={true}
      length={(form.values.campaign?.prizes || []).length}
      setRowPriority={setRowPriorityHandler}
      rowReordering={true}
      searchValue={""}
      minHeight={200}
    />
  );

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "details_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox columns="1fr 1fr" cg="30px" rg="30px">
            <FormikColorField
              label={intl.formatMessage({ id: "button_color" })}
              name="detailsAssetsButtonColor"
              value={form.values.detailsAssetsButtonColor}
              {...{ form }}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "button_text_color" })}
              name="detailsAssetsButtonTextColor"
              value={form.values.detailsAssetsButtonTextColor}
              {...{ form }}
            />
          </GridBox>
          <GridBox columns="1fr" cg="30px" rg="30px">
            <ContainerTypeSelect
              label={intl.formatMessage({ id: "container_color" })}
              value={form.values.detailsAssetsContainerType}
              submit={(value) =>
                form.setFieldValue("detailsAssetsContainerType", value)
              }
              {...{ form }}
            />
          </GridBox>
          <GridBox columns="1fr" cg="30px" rg="30px">
            <BackgroundTypeSelect
              label={intl.formatMessage({ id: "background" })}
              value={form.values.detailsAssetsBackgroundType}
              submit={(value) =>
                form.setFieldValue("detailsAssetsBackgroundType", value)
              }
              {...{ form }}
            />
            {[BackgroundTypeEnum.COLOR].includes(
              form.values.detailsAssetsBackgroundType
            ) && (
              <GridBox columns="1fr 1fr 1fr" cg="30px" rg="30px">
                <FormikColorField
                  label={intl.formatMessage({ id: "primary_background_color" })}
                  name="detailsAssetsBackgroundColor"
                  value={form.values.detailsAssetsBackgroundColor}
                  {...{ form }}
                />
                <FormikColorField
                  label={intl.formatMessage({ id: "win_background_color" })}
                  name="detailsAssetsWinBackgroundColor"
                  value={form.values.detailsAssetsWinBackgroundColor}
                  {...{ form }}
                />
                <FormikColorField
                  label={intl.formatMessage({ id: "miss_background_color" })}
                  name="detailsAssetsMissBackgroundColor"
                  value={form.values.detailsAssetsMissBackgroundColor}
                  {...{ form }}
                />
              </GridBox>
            )}
            {[BackgroundTypeEnum.IMAGE].includes(
              form.values.detailsAssetsBackgroundType
            ) && (
              <GridBox columns="1fr" cg="30px" rg="30px">
                <FeedMedia form={form} isVideo={true} isFullScreenState={true} />
              </GridBox>
            )}
            {!isEmpty(form.values.campaign?.prizes || []) ? (
              <div className={classes.feedTable}>{TableDrag}</div>
            ) : null}
          </GridBox>
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
