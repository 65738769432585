import { makeStyles } from "@material-ui/core";

export const useWinnerItemStyles = makeStyles(() => ({
  container: {
    maxWidth: "max-content",
    padding: "4px 12px",
    borderRadius: "100px",
    cursor: "pointer",
  },
}));
