import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import MDSpinner from "react-md-spinner";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { FlexBox } from "../box";
import { useDropzoneStyles } from "./dropzone-styles.hook";
import { DropzoneEmpty } from "./components/dropzome-empty";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

interface IProps {
  loading?: boolean;
  isError?: boolean;
  form: Record<string, any>;
  name: string;
  sendData: (file: Blob | string) => void;
  imagePreview?: string;
}

export const Dropzone: React.FC<IProps> = ({
  loading,
  isError,
  form,
  name,
  sendData,
  imagePreview,
}) => {
  const intl = useIntl();
  const [preview, setPreview] = useState<string | undefined>();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useDropzoneStyles();
  const errorClass: string =
    (form?.errors[name] && form?.submitCount) || isError
      ? classes.errorWrap
      : "";

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (loading !== undefined) {
      setIsLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
    setPreview(imagePreview);
  }, [imagePreview]);

  const notify = () => toast(intl.formatMessage({ id: "only_image_file" }));

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (isLoading) return;
      if (!acceptedFiles.length) {
        notify();
      } else {
        try {
          setIsLoading(true);
          await sendData(acceptedFiles[0]);
          setSelectedFile(acceptedFiles[0]);
          setIsLoading(false);
        } catch (e) {
          console.warn(e);
        }
      }
    },
    [form[name]]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const setFileNull = () => {
    setPreview(undefined);
    setSelectedFile(null);
    form.setFieldValue(name, '');
  };

  if (isLoading) {
    return (
      <FlexBox items="center" content="center" className={classes.preview}>
        <MDSpinner />
      </FlexBox>
    );
  }

  if (form.values[name] || preview) {
    return (
      <FlexBox items="center" content="center" className={classes.preview}>
        <DeleteForeverIcon onClick={setFileNull} className={classes.icon} />
        <img src={preview} alt="Image" />
      </FlexBox>
    );
  }

  return (
    <div {...getRootProps()} className={`${classes.wrap} ${errorClass}`}>
      <input name={name} {...getInputProps()} />
      <FlexBox className={classes.container}>
        <DropzoneEmpty />
      </FlexBox>
    </div>
  );
};
