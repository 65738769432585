import React, { useEffect, useState } from "react";
import { CopyItem, WinnerItem } from "../../components/items";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { MainLayout } from "../../components";
import { SubmissionsHeader } from "./components/submissions-header";
import { UiTable } from "../../components/wilson-ui/table";
import SubmissionsStore from "../../store/submissions/submissions.store";
import { submissionsTableConfig } from "./submissions-table-config";
import { useChangeFilterValue, useSortState } from "./hooks";
import { Spiner } from "../../components/wilson-ui/spiner";
import { SubmissionsTypeModal } from "./components/submissions-type-modal";
import { LayoutWrap } from "../../components/wrappers/layout-wrap/layout-wrap";
import { EMPTY_FUNCTION } from "../../constants";
import moment from "moment-timezone";

export const SubmissionsPage: React.FC = observer(() => {
  const [rows, setRows] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sortByName, setSortByName] = useState<string>("name");
  const [order, setOrder] = useState<string>("ASC");
  const [isTypeModalShow, setIsTypeModalShow] = useState(false);
  const submissions = SubmissionsStore.getSubmissions();
  const [filterType, setFilterType] = useState<Array<string>>([
    "post",
    "story",
  ]);
  const [filterWinner, setFilterWinner] = useState<Array<string>>([
    "winner",
    "assign",
  ]);
  const [formWinners, setFormWinners] = useState<Record<string, any>>({
    winner: "winner",
    assign: "assign",
  });
  const [formType, setFormType] = useState<Record<string, any>>({
    post: "post",
    story: "story",
  });
  const titleFilteringArrayForm = useFormik({
    initialValues: {
      array: [],
    },
    onSubmit: () => EMPTY_FUNCTION(),
  });

  useEffect(() => {
    if (SubmissionsStore.isLoading) return;
    SubmissionsStore.sendData(
      rows,
      page,
      order,
      sortByName,
      filterType,
      filterWinner,
      titleFilteringArrayForm.values.array.map(
        (item: Record<string, string>) => item.title
      )
    );
  }, [
    rows,
    page,
    sortByName,
    order,
    filterType,
    filterWinner,
    titleFilteringArrayForm.values.array,
  ]);

  const toggleModal = () => setIsTypeModalShow(!isTypeModalShow);

  const changeWinnersHandler = (event: any) =>
    useChangeFilterValue(event, formWinners, setFormWinners, setFilterWinner);

  const changeTypeHandler = (event: any) =>
    useChangeFilterValue(event, formType, setFormType, setFilterType);

  const useSortStateHandler = (val: string) =>
    useSortState(val, order, sortByName, setOrder, setSortByName);

  const setRowPerPageHandler = (val: number) => {
    setPage(1);
    setRows(val);
  };

  const typeModal = isTypeModalShow && (
    <SubmissionsTypeModal
      toggleModal={toggleModal}
      titleFilteringArrayForm={titleFilteringArrayForm}
      changeWinnersHandler={changeWinnersHandler}
      changeTypeHandler={changeTypeHandler}
      formType={formType}
      formWinners={formWinners}
    />
  );

  const components = {
    winner: (row: Record<string, any>, col: string) => (
      <WinnerItem name={row[col]} row={row} />
    ),
    email: (row: Record<string, any>, col: string) => (
      <CopyItem row={row} col={col} maxLengthProps={20} />
    ),
    data: (row: Record<string, any>) => {
      return (
        <span>
          {row?.createdAt &&
            moment(row.createdAt).format("MMMM Do YYYY, HH:mm")}
        </span>
      );
    },
  };

  const table =
    SubmissionsStore.isLoading && submissions.data.length < 1 ? (
      <Spiner />
    ) : (
      <UiTable
        sortTypeSubmit={() => setIsTypeModalShow(!isTypeModalShow)}
        sortSubmit={useSortStateHandler}
        components={components}
        config={submissionsTableConfig()}
        data={submissions.data}
        setRowPerPage={setRowPerPageHandler}
        rowPerPage={rows}
        page={page}
        setPage={setPage}
        length={submissions.length}
      />
    );

  return (
    <MainLayout>
      <SubmissionsHeader
        sortByName={sortByName}
        filterType={filterType}
        filterWinner={filterWinner}
        rows={rows}
        page={page}
        order={order}
        length={submissions.length}
        sortByTitle={titleFilteringArrayForm.values.array}
      />
      <LayoutWrap minHeight="400px">{table}</LayoutWrap>
      {typeModal}
    </MainLayout>
  );
});
