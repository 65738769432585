import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../wilson-ui/constants/colors/base-colors";
import { mediaQuery } from "../../../wilson-ui/constants/media";

export const useNavBarListStyles = makeStyles(() => ({
  container: {
    display: "flex",
    "& li a": {
      fontFamily: "GT America Bold",
      fontWeight: 500,
      fontSize: "14px",
      color: baseColors.black,
    },
    "& li.active a": {
      color: "red",
    },
    [mediaQuery.maxWidth800]: {
      marginTop: "80px",
      flexDirection: "column",
      gap: "20px",
      // "& li a": {
      //   fontFamily: "GT America Bold",
      //   fontWeight: 500,
      //   color: baseColors.black,
      // },
    },
  },
}));
