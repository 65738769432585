import { axiosAuthorized } from "../../axios";

export const updatePriorityHelper = async (
  payload: Array<Record<string, any>>
): Promise<void> => {
  if (payload.length) {
    try {
      await axiosAuthorized.put(
        process.env.REACT_APP_API_URL + "tags/cms/priority",
        { tags: payload }
      );
    } catch (error) {
      console.warn(error);
    }
  }
};
