import React from "react";
import {
  Table,
  TableBody as MuiTableBody,
  TableContainer,
} from "@material-ui/core";
import { useTableBodyStyles } from "./table-body-styles.hook";
import { TableRow } from "../table-row";

interface IProps {
  data: Array<Record<string, any>>;
  rowCarousel?: Record<string, any>;
  columns: Array<Record<string, any>>;
  rowColumns: string;
  rowColumnsCarousel: string;
  components: Record<string, any>;
}

export const TableBody: React.FC<IProps> = ({
  data,
  rowCarousel,
  columns,
  rowColumns,
  components,
  rowColumnsCarousel,
}) => {
  const classes = useTableBodyStyles();

  return (
    <TableContainer>
      <Table>
        <MuiTableBody>
          {data.map((row: any, index: number) => (
            <TableRow
              key={row.id || index}
              row={row}
              rowCarousel={rowCarousel}
              columns={columns}
              components={components}
              rowColumns={rowColumns}
              rowColumnsCarousel={rowColumnsCarousel}
              className={classes.container}
            />
          ))}
        </MuiTableBody>
      </Table>
    </TableContainer>
  );
};
