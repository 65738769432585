import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UiButton } from "../../components/wilson-ui/buttons";
import { useFormik } from "formik";
import { FormikField } from "../../components/wilson-ui/inputs";
import { AuthWrap } from "../../components/wrappers";
import image from "../../assets/images/wilson_logo-red@3x.png";
import { AuthContext } from "../../contexts/auth-context";
import { ROUTING_CONFIG } from "../../constants/routing-config/routing-config";
import { sendConfirmData } from "../../actions/auth/auth-confirm.action";
import { ConfirmAuthSchema } from "./confirm-auth.schema";
import { useConfirmAuthStyle } from "./confirm-auth-style.hook";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IProps {
  location?: string;
}

interface LocationState {
  phoneNumber: string;
}

export const ConfirmAuthPage: React.FC<IProps> = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation<LocationState>();
  const classes = useConfirmAuthStyle();
  if (!location.state) history.push(ROUTING_CONFIG.auth);

  const form = useFormik({
    initialValues: {
      verificationCode: "",
      phoneNumber: location.state ? location.state.phoneNumber : "",
    },
    validationSchema: ConfirmAuthSchema,
    onSubmit: () => sendFormHandler(),
  });

  const { login } = useContext(AuthContext);

  const notify = () => toast("You submitted incorrect code");

  const sendFormHandler = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const token = await sendConfirmData(form.values);
      setIsLoading(false);
      login(token);
    } catch (error) {
      notify();
      setIsLoading(false);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendFormHandler();
    }
  };

  return (
    <AuthWrap>
      <img src={image} alt="wilson logo" />
      <div className={classes.container}>
        <FormikField
          label="Code"
          name="verificationCode"
          submit={form.handleChange}
          value={form.values.verificationCode}
          onKeyDown={onKeyDown}
          {...{ form }}
        />
        {/* <span className={classes.errorField}>
          {form.errors.verificationCode}
        </span> */}
      </div>
      <UiButton
        buttonType="black"
        onClick={form.handleSubmit}
        disabled={isLoading}
      >
        Send
      </UiButton>
    </AuthWrap>
  );
};
