import { AddWidgetsPostSchema } from "./add-widgets-post-schema";
import { AddWidgetsPromotionSchema } from "./add-widgets-promotion-schema";
import { AddWidgetsInstantWinSchema } from "./add-widgets-instant_win-schema";
import { AddWidgetsStorySchema } from "./add-widgets-story-schema";
import { AddWidgetsExternalLinkSchema } from "./add-widgets-external_link-schema";
import { AddWidgetsDropAdditionSchema } from "./add-widgets-drop-addition-schema";
import { AddWidgetsLocationBasedSchema } from "./add-widgets-location-based-schema";
import { AddCarouselSchema } from "./add-widgets-carousel-schema";
import { WidgetTypeEnum } from "../../enum";

const schemas = [
  {
    name: WidgetTypeEnum.POST,
    schema: AddWidgetsPostSchema,
  },
  {
    name: WidgetTypeEnum.STORY,
    schema: AddWidgetsStorySchema,
  },
  {
    name: WidgetTypeEnum.EXTERNAL_LINK,
    schema: AddWidgetsExternalLinkSchema,
  },
  {
    name: WidgetTypeEnum.FEATURED_CAROUSEL,
    schema: AddCarouselSchema,
  },
  {
    name: WidgetTypeEnum.DROP,
    schema: AddWidgetsDropAdditionSchema,
  },
  {
    name: WidgetTypeEnum.PROMOTION,
    schema: AddWidgetsPromotionSchema,
  },
  {
    name: WidgetTypeEnum.INSTANT_WIN,
    schema: AddWidgetsInstantWinSchema,
  },
  {
    name: WidgetTypeEnum.LOCATION_BASED,
    schema: AddWidgetsLocationBasedSchema,
  },
];

export { schemas };
