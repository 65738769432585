import React from "react";
import { Table, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useTableHeaderStyles } from "./table-header-styles.hook";
import { HeaderColumn } from "../header-column";
import { IRowColumn } from "../interface";

interface IProps {
  columns: Array<IRowColumn>;
  rowColumns: string;
  sortSubmit: (title: string) => void;
  sortTypeSubmit: () => void;
  marginForDraggable?: boolean;
}

export const TableHeader: React.FC<IProps> = ({
  columns,
  rowColumns,
  sortSubmit,
  sortTypeSubmit,
  marginForDraggable = false,
}) => {
  const classes = useTableHeaderStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            className={classes.row}
            style={{ gridTemplateColumns: rowColumns }}
          >
            {columns.map((item: IRowColumn) => (
              <div
                className={classes.column}
                style={{
                  marginLeft: marginForDraggable ? 65 : 0,
                  ...item.style,
                }}
                key={Math.random().toString().substr(2)}
              >
                <HeaderColumn
                  sortTypeSubmit={sortTypeSubmit}
                  title={item.title}
                  type={item.filterType}
                  name={item.name}
                  sortSubmit={sortSubmit}
                />
              </div>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};
