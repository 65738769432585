import React from "react";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useContainerTypeSelectStyle } from "./container-type-select.hook";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { ContainerTypeEnum, ContainerTypeValuesEnum } from "../../../../enum";

interface ContainerTypeSelectProps {
  label: string;
  value: ContainerTypeEnum;
  disableType?: ContainerTypeEnum;
  submit: (value: ContainerTypeEnum) => void;
  form: Record<string, any>;
}

export const ContainerTypeSelect: React.FC<ContainerTypeSelectProps> = ({
  label,
  value,
  disableType,
  submit,
  form,
}) => {
  const classes = useContainerTypeSelectStyle();
  const isHaveError: boolean =
    form.errors.feedBackgroundType && form.submitCount;
  const types = [ContainerTypeEnum.LIGHT, ContainerTypeEnum.DARK];
  const errorClass: string = isHaveError ? ` ${classes.errorBorderField}` : "";

  return (
    <FlexBox items="center" gap="16px">
      <span className={classes.label}>{label}</span>
      <FlexBox items="center">
        {types.map((type) => (
          <UiButton
            key={type}
            buttonType={value === type ? "black" : "white"}
            onClick={() => submit(type)}
            disabled={disableType && disableType === type}
            className={`${classes.button} ${classes.size}${errorClass}`}
          >
            {ContainerTypeValuesEnum[type]}
          </UiButton>
        ))}
      </FlexBox>
    </FlexBox>
  );
};
