import { axiosAuthorized } from "../../../../axios";

export const updatePriorityHelper = async (
  payload: Array<Record<string, any>>
): Promise<void> => {
  if (payload.length) {
    try {
      await axiosAuthorized.put(
        process.env.REACT_APP_API_URL + "widgets/v2/priority",
        { widgets: payload }
      );
    } catch (error) {
      console.warn(error);
    }
  }
};

export const updateFeedPriorityHelper = async (
  payload: Array<Record<string, any>>
): Promise<void> => {
  if (payload.length) {
    try {
      await axiosAuthorized.put(
        process.env.REACT_APP_API_URL + "widgets/drop/feed/priority",
        { widgetCarousels: payload }
      );
    } catch (error) {
      console.warn(error);
    }
  }
};

export const updateFeedPriorityForLocationBasedHelper = async (
  payload: Array<Record<string, any>>
): Promise<void> => {
  if (payload.length) {
    try {
      await axiosAuthorized.put(
        process.env.REACT_APP_API_URL + "widgets/location-based/feed/priority",
        { widgetCarousels: payload }
      );
    } catch (error) {
      console.warn(error);
    }
  }
};

export const updateFeedWidgetHelper = async (
  id: string,
  payload: Record<string, any>
): Promise<void> => {
  try {
    await axiosAuthorized.patch(
      process.env.REACT_APP_API_URL + `widgets/drop/feed/${id}/update`,
      { ...payload }
    );
  } catch (error) {
    console.warn(error);
  }
};

export const updateFeedWidgetForLocationBasedHelper = async (
  id: string,
  payload: Record<string, any>
): Promise<void> => {
  try {
    await axiosAuthorized.patch(
      process.env.REACT_APP_API_URL + `widgets/location-based/feed/${id}/update`,
      { ...payload }
    );
  } catch (error) {
    console.warn(error);
  }
};

export const updateCarouselWidgetHelper = async (
  id: string,
  payload: { carouselTitle: string }
): Promise<void> => {
  try {
    return await axiosAuthorized.put(
      process.env.REACT_APP_API_URL + `widgets/carousel/child/${id}`,
      { ...payload }
    );
  } catch (error) {
    console.warn(error);
  }
};

export const updatePrizePriorityHelper = async (
  payload: Array<Record<string, any>>
): Promise<void> => {
  if (payload.length) {
    try {
      await axiosAuthorized.put(
        process.env.REACT_APP_API_URL + "campaigns/prize/priority",
        { prizes: payload }
      );
    } catch (error) {
      console.warn(error);
    }
  }
};

export const updatePrizeHelper = async (
  id: string,
  payload: Record<string, any>
): Promise<void> => {
  try {
    await axiosAuthorized.patch(
      process.env.REACT_APP_API_URL + `campaigns/prize/${id}`,
      { ...payload }
    );
  } catch (error) {
    console.warn(error);
  }
};
