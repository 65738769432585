import { makeStyles } from "@material-ui/core";
import { baseColors } from "../wilson-ui/constants/colors/base-colors";
import { mediaQuery } from "../wilson-ui/constants/media";

export const useNavBarStyles = makeStyles(() => ({
  container: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: baseColors.black,
    "& li": {
      marginLeft: "40px",
    },
  },
  list: {
    [mediaQuery.maxWidth800]: {
      display: "none",
    },
  },
  button: {
    [mediaQuery.minWidth800]: {
      display: "none",
    },
  },
  icon: {
    color: baseColors.darkGreen,
    cursor: "pointer",
    transition: "0.5s",
    "&::hover": {
      opacity: 0.6,
    },
  },
}));
