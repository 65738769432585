import React, { Dispatch, SetStateAction } from "react";
import { useSelectsStyles } from "./selects-styles.hook";
import Autocomplete from "@mui/material/Autocomplete";
import { DefaultInput } from "../inputs";
import { EMPTY_FUNCTION } from "../../../constants";
import { useIntl } from "react-intl";

interface IProps {
  form: Record<string, any>;
  options: Array<Record<string, any>>;
  name: string;
  value: Array<Record<any, any>>;
  label?: string;
  textSubmit?: Dispatch<SetStateAction<string>>;
  textValue?: string;
  type?: string | undefined;
  optionLabel: string;
  clearTextValue?: () => void;
  variant?: "filled" | "standard" | "outlined" | undefined;
  addHandler?: (value: string) => Promise<void> | void;
  addOnChange?: (type: any) => Promise<void> | void;
  placeholder: string;
  onFocus?: (value: string) => Promise<void> | void;
  limitTags?: number;
  multiple?: boolean,
  isBorder?: boolean
}

export const FormikAutocomplete: React.FC<IProps> = ({
  form,
  options,
  name,
  value,
  label,
  textSubmit,
  textValue,
  optionLabel,
  clearTextValue,
  variant,
  addHandler,
  addOnChange,
  placeholder,
  onFocus,
  limitTags,
  multiple = true,
  isBorder = false
}) => {
  const intl = useIntl();
  const classes = useSelectsStyles();
  const isHaveError: boolean = form?.errors[name] && form?.submitCount;
  const errorClass: string = isHaveError ? classes.errorSelect : "";
  const placeholderValue = value?.length < 1 ? placeholder : "";
  const inputType = variant ? variant : "standard";

  const errorText =
    form.touched[name] && form.errors[name] && form.errors[name];

  const clearTextValueHandler = clearTextValue
    ? clearTextValue
    : EMPTY_FUNCTION;

  const keyPressHandler = (event: any) => {
    if (addHandler === undefined) return;
    if (event.code === "Enter") {
      if (!event.target.value) return;
      addHandler(event.target.value);
    }
  };

  return (
    <div>
      <span className={classes.fieldLabel}>{label}</span>
      <Autocomplete
        className={`${isBorder ? classes.autocompleteBorder: classes.autocomplete} ${errorClass}`}
        fullWidth
        multiple={multiple}
        limitTags={limitTags}
        disablePortal
        value={value}
        getOptionLabel={(option: Record<string, any>) => option[optionLabel]}
        options={options}
        sx={{ width: 300 }}
        id="tags-standard"
        renderInput={(params: Record<string, any>) => (
          <DefaultInput
            value={textValue}
            onFocus={() => onFocus && onFocus("")}
            onChange={(e) => textSubmit && textSubmit(e.target.value)}
            {...params}
            fullWidth
            variant={inputType}
            placeholder={placeholderValue}
            onKeyDown={keyPressHandler}
          />
        )}
        onChange={(event, val) => {
          if (addOnChange) {
            addOnChange(val);
          } else 
            form.setFieldValue(name, val);
          clearTextValueHandler();
        }}
      />
      <div className={classes.errorText}>
        {errorText &&
          intl.formatMessage({
            id: errorText,
          })}
      </div>
    </div>
  );
};
