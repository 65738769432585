import React, { useState } from "react";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { FormikColorField } from "../../../../components/wilson-ui/inputs";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { RadioCheckboxRed } from "../../../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal } from "../modals";
import { useIntl } from "react-intl";
import image from "../../../../assets/images/postModalImage.png";
import { Accordion } from "../accordion";
import { FeedMedia } from "../feed-media";
import { WidgetTypeEnum, ContainerTypeEnum } from "../../../../enum";
import { ContainerTypeSelect } from "../container-type-select";

const NAMES = ["Title", "Description", "Button Text", "Button Color"];

interface IProps {
  form: Record<string, any>;
}

export const DetailsAssets: React.FC<IProps> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "post_preview" })}
      nameArray={NAMES}
      image={image}
      maxHeight="400px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "details_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          {[WidgetTypeEnum.DROP, WidgetTypeEnum.LOCATION_BASED].includes(
            form.values.type.toUpperCase()
          ) && (
            <GridBox
              columns="1fr 1fr 1fr"
              rg="15px"
              cg="25px"
              className={classes.radioButtonContainer}
            >
              <RadioCheckboxRed
                value={form.values.detailsHasCountdown}
                label={intl.formatMessage({ id: "countdown_time" })}
                submit={(event, value) =>
                  form.setFieldValue("detailsHasCountdown", value)
                }
                name="detailsHasCountdown"
              />
            </GridBox>
          )}
          <GridBox columns="1fr 1fr 1fr" cg="30px" rg="30px">
            <FormikField
              label={intl.formatMessage({ id: "button_text" })}
              name="detailsAssetsButtonText"
              submit={form.handleChange}
              value={form.values.detailsAssetsButtonText}
              placeholder={intl.formatMessage({ id: "add_button_text" })}
              {...{ form }}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "button_color" })}
              name="detailsAssetsButtonColor"
              value={form.values.detailsAssetsButtonColor}
              {...{ form }}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "button_text_color" })}
              name="detailsAssetsButtonTextColor"
              value={form.values.detailsAssetsButtonTextColor}
              {...{ form }}
            />
          </GridBox>
          <GridBox columns="1fr" cg="30px" rg="30px">
            <ContainerTypeSelect
              label={intl.formatMessage({ id: "container_color" })}
              value={form.values.detailsAssetsContainerType}
              disableType={
                [WidgetTypeEnum.LOCATION_BASED].includes(
                  form.values.type.toUpperCase()
                )
                  ? ContainerTypeEnum.DARK
                  : undefined
              }
              submit={(value) =>
                form.setFieldValue("detailsAssetsContainerType", value)
              }
              {...{ form }}
            />
          </GridBox>
          {[
            WidgetTypeEnum.DROP,
            WidgetTypeEnum.PROMOTION,
            WidgetTypeEnum.LOCATION_BASED,
          ].includes(form.values.type.toUpperCase()) && (
            <GridBox columns="1fr" cg="30px" rg="30px">
              <FeedMedia
                form={form}
                isVideo={true}
                isFullScreenState={[
                  WidgetTypeEnum.DROP,
                  WidgetTypeEnum.PROMOTION,
                ].includes(form.values.type.toUpperCase())}
              />
            </GridBox>
          )}
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
