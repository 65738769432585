import { makeStyles } from "@material-ui/core";

export const useCarouselListBodyStyle = makeStyles(() => ({
  wrap: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
  },
  tableHint: {
    textAlign: "center",
    paddingTop: "50px",
  },
  button: {
    opacity: 0,
    display: "none",
    marginTop: "40px",
    transition: "1s",
    justifyContent: "flex-end",
  },
  buttonVisible: {
    opacity: 1,
    display: "flex",
  },
}));
