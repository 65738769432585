import { makeStyles } from "@material-ui/core";
import { baseColors } from "../constants/colors";

export const useDropzoneStyles = makeStyles(() => ({
  wrap: {
    border: "1px dotted #808080",
    position: "relative",
    backgroundColor: "#F5F5F5",
    borderRadius: "10px",
  },
  errorWrap: {
    border: `1px solid ${baseColors.red}`,
  },
  container: {
    height: "208px",
    padding: "40px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  valueText: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  preview: {
    height: "208px",
    background: "#F5F5F5",
    borderRadius: "10px",
    padding: "10px",
    position: "relative",
    border: "1px dotted #808080",
    "& img": {
      maxWidth: "100%",
      maxHeight: "188px",
    },
  },
  icon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
    color: baseColors.red,
    transition: "0.7s",
    "&:hover": {
      opacity: 0.7,
    },
  },
}));
