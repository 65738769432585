import { useIntl } from "react-intl";
import { ITableConfig } from "../../components/wilson-ui/types/table-config.inteface";

export const carouselsTableConfig = (): ITableConfig => {
  const intl = useIntl();

  return {
    rowColumns: "250px 250px 250px 450px",
    columns: [
      {
        name: "id",
        type: "text",
        title: intl.formatMessage({ id: "id" }),
        filterType: "",
      },
      {
        name: "title",
        type: "text",
        title: intl.formatMessage({ id: "title" }),
        filterType: "",
      },
      {
        name: "status",
        type: "text",
        title: intl.formatMessage({ id: "status" }),
        filterType: "",
      },
      {
        name: "actions",
        type: "component",
        componentName: 'actions',
        title: '',
        filterType: "",
      },
    ],
  };
};
