import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty, orderBy } from "lodash";
import { useAdditionalSettingsStyles } from "./additional-settings-styles.hook";
import { AdditionalItem } from "../additional-item";
import {
  FormikData,
  FormikSelect,
  FormikTime,
  FormikAutocomplete,
} from "../../../../components/wilson-ui/selects";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { AdditionChannelsItem } from "../addition-channels-item/addition-channels-item";
import { useIntl } from "react-intl";
import { WidgetTypeEnum, PrizeType } from "../../../../enum";
import { WidgetStatus, WidgetStatusForCreateWidget } from "../../../../types";
import {
  getChannels,
  removeWidget,
  getFullCampaignsByString,
} from "../../../../actions/widgets";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { ConfirmModal } from "../../../submissions/components/confirm-modal";
import WidgetsStore from "../../../../store/widgets/widgets.store";

interface IAdditionalSettings {
  form: Record<string, any>;
}

export const AdditionalSettings: React.FC<IAdditionalSettings> = ({ form }) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isDeleteWidgetModalShow, setIsDeleteWidgetModalShow] = useState(false);
  const [campaignsOptions, setCampaignsOptions] = useState<
    Array<Record<string, any>>
  >([]);
  const [filterString, setFilterString] = useState<any>(null);
  const [threadTimeOut, setThreadTimeOut] = useState(0);

  const toggleModal = () =>
    setIsDeleteWidgetModalShow(!isDeleteWidgetModalShow);
  const [offset, setOffset] = useState<number>(0);
  const [chanelsOptions, setChannels] = useState<Array<Record<string, any>>>(
    []
  );

  const classes = useAdditionalSettingsStyles();
  const intl = useIntl();
  const divRef = React.useRef<HTMLDivElement>(null);
  const fixedBlockClass = offset > 80 ? classes.containerOffsetActive : "";
  // const offsetLeft = divRef.current ? divRef.current.offsetLeft : "auto";

  useEffect(() => {
    filterString !== null && onChange(filterString);
  }, [filterString]);

  const onChange = (value: string) => {
    setFilterString(value);
    callSearch(value);
  };

  const onFocus = () => {
    setFilterString("");
    callSearch("");
  };

  const callSearch = (term: string) => {
    if (threadTimeOut) {
      clearTimeout(threadTimeOut);
    }
    const timer: any = setTimeout(function () {
      sendData(term);
    }, 500);
    setThreadTimeOut(timer);
  };

  const sendData = async (term: string) => {
    const data = await getFullCampaignsByString(term || "");
    const campaigns = data.map((item: any) => ({
      ...item,
      prizes: !isEmpty(item?.prizes || [])
        ? orderBy(item?.prizes || [], ["priority"], ["asc"])
        : [
            {
              id: 1,
              priority: 1,
              type: PrizeType.PROMOTION,
              name: "",
              prizeUrl: "",
              qty: "",
              offerCodeUrl: item?.offerCodeUrl || "",
              mediaUrl: "",
              typeformUrl: "",
              richText: "",
              redemptionInstructions: "",
              noId: true,
            },
          ],
    }));
    setCampaignsOptions(campaigns);
    setFilterString(null);
  };

  const logit = () => {
    setOffset(window.scrollY);
  };

  const notifyAllField = () => {
    console.log("form.errors", form.errors);
    const formErrorsArray = Object.values(form.errors);
    const invalidFormatErrors = formErrorsArray.filter(
      (error) => error === "must_correspond"
    );

    const invalidFormatDateErrors = formErrorsArray.filter(
      (error: any) => error && error?.includes("Invalid Date")
    );

    if (invalidFormatDateErrors.length) {
      toast(
        intl.formatMessage({
          id: "please_fill_in_all_date_fields_in_the_correct_format_date",
        })
      );
    }

    if (invalidFormatErrors.length) {
      toast(
        intl.formatMessage({
          id: "please_fill_in_all_fields_in_the_correct_format",
        })
      );
    }
    if (invalidFormatErrors.length !== formErrorsArray.length) {
      return toast(
        intl.formatMessage({ id: "please_fill_in_all_required_fields" })
      );
    }
  };

  const campaignsOptionsValue = campaignsOptions.filter((item: any) => {
    let isUsed = false;
    if (item.id === form.values?.campaign?.id) isUsed = true;
    if (!isUsed) {
      const prizes = orderBy(item?.prizes || [], ["priority"], ["asc"]);
      return {
        ...item,
        prizes: !isEmpty(prizes)
          ? prizes
          : [
              {
                id: 1,
                priority: 1,
                type: PrizeType.PROMOTION,
                name: "",
                prizeUrl: "",
                qty: "",
                offerCodeUrl: item?.offerCodeUrl || "",
                mediaUrl: "",
                typeformUrl: "",
                richText: "",
                redemptionInstructions: "",
                noId: true,
              },
            ],
      };
    }
  });

  const submitHandler = () => {
    if (form.touched && !form.isValid) notifyAllField();
    form.handleSubmit();
  };

  const removeWidgetHandler = async (id: string) => {
    WidgetsStore.isLoading = true;
    await removeWidget(id);
    WidgetsStore.isLoading = false;
    history.push("/widgets");
    toggleModal();
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  useEffect(() => {
    const sendData = async () => {
      const newChannelsArray = await getChannels();
      setChannels(newChannelsArray);
    };
    sendData();
  }, []);

  return (
    <div ref={divRef}>
      <div className={`${fixedBlockClass} ${classes.container}`}>
        <AdditionalItem title={intl.formatMessage({ id: "status" })}>
          <FormikSelect
            {...{ form }}
            name="status"
            value={
              form.values.status === WidgetStatusForCreateWidget.hidden
                ? WidgetStatusForCreateWidget.hidden
                : form.values.status ===
                    WidgetStatusForCreateWidget.onTheBench ||
                  form.values.status === WidgetStatus.onTheBench
                ? WidgetStatusForCreateWidget.onTheBench
                : WidgetStatusForCreateWidget.itsAGameTime
            }
            options={Object.values(WidgetStatusForCreateWidget)}
            placeholder={intl.formatMessage({ id: "set_status" })}
          />
        </AdditionalItem>
      </div>
      <div className={`${fixedBlockClass} ${classes.container}`}>
        <h3>{intl.formatMessage({ id: "feed" })}</h3>
        <AdditionalItem
          title={intl.formatMessage({ id: "start_expiration_date" })}
        >
          <FlexBox direction="row" gap="10px">
            <FormikData
              {...{ form }}
              time={form.values.startDateTime}
              value={form.values.startDateDate}
              name="startDateDate"
              nameTime="startDateTime"
            />
            <FormikData
              {...{ form }}
              time={form.values.expirationDateTime}
              value={form.values.expirationDateDate}
              name="expirationDateDate"
              nameTime="expirationDateTime"
            />
          </FlexBox>
        </AdditionalItem>
        <AdditionalItem
          title={intl.formatMessage({ id: "start_expiration_time" })}
        >
          <FlexBox direction="row" gap="10px">
            <FormikTime
              {...{ form }}
              date={form.values.startDateDate}
              value={form.values.startDateTime}
              name="startDateTime"
              label={`${intl.formatMessage({
                id: "start_time",
              })}`}
            />
            <FormikTime
              {...{ form }}
              date={form.values.expirationDateDate}
              value={form.values.expirationDateTime}
              name="expirationDateTime"
              label={`${intl.formatMessage({
                id: "expiration_time",
              })}`}
            />
          </FlexBox>
        </AdditionalItem>
        {[WidgetTypeEnum.EXTERNAL_LINK, WidgetTypeEnum.POST].includes(
          form.values.type.toUpperCase()
        ) && (
          <AdditionalItem title={intl.formatMessage({ id: "channels" })}>
            <FlexBox direction="column" gap="10px">
              {chanelsOptions.map(
                (item: Record<string, any>, index: number) => (
                  <AdditionChannelsItem key={index} {...{ form }} item={item} />
                )
              )}
            </FlexBox>
          </AdditionalItem>
        )}
      </div>
      {[WidgetTypeEnum.PROMOTION, WidgetTypeEnum.INSTANT_WIN].includes(
        form.values.type.toUpperCase()
      ) && (
        <div className={`${fixedBlockClass} ${classes.container}`}>
          <h3>{intl.formatMessage({ id: "campaign" })}</h3>
          <AdditionalItem title="">
            <FlexBox direction="column" gap="10px">
              <FormikAutocomplete
                placeholder="select_campaign"
                optionLabel="name"
                options={campaignsOptionsValue}
                label=""
                multiple={false}
                isBorder={true}
                name="campaign"
                value={form.values.campaign}
                {...{ form }}
                textSubmit={setFilterString}
                textValue={filterString}
                addHandler={onChange}
                onFocus={onFocus}
              />
            </FlexBox>
          </AdditionalItem>
        </div>
      )}

      <GridBox columns="1fr 2fr" cg="10px" style={{ marginTop: "40px" }}>
        <UiButton
          type="button"
          buttonType="white"
          onClick={toggleModal}
          disabled={form.isSubmitting || !id}
          className={classes.deleteButton}
        >
          {intl.formatMessage({ id: "delete" })}
        </UiButton>
        <UiButton
          buttonType="black"
          onClick={submitHandler}
          disabled={form.isSubmitting}
          className={classes.saveButton}
        >
          {intl.formatMessage({ id: "save" })}
        </UiButton>
      </GridBox>
      {isDeleteWidgetModalShow && (
        <ConfirmModal
          toggleModal={toggleModal}
          submit={() => removeWidgetHandler(id)}
          title="Are you sure?"
        />
      )}
    </div>
  );
};
