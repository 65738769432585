import React, { FC } from "react";
import { useChannelsItemStyles } from "../../channels-item-styles.hook";

import { IChanelUpgradeElement } from "../../../../../types";

interface IProps {
  item: IChanelUpgradeElement;
}

export const ChannelElement: FC<IProps> = ({ item }) => {
  const classes = useChannelsItemStyles();

  if (!item) return null;

  return (
    <div
      className={classes.container}
      // style={{ background: item.background, color: item.color }}
    >
      {item.name}
    </div>
  );
};
