import { makeStyles } from "@material-ui/core";

export const useSubmissionsConfirmModalStyles = makeStyles(() => ({
  container: {
    display: "grid",
    padding: "40px",
    rowGap: "60px",
  },
  header: {},
  body: {
    display: "grid",
    rowGap: "10px",
  },
  title: {
    textTransform: "uppercase",
    fontSize: "21px",
    textAlign: "center",
    lineHeight: "1.5",
  },
}));
