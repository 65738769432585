import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";

export const useAccordionStyles = makeStyles(() => ({
  root: {
    boxShadow: "none !important",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "&::before": {
      content: "none !important",
    },
  },
  arrow: {
    border: "1px solid " + baseColors.grey,
    width: 35,
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
