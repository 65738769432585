import * as Yup from "yup";
import { URL_REGEX } from "../../constants";

export const AddWidgetsPromotionSchema = Yup.object().shape({
  site: Yup.string()
    .matches(URL_REGEX, "must_correspond")
    .required("Required"),
  description: Yup.string()
    .min(3, "min_length_3")
    .max(50000, "max_length_50000")
    .required("Required"),
  favoriteTitle: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string().required("Required"),
  }),
  favoriteSiteUrl: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string()
      .matches(URL_REGEX, "must_correspond")
      .required("Required"),
  }),
  favoriteThumbnailImage: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.mixed().required("Required"),
  }),
  campaign: Yup.mixed().required("Required"),
  feedMedia: Yup.array().when("description", (description, schema) => {
    return schema.test({
      test: (feedMediaArr: Array<Record<string, any>>) => {
        let isValid = true;
        if (feedMediaArr.length) {
          feedMediaArr.forEach((item: Record<string, any>) => {
            if (!item.assetUrl) {
              isValid = false;
            }
          });
        } else {
          isValid = false;
        }
        return isValid;
      },
    });
  }),
});
