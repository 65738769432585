import { axiosAuthorized } from "../axios";

const SubmissionsService = {
  async getSubmissions(
    rows: number,
    page: number,
    order: string,
    sortByName: string,
    filterType: Array<string>,
    filterWinner: Array<string>,
    sortByTitle: Array<string>
  ): Promise<Array<Record<string, any>>> {
    let sortTypeString = "";
    let sortWinnerTypeString = "";
    let sortByTitleString = "";
    filterType.forEach(
      (item) =>
        (sortTypeString =
          sortTypeString + `&filteringType[]=${item.toUpperCase()}`)
    );
    filterWinner.forEach(
      (item) =>
        (sortWinnerTypeString =
          sortWinnerTypeString + `&filteringWinner[]=${item.toUpperCase()}`)
    );
    sortByTitle.forEach(
      (item) =>
        (sortByTitleString = sortByTitleString + `&filteringTitle[]=${item}`)
    );

    const data = await axiosAuthorized.get(
      `promotions/submissions?fieldName=${sortByName}&order=${order}&limit=${rows}&pageNumber=${page}${sortTypeString}${sortWinnerTypeString}${sortByTitleString}`
    );

    return data.data;
  },
};

export default SubmissionsService;
