import { makeStyles } from "@material-ui/core";

export const useSwipeUpUrlStyle = makeStyles(() => ({
  container: {
    padding: "15px",
    borderRadius: "24px",
    backgroundColor: "#F5F5F5"
  },
  button: {
    width: "auto",
    minWidth: "auto",
    height: 32,
    padding: "2px 22px",
    borderWidth: 1.5,
    borderRadius: 100,
  },
  divListVideos: {
    display: 'none',
    width: 0,
    height: 0,
  }
}));
