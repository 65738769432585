import React from "react";
import { observer } from "mobx-react-lite";
import { MainLayout } from "../../components";
import { CarouselHeader } from "./components/carousel-header";
import { GridBox } from "../../components/wilson-ui/box";
import { useCarouselPageStyles } from "./carousel-page-styles.hook";
import { CarouselForm } from "./components/carousel-form";
import { CarouselList } from "./components/carousel-list";
import CarouselStore from "../../store/carousel/corousel.store";
import { Text } from "../../components/wilson-ui/text";
import { Spiner } from "../../components/wilson-ui/spiner";

export const CarouselPage: React.FC = observer(() => {
  const classes = useCarouselPageStyles();

  const body = CarouselStore.isLoading  ? (
    <Spiner />
  ) : (
    <>
      {
        CarouselStore.error ? (
          <GridBox className={classes.container__error}>
            <Text fontSize={"21px"}>{CarouselStore.error}</Text>
          </GridBox>
        ) : (
          <>
            <CarouselHeader />
            <GridBox className={classes.container}>
              <CarouselForm />
              <CarouselList />
            </GridBox>
          </>
        )
      }
    </>
  )

  return (
    <MainLayout>
      {body}
    </MainLayout>
  );
});
