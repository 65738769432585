import { Box } from "@material-ui/core";
import styled from "styled-components";

interface IFlexBox {
  items?: string;
  content?: string;
  direction?: string;
  wrap?: string;
  gap?: string;
  cg?: string;
}

interface IGridBox {
  rows?: string;
  items?: string;
  content?: string;
  columns?: string;
  cg?: string;
  rg?: string;
  col?: number;
}

interface IRadiusBox {
  width?: string;
  height?: string;
}

export const GridBox = styled(Box)<IGridBox>`
  display: grid;
  align-items: ${(props) => props.items && props.items};
  justifycontent: ${(props) => props.content && props.content};

  grid-template-columns: ${(props) =>
    props.col
      ? "repeat(" + props.col + ", 1fr)"
      : props.columns && props.columns};
  grid-template-rows: ${(props) => props.rows && props.rows};
  column-gap: ${(props) => props.cg && props.cg};
  row-gap: ${(props) => props.rg && props.rg};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
`;

export const FlexBox = styled(Box)<IFlexBox>`
  display: flex;
  width: ${(props) => props.width && props.width};
  align-items: ${(props) => props.items && props.items};
  justify-content: ${(props) => props.content && props.content};
  flex-direction: ${(props) => props.direction && props.direction};
  flex-wrap: ${(props) => props.wrap && props.wrap};
  gap: ${(props) => props.gap && props.gap};
  column-gap: ${(props) => props.cg && props.cg}
  marginbottom: ${(props) => props.marginBottom && props.marginBottom};
`;

export const RadiusBox = styled(Box)<IRadiusBox>`
  display: inline-flex;
  border-radius: 50%;
  border: 1px solid #a2a8ae;
  width: ${(props) => props.width && props.width};
  height: ${(props) => props.height && props.height};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
