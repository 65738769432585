import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { useCopyItemsStyle } from "./copy-item-style.hook";

interface IProps {
  row: Record<string, any>;
  col: string;
  maxLengthProps: number;
}

export const CopyItem: React.FC<IProps> = ({
  row,
  col,
  maxLengthProps,
  children,
}) => {
  const classes = useCopyItemsStyle();
  const maxLength =
    row[col]?.length < maxLengthProps ? row[col].length : maxLengthProps;
  const email =
    row[col]
      ?.slice(0, maxLength)
      .split("")
      .filter((item: string, index: number) =>
        index !== maxLengthProps - 1 ? item : item === "." ? "" : item
      )
      .join("") ?? "";
  const emailFull = row[col] && row[col];
  const [isCopied, setCopied] = useState(false);
  const copyIcon = !isCopied ? <ContentCopyIcon /> : <CheckIcon />;

  const isShorted = row[col]?.length > maxLengthProps ? "..." : "";

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(emailFull)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <span className={classes.container} onClick={handleCopyClick}>
      <span>
        <input className={classes.none} type="text" value={email} readOnly />
        {emailFull}
        {copyIcon}
      </span>
      <label>{children ? children : `${email}${isShorted}`}</label>
    </span>
  );
};
