import { makeAutoObservable } from "mobx";
import SubmissionsService from "../../services/submissions.service";

export interface ISubmission {
  length: number;
  data: Array<Record<string, any>>;
}

class SubmissionsStore {
  submission: ISubmission = { length: 0, data: [] };
  isLoading = false;
  constructor() {
    makeAutoObservable(this);
  }

  async sendData(
    rows: number,
    page: number,
    order: string,
    sortByName: string,
    filterType: Array<string>,
    filterWinner: Array<string>,
    sortByTitle: Array<string>
  ) {
    this.isLoading = true;
    const data: Record<string, any> = await SubmissionsService.getSubmissions(
      rows,
      page,
      order,
      sortByName,
      filterType,
      filterWinner,
      sortByTitle
    );

    this.setSubmissions({ length: data.length, data: data.submissions });
    this.isLoading = false;
  }

  setWinner(widgetId: string, userId: string) {
    const updatedSubmissions: Array<Record<string, any>> =
      this.submission.data.map((item) => {
        if (userId === item.userId && widgetId === item.widgetId) {
          item.winner = true;
        }
        return item;
      });

    this.setSubmissions({
      data: updatedSubmissions,
      length: this.submission.length,
    });
  }

  getSubmissions() {
    return this.submission;
  }

  setSubmissions(submissions: ISubmission) {
    this.submission = submissions;
  }
}

export default new SubmissionsStore();
