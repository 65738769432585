import React, { useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FlexBox } from "../../../../../components/wilson-ui/box";
import { UiButton } from "../../../../../components/wilson-ui/buttons";
import { UIModalUpload } from "../../../../../components/wilson-ui/modals";
import { DropzoneImageVideo } from "../../../../../components/wilson-ui/dropzone";
import { useModalStyle } from "./modal-style.hook";

interface IProps {
  loading: boolean;
  title?: string;
  name?: string;
  filePreview?: string;
  form: Record<string, any>;
  closeHandler: () => void;
  submitHandler: (file: Blob | string, type: string) => void;
}

export const UploadModal: React.FC<IProps> = ({
  form,
  name,
  loading,
  title,
  filePreview,
  closeHandler,
  submitHandler
}) => {
  const intl = useIntl();
  const classes = useModalStyle();
  const [file, setFile] = useState<Blob | string | any>(null);

  const notify = () => toast(intl.formatMessage({ id: "select_image_file" }));
  const onUpload = () => {
    if (file && file?.size > 0) {
      const type = file.type.split("/")[0];
      submitHandler(file, type)
    } else notify();
    return;
  }

  return (
    <UIModalUpload width="700px" closeHandler={closeHandler}>
      <FlexBox items="center" content="flex-end">
        <CloseOutlinedIcon style={{ cursor: 'pointer' }} onClick={closeHandler} />
      </FlexBox>
      <FlexBox className={classes.header}>
        <h4>{title}</h4>
      </FlexBox>
      <div style={{ marginBottom: '24px' }}>
        <DropzoneImageVideo
          loading={loading}
          sendData={file => setFile(file)}
          {...{ form }}
          name={name || ''}
          filePreview={filePreview}
        />
      </div>
      <FlexBox content="flex-end">
        <UiButton
          buttonType="white"
          onClick={closeHandler}
          className={classes.cancelButton}
        >
          {intl.formatMessage({ id: "cancel" })}
        </UiButton>
        <UiButton
          buttonType="black"
          onClick={onUpload}
          className={classes.uploadButton}
        >
          {intl.formatMessage({ id: "upload" })}
        </UiButton>
      </FlexBox>
    </UIModalUpload>
  );
};
