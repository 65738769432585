import { makeStyles } from "@material-ui/core";
import { mediaQuery } from "../../components/wilson-ui/constants/media";
import { baseColors } from "../../components/wilson-ui/constants/colors";

export const useCreateWidgetsStyles = makeStyles(() => ({
  generalSettingsAccordion: {
  },
  accordionBody: {
    marginTop: 35,
  },
  headerContainer: {
    alignItems: "center",
    gap: "10px",
    "& h3": {
      fontWeight: 900,
      fontSize: "24px",
      textTransform: "uppercase",
      fontFamily: "GT America Extended Bold, sans-serif",
      "& span": {
        marginRight: '10px'
      }
    },
  },
  sectionContainer: {
    marginTop: "50px",
  },
  container: {
    "& h4": {
      color: "#000",
      display: "block",
      fontSize: "14px",
      fontFamily: "'GT America', sans-serif",
      fontWeight: "500",
      marginBottom: "15px",
    },
  },
  [mediaQuery.maxWidth1024]: {
    container: {
      display: "flex !important",
      flexDirection: "column !important",
      gap: "50px",
    },
  },
  errorTitle: {
    textTransform: "uppercase",
    color: baseColors.red,
    fontSize: "20px",
    fontWeight: 700,
  },
  feedTable: {
    "& h4": {
      marginBottom: "0px",
    },
  },
  resultsTable: {
    "& h4": {
      marginBottom: "0px",
    },
    // "& .MuiDataGrid-footerContainer": {
    //   display: "none !important",
    // },
  },
  icon: {
    fontSize: "1.1rem !important",
  },
  removeIcon: {
    color: baseColors.red,
  },
  image: {
    width: "32px",
    height: "32px",
    borderRadius: '50%'
  },
  errorText: {
    fontFamily: "'GT America',  sans-serif",
    fontSize: "12px",
    color: baseColors.red,
    fontWeight: 500,
    display: "block",
    marginTop: "5px",
  },
}));
