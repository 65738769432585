import { orderBy, isEmpty } from "lodash";
import { getNewItem } from "../../pages/create-widgets/components/feed-media/helpers/get-default-item";
import { DropStatusEnum } from "../../types";
import { WidgetTypeEnum, prizeTypesOptions, PrizeType, WidgetFeedTitleFont } from "../../enum";
import { formatLocation } from "./place.helper"

type FeedMediaLinkList = Array<{
  link: string;
  textColor: string;
  priority: number;
  mediaKey: string;
  isFullScreen: boolean;
}>;

export const getWithPriority = (data: Record<string, any>[]) => {
  const storyItems: Record<string, any>[] = data
    ? data.map((item: Record<string, any>) => {
        return {
          ...item,
          preview: item.assetLink ?? "",
          type: item.type,
        };
      })
    : [];

  storyItems.sort((a, b) => a.priority - b.priority);

  return storyItems;
};

export const UpgradeWidgetToEditHelper = (
  item: Record<string, any>
): Record<string, any> => {
  const sortedStoryItems = getWithPriority(item.stories);
  const feedMediaLinkList = (item.feedMediaLinkList as FeedMediaLinkList).filter(item => item.link).map(
    ({ link, mediaKey, isFullScreen }) => link && getNewItem(mediaKey, link, isFullScreen)
  );

  let otherWidget = {};

  switch(item.type) {
    case WidgetTypeEnum.DROP: {
      otherWidget = {
        richContent: item?.widgetDrop?.richContent,
        dropName: item?.widgetDrop?.dropName,
        dropUrl: item?.widgetDrop?.dropUrl,
        dropStatus: item?.widgetDrop?.dropStatus || DropStatusEnum.AUTOMATIC,
        liveDateDate: item?.widgetDrop?.liveDate,
        liveDateTime: item?.widgetDrop?.liveDate
          ? new Date(item?.widgetDrop?.liveDate)
          : null,
        hoursBeforeDrop: item?.widgetDrop?.hoursBeforeDrop || undefined,
    
        hasPinned: item?.widgetDrop?.hasPinned || item.hasPinned || false,
        pinnedTitleVisibility:
          item?.widgetDrop?.pinnedTitleVisibility ||
          item?.pinnedTitleVisibility,
        pinnedHasCountdown:
          item?.widgetDrop?.pinnedHasCountdown ||
          item?.pinnedHasCountdown,
        pinnedTitleColor:
          item?.widgetDrop?.pinnedTitleColor || item?.pinnedTitleColor,
        pinnedTitleSize:
          item?.widgetDrop?.pinnedTitleSize || item?.pinnedTitleSize,
        pinnedTextColor:
          item?.widgetDrop?.pinnedTextColor || item?.pinnedTextColor,
        pinnedFillColor:
          item?.widgetDrop?.pinnedFillColor || item?.pinnedFillColor,
        pinnedBackgroundColor:
          item?.widgetDrop?.pinnedBackgroundColor ||
          item?.pinnedBackgroundColor,
        pinnedMediaUrl: item.pinnedMediaUrl,
        pinnedMediaLink: item.pinnedMediaLink,
    
        alertTitleVisibility: item?.widgetDrop?.alertTitleVisibility,
        alertHasCountdown: item?.widgetDrop?.alertHasCountdown,
        alertCanBeShared: item?.widgetDrop?.alertCanBeShared,
        alertTitleColor: item?.widgetDrop?.alertTitleColor || "",
        alertTitleSize: item?.widgetDrop?.alertTitleSize,
        alertTitleHighlightColor: item?.widgetDrop?.alertTitleHighlightColor || "",
        alertButtonTextColor: item?.widgetDrop?.alertButtonTextColor || "",
        alertButtonColor: item?.widgetDrop?.alertButtonColor || "",
        alertBorderColor: item?.widgetDrop?.alertBorderColor || "",
        alertCountdownTimerColor: item?.widgetDrop?.alertCountdownTimerColor || "",
        alertMediaUrl: item.alertMediaUrl,
        alertMediaLink: item.alertMediaLink,

        detailsHasCountdown: item?.detailsHasCountdown || item?.widgetDrop?.detailsHasCountdown,
        
        successTitle: item?.widgetDrop?.successTitle || "",
        successTitleColor: item?.widgetDrop?.successTitleColor || "",
        successDescription: item?.widgetDrop?.successDescription || "",
        successDescriptionColor: item?.widgetDrop?.successDescriptionColor || "",
        successBackgroundColor: item?.widgetDrop?.successBackgroundColor || "",
        successMediaUrl: item.successMediaUrl,
        successMediaLink: item.successMediaLink,
        missedTitle: item?.widgetDrop?.missedTitle,
        missedTitleColor: item?.widgetDrop?.missedTitleColor || "",
        missedDescription: item?.widgetDrop?.missedDescription || "",
        missedDescriptionColor: item?.widgetDrop?.missedDescriptionColor || "",
        missedBackgroundColor: item?.widgetDrop?.missedBackgroundColor || "",
      }
      break;
    }
    case WidgetTypeEnum.LOCATION_BASED: {
      otherWidget = {
        address: formatLocation(item.widgetLocationBased?.location),
        lat: item.widgetLocationBased?.location?.latitude,
        lng: item.widgetLocationBased?.location?.longitude,
        location: item.widgetLocationBased?.location,
        city: item.widgetLocationBased?.location?.city,
        country: item.widgetLocationBased?.location?.country,
        state: item.widgetLocationBased?.location?.state,
        street: item.widgetLocationBased?.location?.street,
        postalCode: item.widgetLocationBased?.location?.postalCode,
        radius: item.widgetLocationBased?.radius,
        locationAlias: item.widgetLocationBased?.locationAlias,
        locationSuccessBackgroundColor: item.widgetLocationBased?.locationSuccessBackgroundColor,
        locationButtonPostRedemptionColor: item.widgetLocationBased?.locationButtonPostRedemptionColor,
        locationButtonText: item.widgetLocationBased?.locationButtonText,
        locationButtonTextColor: item.widgetLocationBased?.locationButtonTextColor,
        locationButtonColor: item.widgetLocationBased?.locationButtonColor,
        locationMediaUrl: item?.locationMediaUrl,
        locationMediaLink: item?.locationMediaLink,
    
        detailsHasCountdown: item?.detailsHasCountdown || item?.widgetLocationBased?.detailsHasCountdown,

        successTitle: item?.widgetLocationBased?.successTitle || "",
        successTitleColor: item?.widgetLocationBased?.successTitleColor || "",
        successDescription: item?.widgetLocationBased?.successDescription || "",
        successDescriptionColor: item?.widgetLocationBased?.successDescriptionColor || "",
        successBackgroundColor: item?.widgetLocationBased?.successBackgroundColor || "",
      }
      break;
    }
    default: {
      otherWidget = {};
    }
  }

  const widget = {
    title: item.title,
    subtitle: item.subTitle ? item.subTitle : "",
    type: item.type,
    background: item.backgroundColor,
    site: item.webViewUrl,
    tags: item.tags,
    description: item.description,
    terms: item.terms,
    status: item.status ? item.status : "",
    scanRequire: item.scanRequire,
    shareIcon: item.canBeShared,
    titleVisibility: item.titleVisibility,
    buttonVisibility: item.buttonVisibility,
    exclusive: item.isExclusive,
    favoriteIcon: item.canBeLiked,
    countdownTime: item.hasCountdown,
    fullHeightVisibility: item.fullHeightVisibility,
    startDateDate: item.startDate,
    startDateTime: new Date(item.startDate),
    expirationDateDate: item.expirationDate,
    expirationDateTime: new Date(item.expirationDate),
    channels: item.channels.map((item: Record<string, any>) => item.id),
    feedBorderColor: item.feedBorderColor ? item.feedBorderColor : "",
    feedTitleColor: item.feedTitleColor ? item.feedTitleColor : "",
    feedTitleSize: item.feedTitleSize,
    feedTitleFont: item.feedTitleFont || WidgetFeedTitleFont.GT_AMERICA,
    feedTitleHighlightColor: item.feedTitleHighlightColor,
    feedAssetsButtonText: item.feedButtonText,
    feedAssetsButtonColor: item.feedButtonColor,
    feedMedia: feedMediaLinkList || [],
    feedMediaLink: item.feedMediaLink,
    feedDropMediaLink: item.feedDropMediaLink,
    feedAssetsButtonImage:
      item.type === WidgetTypeEnum.DROP ||
      item.type === WidgetTypeEnum.PROMOTION ||
      item.type === WidgetTypeEnum.INSTANT_WIN ||
      item.type === WidgetTypeEnum.LOCATION_BASED
        ? item.feedDropMediaUrl
        : item.feedMediaUrl,
    feedAssetsButtonTextColor: item.feedButtonTextColor
      ? item.feedButtonTextColor
      : "",

    storyAuthorName: item.storyAuthorName || "",
    storyAuthorNameColor: item.storyAuthorNameColor || "",
    storyDescription: item.storyDescription || "",
    storyAuthorAvatar: item.storyAuthorAvatarUrl || "",
    storyAuthorAvatarLink: item.storyAuthorAvatarLink || "",
    storyButtonStyle: item.storyButtonStyle || " ",
    storyImageMap: item.storyImageMap || " ",
    storyAssetsImage: null,
    storyButtonText: item.storyButtonText || "",
    storySwipe: sortedStoryItems,

    detailsAssetsButtonText: item.detailsButtonText,
    detailsAssetsButtonColor: item.detailsButtonColor,
    detailsAssetsButtonTextColor: item.detailsButtonTextColor || "",
    detailsAssetsBackgroundType: item.detailsBackgroundType || "",
    detailsAssetsContainerType: item.detailsContainerType || "",
    detailsAssetsBackgroundColor: item.detailsBackgroundColor || "",
    detailsAssetsWinBackgroundColor: item.detailsWinBackgroundColor || "",
    detailsAssetsMissBackgroundColor: item.detailsMissBackgroundColor || "",

    successAssetsButtonText: item.promotionButtonText,
    successAssetsButtonColor: item.promotionButtonColor,
    successAssetsButtonImage: item.promotionImageUrl,
    successAssetsMediaLink: item.promotionMediaLink,
    successAssetsButtonTextColor: item.promotion?.buttonTextColor
      ? item.promotion?.buttonTextColor
      : "",

    favoriteTitle: item.favoriteTitle,
    favoriteSiteUrl: item.favoriteSiteUrl,
    favoriteThumbnailImage: item.thumbnailUrl,
    favouriteThumbnailLink: item.thumbnailLink,

    thumbnailLockerUrl: item.thumbnailLockerUrl || "",
    thumbnailLockerLink: item.thumbnailLockerLink || "",

    id: item.id,

    promotionsTitle: item.promotionTitle,
    promotionsTitleColor: item.promotionTitleColor || "",
    promotionsDescription: item.promotionDescription,
    promotionsDescriptionColor: item.promotionDescriptionColor || "",
    promotionsCollaborationImage: item.promotionCollaborationImgUrl,
    promotionCollaborationImgLink: item.promotionCollaborationImgLink,
    promotionsModalImage: item.promotionModalImgUrl,
    promotionModalImgLink: item.promotionModalImgLink,
    promotionsBackgroundColor: item.promotionBackgroundColor,
    promotionsModalBackgroundImage: item.promotionModalBackgroundImgUrl,
    promotionsModalBackgroundImgLink: item.promotionModalBackgroundImgLink,
    promotionsModalVisibility: item.promotionModalVisibility,
    promotionsModalBackgroundColor: item.promotionModalBackgroundColor,
    promotionsModalTitle: item.promotionModalTitle,
    promotionsModalTitleColor: item.promotionModalTitleColor,

    ...otherWidget, // parse values other widget: drop, location-based

    feedItems: item.childDropWidgets,
    campaign: item.campaign ? {
      ...item.campaign,
      prizes: !isEmpty(item.campaign?.prizes || [])
        ? orderBy(item.campaign?.prizes || [], ["priority"], ["asc"])
        : [
            {
              id: 1,
              priority: 1,
              type: PrizeType.PROMOTION,
              name: "",
              prizeUrl: "",
              qty: "",
              offerCodeUrl: item.campaign?.offerCodeUrl || "",
              mediaUrl: "",
              mediaLink: "",
              typeformUrl: "",
              richText: "",
              redemptionInstructions: "",
              noId: true,
            },
          ],
    }: null,
  };

  return widget;
};

export const UpgradeInterestToEditHelper = (
  item: Record<string, any>
): Record<string, any> => {
  const interest = {
    id: item.id,
    name: item.name,
    category: item.category || "",
    type: item.type ? item.type : "",
    status: item.status ? item.status : "",
    mediaLink: item.mediaLink ? item.mediaLink : "",
    mediaUrl: item.mediaUrl ? item.mediaUrl : "",
  };

  return interest;
};

export const UpgradeCampaignToEditHelper = (
  item: Record<string, any>
): Record<string, any> => {
  const prizes = orderBy(item?.prizes || [], ["priority"], ["asc"]);
  const campaign = {
    id: item.id,
    type: item.type ? item.type : "",
    status: item.status ? item.status : "",
    name: item.name,
    startDateDate: item.startDate,
    startDateTime: new Date(item.startDate),
    expirationDateDate: item.expirationDate,
    expirationDateTime: new Date(item.expirationDate),
    category: item.category || "",
    webViewUrl: item.webViewUrl || "",
    offerCodeUrl: item?.offerCodeUrl || " ",
    hits: item.hits ?? 0,
    misses: item.misses ?? 0,
    prizeTypes: !isEmpty(prizes)
      ? prizes?.map((prize: any) => ({
          ...prizeTypesOptions.find((option) => option.value === prize.type),
          id: prize.id,
          noId: false,
        }))
      : [
          {
            id: 1,
            label: "Promotion Code",
            value: "PROMOTION",
            name: "upload_offer_codes",
            noId: true,
          },
        ],
    prizes: !isEmpty(prizes || [])
      ? prizes.map((prize: any) => ({
          id: prize.id,
          priority: prize.priority,
          type: prize.type,
          name: prize.name,
          subTitle: prize.subTitle,
          qty: prize.qty || 0,
          prizeUrl: prize.prizeUrl || " ",
          offerCodeUrl: prize.type === PrizeType.PROMOTION ? prize?.offerCodeUrl || item?.offerCodeUrl: " ",
          mediaUrl: prize.mediaUrl,
          mediaLink: prize.mediaLink,
          typeformUrl: prize.typeformUrl || " ",
          richText: prize.richText,
          redemptionInstructions: prize.redemptionInstructions,
        }))
      : [
          {
            id: 1,
            priority: 1,
            type: PrizeType.PROMOTION,
            name: "",
            prizeUrl: "",
            qty: 0,
            offerCodeUrl: item?.offerCodeUrl || "",
            mediaUrl: "",
            mediaLink: "",
            typeformUrl: " ",
            richText: "",
            redemptionInstructions: "",
            noId: true,
          },
        ],
  };

  return campaign;
};
