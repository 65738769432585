import { isEmpty } from 'lodash';
export function placeToAddress(place: any) {
  const address: any = {
    latitude: place?.geometry?.location?.lat(),
    longitude: place?.geometry?.location?.lng(),
    street: ''
  };
  place?.address_components?.forEach(function (c: Record<string, any>) {
    switch (c.types[0]) {
      case 'street_number':
        address.streetNumber = c?.short_name;
        break;
      case 'route':
        address.street = c?.short_name;
        break;
      case 'neighborhood':
      case 'locality': // North Hollywood or Los Angeles?
        address.city = c?.short_name;
        break;
      case 'administrative_area_level_1': //  Note some countries don't have states
        address.state = c?.short_name;
        if (!address.city) address.city = c?.short_name;
        break;
      case 'postal_code':
        address.postalCode = c.short_name;
        break;
      case 'country':
        address.country = 'US';
        break;
      /*
       *   . . .
       */
    }
  });

  if (address?.streetNumber && address?.street) {
    address.street = `${address.streetNumber} ${address.street}`;
  }
  return address;
}

export const formatLocation = (location: {
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  state: string;
  street: string;
  postalCode: string;
}) => {
  if (isEmpty(location)) return '';

  const parts = [];

  if (typeof location?.street === 'string' && location?.street !== '') {
    parts.push(location?.street);
  }

  if (typeof location?.city === 'string' && location?.city !== '') {
    parts.push(location?.city);
  }

  if (typeof location?.postalCode === 'string' && location?.postalCode !== '') {
    parts.push(location?.postalCode);
  }

  if (typeof location?.state === 'string' && location?.state !== '') {
    parts.push(location?.state);
  }

  if (typeof location?.country === 'string' && location?.country !== '') {
    parts.push(location?.country);
  }

  return parts?.filter((item) => typeof item === 'string' && item !== '')?.join(', ');
};
