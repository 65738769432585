import { Dispatch } from "react";

export const setSwipeImage = (
  id: string,
  file: string,
  preview: string,
  hasSound: boolean,
  isLoading: boolean,
  swipeState: Record<string, any>,
  setSwipeState: Dispatch<any>,
  type?: string
): void => {
  if (isLoading) return;
  const newSwipeState = swipeState.map((item: any) => {
    if (item.id === id) {
      item.assetUrl = file;
      item.preview = preview;
      item.hasSound = hasSound;
      if (type) item.type = type.toUpperCase();
    }

    return item;
  });
  setSwipeState(newSwipeState);
};
