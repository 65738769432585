import { LOCALES } from "../../I18n/locales";

type LanguageType = LOCALES.ENGLISH | LOCALES.RUSSIAN;

interface ILanguageHook {
  getLanguage: () => LanguageType;
  setLanguage: (language: string) => void;
}

export const useLanguage = (): ILanguageHook => {
  const setLanguage = (language: string): void => {
    localStorage.setItem("language", language);
    window.location.reload();
  };

  const getLanguage = () => {
    const chosenLanguage = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : LOCALES.ENGLISH;
    return chosenLanguage as LanguageType;
  };

  return { getLanguage, setLanguage };
};
