import React, { useState } from "react";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { FormikColorField } from "../../../../components/wilson-ui/inputs/formik-color-field";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import {
  // Dropzone,
  DropzoneImageVideo,
} from "../../../../components/wilson-ui/dropzone";
import { QuestionIcon } from "../../../../components/icons";
import { useIntl } from "react-intl";
import { axiosAuthorized } from "../../../../axios";
import { ItemQuestionModal } from "../modals";
import image from "../../../../assets/images/testImage.png";
import { EMPTY_FUNCTION } from "../../../../constants";
import { FeedMedia } from "../feed-media";
import { RadioCheckboxRed } from "../../../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { TitleSizeSelect } from "../title-size-select";
import { FontTypeSelect } from "../font-type-select";
import { Accordion } from "../accordion";
import { WidgetTypeEnum } from "../../../../enum";

const NAMES = ["Title", "Button Text", "Color"];

interface IProps {
  form: Record<string, any>;
}

export const FeedAssets: React.FC<IProps> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);
  const sendData = async (file: any, type: string) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL +
          (type === "video" ? "widgets/feedMediaVideo" : "widgets/feedMedia"),
        formData
      );

      if (
        [
          WidgetTypeEnum.STORY,
          WidgetTypeEnum.DROP,
          WidgetTypeEnum.PROMOTION,
          WidgetTypeEnum.INSTANT_WIN,
          WidgetTypeEnum.LOCATION_BASED,
        ].includes(form.values.type.toUpperCase())
      ) {
        form.setFieldValue("feedAssetsButtonImage", data.data.feedMedia);
        form.setFieldValue("feedMediaLink", data.data.feedMedia);
        form.setFieldValue("feedDropMediaLink", data.data.feedMedia);
      } else {
        form.setFieldValue("feedMedia", data.data.feedMedia);
      }
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };
  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "feed" })}
      nameArray={NAMES}
      image={image}
      maxHeight="400px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "feed_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox columns="1fr 1fr 1fr 1fr">
            <RadioCheckboxRed
              value={form.values.titleVisibility}
              label={intl.formatMessage({ id: "title_visibility" })}
              labelFirst={true}
              submit={(event, value) =>
                form.setFieldValue("titleVisibility", value)
              }
              name="titleVisibility"
            />
            <RadioCheckboxRed
              value={form.values.buttonVisibility}
              label={intl.formatMessage({ id: "button_visibility" })}
              labelFirst={true}
              submit={(event, value) =>
                form.setFieldValue("buttonVisibility", value)
              }
              name="buttonVisibility"
            />
            <RadioCheckboxRed
              value={form.values.favoriteIcon}
              label={intl.formatMessage({ id: "favorite_icon" })}
              labelFirst={true}
              submit={(event, value) =>
                form.setFieldValue("favoriteIcon", value)
              }
              {...{ form }}
              name="favoriteIcon"
            />
            <RadioCheckboxRed
              value={form.values.shareIcon}
              label={intl.formatMessage({ id: "share_icon" })}
              labelFirst={true}
              submit={(event, value) => form.setFieldValue("shareIcon", value)}
              name="shareIcon"
            />
          </GridBox>
          {[WidgetTypeEnum.DROP, WidgetTypeEnum.LOCATION_BASED].includes(form.values.type.toUpperCase()) ? (
            <>
              <GridBox columns="1fr 1fr 1fr" cg="30px" rg="30px" items="center">
                <FormikColorField
                  label={intl.formatMessage({ id: "title_color" })}
                  name="feedTitleColor"
                  value={form.values.feedTitleColor}
                  {...{ form }}
                />
                <TitleSizeSelect
                  label="Title Size"
                  value={form.values.feedTitleSize}
                  submit={(value) => form.setFieldValue("feedTitleSize", value)}
                  {...{ form }}
                />
                <FormikColorField
                  label={intl.formatMessage({ id: "title_highlight_color" })}
                  name="feedTitleHighlightColor"
                  value={form.values.feedTitleHighlightColor}
                  {...{ form }}
                />
              </GridBox>
            </>
          ) : [WidgetTypeEnum.INSTANT_WIN].includes(
              form.values.type.toUpperCase()
            ) ? (
            <GridBox columns="1fr 1fr 1fr" cg="30px" rg="30px" items="center">
              <FormikColorField
                label={intl.formatMessage({ id: "title_color" })}
                name="feedTitleColor"
                value={form.values.feedTitleColor}
                {...{ form }}
              />
              <FontTypeSelect
                label="Font Family"
                value={form.values.feedTitleFont}
                submit={(value) => form.setFieldValue("feedTitleFont", value)}
                {...{ form }}
              />
              <TitleSizeSelect
                label="Title Size"
                value={form.values.feedTitleSize}
                submit={(value) => form.setFieldValue("feedTitleSize", value)}
                {...{ form }}
              />
            </GridBox>
          ) : (
            <GridBox columns="2fr 1fr" cg="30px" rg="30px" items="center">
              <FormikColorField
                label={intl.formatMessage({ id: "title_color" })}
                name="feedTitleColor"
                value={form.values.feedTitleColor}
                {...{ form }}
              />
              <TitleSizeSelect
                label="Title Size"
                value={form.values.feedTitleSize}
                submit={(value) => form.setFieldValue("feedTitleSize", value)}
                {...{ form }}
              />
            </GridBox>
          )}
          <GridBox columns="1fr 1fr 1fr" cg="30px" rg="30px">
            <FormikField
              label={intl.formatMessage({ id: "button_text" })}
              name="feedAssetsButtonText"
              submit={form.handleChange}
              value={form.values.feedAssetsButtonText}
              {...{ form }}
              placeholder={intl.formatMessage({ id: "add_button_text" })}
            />
            <FormikColorField
              label={intl.formatMessage({ id: "button_color" })}
              name="feedAssetsButtonColor"
              value={form.values.feedAssetsButtonColor}
              {...{ form }}
            />
            <FormikColorField
              label={intl.formatMessage({ id: "button_text_color" })}
              name="feedAssetsButtonTextColor"
              value={form.values.feedAssetsButtonTextColor}
              {...{ form }}
            />
          </GridBox>

          <FlexBox direction="column" gap="30px">
            {[WidgetTypeEnum.POST, WidgetTypeEnum.EXTERNAL_LINK].includes(
              form.values.type.toUpperCase()
            ) && <FeedMedia form={form} isVideo={true} isFullScreenState={false} />}
            {[WidgetTypeEnum.STORY].includes(
              form.values.type.toUpperCase()
            ) && (
              <DropzoneImageVideo
                {...{ form, sendData }}
                name="feedAssetsButtonImage"
                filePreview={form.values.feedMediaLink}
              />
            )}
            {[WidgetTypeEnum.DROP, WidgetTypeEnum.LOCATION_BASED].includes(
              form.values.type.toUpperCase()
            ) && (
              <>
                <GridBox columns="1fr" cg="30px" rg="30px" items="center">
                  <FormikColorField
                    label={intl.formatMessage({ id: "border_color" })}
                    name="feedBorderColor"
                    value={form.values.feedBorderColor}
                    {...{ form }}
                  />
                </GridBox>
                <DropzoneImageVideo
                  {...{ form, sendData }}
                  name="feedAssetsButtonImage"
                  filePreview={form.values.feedDropMediaLink}
                />
              </>
            )}
            {[WidgetTypeEnum.PROMOTION, WidgetTypeEnum.INSTANT_WIN].includes(
              form.values.type.toUpperCase()
            ) && (
              <DropzoneImageVideo
                {...{ form, sendData }}
                name="feedAssetsButtonImage"
                filePreview={form.values.feedDropMediaLink}
              />
            )}
          </FlexBox>
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
