import { ROUTING_TO_BACK } from "../../constants";
import axios from "axios";

export const sendConfirmData = async (
  form: Record<string, string>
): Promise<string> => {
  const data = await axios.post(
    process.env.REACT_APP_API_URL + ROUTING_TO_BACK.auth_confirm,
    form
  );
  return data.data.authToken;
};
