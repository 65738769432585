import React from "react";
import { FlexBox, GridBox } from "../../../../../components/wilson-ui/box";
import { itemQuestionModalStyles } from "./item-question-modal-styles.hook";
import { ItemQuestionFooter } from "../item-question-footer";

import { UiModal } from "../../../../../components/wilson-ui/modals";

interface IProps {
  title: string;
  nameArray?: Array<string>;
  image: string;
  maxHeight: string;
  closeHandler: () => void;
}

export const ItemQuestionModal: React.FC<IProps> = ({
  image,
  title,
  nameArray,
  maxHeight,
  closeHandler,
}) => {
  const classes = itemQuestionModalStyles();
  const titleArray: Array<string> = nameArray ? nameArray : [];
  const rows = nameArray ? "60px auto 60px" : "60px auto";

  return (
    <UiModal width="700px" closeHandler={closeHandler}>
      <GridBox rows={rows}>
        <FlexBox className={classes.header}>
          <h4>{title}</h4>
        </FlexBox>
        <FlexBox content="center" className={classes.content}>
          <img src={image} style={{ height: maxHeight }} />
        </FlexBox>
        <ItemQuestionFooter titleArray={titleArray} />
      </GridBox>
    </UiModal>
  );
};
