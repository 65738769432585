import React from "react";
import { Navbar } from "../navbar";
import { useMainLayoutStyles } from "./main-layout-styles.hook";

export const MainLayout: React.FC = ({ children }) => {
  const classes = useMainLayoutStyles();

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.wrapper}>
          <Navbar />
        </div>
      </header>

      <main>
        <div className={classes.main}>
          <div className={classes.wrapper}>{children}</div>
        </div>
      </main>
    </div>
  );
};
