import * as Yup from "yup";

export const AddInterestsOtherSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "min_length_3")
    .max(50, "max_length_50")
    .required("Required"),
  type: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
});
