import React, { ChangeEvent } from "react";
import Radio from "@mui/material/Radio";

import { FlexBox } from "../box";
import { useRadioToggleStyles } from "./radio-toggle-styles.hook";

interface IProps {
  label: string;
  value: boolean;
  submit: (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  name: string;
  disabled?: boolean;
  size?: "small" | "medium";
}

export const RadioToggleRed: React.FC<IProps> = ({
  label,
  value,
  submit,
  name,
  disabled,
  size,
}) => {
  const classes = useRadioToggleStyles();

  return (
    <FlexBox items="center">
      <Radio
        onChange={submit}
        size={size || "small"}
        checked={value}
        name={name}
        disabled={disabled}
        classes={{ root: classes.redRadio, checked: classes.redRadioActive }}
      />
      <span className={classes.redRadioLabel}>{label}</span>
    </FlexBox>
  );
};
