import { makeStyles } from "@material-ui/core";

export const useAuthWrapStyles = makeStyles(() => ({
  wrap: {
    height: "100vh",
  },
  container: {
    width: "600px",
    padding: "0px 20px",
  },
}));
