import React from "react";
import ReactDOM from "react-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import { GlobalStyleProvider } from "./app/styles";
import { App } from "./app/app";
'use client';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

ReactDOM.render(
  <GlobalStyleProvider>
    <App />
  </GlobalStyleProvider>,
  document.getElementById("root")
);
