import axios from "axios";
import { ROUTING_TO_BACK } from "../../constants";

export const sendPhoneNumber = async (
  form: Record<string, string>
): Promise<void> => {
  await axios.post(
    process.env.REACT_APP_API_URL + ROUTING_TO_BACK.auth_login,
    form
  );
};
