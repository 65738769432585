import React, { FC } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useChannelMoreButtonStyle } from "./channel-more-button-style.hook";

interface IProps {
  toggleShowMore: () => void;
  length: number;
  isShowMoreTrue: boolean;
}

export const ChannelMoreButton: FC<IProps> = ({
  toggleShowMore,
  length,
  isShowMoreTrue,
}) => {
  const classes = useChannelMoreButtonStyle();
  const value = !isShowMoreTrue ? (
    <span className={classes.more}>{length}</span>
  ) : (
    <HighlightOffIcon />
  );

  return (
    <div className={classes.container} onClick={toggleShowMore}>
      {value}
    </div>
  );
};
