import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../components/wilson-ui/constants/colors";

export const useConfirmAuthStyle = makeStyles(() => ({
  container: {
    width: "100%",
    position: "relative",
  },
  errorField: {
    position: "absolute",
    top: "70px",
    left: "0",
    color: baseColors.red,
    fontWeight: 700,
  },
}));
