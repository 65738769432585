import { axiosAuthorized } from "../../axios";
import { toast } from "react-toastify";

export const downloadCampaignsCSV = async (
  rows: number,
  page: number,
  order: string,
  sortByName: string
): Promise<string> => {
  let downloadData = "";
  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `campaigns/exportCsv?limit=${rows}&pageNumber=${page}&fieldName=${sortByName}&order=${order}`
    );
    downloadData = data.data ?? "";
  } catch (error) {
    console.warn(error);
  }

  return downloadData;
};

export const sendCampaign = async (
  campaign: Record<string, any>
): Promise<any> => {
  try {
    return await axiosAuthorized.post(process.env.REACT_APP_API_URL + "campaigns/v2", {
      ...campaign,
    });
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export const getCampaign = async (id: string): Promise<Record<string, any>> => {
  let campaign = {};

  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL + `campaigns/${id}`
    );
    campaign = data.data;
  } catch (error) {
    console.warn(error);
  }

  return campaign;
};

export const editCampaign = async (
  campaign: Record<string, any>
): Promise<any> => {
  try {
    return await axiosAuthorized.patch(
      process.env.REACT_APP_API_URL + "campaigns/" + `${campaign.id}/v2`,
      {
        ...campaign,
      }
    );
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export const removeCampaigns = async (id: string): Promise<boolean> => {
  let isRemove;

  try {
    await axiosAuthorized.delete(process.env.REACT_APP_API_URL + `campaigns/${id}`);
    isRemove = true;
  } catch (error) {
    console.log({ ...error });
    toast(error.response.data.message);
    isRemove = false;
  }

  return isRemove;
};
