import { makeStyles } from "@material-ui/core";

export const useDataSortStyles = makeStyles(() => ({
  dataSort: {
    "& > div": {
      height: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& svg": {
        transform: "scale(0.8)",
        marginLeft: "-3px",
      },
    },
  },
}));
