import React from "react";
import { useModalStyle } from "./modal-style.hook";

interface IProps {
  width: string;
  minHeight?: string;
  closeHandler: () => void;
}

export const UIModalUpload: React.FC<IProps> = ({
  children,
  width,
  minHeight,
  closeHandler
}) => {
  const classes = useModalStyle();
  const minHeightValue = minHeight ? minHeight : '200px'

  const closeSubmit = (e: any) => {
    if (e.target.id !== "ui-modal") return;
    closeHandler();
  };

  return (
    <div className={classes.wrap} id="ui-modal" onClick={closeSubmit}>
      <div style={{ maxWidth: width, minHeight: minHeightValue }} className={classes.container}>
        {children}
      </div>
    </div>
  );
};
