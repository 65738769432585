import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../constants/colors";

export const useDownloadCSVdModalStyles = makeStyles(() => ({
  header: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: baseColors.black,
    color: baseColors.white,
  },
  container: {
    height: "350px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "35px",
  },
}));
