import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../wilson-ui/constants/colors/base-colors";
import { mediaQuery } from "../../../wilson-ui/constants/media";

export const useMobileMenuStyles = makeStyles(() => ({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "250px",
    borderRight: "1px solid",
    background: baseColors.white,
    height: "100vh",
    zIndex: 200,
    padding: "30px 20px",
    transition: "0.7s",
    [mediaQuery.minWidth800]: {
      display: "none !important",
    },
  },
}));
