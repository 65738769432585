import React, { ReactNode } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface IProps {
  options: Array<string>;
  name: string;
  value: string;
  label?: string;
  submit?: (event: SelectChangeEvent<string>, child: ReactNode) => void;
}

export const SelectSimple: React.FC<IProps> = ({
  options,
  name,
  value,
  label,
  submit,
}) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select fullWidth value={value} name={name} onChange={submit}>
        {options.map((item: string) => (
          <MenuItem key={Math.random().toString().substr(2)} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
