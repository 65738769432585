import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import WidgetsStore from "../../store/widgets/widgets.store";
import { removeWidget, sendCarouselData } from "../../actions/widgets";
import { MainLayout } from "../../components";
import { FlexBox } from "../../components/wilson-ui/box";
import { UiTableDrag } from "../../components/wilson-ui/table";
import { ChannelsItem } from "../../components/items";
import { CopyItem } from "../../components/items";
import {
  WidgetTabLabel,
  WidgetTabValue,
  WidgetStatus,
  WidgetStatusForCreateWidget,
} from "../../types";
import { getTypeWidget } from "../../utils/utils";
import { WidgetsTypeModal } from "./components/widgets-type-modal";
import { WidgetsHeader } from "./components/widgets-header";
import { WidgetFeedActionsButton } from "../../components/items/widget-actions-button";
import {
  widgetsTableDragConfig,
  widgetsTableNoDragConfig,
} from "./widgets-table-config";
import { ConfirmModal } from "../submissions/components/confirm-modal";
import { updatePriorityHelper } from "./helpers/update-priority-widget";
import { useWidgetsPageStyles } from "./widgets-page-styles.hook";
import { WidgetTypeEnum } from "../../enum";
import { getWidget } from "../../actions/widgets";
import {
  getCarousel,
  createNewCarouseRequest,
  sendCarouselData as sendCarouselDataCarousel,
} from "../../actions/carousel";
import { sendFormHelper } from "../create-widgets/helpers/send-widget";
import { UpgradeWidgetToEditHelper } from "../../helpers/widgets/upgrade-widget-to-edit.helper";

const defaultSortType = [
  "post",
  "story",
  "external link",
  "carousel",
  "drop",
  "promotion",
  "instant win",
  "location based",
];
const defaultRowPerPage = 10;
const defaultPage = 1;
const defaultSortState = "";
const defaultSortBy = null;
const defaultIsTypeModalShow = false;
const defaultTerm = "";

export const WidgetsPage: React.FC = observer(() => {
  const intl = useIntl();
  const [tab, setTab] = React.useState(WidgetTabValue.feedOrder);
  const [rowPerPage, setRowPerPage] = useState<number>(defaultRowPerPage);
  const [page, setPage] = useState<number>(defaultPage);
  const [sortState, setSortState] = useState<string>(defaultSortState);
  const [sortBy, setSortBy] = useState<any>(defaultSortBy);
  const [sortType, setSortType] = useState<Array<string>>(defaultSortType);
  const [isTypeModalShow, setIsTypeModalShow] = useState(
    defaultIsTypeModalShow
  );
  const [term, setTerm] = useState<string>(defaultTerm);
  const [isModalShow, setIsModalShow] = useState(false);
  const [idWidget, setIdWidget] = useState<string>("");
  const [carousel, setCarousel] = useState<Record<string, any> | null>(null);
  const widgets = WidgetsStore.getWidgets();
  const widgetStyles = useWidgetsPageStyles();

  useEffect(() => {
    if (WidgetsStore.isLoading) return;
    WidgetsStore.setData(
      tab,
      rowPerPage,
      page,
      sortState,
      sortBy,
      sortType,
      term
    );
  }, [rowPerPage, page, sortState, sortBy, sortType]);

  useEffect(() => {
    if (WidgetsStore.isLoading) return;
    setRowPerPage(defaultRowPerPage);
    setPage(defaultPage);
    setSortState(defaultSortState);
    setSortBy(defaultSortBy);
    setSortType(defaultSortType);
    setIsTypeModalShow(defaultIsTypeModalShow);
    setTerm(defaultTerm);
    WidgetsStore.setData(
      tab,
      defaultRowPerPage,
      defaultPage,
      defaultSortState,
      defaultSortBy,
      defaultSortType,
      defaultTerm
    );
  }, [tab]);

  const toggleModal = () => setIsTypeModalShow(!isTypeModalShow);
  const setSearch = (val: string) => {
    setTerm(val);
    WidgetsStore.setData(
      tab,
      rowPerPage,
      page,
      sortState,
      sortBy,
      sortType,
      val
    );
  };

  const setSortModel = (field: string, sortOrder: string | null) => {
    setSortBy(sortOrder || null);
    setSortState(field);
  };

  const setRowPerPageHandler = (val: number) => {
    setPage(1);
    setRowPerPage(val);
  };

  const setRowPriorityHandler = async (payload: Array<Record<string, any>>) => {
    await updatePriorityHelper(payload);
  };

  const typeModal = isTypeModalShow && (
    <WidgetsTypeModal
      toggleModal={toggleModal}
      setSortType={setSortType}
      sortType={sortType}
    />
  );

  const handleSortTypeSubmit = useCallback(() => {
    setIsTypeModalShow(!isTypeModalShow);
  }, [isTypeModalShow]);

  const toggleModalDelete = (
    row: any,
    carousel: Record<string, any> | null
  ) => {
    setIsModalShow(!isModalShow);
    setIdWidget(row?.id || "");
    setCarousel(toJS(carousel) || null);
  };

  const removeWidgetHandler = async () => {
    if (idWidget) {
      WidgetsStore.isLoading = true;
      await removeWidget(idWidget);
      WidgetsStore.setData(
        tab,
        rowPerPage,
        page,
        sortState,
        sortBy,
        sortType,
        term
      );
      WidgetsStore.isLoading = false;
    }
  };

  const updateCarouselHandle = async (
    id: string,
    status: string,
    title: string,
    carouselArray: Array<Record<string, any>>
  ) => {
    try {
      await sendCarouselData(id, carouselArray, status, title);
    } catch (error) {
      console.warn(error);
    }
  };

  const removeWidgetAndUpdateCarousel = async () => {
    WidgetsStore.isLoading = true;
    if (!carousel) {
      await removeWidgetHandler();
    } else if (carousel) {
      const { id, status, title, childWidgets } = carousel;
      const carouselArray = childWidgets.filter(
        (wid: Record<string, any>) => wid.id !== idWidget
      );
      await updateCarouselHandle(id, status, title, carouselArray);
      WidgetsStore.setData(
        tab,
        rowPerPage,
        page,
        sortState,
        sortBy,
        sortType,
        term
      );
    }
    WidgetsStore.isLoading = false;
    toggleModalDelete(null, null);
  };

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newValue: WidgetTabValue
  ) => {
    setTab(newValue);
  };

  const duplicateHandler = async (row: Record<string, any>) => {
    let widget = null;
    WidgetsStore.isLoading = true;
    switch(row.type) {
      case WidgetTypeEnum.CAROUSEL: {
        const data = await getCarousel(row.id);
        widget = await createNewCarouseRequest({
          title: `${row.title} Copy`,
          type: row.type,
          status: row.status || Object.values(WidgetStatus)[0],
          widgetPriority: row.widgetPriority,
          titleColor: row.titleColor,
          backgroundColor: row.backgroundColor
        });
        const carousels = (data?.childWidgets || []).map((item: any) => ({
          ...item,
          id: undefined,
          carouselId: undefined,
          feedMedia: undefined,
        }));
        await sendCarouselDataCarousel(
          widget.id,
          carousels,
          widget.status,
          widget.title,
          widget.titleColor,
          widget.backgroundColor
        );
        break;
      }
      default: {
        const data = await getWidgetHandler(row);
        data.feedItems = (data.feedItems || []).map((feed: any) => ({
          ...feed,
          carouselId: undefined
        }));
        widget = await sendFormHelper(data);
      }
    }
    if (widget) {
      toast.success(intl.formatMessage({ id: "duplicate_widget_success" }));
    } else {
      toast(intl.formatMessage({ id: "something_is_wrong" }));
    }
    WidgetsStore.setData(
      tab,
      rowPerPage,
      page,
      sortState,
      sortBy,
      sortType,
      ''
    );
    WidgetsStore.isLoading = false;
  };

  const getWidgetHandler = async (row: Record<string, any>) => {
    const widget = await getWidget(row.id);
    const widgetToSet: any = UpgradeWidgetToEditHelper(widget);
    widgetToSet.id = undefined;
    widgetToSet.status = WidgetStatus.onTheBench;
    widgetToSet.title = `${widgetToSet.title} Copy`;
    widgetToSet.widgetPriority = widget.widgetPriority;
    widgetToSet.campaignId = undefined;
    return widgetToSet;
  };


  const components = {
    type: ({ row }: any) => getTypeWidget(row.type),
    thumbnail: ({ row }: any) => {
      return (
        <CopyItem maxLengthProps={5} row={row} col="id">
          <FlexBox items="center" content="center">
            <Avatar
              variant="square"
              src={row.feedMediaLink}
              sx={{ marginRight: 2 }}
            />
            {row.title}
          </FlexBox>
        </CopyItem>
      );
    },
    status: ({ row }: any) => {
      let status = WidgetStatusForCreateWidget.itsAGameTime;
      let color = "#000000";
      let icon = <>&#10148;</>;
      if (row.status === WidgetStatus.onTheBench) {
        status = WidgetStatusForCreateWidget.onTheBench;
        color = "#d95600";
        icon = <>&#9864;</>;
      }
      if (row.status === WidgetStatus.hidden) {
        status = WidgetStatusForCreateWidget.hidden;
        color = "#fc0000";
        icon = <>&#9864;</>;
      }
      return (
        <Box
          sx={{
            width: 95,
            height: 25,
            border: "2px solid",
            borderColor: color,
            padding: "0 15px",
            borderRadius: 8,
          }}
        >
          <FlexBox items="center" content="center">
            <span style={{ color }}>
              {icon} {status}
            </span>
          </FlexBox>
        </Box>
      );
    },
    channels: ({ row, handleSelectRow }: any) => {
      const channels = (row?.channels || [])
        .map((item: any) => `${item.leagueLabel} ${item.type}`)
        .join(", ");
      return (
        <Box>
          <ChannelsItem
            arr={channels}
            row={row}
            handleSelectRow={handleSelectRow}
          />
        </Box>
      );
    },
    entries: ({ row }: any) => {
      return (<span>{row.entries}</span>)
    },
    button: ({ row, carousel }: any) => (
      <Box>
        <WidgetFeedActionsButton
          row={row}
          isCarousel={false}
          onDuplicate={duplicateHandler}
          toggleModal={() => toggleModalDelete(row, carousel)}
        />
      </Box>
    ),
    data: ({ row, col }: any) => (
      <span>{moment(row[col]).format("MMMM Do YYYY, HH:mm")}</span>
    ),
  };

  const componentsTable = {
    type: (row: any) => getTypeWidget(row.type),
    thumbnail: (row: any) => {
      return (
        <CopyItem maxLengthProps={5} row={row} col="id">
          <FlexBox items="center">
            <Avatar
              variant="square"
              src={row?.overrideThumbnailLink || row?.overrideImageLink || row.feedMediaLink}
              sx={{ marginRight: 2 }}
            />
            {row.title}
          </FlexBox>
        </CopyItem>
      );
    },
    status: (row: any) => {
      let status = WidgetStatusForCreateWidget.itsAGameTime;
      let color = "#000000";
      let icon = <>&#10148;</>;
      if (row.status === WidgetStatus.hidden) {
        status = WidgetStatusForCreateWidget.hidden;
        color = "#fc0000";
        icon = <>&#9864;</>;
      }
      if (row.status === WidgetStatus.onTheBench) {
        status = WidgetStatusForCreateWidget.onTheBench;
        color = "#d95600";
        icon = <>&#9864;</>;
      }
      return (
        <Box
          sx={{
            width: 95,
            height: 25,
            border: "2px solid",
            borderColor: color,
            padding: "0 15px",
            borderRadius: 8,
          }}
        >
          <FlexBox items="center" content="center">
            <span style={{ color, fontWeight: "normal" }}>
              {icon} {status}
            </span>
          </FlexBox>
        </Box>
      );
    },
    channels: (row: any) => {
      const channels = (row.channels || [])
        .map((item: any) => `${item.leagueLabel} ${item.type}`)
        .join(", ");
      return (
        <Box>
          <ChannelsItem arr={channels} />
        </Box>
      );
    },
    entries: (row: any) => {
      return (<span>{row.entries}</span>)
    },
    button: (row: any, carousel: Record<string, any> | null) => (
      <Box>
        <WidgetFeedActionsButton
          row={row}
          isCarousel={!!carousel}
          onDuplicate={duplicateHandler}
          toggleModal={() => toggleModalDelete(row, carousel)}
        />
      </Box>
    ),
    data: (row: any, col: string) => (
      <span>{moment(row[col]).format("MMMM Do YYYY, HH:mm")}</span>
    ),
  };

  const TableDrag = (
    <UiTableDrag
      loading={WidgetsStore.isLoading}
      sortModelInit={
        sortState && sortBy ? [{ field: sortState, sort: sortBy }] : []
      }
      isSortType={true}
      sortTypeSubmit={handleSortTypeSubmit}
      sortSubmit={setSortModel}
      components={components}
      componentsTable={componentsTable}
      configDrag={widgetsTableDragConfig()}
      data={toJS(widgets.data)}
      disablePagination={true}
      length={widgets.length}
      setRowPriority={setRowPriorityHandler}
      setSearch={setSearch}
      searchValue={term}
      searchTable={false}
      rowReordering={true}
    />
  );

  const Table = (
    <UiTableDrag
      loading={WidgetsStore.isLoading}
      onLoading={(loading: boolean) => WidgetsStore.isLoading = loading}
      sortModelInit={
        sortState && sortBy ? [{ field: sortState, sort: sortBy }] : []
      }
      sortTypeSubmit={handleSortTypeSubmit}
      sortSubmit={setSortModel}
      components={components}
      componentsTable={componentsTable}
      configDrag={widgetsTableNoDragConfig()}
      data={toJS(widgets.data)}
      disablePagination={!widgets.length}
      setRowPerPage={setRowPerPageHandler}
      rowPerPage={rowPerPage}
      page={page}
      setPage={setPage}
      length={widgets.length}
      setRowPriority={setRowPriorityHandler}
      setSearch={setSearch}
      searchValue={term}
      searchTable
      rowReordering={false}
    />
  );

  return (
    <MainLayout>
      <WidgetsHeader
        tab={tab}
        sortType={sortType}
        name={sortState}
        order={sortBy}
        visibleWidgetsId={toJS(widgets.data).map((widget) => {
          if (widget.type === WidgetTypeEnum.CAROUSEL) {
            return widget.childWidgets.map((childWidget) => childWidget.id);
          }
          return widget.id as string;
        })}
      />
      {typeModal}
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChangeTab}
            textColor="secondary"
            indicatorColor="secondary"
            classes={{ root: widgetStyles.tabsRoot }}
            aria-label="lab API tabs example"
          >
            <Tab
              label={WidgetTabLabel.promotions}
              value={WidgetTabValue.promotions}
              classes={{ root: widgetStyles.tabRoot }}
            />
            <Tab
              label={WidgetTabLabel.feedOrder}
              value={WidgetTabValue.feedOrder}
              classes={{ root: widgetStyles.tabRoot }}
            />
          </TabList>
        </Box>
        <TabPanel
          value={WidgetTabValue.promotions}
          classes={{ root: widgetStyles.tabPanel }}
        >
          {Table}
        </TabPanel>
        <TabPanel
          value={WidgetTabValue.feedOrder}
          classes={{ root: widgetStyles.tabPanel }}
        >
          {TableDrag}
        </TabPanel>
      </TabContext>
      {isModalShow && (
        <ConfirmModal
          toggleModal={() => toggleModalDelete(null, null)}
          submit={removeWidgetAndUpdateCarousel}
          title={
            !carousel
              ? "Are you sure you want to remove this widget?"
              : "Are you sure you want to remove this widget from carousel?"
          }
        />
      )}
    </MainLayout>
  );
});
