import React, { ChangeEvent, useState } from "react";
import { FlexBox, GridBox, RadiusBox } from "../../../../components/wilson-ui/box";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { baseColors } from "../../../../components/wilson-ui/constants/colors";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import ImageIcon from "@mui/icons-material/Image";
import MDSpinner from "react-md-spinner";
import { useSwipeItemStyle } from "./swipe-item-styles.hook";
import { ISwipeItem, WidgetStoryButtonStyle } from "../../../../types";
import { RadioToggleRed } from "../../../../components/wilson-ui/checkboxes/radio-toggle-red";
import { onSelectFile } from "../../../../helpers/widgets/select-file.helper";
import { setSwipeImage } from "./helpers";
import { setSwipeField } from "./helpers";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { MAX_FILE_SIZE } from "../../../../constants";
import { WidgetTypeEnum } from "../../../../enum";
import { Box } from "@material-ui/core";

interface IProps {
  item: ISwipeItem;
  index: number | null;
  swipeState: Array<ISwipeItem>;
  setSwipeState: React.Dispatch<any>;
  updateSwipeArray: () => void;
  checkSoundVideo: (preview: string) => Promise<boolean>;
  form: Record<string, any>;
}

export const SwipeItem: React.FC<IProps> = ({
  item,
  index,
  swipeState,
  setSwipeState,
  updateSwipeArray,
  checkSoundVideo,
  form,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useSwipeItemStyle();
  const isHasError = item.isHaveError && !!form.submitCount;
  const labelClass = isHasError
    ? `${classes.label} ${classes.labelError}`
    : classes.label;

  const notify = (id: string) => toast(intl.formatMessage({ id }));

  const assetsUrl = isLoading ? (
    <MDSpinner />
  ) : item.preview ? (
    item.type === "VIDEO" ? (
      <video
        className={classes.image}
        src={item.preview}
        style={{ objectFit: "inherit" }}
      />
    ) : (
      <img className={classes.image} src={item.preview} />
    )
  ) : (
    <ImageIcon />
  );

  const setSwipeFieldHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSwipeField(
      item.id,
      event.target.value,
      item?.hasSound || false,
      item?.buttonStyle || "",
      item?.imageMap || "",
      swipeState,
      setSwipeState
    );
  };

  const setButtonStyleFieldHandler = (buttonStyle: string) => {
    setSwipeField(
      item.id,
      item?.swipeUrl || "",
      item?.hasSound || false,
      buttonStyle,
      item?.imageMap || "",
      swipeState,
      setSwipeState
    );
  };

  const setImageMapFieldHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSwipeField(
      item.id,
      item?.swipeUrl || "",
      item?.hasSound || false,
      item?.buttonStyle || "",
      event.target.value || "",
      swipeState,
      setSwipeState
    );
  };

  const setSwipeImageHandler = async (
    id: string,
    file: string,
    preview: string,
    type: string
  ) => {
    let hasSound = false;
    if (type === 'video') {
      hasSound = await checkSoundVideo(preview);
    }
    setSwipeImage(
      id,
      file,
      preview,
      hasSound,
      isLoading,
      swipeState,
      setSwipeState,
      type
    );
    updateSwipeArray();
  };

  const test = async (event: any) => {
    const file = event.target.files[0];

    if (!file) return;

    const fileType = file.type.split("/")[0];
    const fileExt = file.name.match(/((?<=\.)(\d|\w)*)$/gi);

    if (!["image", "video"].includes(fileType)) {
      return notify("only_image_and_video_file");
    }

    if (file.size >= MAX_FILE_SIZE) {
      return notify("up_to_100_mb");
    }

    if (fileType === "video") {
      const allowedVideoExtensionRegExp = /(mp4|mov)/gi;
      const isFileExtensionAllowed = allowedVideoExtensionRegExp.test(fileExt);

      if (!isFileExtensionAllowed)
        return notify("only_allowed_video_extension");
    }

    return await onSelectFile(
      event,
      isLoading,
      setIsLoading,
      setSwipeImageHandler,
      WidgetTypeEnum.STORY
    );
  };

  const removeItem = (id: string) => {
    if (isLoading) return;
    const newSwipeState = swipeState.filter((item: any) => item.id !== id)
    setSwipeState(newSwipeState);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      className={classes.inner}
    >
      <GridBox className={classes.container}>
        <FlexBox className={classes.dragContainer}>
          <DragIndicatorOutlinedIcon />
        </FlexBox>
        <div
          style={{
            width: "42px",
            height: "86px",
            position: "relative",
          }}
        >
          <input
            className={classes.fileInput}
            id={item.id}
            name={item.id}
            type="file"
            onChange={test}
          />
          <label htmlFor={item.id} className={labelClass}>
            {assetsUrl}
          </label>
          <div
            style={{
              width: "18px",
              height: "18px",
              fontSize: 12,
              borderRadius: "50%",
              color: "#fff",
              background: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: -8,
              right: -8,
            }}
          >
            {(index ?? 0) + 1}
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FlexBox items="center" marginBottom="5px">
            {Object.values(WidgetStoryButtonStyle).map((buttonStyle, index) => (
              <div key={index} className={classes.radioButtonContainer}>
                <RadioToggleRed
                  label={buttonStyle}
                  value={item.buttonStyle === buttonStyle}
                  submit={() => setButtonStyleFieldHandler(buttonStyle)}
                  name="buttonStyle"
                />
              </div>
            ))}
          </FlexBox>
          <FlexBox items="center" marginBottom="15px">
            <FormikField
              style={{ width: "100%" }}
              label={intl.formatMessage({ id: "url" })}
              name="swipeUrl"
              submit={setSwipeFieldHandler}
              value={item.swipeUrl || ""}
              {...{ form }}
              placeholder={intl.formatMessage({ id: "enter_website_url" })}
            />
          </FlexBox>
          {item.buttonStyle === WidgetStoryButtonStyle.lookbook ? (
            <FlexBox items="center">
              <FormikField
                placeholder={intl.formatMessage({
                  id: "image_map",
                })}
                name="imageMap"
                submit={setImageMapFieldHandler}
                value={item.imageMap}
                {...{ form }}
                label={intl.formatMessage({ id: "image_map_json" })}
                multiline
                variant="outlined"
                rows={12}
                style={{ width: "100%" }}
              />
            </FlexBox>
          ): null}
        </Box>
      </GridBox>
      <FlexBox
        content="flex-end"
        column-gap="10"
        sx={{
          width: "6%",
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        <RadiusBox
          width="40px"
          height="40px"
          onClick={() => removeItem(item.id)}
        >
          <DeleteForeverIcon
            style={{ fontSize: "26px", color: baseColors.red }}
          />
        </RadiusBox>
      </FlexBox>
    </Box>
  );
};
