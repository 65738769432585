import { makeStyles } from "@material-ui/core";

export const useQuestionIconStyles = makeStyles(() => ({
  container: {
    "& svg": {
      width: "30px",
      height: "30px",
      cursor: "pointer",
    },
  },
}));
