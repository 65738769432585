import { makeStyles } from "@material-ui/core";
import { baseColors } from "../wilson-ui/constants/colors/base-colors";

export const useMainLayoutStyles = makeStyles(() => ({
  root: {},
  header: {
    zIndex: 1000,
    position: "fixed",
    top: 0,
    left: 0,
    background: baseColors.white,
    height: "80px",
    width: "100vw",
    borderBottom: `1px solid ${baseColors.grey}`,
    "& > div": {
      height: "100%",
    },
  },
  main: {
    marginTop: "80px",
    paddingTop: "80px",
    paddingBottom: "200px",
  },
  wrapper: {
    maxWidth: "1240px",
    margin: "0 auto",
    padding: "0px 20px",
  },
}));
