import React from "react";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import InvertColorsOutlinedIcon from "@mui/icons-material/InvertColorsOutlined";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useBackgroundTypeSelectStyle } from "./background-type-select.hook";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { BackgroundTypeEnum } from "../../../../enum";

interface BackgroundTypeSelectProps {
  label: string;
  value: BackgroundTypeEnum;
  submit: (value: BackgroundTypeEnum) => void;
  form: Record<string, any>;
}

export const BackgroundTypeSelect: React.FC<BackgroundTypeSelectProps> = ({
  label,
  value,
  submit,
  form,
}) => {
  const classes = useBackgroundTypeSelectStyle();
  const isHaveError: boolean =
    form.errors.feedBackgroundType && form.submitCount;
  const types = [BackgroundTypeEnum.COLOR, BackgroundTypeEnum.IMAGE];
  const errorClass: string = isHaveError ? ` ${classes.errorBorderField}` : "";

  return (
    <FlexBox items="center" gap="16px">
      <span className={classes.label}>{label}</span>
      <FlexBox items="center">
        {types.map((type) => (
          <UiButton
            key={type}
            buttonType={value === type ? "black" : "white"}
            onClick={() => submit(type)}
            className={`${classes.button} ${classes.size}${errorClass}`}
          >
            {type === BackgroundTypeEnum.IMAGE ? (
              <AddPhotoAlternateOutlinedIcon />
            ) : (
              <InvertColorsOutlinedIcon />
            )}
          </UiButton>
        ))}
      </FlexBox>
    </FlexBox>
  );
};
