import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import Box from "@mui/material/Box";
import { tagsTableConfig } from "./tags-table-config";
import { MainLayout } from "../../components";
import { LayoutWrap } from "../../components/wrappers/layout-wrap/layout-wrap";
import { TagsHeader } from "./components/header";
import { UiTableDrag } from "../../components/wilson-ui/table";
import { EMPTY_FUNCTION } from "../../constants";
import { FlexBox } from "../../components/wilson-ui/box";
import TagsStore from "../../store/tags/tags.store";
import { updatePriorityHelper } from "../../actions/tags/tags.action";

const defaultRowPerPage = 25;
const defaultPage = 1;
const defaultSortState = "priority";
const defaultSortBy = "asc";
export const TagsPage: React.FC = observer(() => {
  const [term, setTerm] = useState<string>("");
  const [rowPerPage, setRowPerPage] = useState<number>(defaultRowPerPage);
  const [page, setPage] = useState<number>(defaultPage);
  const [sortState, setSortState] = useState<string>(defaultSortState);
  const [sortBy, setSortBy] = useState<any>(defaultSortBy);
  const tags = TagsStore.getTags();
  // const tagStyles = useTagsPageStyles();

  useEffect(() => {
    if (TagsStore.isLoading) return;
    TagsStore.tagsRequest(
      rowPerPage,
      page,
      term,
    );
  }, [rowPerPage, page, term]);

  useEffect(() => {
    EMPTY_FUNCTION();
    return () => TagsStore.setTags({ data: [], length: 0 });
  }, []);

  const setSearch = (val: string) => {
    setTerm(val);
    TagsStore.tagsRequest(
      rowPerPage,
      page,
      val,
    );
  };

  const setRowPerPageHandler = (val: number) => {
    setPage(1);
    setRowPerPage(val);
  };

  const setSortModel = (field: string, sortOrder: string | null) => {
    setSortBy(sortOrder || null);
    setSortState(field);
  };

  const componentsTable = {
    name: ({ row }: any) => {
      return (
        <FlexBox items="center">
          {row.name}
        </FlexBox>
      );
    },
    widgets: ({ row }: any) => (
      <Box
        sx={{
          width: "100%",
          display: "inline-block",
        }}
      >
        {row.widgets.map((item: any) => (
          <Box
            sx={{
              display: "inline-block",
              textTransform: "capitalize",
              border: "2px solid #000",
              padding: "0 3px",
              marginTop: 0.5,
              marginRight: 1,
              marginBottom: 1,
            }}
          >
            {item.title}
          </Box>
        ))}
      </Box>
    ),
    button: () => (
      <Box/>
    ),
  };

  const setRowPriorityHandler = async (payload: Array<Record<string, any>>) => {
    await updatePriorityHelper(
      payload.map((u) => ({
        ...u,
        priority: u.widgetPriority,
        widgetPriority: undefined,
      }))
    );
  };

  const Table = (
    <UiTableDrag
      loading={TagsStore.isLoading}
      onLoading={(loading: boolean) => (TagsStore.isLoading = loading)}
      sortModelInit={
        sortState && sortBy ? [{ field: sortState, sort: sortBy }] : []
      }
      sortTypeSubmit={EMPTY_FUNCTION}
      sortSubmit={setSortModel}
      components={componentsTable}
      // componentsTable={componentsTable}
      configDrag={tagsTableConfig()}
      data={toJS(tags.data)}
      disablePagination={!tags.length}
      setRowPerPage={setRowPerPageHandler}
      rowPerPage={rowPerPage}
      page={page}
      setPage={setPage}
      length={tags.length}
      setSearch={setSearch}
      searchValue={term}
      searchTable
      setRowPriority={setRowPriorityHandler}
      rowReordering={true}
    />
  );

  return (
    <MainLayout>
      <TagsHeader />
      <LayoutWrap minHeight="200px">
        {Table}
      </LayoutWrap>
    </MainLayout>
  );
});
