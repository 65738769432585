import * as Yup from "yup";
import moment from "moment-timezone";
import { COLOR_REGEX } from "../../constants";

export const AddWidgetsDropSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "min_length_3")
    .max(65, "max_length_65")
    .required("Required"),
  subtitle: Yup.string().max(50, "Max length 50"),
  type: Yup.string().required("Required"),
  background: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  status: Yup.string().required("Required"),
  startDateDate: Yup.date().required("Required"),
  startDateTime: Yup.date().required("Required"),
  liveDateDate: Yup.date()
    .required("Required")
    .when("startDateDate", (startDateDate, schema) => {
      return schema.test({
        test: (liveDateDate: string) => {
          const liveDateDateVal = moment(
            moment(liveDateDate).format("MM/DD/YYYY")
          ).valueOf();
          const startDateDateVal = moment(
            moment(startDateDate).format("MM/DD/YYYY")
          ).valueOf();
          return (
            !liveDateDate ||
            (!!liveDateDate && liveDateDateVal >= startDateDateVal)
          );
        },
      });
    }),
  liveDateTime: Yup.date()
    .required("Required")
    .when("startDateTime", (startDateTime, schema) => {
      return schema.test({
        test: (liveDateTime: string) => {
          const liveDateDateVal = moment(
            moment(liveDateTime).format("MM/DD/YYYY HH:mm")
          ).valueOf();
          const startDateDateVal = moment(
            moment(startDateTime).format("MM/DD/YYYY HH:mm")
          ).valueOf();
          return (
            !liveDateTime || 
            (!!liveDateTime && liveDateDateVal >= startDateDateVal)
          );
        },
      });
    }),
  expirationDateDate: Yup.date()
    .required("Required")
    .when("liveDateDate", (liveDateDate, schema) => {
      return schema.test({
        test: (expirationDateDate: string) => {
          const expirationDateDateVal = moment(
            moment(expirationDateDate).format("MM/DD/YYYY")
          ).valueOf();
          const liveDateDateVal = moment(
            moment(liveDateDate).format("MM/DD/YYYY")
          ).valueOf();
          return (
            !expirationDateDate ||
            (!!expirationDateDate && expirationDateDateVal >= liveDateDateVal)
          );
        },
      });
    }),
  expirationDateTime: Yup.date()
    .required("Required")
    .when("liveDateTime", (liveDateTime, schema) => {
      return schema.test({
        test: (expirationDateTime: string) => {
          const expirationDateDateVal = moment(
            moment(expirationDateTime).format("MM/DD/YYYY HH:mm")
          ).valueOf();
          const liveDateDateVal = moment(
            moment(liveDateTime).format("MM/DD/YYYY HH:mm")
          ).valueOf();
          return (
            !expirationDateTime || 
            (!!expirationDateTime && expirationDateDateVal >= liveDateDateVal)
          );
        },
      });
    }),
  tags: Yup.array(),
  feedAssetsButtonText: Yup.string()
    .min(3, "min_length_3")
    .max(50, "max_length_50")
    .required("Required"),
  feedAssetsButtonColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  feedAssetsButtonTextColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  feedBorderColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond"),
  feedTitleHighlightColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond"),
  feedTitleColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  feedTitleSize: Yup.string().required("Required")
});
