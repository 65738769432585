import React from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { MainLayout } from "../../components";
import { CarouselHeader } from "../carousel/components/carousel-header";
import { GridBox } from "../../components/wilson-ui/box";
import { useCarouselPageStyles } from "../carousel/carousel-page-styles.hook";
import { CarouselForm } from "../carousel/components/carousel-form";
import { CarouselList } from "../carousel/components/carousel-list";
import CarouselStore from "../../store/carousel/corousel.store";
import { Text } from "../../components/wilson-ui/text";
import { Spiner } from "../../components/wilson-ui/spiner";

export const CreateWidgetsCarouselPage: React.FC = observer(() => {
  const classes = useCarouselPageStyles();
  const intl = useIntl();
  const body = CarouselStore.isLoading ? (
    <Spiner />
  ) : (
    <>
      {CarouselStore.error ? (
        <GridBox className={classes.container__error}>
          <Text fontSize={"21px"}>{CarouselStore.error}</Text>
        </GridBox>
      ) : (
        <GridBox rg="30px">
          <Text fontSize={"32px"}>
            {intl.formatMessage({ id: "edit_carousel" })}
          </Text>
          <CarouselHeader isEdit={true} />
          <GridBox className={classes.container}>
            <CarouselForm />
            <CarouselList />
          </GridBox>
        </GridBox>
      )}
    </>
  );

  return <MainLayout>{body}</MainLayout>;
});
