import * as Yup from "yup";
import { COLOR_REGEX, URL_REGEX } from "../../constants";

export const AddWidgetsLocationBasedSchema = Yup.object().shape({
  site: Yup.string(),

  address: Yup.string().required("Required"),
  lat: Yup.number().required("invalid_address"),
  lng: Yup.number().required("invalid_address"),
  radius: Yup.number().min(1, "min_1").required("Required"),
  locationAlias: Yup.string().required("Required"),
  locationSuccessBackgroundColor: Yup.string().matches(COLOR_REGEX, "must_correspond").required("Required"),
  locationButtonPostRedemptionColor: Yup.string().matches(COLOR_REGEX, "must_correspond").required("Required"),
  locationButtonText: Yup.string().required("Required"),
  locationButtonTextColor: Yup.string().matches(COLOR_REGEX, "must_correspond").required("Required"),
  locationButtonColor: Yup.string().matches(COLOR_REGEX, "must_correspond").required("Required"),
  locationMediaUrl: Yup.string().required("Required"),

  feedBorderColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond"),
  feedTitleHighlightColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond"),
  
  detailsAssetsButtonText: Yup.string()
    .min(3, "min_length_3")
    .max(50000, "max_length_50000")
    .required("Required"),
  detailsAssetsButtonColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  detailsAssetsButtonTextColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),

  successTitle: Yup.string().required("Required"),
  successTitleColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  successDescription: Yup.string().max(500, "max_length_500"),
  successDescriptionColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),

  favoriteTitle: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string().required("Required"),
  }),
  favoriteSiteUrl: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string()
      .matches(URL_REGEX, "must_correspond")
      .required("Required"),
  }),
  favoriteThumbnailImage: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.mixed().required("Required"),
  }),
  // thumbnailLockerUrl: Yup.string(),
  feedMedia: Yup.array().when("description", (description, schema) => {
    return schema.test({
      test: (feedMediaArr: Array<Record<string, any>>) => {
        let isValid = true;
        if (feedMediaArr.length) {
          feedMediaArr.forEach((item: Record<string, any>) => {
            if (!item.assetUrl) {
              isValid = false;
            }
          });
        } else {
          isValid = false;
        }
        return isValid;
      },
    });
  }),
});
