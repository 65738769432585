import React, {useEffect, useState} from "react";
import {FlexBox} from "../../../../components/wilson-ui/box";
import {UiButton} from "../../../../components/wilson-ui/buttons";
import {FeedMediaItem} from "../feed-media-item";
import {useIntl} from "react-intl";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {arrayMoveImmutable} from "array-move";
import {getNewItem} from "./helpers/get-default-item";

interface IProps {
    form: Record<string, any>;
    isVideo?: boolean;
    isFullScreenState?: boolean;
}

const SortableItem = SortableElement(
    ({keyNumber, setMediaItemState, items, item, isTouched, updateFeedMediaArray, isVideo, isFullScreenState}: any) => (
        <FeedMediaItem
            index={keyNumber}
            setMediaItemState={setMediaItemState}
            isTouched={isTouched}
            mediaItemState={items}
            item={item}
            isVideo={isVideo}
            isFullScreenState={isFullScreenState}
            updateFeedMediaArray={updateFeedMediaArray}
        />
    )
);

const SortableList = SortableContainer(
    ({items, setMediaItemState, form, isVideo, isFullScreenState, updateFeedMediaArray}: any) => {
        return (
            <FlexBox direction="column" gap="10px">
                {items &&
                items.length ?
                items.map((item: any, index: any) => (
                    <SortableItem
                        index={index}
                        key={item.id}
                        keyNumber={index}
                        isTouched={form.touched.feedMedia}
                        item={item}
                        items={items}
                        isVideo={isVideo}
                        isFullScreenState={isFullScreenState}
                        setMediaItemState={setMediaItemState}
                        updateFeedMediaArray={updateFeedMediaArray}
                    />
                )): null}
            </FlexBox>
        );
    }
);

export const FeedMedia: React.FC<IProps> = ({form, isVideo, isFullScreenState}) => {
    const [mediaItemState, setMediaItemState] = useState<any>([]);
    const intl = useIntl();
    const isHaveSwipeUrlError: boolean =
        form?.touched.feedMedia && Boolean(form.errors.feedMedia);

    const addNewMediaItem = () => {
        const newState = mediaItemState || [];

        newState.push(getNewItem());

        const newMedia = newState.map((item: any, index: number) => {
            item.priority = index + 1 + "";
            return item;
        });

        setMediaItemState(newMedia);
    };

    useEffect(() => {
        if (form.values.isAdd) {
            form.setFieldValue("storySwipe", []);
            if (!mediaItemState || mediaItemState?.length === 0) {
                addNewMediaItem();
            }
        } else {
            setMediaItemState(form.values.feedMedia);
        }
    }, []);

    useEffect(() => {
        updateFeedMediaArray();
    }, [mediaItemState]);

    useEffect(() => {
        if (!mediaItemState || mediaItemState?.length === 0) {
            // addNewMediaItem();
            return;
        }

        const newItems = mediaItemState.map((item: {
            isHaveError: boolean;
            assetUrl: any;
        }) => {
            item.isHaveError = !item.assetUrl

            return item;
        });

        setMediaItemState(newItems)
    }, [isHaveSwipeUrlError]);

    const onSortEnd = ({oldIndex, newIndex}: any) =>
        setMediaItemState((items: any) =>
            arrayMoveImmutable(items, oldIndex, newIndex)
        );

    const updateFeedMediaArray = () => {
        if (!mediaItemState) {
            return;
        }

        const newState = mediaItemState.map(
            (item: Record<string, any>, index: number) => {
                return {
                    ...item,
                    priority: index,
                };
            }
        );

        form.setFieldValue("feedMedia", newState);
    };

    return (
        <>
            <SortableList
                distance={1}
                form={form}
                isVideo={isVideo}
                isFullScreenState={isFullScreenState}
                items={mediaItemState || []}
                {...{onSortEnd, setMediaItemState, updateFeedMediaArray}}
            />

            <UiButton width="200px" buttonType="white" onClick={addNewMediaItem}>
                {intl.formatMessage({id: "add"})}
            </UiButton>
        </>
    );
};
