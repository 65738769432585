import { makeStyles } from "@material-ui/core";

export const useAdditionalItemsStyles = makeStyles(() => ({
  wrap: {
    padding: "0px",
    paddingTop: "10px",
    cursor: "pointer",
  },
  container: {
    overflow: "hidden",
    transition: "max-height 0.3s",
    maxHeight: "auto",
  },
  icon: {
    transition: "all 0.5s",
  },
}));
