import { axiosAuthorized } from "../axios";
import { isEmpty } from "lodash";

const WidgetsService = {
  async getWidgets(
    row: number,
    page: number,
    sortState: string,
    sortBy: string | null,
    sortType: Array<string>,
    term: string
  ): Promise<Array<Record<string, any>>> {
    let sortTypeString = "";
    if (!isEmpty(sortType))
      sortType.forEach(
        (item) =>
          (sortTypeString =
            sortTypeString + `&filteringType[]=${item.toUpperCase()}`)
      );
    let stringRequest = `widgets/v3?limit=${row}&pageNumber=${page}`;
    if (term) stringRequest = stringRequest + `&term=${term}`;
    if (sortState && sortBy)
      stringRequest =
        stringRequest + `&fieldName=${sortState}&order=${sortBy.toUpperCase()}`;
    if (sortTypeString) stringRequest = stringRequest + sortTypeString;
    const data = await axiosAuthorized.get(stringRequest);
    return data.data;
  },

  async getWidgetsFeedorder(
    row: number,
    page: number,
    sortState: string,
    sortBy: string | null,
    sortType: Array<string>,
    term: string
  ): Promise<Array<Record<string, any>>> {
    let sortTypeString = "";
    if (!isEmpty(sortType))
      sortType.forEach(
        (item) =>
          (sortTypeString =
            sortTypeString + `&filteringType[]=${item.toUpperCase()}`)
      );
    let stringRequest = `widgets/v3/feedOrder?limit=${row}&pageNumber=${page}`;
    if (term) stringRequest = stringRequest + `&term=${term}`;
    if (sortState && sortBy)
      stringRequest =
        stringRequest + `&fieldName=${sortState}&order=${sortBy.toUpperCase()}`;
    if (sortTypeString) stringRequest = stringRequest + sortTypeString;
    const data = await axiosAuthorized.get(stringRequest);
    return data.data;
  },

  async getDropsResults(
    id: string,
    row: number,
    page: number,
    sortState: string | null,
    sortBy: string | null,
    status: string
  ): Promise<Array<Record<string, any>>> {
    let stringRequest = `widgets/drop/${id}/results?limit=${row}&pageNumber=${page}&status=${status}`;
    if (sortState && sortBy)
      stringRequest =
        stringRequest + `&fieldName=${sortState}&order=${sortBy.toUpperCase()}`;
    const data = await axiosAuthorized.get(stringRequest);
    return data.data;
  },

  async getInstantResults(
    id: string,
    row: number,
    page: number,
    sortState: string | null,
    sortBy: string | null,
  ): Promise<Array<Record<string, any>>> {
    let stringRequest = `widgets/instant/${id}/results?limit=${row}&pageNumber=${page}`;
    if (sortState && sortBy)
      stringRequest =
        stringRequest + `&fieldName=${sortState}&order=${sortBy.toUpperCase()}`;
    const data = await axiosAuthorized.get(stringRequest);
    return data.data;
  },

  async exportInstantResults(
    id: string,
    sortState: string | null,
    sortBy: string | null,
  ): Promise<Array<Array<any>> | string | undefined> {
    const stringRequest = `widgets/instant/${id}/results/exportCsv?fieldName=${sortState || "name"}&order=${
        sortBy?.toUpperCase() || "ASC"
      }`;
    const data = await axiosAuthorized.get(stringRequest);
    return data.data;
  },

  async getCampaigns(
    row: number,
    page: number,
    order: string,
    sortByName: string,
    term: string
  ): Promise<Array<Record<string, any>>> {
    const fieldName = sortByName || 'createdAt';
    const orderBy = order === 'asc' ? 'ASC': 'DESC';
    const data = await axiosAuthorized.get(
      `campaigns/all?limit=${row}&pageNumber=${page}&fieldName=${fieldName}&order=${orderBy}&term=${term || ''}`
    );
    return data.data;
  },

  async setCampaignPaused(
    id: string,
    isPaused: boolean
  ): Promise<Record<string, any>> {
    const data = await axiosAuthorized.patch(
      `campaigns/${id}/pause`,
      { isPaused }
    );
    return data.data;
  },
};

export default WidgetsService;
