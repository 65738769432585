import React, { ChangeEvent, ChangeEventHandler } from "react";

import { useInputStyles } from "./inputs-styles.hook";
import { DefaultInput } from "./default-input";
import { useIntl } from "react-intl";
import { COLOR_REGEX } from "../../../constants";

interface IFormikField {
  label?: string;
  name: string;
  value: string;
  form: Record<string, any>;
  onChange?:
  | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  | undefined;
}

export const FormikColorField: React.FC<IFormikField> = ({
  label,
  name,
  value,
  form,
  onChange
}) => {
  const classes = useInputStyles();
  const intl = useIntl();
  const isHaveError: boolean = form.errors[name] && form.submitCount;
  const blockColor: string = COLOR_REGEX.test(value) ? value : "transparent";
  const errorClass: string = isHaveError ? classes.errorBorderField : "";

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(name, event.target.value);
    if (onChange) onChange(event);
  };

  const errorText =
    form.touched[name] && form.errors[name] && form.errors[name];

  return (
    <div>
      <span className={classes.fieldLabel}>{label}</span>
      <DefaultInput
        className={errorClass}
        fullWidth
        multiline
        type="number"
        variant="standard"
        value={value}
        onChange={changeHandler}
        placeholder={intl.formatMessage({
          id: "enter_hex_value",
        })}
        name={name}
        error={!!isHaveError}
        InputProps={{
          endAdornment: (
            <div
              className={classes.inputColorBlock}
              style={{ backgroundColor: blockColor, border: COLOR_REGEX.test(blockColor) ? '1px solid #ccc': 'none' }}
            />
          ),
        }}
      />

      <div className={classes.errorText}>
        {errorText &&
          intl.formatMessage({
            id: errorText,
          })}
      </div>
    </div>
  );
};
