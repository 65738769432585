import React from "react";
import { useIntl } from "react-intl";
import { usePageStyles } from "../../../pages-styles.hook";
import { Text } from "../../../../components/wilson-ui/text";
import { FlexBox } from "../../../../components/wilson-ui/box";

export const TagsHeader = () => {
  const pageClasses = usePageStyles();
  const intl = useIntl();

  return (
    <FlexBox className={pageClasses.container}>
      <Text className={pageClasses.pageTitle}>
        {intl.formatMessage({ id: "tags" })}
      </Text>
    </FlexBox>
  );
};
