import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";
import { usePageStyles } from "../../../../pages-styles.hook";
import { useHeaderStyles } from "./header-styles.hook";
import { FlexBox } from "../../../../../components/wilson-ui/box";
import { UiButton } from "../../../../../components/wilson-ui/buttons";
import WidgetsStore from "../../../../../store/widgets/widgets.store";

interface IProps {
  location: any;
  isResults: boolean;
  order: string;
}

export const ResultsWidgetHeader: React.FC<IProps> = ({
  location,
  isResults,
  // order
}) => {
  const [dataCSV, setDataCSV] = useState<any>([[]]);
  const pageClasses = usePageStyles();
  const classes = useHeaderStyles();
  const intl = useIntl();
  const csvResults: any = useRef();

  const downloadCSV = async () => {
    WidgetsStore.exportInstantResults(
      location,
      '',
      '',
      setDataCSV
    );
  };

  useEffect(() => {
    if (
      !isEmpty(dataCSV) &&
      csvResults?.current &&
      csvResults?.current?.link
    ) {
      setTimeout(() => {
        csvResults.current.link.click();
        setDataCSV([]);
      });
    }
  }, [dataCSV]);

  return (
    <FlexBox className={pageClasses.container}>
      <h2 className={pageClasses.pageTitle}>
        {intl.formatMessage({ id: "feed_widget_details" })}
      </h2>
      {isResults ? (
        <FlexBox className={classes.buttons}>
          <>
            <UiButton
              buttonType="white"
              style={{ minWidth: "147px", border: "1px solid #000" }}
              disabled={WidgetsStore.isLoadingExport}
              onClick={downloadCSV}
            >
              {intl.formatMessage({
                id: WidgetsStore.isLoadingExport ? "exporting" : "export_results",
              })}
            </UiButton>
            {dataCSV && (
              <CSVLink
                id="download-result-csv"
                filename="results-list"
                data={dataCSV}
                ref={csvResults}
              />
            )}
          </>
        </FlexBox>
      ) : null}
    </FlexBox>
  );
};
