import React, { useEffect, useState } from "react";
import {
  FormikField,
  FormikVisibleField,
} from "../../../../components/wilson-ui/inputs";
import { useGeneralSettingsStyles } from "./general-settings-styles.hook";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { RadioCheckboxRed } from "../../../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { FormikColorField } from "../../../../components/wilson-ui/inputs";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { useIntl } from "react-intl";
import { FormikAutocomplete } from "../../../../components/wilson-ui/selects";
import {
  getTagsBySting,
  sendNewTag,
} from "../../../../actions/widgets/tags.actions";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal } from "../modals";
import image from "../../../../assets/images/testImage.png";
import { Accordion } from "../accordion";

interface IGeneralSettings {
  form: Record<string, any>;
}

export const GeneralSettingsLocationBased: React.FC<IGeneralSettings> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);
  const [tagsOptions, setTagsOptions] = useState<Array<Record<string, any>>>(
    []
  );
  const [filterString, setFilterString] = useState("");
  const classes = useGeneralSettingsStyles();
  const createWidgetClasses = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  useEffect(() => {
    sendData();
  }, [filterString, form.values.tags]);

  const sendData = async () => {
    const tags = await getTagsBySting(filterString);
    setTagsOptions(tags);
  };

  const sendNewTagHandler = async (value: string) => {
    const isSuccess = await sendNewTag(value);
    if (isSuccess) sendData();
  };

  const tagsOptionsValue = tagsOptions.filter((item: any) => {
    let isUsed = false;
    form.values.tags.forEach((tag: any) => {
      if (item.id === tag.id) isUsed = true;
    });
    if (!isUsed) return item;
  });

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "feed" })}
      image={image}
      maxHeight="400px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  return (
    <div>
      <Accordion
        className={createWidgetClasses.generalSettingsAccordion}
        summary={
          <FlexBox className={createWidgetClasses.headerContainer}>
            <h3>{intl.formatMessage({ id: "general_setting" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox
            columns="1fr 1fr 1fr"
            rg="15px"
            cg="25px"
            className={classes.radioButtonContainer}
          >
            <RadioCheckboxRed
              value={form.values.countdownTime}
              label={intl.formatMessage({ id: "countdown_time" })}
              labelFirst={true}
              submit={(event, value) =>
                form.setFieldValue("countdownTime", value)
              }
              name="countdownTime"
            />
            <RadioCheckboxRed
              value={form.values.fullHeightVisibility}
              label={intl.formatMessage({
                id: "full_height",
              })}
              labelFirst={true}
              submit={(event, value) => form.setFieldValue("fullHeightVisibility", value)}
              name="fullHeightVisibility"
            />
          </GridBox>
          <GridBox columns="2fr 2fr" cg="30px" rg="30px" items="end">
            <FormikVisibleField
              label={`${intl.formatMessage({ id: "title" })}*`}
              name="title"
              submit={form.handleChange}
              value={form.values.title}
              {...{ form }}
              placeholder={intl.formatMessage({ id: "add_title" })}
            />
            <FormikField
              label={`${intl.formatMessage({ id: "subtitle" })}`}
              name="subtitle"
              submit={form.handleChange}
              value={form.values.subtitle}
              {...{ form }}
              placeholder={intl.formatMessage({ id: "add_subtitle" })}
            />
          </GridBox>
          <GridBox rg="30px" cg="30px" columns="2fr 2fr">
            <FormikColorField
              label={intl.formatMessage({
                id: "background_color_hex_value",
              })}
              name="background"
              value={form.values.background}
              {...{ form }}
            />
              <FormikField
                label={intl.formatMessage({ id: "website_url" })}
                name="site"
                submit={form.handleChange}
                value={form.values.site}
                {...{ form }}
                placeholder={intl.formatMessage({ id: "enter_website_url" })}
              />
          </GridBox>
        </GridBox>
        <FlexBox
          direction="column"
          gap="30px"
          className={classes.bottomContainer}
        >
          <FormikField
            placeholder={intl.formatMessage({
              id: "enter_terms_and_conditions",
            })}
            name="terms"
            submit={form.handleChange}
            value={form.values.terms}
            {...{ form }}
            label={intl.formatMessage({ id: "terms_and_conditions" })}
            multiline
            rows={4}
          />
          <FormikField
            placeholder={intl.formatMessage({ id: "description" })}
            name="description"
            submit={form.handleChange}
            value={form.values.description}
            {...{ form }}
            label={intl.formatMessage({ id: "description" })}
            multiline
            rows={4}
          />
          <FormikAutocomplete
            placeholder="Add tags"
            optionLabel="name"
            options={tagsOptionsValue}
            label={intl.formatMessage({ id: "tags" })}
            name="tags"
            limitTags={8}
            value={form.values.tags}
            {...{ form }}
            textSubmit={setFilterString}
            textValue={filterString}
            addHandler={sendNewTagHandler}
          />
        </FlexBox>
      </Accordion>
      {modal}
    </div>
  );
};
