import React, { useState } from "react";
import { FormikColorField } from "../../../../components/wilson-ui/inputs/formik-color-field";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { RadioCheckboxRed } from "../../../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { useIntl } from "react-intl";
import { DropzoneImageMP4 } from "../../../../components/wilson-ui/dropzone";
import { axiosAuthorized } from "../../../../axios";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal } from "../modals";
import image from "../../../../assets/images/testImage.png";
import { Accordion } from "../accordion";
import { TitleSizeSelect } from "../title-size-select";
import { EMPTY_FUNCTION } from "../../../../constants";
import { WidgetTypeEnum } from "../../../../enum";

interface IGeneralSettings {
  form: Record<string, any>;
}

export const PinnedAssets: React.FC<IGeneralSettings> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);
  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const sendData = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "widgets/feedMedia",
        formData
      );
      form.setFieldValue("pinnedMediaUrl", data.data.feedMedia);
      form.setFieldValue("pinnedMediaLink", data.data.feedMedia);
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "pinned" })}
      image={image}
      maxHeight="400px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  if (!form.values.hasPinned) return null;

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "pinned_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox
            columns="1fr 1fr 1fr"
            rg="15px"
            cg="25px"
            className={classes.radioButtonContainer}
          >
            <RadioCheckboxRed
              value={form.values.pinnedTitleVisibility}
              label={intl.formatMessage({
                id: "title_visibility",
              })}
              submit={(event, value) =>
                form.setFieldValue("pinnedTitleVisibility", value)
              }
              name="pinnedTitleVisibility"
            />
            <RadioCheckboxRed
              value={form.values.pinnedHasCountdown}
              label={intl.formatMessage({ id: "countdown_time" })}
              submit={(event, value) =>
                form.setFieldValue("pinnedHasCountdown", value)
              }
              name="pinnedHasCountdown"
            />
          </GridBox>
          {[WidgetTypeEnum.INSTANT_WIN].includes(
            form.values.type.toUpperCase()
          ) ? (
            <GridBox columns="2fr 1fr" cg="30px" rg="30px" items="center">
              <FormikColorField
                label={intl.formatMessage({ id: "title_color" })}
                name="pinnedTitleColor"
                value={form.values.pinnedTitleColor}
                {...{ form }}
              />
              <TitleSizeSelect
                label="Title Size"
                value={form.values.pinnedTitleSize}
                submit={(value) => form.setFieldValue("pinnedTitleSize", value)}
                {...{ form }}
              />
            </GridBox>
          ) : (
            <GridBox columns="1fr 1fr 1fr" cg="30px" rg="30px" items="center">
              <FormikColorField
                label={intl.formatMessage({ id: "background_color" })}
                name="pinnedBackgroundColor"
                value={form.values.pinnedBackgroundColor}
                {...{ form }}
              />
              <FormikColorField
                label={intl.formatMessage({ id: "title_color" })}
                name="pinnedTitleColor"
                value={form.values.pinnedTitleColor}
                {...{ form }}
              />
              <TitleSizeSelect
                label="Title Size"
                value={form.values.pinnedTitleSize}
                submit={(value) => form.setFieldValue("pinnedTitleSize", value)}
                {...{ form }}
              />
            </GridBox>
          )}
          <GridBox columns="2fr 2fr" cg="30px" rg="30px">
            <FormikColorField
              label={intl.formatMessage({ id: "countdown_text_color" })}
              name="pinnedTextColor"
              value={form.values.pinnedTextColor}
              {...{ form }}
            />

            <FormikColorField
              label={intl.formatMessage({ id: "countdown_fill_color" })}
              name="pinnedFillColor"
              value={form.values.pinnedFillColor}
              {...{ form }}
            />
          </GridBox>
          <GridBox columns="1fr" cg="30px" rg="30px">
            <DropzoneImageMP4
              {...{ form, sendData }}
              name="pinnedMediaUrl"
              filePreview={form.values.pinnedMediaLink}
            />
          </GridBox>
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
