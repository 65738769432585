import { makeStyles } from "@material-ui/core";

export const useWinnerItemStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '15px'
  },
  icon: {
    color: "#CF102D",
    cursor: "pointer",
    opacity: 0.9,
    transition: '0.5s',
    '&:hover': {
      opacity: 1
    }
  }
}));
