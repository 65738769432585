import React, { ChangeEventHandler } from "react";
import { DefaultInput } from "./index";
import { useInputStyles } from "./inputs-styles.hook";
import { MaterialFieldType } from "../types/material-field.type";
import { useIntl } from "react-intl";

interface IFormikField {
  label?: string;
  isError?: boolean;
  isSmallLabel?: boolean;
  name: string;
  type?: string;
  submit?:
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  onKeyDown?: (event: any) => void;
  value: string;
  form: Record<string, any>;
  variant?: "filled" | "standard" | "outlined" | undefined;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  style?: any;
  disabled?: boolean;
  defaultSubmit?: (event: any) => void;
}

export const FormikField: React.FC<IFormikField> = ({
  label,
  isSmallLabel,
  isError,
  name,
  type,
  submit,
  value,
  variant,
  form,
  placeholder,
  multiline,
  rows,
  style,
  disabled,
  onKeyDown,
  defaultSubmit,
}) => {
  const intl = useIntl();
  const classes = useInputStyles();
  const isHaveError: boolean = (form?.errors[name] && form?.submitCount) || isError;
  const errorClass = isHaveError ? classes.errorBorderField : "";
  const fieldVariant: MaterialFieldType = variant ? variant : "standard";

  const submitHandler = defaultSubmit ? defaultSubmit : submit;
  const errorText =
    form.touched[name] && form.errors[name] ? form.errors[name].toLowerCase(): '';

  return (
    <div style={style}>
      <span className={!isSmallLabel ? classes.fieldLabel: classes.fieldLabelSmall}>{label}</span>
      <DefaultInput
        multiline={multiline}
        rows={rows}
        className={errorClass}
        fullWidth
        variant={fieldVariant}
        value={value}
        onChange={submitHandler}
        onBlur={form.handleBlur}
        onKeyDown={onKeyDown}
        name={name}
        disabled={disabled}
        type={type || "text"}
        placeholder={placeholder}
        error={!!isHaveError}
      />
      <div className={classes.errorText}>
        {errorText &&
          intl.formatMessage({
            id: errorText,
          })}
      </div>
    </div>
  );
};
