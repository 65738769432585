import React, { Dispatch, SetStateAction } from "react";
import { useSelectsStyles } from "./selects-styles.hook";
import Autocomplete from "@mui/material/Autocomplete";
import { DefaultInput } from "../inputs";
import { EMPTY_FUNCTION } from "../../../constants";

interface IProps {
  form: Record<string, any>;
  options: Array<Record<string, any>>;
  name: string;
  value?: Record<any, any> | null;
  label?: string;
  textSubmit?: Dispatch<SetStateAction<string>>;
  textValue?: string;
  type?: string | undefined;
  optionLabel: string;
  clearTextValue?: () => void;
  variant?: "filled" | "standard" | "outlined" | undefined;
  addHandler?: (value: string) => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  onFocus?: Dispatch<SetStateAction<string>>;
}

export const FormikAutocompleteDefault: React.FC<IProps> = ({
  form,
  options,
  name,
  value,
  label,
  onFocus,
  textSubmit,
  textValue,
  optionLabel,
  clearTextValue,
  variant,
  open,
  setOpen,
}) => {
  const classes = useSelectsStyles();
  const isHaveError: boolean = form?.errors[name] && form?.submitCount;
  const errorClass: string = isHaveError ? classes.errorSelect : "";
  const placeholderValue = "";
  const inputType = variant ? variant : "standard";

  const clearTextValueHandler = clearTextValue
    ? clearTextValue
    : EMPTY_FUNCTION;

  return (
    <div>
      <span className={classes.fieldLabel}>{label}</span>
      <Autocomplete
        className={`${classes.autocomplete} ${errorClass}`}
        fullWidth
        disablePortal
        value={value}
        open={open}
        onOpen={() => {
          setOpen && setOpen(true);
        }}
        onClose={() => {
          setOpen && setOpen(false);
        }}
        getOptionLabel={(option: Record<string, any>) => option[optionLabel]}
        options={options}
        sx={{ width: 300 }}
        renderInput={(params: Record<string, any>) => (
          <DefaultInput
            value={textValue}
            onFocus={() => onFocus && onFocus("")}
            onChange={(e) => textSubmit && textSubmit(e.target.value)}
            {...params}
            fullWidth
            variant={inputType}
            placeholder={placeholderValue}
          />
        )}
        onChange={(event, val) => {
          form.setFieldValue(name, val);
          clearTextValueHandler();
        }}
      />
    </div>
  );
};
