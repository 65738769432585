import * as Yup from "yup";
import { COLOR_REGEX, URL_REGEX } from "../../constants";

export const AddWidgetsDropAdditionSchema = Yup.object().shape({
  dropName: Yup.string()
    .min(3, "Please value must be at least 3 characters.")
    .required("Required"),
  dropUrl: Yup.string().required("Required"),
  site: Yup.string(),
  hoursBeforeDrop: Yup.number()
    .min(1, "min_1"),

  alertTitleColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  alertTitleHighlightColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  alertButtonTextColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  alertButtonColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  alertBorderColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  alertCountdownTimerColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),

  detailsAssetsButtonText: Yup.string()
    .min(3, "Please value must be at least 3 characters.")
    .max(50000, "max_length_50000")
    .required("Required"),
  detailsAssetsButtonColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  detailsAssetsButtonTextColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),

  successTitle: Yup.string().required("Required"),
  successTitleColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  successDescription: Yup.string().max(500, "max_length_500"),
  successDescriptionColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  successBackgroundColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  successMediaUrl: Yup.mixed(),

  missedTitle: Yup.string(),
  missedTitleColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  missedDescriptionColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  missedBackgroundColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),

  favoriteTitle: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string().required("Required"),
  }),
  favoriteSiteUrl: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string()
      .matches(URL_REGEX, "must_correspond")
      .required("Required"),
  }),
  favoriteThumbnailImage: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.mixed().required("Required"),
  }),
  // feedMedia: Yup.array().when("description", (description, schema) => {
  //   return schema.test({
  //     test: (feedMediaArr: Array<Record<string, any>>) => {
  //       let isValid = true;
  //       if (feedMediaArr.length) {
  //         feedMediaArr.forEach((item: Record<string, any>) => {
  //           if (!item.assetUrl) {
  //             isValid = false;
  //           }
  //         });
  //       } else {
  //         isValid = false;
  //       }
  //       return isValid;
  //     },
  //   });
  // }),
});
