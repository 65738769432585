import { makeStyles } from "@material-ui/core";

export const useModalStyle = makeStyles(() => ({
  header: {
    color: "#000",
    display: "block",
    
    fontFamily: "GT America",
    fontWeight: 500,
    marginBottom: "24px",
    "& h4": {
      fontSize: "24px",
    }
  },
  cancelButton: {
    width: "80px !important",
    borderRadius: "8px",
    border: "1px solid #a2a8ae",
    marginRight: "12px",
    fontSize: "16px"
  },
  uploadButton: {
    borderRadius: "8px",
    border: "1px solid #a2a8ae",
    backgroundColor: "#cf0f2e",
    fontSize: "16px"
  }
}));
