import { makeAutoObservable } from "mobx";
import CarouselService from "../../services/carousel.service";
import { toJS } from "mobx";
import { findIndex } from 'lodash';

export type ICarouselData = Array<Record<string, any>>;

class CarouselStore {
  isLoading = false;
  isChanged = false;
  carouselData = { id: "", title: "", titleColor: "", backgroundColor: "", data: [], status: "" };
  carouselsArray = { data: [], length: 0 };
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  async carouselDataRequest(carouselId: string) {
    try {
      this.isLoading = true;
      const carousel = await CarouselService.getCarousel(carouselId);
      this.carouselData.title = carousel ? carousel.title : "";
      this.carouselData.titleColor = carousel ? carousel.titleColor : "";
      this.carouselData.backgroundColor = carousel ? carousel.backgroundColor : "";
      this.carouselData.data = carousel ? carousel.childWidgets : [];
      this.carouselData.status = carousel ? carousel.status : "";
      this.carouselData.id = carousel ? carousel.id : "";
    } catch (error) {
      this.error = error.message;
    } finally {
      this.isLoading = false;
    }
  }

  async getCarouselsDataRequest(page: number) {
    this.isLoading = true;
    const carousel = await CarouselService.getCarouselsArray(page);
    this.carouselsArray.data = carousel ? carousel.carouselWidgets : [];
    this.carouselsArray.length = carousel ? carousel.length : 0;
    this.isLoading = false;
  }

  changeCarousels(arr: any) {
    this.carouselsArray.data = arr;
  }

  changeCarousel(arr: any) {
    this.carouselData.data = arr;
    this.isChanged = true;
  }

  addItem(item: Record<string, any>) {
    const newCarouselData: Array<Record<string, any>> = this.carouselData.data;
    item.priority = newCarouselData.length;
    newCarouselData.push({
      ...item,
      id: undefined,
      carouselId: undefined,
      childId: item.childId,
    });
    const sortData: any = newCarouselData.sort((a: any, b: any) =>
      a.priority > b.priority
        ? 1
        : b.priority > a.priority
        ? -1
        : 0
    );
    this.carouselData.data = toJS(sortData);
    this.isChanged = true;
  }

  updateItem(item: Record<string, any>) {
    const newCarouselData: any = this.carouselData.data;
    const index = findIndex(newCarouselData, (u: any) => u.childId === item.childId);
    if (index >= 0) {
      newCarouselData[index] = {
        ...newCarouselData[index],
        ...item
      };
    }
    this.carouselData.data = toJS(newCarouselData);
  }

  getStatus() {
    return this.carouselData.status;
  }

  setStatus(status: string) {
    this.carouselData.status = status;
    this.isChanged = true;
  }

  setTitle(title: string) {
    this.carouselData.title = title;
    this.isChanged = true;
  }

  setTitleColor(titleColor: string) {
    this.carouselData.titleColor = titleColor;
    this.isChanged = true;
  }

  setBackgroundColor(backgroundColor: string) {
    this.carouselData.backgroundColor = backgroundColor;
    this.isChanged = true;
  }

  setIsChanged(value: boolean) {
    this.isChanged = value;
  }

  resetCarouselData() {
    this.carouselData = { id: "", title: "", titleColor: "", backgroundColor: "", data: [], status: "" };
  }
}

export default new CarouselStore();
