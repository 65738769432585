import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";
import { mediaQuery } from "../../../../components/wilson-ui/constants/media";

export const useCarouselItemStyle = makeStyles(() => ({
  container: {
    minHeight: "40px",
    alignItems: "center",
    gridTemplateColumns: "1fr 1.5fr 1fr 100px 70px",
    borderBottom: `1px solid ${baseColors.grey}`,
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "'GT America', sans-serif",
    padding: '5px 0',
    "& > div": {
      padding: "0px 12px",
    },
    "& svg": {
      transition: "0.5s",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.7,
      },
    },
    [mediaQuery.maxWidth600]: {
      gridTemplateColumns: "1fr 1fr",
      rowGap: "10px !important",
      padding: "10px 0px",
      height: "auto",
    },
  },
  removeIcon: {
    color: baseColors.red,
  },
  carouselColumn: {
    [mediaQuery.maxWidth600]: {
      textAlign: "center",
    },
  },
  icon: {
    fontSize: "1.1rem !important",
  },
  image: {
    width: "32px",
    height: "32px",
    borderRadius: '50%'
  }
}));
