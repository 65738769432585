import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";

export const useAuthorAvatarStyles = makeStyles(() => ({
  container: {
    width: "210px",
    height: "50px",
    gap: "15px",
    padding: "5px",
    borderRadius: "100px",
    border: "1px solid #d6d6d6",
  },
  downloadButton: {
    display: "flex",
    cursor: "pointer",
    transition: "0.7s",
    alignItems: "center",
    gap: "10px",
    "&:hover": {
      opacity: 0.7,
    },
    "& span": {
      borderBottom: `1px solid ${baseColors.black}`,
    },
  },
  imageWrap: {
    height: "40px",
    width: "40px",
    borderRadius: "100%",
    overflow: "hidden",
    border: `1px dashed #ccc`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  baseImage: {
    background: "#fbfbfb",
    padding: 12,
  },
  input: {
    display: "none",
  },
}));
