import React from "react";
import { FlexBox } from "../../wilson-ui/box";
import { useAuthWrapStyles } from "./auth-wrap-styles.hook";

export const AuthWrap: React.FC = ({ children }) => {
  const classes = useAuthWrapStyles();

  return (
    <FlexBox content="center" items="center" className={classes.wrap}>
      <FlexBox
        direction="column"
        items="center"
        gap="120px"
        className={classes.container}
      >
        {children}
      </FlexBox>
    </FlexBox>
  );
};
