import React, { FC } from "react";
import { UiModal } from "../../../../components/wilson-ui/modals";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { useSubmissionsConfirmModalStyles } from "./submissions-confirm-modal-style.hook";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useIntl } from "react-intl";

interface IProps {
  toggleModal: () => void;
  submit: () => void;
  title: string
}

export const ConfirmModal: FC<IProps> = ({
  toggleModal,
  submit,
  title
}) => {
  const classes = useSubmissionsConfirmModalStyles();
  const intl = useIntl();

  return (
    <UiModal width="600px" closeHandler={toggleModal}>
      <div className={classes.container}>
        <FlexBox content="center" items="center">
          <h3 className={classes.title}>
            {title}
          </h3>
        </FlexBox>
        <FlexBox content="center" items="center">
          <div className={classes.body}>
            <UiButton
              type="button"
              buttonType="black"
              onClick={() => {
                submit();
                toggleModal();
              }}
            >
              {intl.formatMessage({ id: "yes" })}
            </UiButton>
            <UiButton type="button" buttonType="white" onClick={toggleModal}>
              {intl.formatMessage({ id: "no" })}
            </UiButton>
          </div>
        </FlexBox>
      </div>
    </UiModal>
  );
};
