import {useIntl} from "react-intl";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";
import {ITableDragConfig} from "../../components/wilson-ui/types/table-config.inteface";

export const campaignsTableConfig = (): ITableDragConfig => {
    const intl = useIntl();

    return {
        rowColumnsCarouselDrag: "180px 180px 150px 170px 80px 100px 150px 120px 100px",
        columns: [
            {
                field: "name",
                type: "component",
                componentName: "name",
                headerName: intl.formatMessage({id: "name"}),
                width: 180,
                sortable: true,
                filterType: "none",
                GRID_DETAIL_PANEL_TOGGLE_FIELD,
            },
            {
                field: "trigger",
                type: "component",
                componentName: "trigger",
                headerName: intl.formatMessage({id: "trigger"}),
                filterType: "none",
                width: 180,
                sortable: true,
            },
            {
                field: "createdAt",
                type: "component",
                componentName: "data",
                headerName: intl.formatMessage({id: "created_on"}),
                filterType: "none",
                width: 150,
                sortable: true,
            },
            {
                field: "startDate",
                type: "component",
                componentName: "data",
                headerName: intl.formatMessage({id: "scheduled_for"}),
                filterType: "none",
                width: 170,
                sortable: true,
            },
            {
                field: "qty",
                type: "component",
                componentName: "qty",
                headerName: intl.formatMessage({id: "qty"}),
                filterType: "none",
                width: 80,
                sortable: false,
            },
            {
                field: "claimed",
                type: "component",
                componentName: "claimed",
                headerName: intl.formatMessage({id: "claimed"}),
                filterType: "none",
                width: 100,
                sortable: false,
            },
            {
                field: "status",
                type: "component",
                componentName: "status",
                headerName: intl.formatMessage({id: "status"}),
                filterType: "none",
                width: 150,
                sortable: false,
            },
            {
                field: "isPaused",
                type: "component",
                componentName: "checkbox",
                headerName: intl.formatMessage({id: "paused"}),
                filterType: "none",
                width: 120,
                sortable: false,
            },
            {
                name: "edit",
                type: "component",
                componentName: "button",
                headerName: "",
                filterType: "none",
                width: 100,
                sortable: false,
            },
        ],
    };
};
