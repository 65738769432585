import { makeStyles } from "@material-ui/core";

export const useGeneralSettingsStyles = makeStyles(() => ({
  containerAddressInput: {
    "& h4": {
      marginBottom: '5px!important',
    },
    "& .pac-target-input": {
      width: '100%',
      height: '32px',
      color: '#000000',
      fontSize: '16px',
      lineHeight: '32px',
      padding: '4px 0 5px',
      border: 'none',
      borderBottom: '1px solid #808080 !important',
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      },
      "&:hover": {
        borderBottom: `1px solid  #000000 !important`,
      },
    }
  },
  containerAddressInputError: {
    "& .pac-target-input": {
      borderBottom: '1px solid #CF102D !important',
    }
  },
  errorText: {
    fontFamily: "'GT America',  sans-serif",
    fontSize: "12px",
    color: "red",
    fontWeight: 500,
    display: "block",
    marginTop: "5px",
  },
}));
