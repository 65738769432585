import { axiosAuthorized } from "../../axios";

export const getCarousel = async (carouselId: string): Promise<any> => {
  try {
    const data: Record<string, any> = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL + "widgets/carousel/" + carouselId + "/v2"
    );
    const sortData = {
      ...data.data,
      childWidgets: data.data.childWidgets.map((item: any) => ({
        ...item,
        id: item?.carouselId,
        carouselId: undefined,
        childId: item.id,
      })),
    };
    return sortData;
  } catch (error) {
    throw new Error(error.response.data.message || error.message);
  }
}

export const sendCarouselData = async (
  carouselId: string,
  data: Array<Record<string, any>>,
  status: string,
  title: string,
  titleColor: string,
  backgroundColor: string
): Promise<void> => {
  const newData = data.map((item, index) => {
    return { ...item, ["priority"]: index };
  });
  await axiosAuthorized.put(
    process.env.REACT_APP_API_URL + "widgets/carousel/" + carouselId + "/v2",
    {
      widgetsToAdd: newData,
      status: status,
      title,
      titleColor,
      backgroundColor
    }
  );
};

export const getCarouselsArray = async (
  page: number
): Promise<Record<string, any>> => {
  let carousels = { data: [], length: 0 };

  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        "widgets/carousel?limit=20&pageNumber=" +
        page
    );

    carousels = data.data;
  } catch (error) {
    console.warn(error);
  }

  return carousels;
};

export const createNewCarouseRequest = async (
  values: Record<string, any>
): Promise<any> => {
  let isCreated = null;

  try {
    const data = await axiosAuthorized.post(
      process.env.REACT_APP_API_URL + "widgets/carousel",
      {
        ...values,
      }
    );
    isCreated = data.data;
  } catch (error) {
    console.warn(error);
  }

  return isCreated;
};

export const editCarouseRequest = async (
  values: Record<string, any>
): Promise<boolean> => {
  let isEdited = false;

  try {
    await axiosAuthorized.put(
      process.env.REACT_APP_API_URL + "widgets/carousel/" + values.id + "/v2",
      {
        ...values,
        widgetsToAdd: null,
      }
    );

    isEdited = true;
  } catch (error) {
    console.warn(error);
  }

  return isEdited;
};

export const removeCarouselRequest = async (id: string): Promise<boolean> => {
  let isRemoved = false;

  try {
    await axiosAuthorized.delete(
      process.env.REACT_APP_API_URL + "widgets/" + id
    );

    isRemoved = true;
  } catch (error) {
    console.warn(error);
  }

  return isRemoved;
};
