import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";
import { mediaQuery } from "../../../../components/wilson-ui/constants/media";

export const useCarouselListHeaderStyle = makeStyles(() => ({
  container: {
    height: "26px",
    gridTemplateColumns: "1fr 1.5fr 1fr 100px 70px",
    borderBottom: `1px solid ${baseColors.black}`,
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "'GT America', sans-serif",
    marginBottom: "15px",
    "& > div": {
      padding: "0px 12px",
    },
    [mediaQuery.maxWidth600]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  carouselColumn: {
    [mediaQuery.maxWidth600]: {
      textAlign: "center",
    },
  },
}));
