import React from "react";
import { FlexBox, GridBox } from "../../../box";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTextSortStyles } from "./text-sort-styles.hook";

export const TextSort: React.FC = () => {
  const classes = useTextSortStyles();

  return (
    <GridBox className={classes.textSort}>
      <FlexBox>
        <span>a</span>
        <KeyboardArrowUpIcon />
      </FlexBox>
      <FlexBox>
        <span>z</span>
        <KeyboardArrowDownIcon />
      </FlexBox>
    </GridBox>
  );
};
