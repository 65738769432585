import React from "react";
import {
  Accordion as MUIAccordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useAccordionStyles } from "./accordion-styles.hook";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface AccordionProps {
  summary: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({
  summary,
  className = "",
  children,
}) => {
  const classes = useAccordionStyles();

  return (
    <MUIAccordion className={`${classes.root} ${className}`}>
      <AccordionSummary
        style={{ margin: 0 }}
        expandIcon={
          <div className={classes.arrow}>
            <KeyboardArrowUpIcon />
          </div>
        }
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MUIAccordion>
  );
};
