import moment from "moment-timezone";
import { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { axiosAuthorized } from "../../axios";
import { EMPTY_FUNCTION } from "../../constants";

export const exportCsv = (body: Record<string, any>) => {
  const result = [];

  if (body.length > 0) {
    result.push(Object.keys(body[0]));

    body.map((item: any) => {
      result.push(Object.values(item));
    });
  }

  return result;
};

export const getSubmissionsCSV = async (
  rows: number,
  page: number,
  order: string,
  sortByName: string,
  filterType: Array<string>,
  filterWinner: Array<string>,
  sortByTitle: Record<string, any>
): Promise<any> => {
  let downloadData: Array<Array<any>> = [[]];
  try {
    let filterTypeString = "";
    let filterWinnerString = "";
    let sortByTitleString = "";
    sortByTitle.forEach(
      (item: any) =>
        (sortByTitleString = sortByTitleString + `&filteringTitle[]=${item}`)
    );
    filterType.forEach(
      (item: any) =>
        (filterTypeString =
          filterTypeString + `&filteringType[]=${item.toUpperCase()}`)
    );
    filterWinner.forEach(
      (item: any) =>
        (filterWinnerString =
          filterWinnerString + `&filteringWinner[]=${item.toUpperCase()}`)
    );
    const res: any = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `promotions/submissions/exportCsv?fieldName=${sortByName}&order=${order}&limit=${rows}&pageNumber=${page}${filterTypeString}${filterWinnerString}`
    );
    const data = res?.data?.data || [];
    const dataExport = data.map((item: any) => {
      const createdAt = (item as any)?.createdAt || new Date();

      return {
        name: `${item.user.firstName} ${item.user.lastName}`,
        email: item.user.email,
        title: item.promotion.widget.title,
        type: item.promotion.widget.type,
        userId: item.user.id,
        promotionId: item.promotion.id,
        widgetId: item.promotion.widget.id,
        winner: !!item.user.wonPromotions.length,
        createdAt: createdAt && moment(createdAt).format("M/D/YYYY"),
        createdAtTime: createdAt && moment(createdAt).format("HH:mm:ss ZZ"),
      };
    });
    downloadData = exportCsv(dataExport);
  } catch (error) {
    EMPTY_FUNCTION();
  }

  return downloadData;
};

export const downloadSubmissionsFullListCSV = async (
  length: number,
  dateRange: DateRange<Dayjs>
): Promise<Array<Array<any>>> => {
  let downloadData: Array<Array<any>> = [[]];
  try {
    const res: any = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `promotions/submissions/exportCsv/all?limit=${length}${
          dateRange[0]
            ? `&startDate=
          ${dateRange[0]}`
            : ""
        }${
          dateRange[1] ? `&endDate=${dateRange[1]}` : ""
        }&pageNumber=1&fieldName=name&order=ASC`
    );
    const data = res?.data?.data || [];
    const dataExport = data.map((item: any) => {
      const createdAt = (item as any)?.createdAt || new Date();

      return {
        name: `${item.user.firstName} ${item.user.lastName}`,
        email: item.user.email,
        title: item.promotion.widget.title,
        type: item.promotion.widget.type,
        userId: item.user.id,
        promotionId: item.promotion.id,
        widgetId: item.promotion.widget.id,
        winner: !!item.user.wonPromotions.length,
        createdAt: createdAt && moment(createdAt).format("M/D/YYYY"),
        createdAtTime: createdAt && moment(createdAt).format("HH:mm:ss ZZ"),
      };
    });
    downloadData = exportCsv(dataExport);
  } catch (error) {
    EMPTY_FUNCTION();
  }

  return downloadData;
};
