import { makeStyles } from "@material-ui/core";
import { mediaQuery } from "../components/wilson-ui/constants/media";

export const usePageStyles = makeStyles(() => ({
  container: {
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "50px",
    [mediaQuery.maxWidth600]: {
      display: "flex !important",
      flexDirection: "column",
      gap: "20px",
    },
  },
  bigLabel: {
    "& span": {
      fontFamily: "GT America Bold",
      fontSize: 16,
      fontWeight: 500
    },
  },
  bigInput: {
    "& span": {
      fontFamily: "GT America Bold",
      fontSize: 16,
      fontWeight: 500
    },
    "& .MuiInput-root": {
      fontFamily: "Roboto",
      fontSize: 24,
      fontWeight: "bold",
    },
  },
  pageTitle: {
    fontWeight: 900,
    fontSize: "32px",
    textTransform: "uppercase",
    fontFamily: "'GT America Extended Bold', sans-serif",
    [mediaQuery.maxWidth600]: {
      fontSize: "21px",
    },
  },
}));
