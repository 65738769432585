import { makeStyles } from "@material-ui/core";
import { baseColors } from "../constants/colors";

export const useInputStyles = makeStyles(() => ({
  errorBorderField: {
    "& .MuiInput-root": {
      borderBottom: `1px solid ${baseColors.red} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${baseColors.red} !important`,
    },
  },
  errorSpan: {
    width: "100%",
    marginLeft: "12px",
    position: "absolute",
    color: "red",
    fontSize: "12px",
    marginTop: "4px",
  },
  inputColorBlock: {
    width: "20px",
    height: "20px",
    borderRadius: "100%",
    transition: "0.7s",
  },
  fieldLabel: {
    fontFamily: "'GT America',  sans-serif",
    fontSize: "14px",
    color: baseColors.black,
    fontWeight: 500,
    display: "block",
    marginBottom: "5px",
  },
  fieldLabelSmall: {
    fontFamily: "'GT America',  sans-serif",
    fontSize: "11px",
    color: baseColors.dark,
    fontWeight: 500,
    display: "block",
    marginBottom: "5px",
  },
  visibleIcon: {
    cursor: "pointer",
  },
  errorText: {
    fontFamily: "'GT America',  sans-serif",
    fontSize: "12px",
    color: baseColors.red,
    fontWeight: 500,
    display: "block",
    marginTop: "5px",
  },
}));
