import React from "react";
import { Text } from "../../../../components/wilson-ui/text";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { usePageStyles } from "../../../pages-styles.hook";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import {UiButton} from "../../../../components/wilson-ui/buttons";

interface IProps {
  toggleModal: () => void
}

export const CarouselsHeader: React.FC<IProps> = observer(({toggleModal}) => {
  const pageClasses = usePageStyles();
  const intl = useIntl();



  return (
    <FlexBox
      items="center"
      content="space-between"
      className={pageClasses.container}
    >
      <Text className={pageClasses.pageTitle}>
        {intl.formatMessage({ id: "carousels" })}
      </Text>

      <UiButton buttonType='black' onClick={() => toggleModal()}>
        {intl.formatMessage({ id: "add" })}
      </UiButton>

    </FlexBox>
  );
});
