import { axiosAuthorized } from "../../axios";

export const getTagsBySting = async (
  string: string
): Promise<Array<Record<string, any>>> => {
  let tags: Array<Record<string, any>> = [];

  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL + `tags/filteredTags?filterValue=${string}`
    );
    tags = data.data;
  } catch (error) {
    console.warn(error);
  }
  return tags;
};

export const sendNewTag = async (title: string): Promise<boolean> => {
  let isSend = false;

  try {
    await axiosAuthorized.post(process.env.REACT_APP_API_URL + "tags/post", {
      name: title,
    });
    isSend = true;
  } catch (error) {
    console.warn(error);
  }

  return isSend;
};
