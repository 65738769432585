import { useState, useCallback, useEffect } from "react";
const storageName = "Authorization";

export interface IAuthReturnValue {
  login: (jwtToken: string) => void;
  logout: () => void;
  token: string | null;
  isFirstRenderEnd: boolean;
}

export const useAuth = (): IAuthReturnValue => {
  const [token, setToken] = useState(null);
  const [isFirstRenderEnd, setIsFirstRenderEnd] = useState<boolean>(false);

  const login = useCallback((jwtToken) => {
    localStorage.setItem(storageName, jwtToken);
    setToken(jwtToken);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    localStorage.removeItem(storageName);
  }, []);

  useEffect(() => {
    const data = localStorage.getItem(storageName);
    if (data) {
      login(data);
    }
    setIsFirstRenderEnd(true);
  }, [login]);

  return { login, logout, token, isFirstRenderEnd };
};
