import { ChangeEvent, Dispatch } from "react";

export const setSelectFile = async (
  event: ChangeEvent<HTMLInputElement>,
  setImage: Dispatch<any>,
  setIsLoading: Dispatch<any>,
  sendData: (file: any) => void
): Promise<void> => {
  if (!event.target.files || event.target.files.length < 1) {
    try {
      setImage(undefined);
    } catch (e) {
      console.warn(e);
    }
  } else {
    setIsLoading(true);
    await sendData(event.target.files[0]);
    setImage(event.target.files[0]);
    setIsLoading(false);
  }
};
