import { axiosAuthorized } from "../../axios";
import { toast } from "react-toastify";

export const downloadUsersCSV = async (
  length: number,
  startDate: Date | null,
  endDate: Date | null,
  order: string,
  sortByName: string,
  term: string | undefined,
  isInterests: boolean
): Promise<string> => {
  let downloadData = "";
  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `users/exportCsv?limit=${length}&pageNumber=1&${startDate ? `&startDate=${startDate}`: ''}${endDate ? `&endDate=${
          endDate}`: ''
        }&fieldName=${sortByName}&order=${order}&term=${
          term || ""
        }&isInterests=${isInterests ? "true" : ""}`
    );
    downloadData = data.data ?? "";
  } catch (error) {
    console.warn(error);
  }

  return downloadData;
};

export const downloadUserFullListCSV = async (length: number): Promise<string> => {
  let downloadData = "";
  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `users/exportCsv?limit=${length}&pageNumber=1&fieldName=name&order=ASC`
    );
    downloadData = data.data ?? "";
  } catch (error) {
    console.warn(error);
  }

  return downloadData;
};

export const removeUser = async (id: string): Promise<boolean> => {
  let isRemove;

  try {
    await axiosAuthorized.delete(process.env.REACT_APP_API_URL + `users/${id}`);
    isRemove = true;
  } catch (error) {
    console.log({ ...error });
    toast(error.response.data.message);
    isRemove = false;
  }

  return isRemove;
};
