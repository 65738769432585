import { sortValue } from "../../../../types";
import { Dispatch } from "react";

export const useSortState = (
  val: string,
  order: string,
  sortByName: string,
  setOrder: Dispatch<any>,
  setSortByName: Dispatch<any>
): void => {
  if (val === sortByName) {
    setOrder(Object.values(sortValue).filter((item) => item !== order)[0]);
  }

  setSortByName(val);
};
