import React from "react";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import { Collapse } from "@mui/material";
import { TableRow as MuiTableRow, TableCell } from "@material-ui/core";
import { IRowColumn } from "../interface";
import { BodyColumn } from "../body-column";
import { TableBody } from "../table-body";
import { FlexBox } from "../../../box";
import { useTableStyles } from "../../table-styles.hook";
import { WidgetTypeEnum } from "../../../../../enum";
export interface TableRowProps {
  row: Record<string, any>;
  rowCarousel?: Record<string, any>;
  columns: Array<Record<string, any>>;
  components: Record<string, any>;
  className: string;
  rowColumns: string;
  rowColumnsCarousel?: string;
}

export const TableRow: React.FC<TableRowProps> = ({
  row,
  rowCarousel,
  columns,
  rowColumns,
  components,
  className,
  rowColumnsCarousel,
}) => {
  const [open, setOpen] = React.useState(false);
  const componentsObject: IRowColumn = {};
  const intl = useIntl();
  const classes = useTableStyles();
  const defaultTableBodyProps = {
    components: components ? components : componentsObject,
    columns,
    data: row?.childWidgets || [],
    rowCarousel: row,
    rowColumns: rowColumnsCarousel || "",
    rowColumnsCarousel: rowColumnsCarousel || "",
  };

  return (
    <>
      <MuiTableRow
        className={className}
        style={{ gridTemplateColumns: rowColumns }}
      >
        {columns.map((column: IRowColumn) => (
          <BodyColumn
            components={components}
            column={column}
            row={row}
            rowCarousel={rowCarousel || undefined}
            open={open}
            setOpen={setOpen}
            key={Math.random().toString().substr(2)}
          />
        ))}
      </MuiTableRow>
      {row.type === WidgetTypeEnum.CAROUSEL && (
        <MuiTableRow
          className={className}
          style={{ gridTemplateColumns: rowColumnsCarousel }}
        >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {!isEmpty(defaultTableBodyProps.data) ? (
                <TableBody {...defaultTableBodyProps} />
              ) : (
                <FlexBox
                  content="center"
                  items="center"
                  className={classes.emptySpanCarousel}
                >
                  {intl.formatMessage({ id: "carousel_is_empty" })}
                </FlexBox>
              )}
            </Collapse>
          </TableCell>
        </MuiTableRow>
      )}
    </>
  );
};
