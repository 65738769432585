import { ChangeEvent, Dispatch } from "react";

export const useChangeFilterValue = (
  event: ChangeEvent<HTMLInputElement>,
  form: Record<string, any>,
  setForm: Dispatch<any>,
  setSortForm: Dispatch<any>
): void => {
  const value = !form[event.target.name] ? event.target.name : "";
  setForm({ ...form, [event.target.name]: value });
  const newArray = Object.values(form).filter((item: any) => {
    return (
      item && item !== event.target.name && form[event.target.name] && item
    );
  });
  setSortForm(newArray);
};
