import React, { FC } from "react";
import { CarouselListHeader } from "../carousel-list-header";
import CarouselListBody from "../carousel-list-body/carousel-list-body";

export const CarouselList: FC = () => {
  return (
    <div>
      <CarouselListHeader />
      <CarouselListBody />
    </div>
  );
};
