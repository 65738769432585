import { axiosAuthorized } from "../../axios";
import { toast } from "react-toastify";

export const downloadUsersInterestsCSV = async (
  rows: number,
  page: number,
  order: string,
  sortByName: string
): Promise<string> => {
  let downloadData = "";
  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `user-interests/exportCsv?limit=${rows}&pageNumber=${page}&fieldName=${sortByName}&order=${order}`
    );
    downloadData = data.data ?? "";
  } catch (error) {
    console.warn(error);
  }

  return downloadData;
};

export const sendInterest = async (
  interest: Record<string, any>
): Promise<any> => {
  try {
    return await axiosAuthorized.post(process.env.REACT_APP_API_URL + "user-interests", {
      ...interest,
    });
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export const getInterest = async (id: string): Promise<Record<string, any>> => {
  let interest = {};

  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL + `user-interests/${id}`
    );
    interest = data.data;
  } catch (error) {
    console.warn(error);
  }

  return interest;
};

export const editInterest = async (
  interest: Record<string, any>
): Promise<any> => {
  try {
    return await axiosAuthorized.patch(
      process.env.REACT_APP_API_URL + "user-interests/" + interest.id,
      {
        ...interest,
      }
    );
  } catch (error) {
    console.warn(error);
    return false;
  }
};

export const removeUserInterests = async (id: string): Promise<boolean> => {
  let isRemove;

  try {
    await axiosAuthorized.delete(process.env.REACT_APP_API_URL + `user-interests/${id}`);
    isRemove = true;
  } catch (error) {
    console.log({ ...error });
    toast(error.response.data.message);
    isRemove = false;
  }

  return isRemove;
};

export const updatePriorityHelper = async (
  payload: Array<Record<string, any>>
): Promise<void> => {
  if (payload.length) {
    try {
      await axiosAuthorized.put(
        process.env.REACT_APP_API_URL + "user-interests/priority",
        { interests: payload }
      );
    } catch (error) {
      console.warn(error);
    }
  }
};
