import React, { useState } from "react";
import { useIntl } from "react-intl";
import { usePageStyles } from "../../../pages-styles.hook";
import { Text } from "../../../../components/wilson-ui/text";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { DownloadUsersCsvModal } from "../../../../components/wilson-ui/modals";

interface IProps {
  length: number;
  sortByName: string;
  order: string;
  term?: string;
}

export const UsersHeader: React.FC<IProps> = ({
  length,
  sortByName,
  order,
  term,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pageClasses = usePageStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const modal = isModalOpen && (
    <DownloadUsersCsvModal
      length={length}
      order={order}
      sortByName={sortByName}
      term={term}
      toggle={toggleModal}
    />
  );

  return (
    <FlexBox className={pageClasses.container}>
      <Text className={pageClasses.pageTitle}>
        {intl.formatMessage({ id: "users" })}
      </Text>
      <UiButton buttonType="white" onClick={toggleModal}>
        {intl.formatMessage({ id: "export" })}
      </UiButton>
      {modal}
    </FlexBox>
  );
};
