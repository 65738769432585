import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { MainLayout } from "../../components";
import { AdditionalSettings } from "./components/addition-settings";
import { AdditionalSettingsDrop } from "./components/addition-settings-drop";
import { FlexBox, GridBox } from "../../components/wilson-ui/box";
import { Settings } from "./components/settings";
import { AddWidgetsSchema } from "./add-widgets-schema";
import { AddWidgetsDropSchema } from "./add-widgets-drop-schema";
import { useCreateWidgetsStyles } from "./create-widgets-style.hook";
import { schemas } from "./create-widgets-schema-options";
import ROUTING_CONFIG from "../../constants/routing-config/routing-config";
import { initialValues } from "./initeal-values";
import { AddWidgetsStorySchema } from "./add-widgets-story-schema";
import { sendFormHelper } from "./helpers/send-widget";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { WidgetTypeEnum } from "../../enum";
import { UpgradeWidgetToEditHelper } from "../../helpers/widgets/upgrade-widget-to-edit.helper";
import { Spiner } from "../../components/wilson-ui/spiner";
import { getWidget } from "../../actions/widgets";
import { usePageStyles } from "../pages-styles.hook";

export const CreateWidgetsPage: React.FC = () => {
  const intl = useIntl();
  const [isWidgetFound, setIsWidgetFound] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstRenderEnd, setIsFirstRenderEnd] = useState(false);
  const [widgetsSchema, setWidgetsSchema] =
    useState<Yup.ObjectSchema<any>>(AddWidgetsSchema);
  const [additionSchema, setAdditionSchema] = useState<Yup.ObjectSchema<any>>(
    AddWidgetsStorySchema
  );
  const classes = useCreateWidgetsStyles();
  const classesTitle = usePageStyles();
  const history = useHistory();
  const location: Record<string, any> = useLocation();
  const formValues: any = initialValues;
  const form = useFormik({
    initialValues: formValues,
    validationSchema: additionSchema
      ? widgetsSchema.concat(additionSchema)
      : widgetsSchema,
    onSubmit: () => {
      sendFormHandler();
    },
  });

  useEffect(() => {
    getWidgetHandler();
  }, []);

  useEffect(() => {
    const item = schemas.find(schema => schema.name.toUpperCase() === form.values.type.toUpperCase());

    if (item) setAdditionSchema(item.schema);

    if (form.values.type.toUpperCase() === WidgetTypeEnum.DROP) {
      setWidgetsSchema(AddWidgetsDropSchema);
    }

    if (isFirstRenderEnd) {
      form.setValues(form.values);
      // form.setValues({
      // ...form.values,
      // scanRequire: false,
      // favoriteIcon: false,
      // countdownTime: false,
      // shareIcon: false,
      // exclusive: false,
      // });
    } else {
      setIsFirstRenderEnd(true);
    }

  }, [form.values.type]);

  const notify = () => toast(intl.formatMessage({ id: "something_is_wrong" }));

  const sendFormHandler = async () => {
    const isOk = await sendFormHelper(form.values);

    if (isOk) {
      history.push(ROUTING_CONFIG.widgets);
    } else {
      notify();
    }
  };

  const getWidgetHandler = async () => {
    const widgetIdPath =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (widgetIdPath) {
      const data = await getWidget(widgetIdPath);

      if (data.id) {
        const widgetToSet: any = UpgradeWidgetToEditHelper(data);
        form.setValues(widgetToSet);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsWidgetFound(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    switch (form.values.type.toUpperCase()) {
      case WidgetTypeEnum.DROP:
        return <AdditionalSettingsDrop {...{ form }} />;
      case WidgetTypeEnum.FEATURED_CAROUSEL:
        return null;
      default:
        return <AdditionalSettings {...{ form }} />;
    }
  }

  const body = isWidgetFound ? (
    isLoading ? (
      <Spiner />
    ) : (
      <>
        <GridBox columns="auto 312px" className={classes.container} cg="50px">
          <div>
            <h2 className={classesTitle.pageTitle}>
              {intl.formatMessage({ id: "create_a_new_widget" })}
            </h2>
            <Settings {...{ form }} />
          </div>
          {renderForm()}
        </GridBox>
      </>
    )
  ) : (
    <FlexBox items="center" content="center">
      <h3 className={classes.errorTitle}>
        {intl.formatMessage({ id: "widget_not_found" })}
      </h3>
    </FlexBox>
  );

  return <MainLayout>{body}</MainLayout>;
};
