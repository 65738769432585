import React from "react";
import { DetailsAssets } from "../details-assets";
import { SuccessAssets } from "../success-assets-drop";
import { PinnedAssets } from "../pinned-assets";
import { AlertAssets } from "../alert-assets";
import { MissedAssets } from "../missed-assets";
import { FavoriteThumbnail } from "../favorite-thumbnail";

interface IProps {
  form: Record<string, any>;
}

export const SettingDrop: React.FC<IProps> = ({ form }) => {
  return (
    <>
      <PinnedAssets {...{ form }} />
      <AlertAssets {...{ form }} />
      <DetailsAssets {...{ form }} />
      <SuccessAssets {...{ form }} />
      <MissedAssets {...{ form }} />
      <FavoriteThumbnail {...{ form }} />
    </>
  );
};
