import React, { ReactElement, useState } from "react";
import { UiButton } from "../../components/wilson-ui/buttons";
import { useFormik } from "formik";
import { FormikField } from "../../components/wilson-ui/inputs";
import { useHistory } from "react-router-dom";
import { ROUTING_CONFIG } from "../../constants/routing-config/routing-config";
import image from "../../assets/images/wilson_logo-red@3x.png";
import { sendPhoneNumber } from "../../actions/auth";
import { AuthWrap } from "../../components/wrappers";
import { AuthSchema } from "./auth.schema";
import { useAuthStyle } from "./auth-style.hook";
import { useIntl } from "react-intl";

export const AuthPage = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const form = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: AuthSchema,
    onSubmit: () => sendFormHandler(),
  });

  const history = useHistory();
  const classes = useAuthStyle();

  const sendFormHandler = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await sendPhoneNumber({
        phoneNumber: form.values.phoneNumber.trim()
      });
      history.push(ROUTING_CONFIG.confirmAuth, form.values);
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      setIsLoading(false);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendFormHandler();
    }
  };

  return (
    <AuthWrap>
      <img src={image} alt="wilson logo" />
      <div className={classes.container}>
        <FormikField
          label="Phone"
          name="phoneNumber"
          defaultSubmit={(e) => form.setFieldValue('phoneNumber', e.target.value.replace(/\s/g, ""))}
          value={form.values.phoneNumber}
          placeholder="+18887775555"
          onKeyDown={onKeyDown}
          {...{ form }}
        />
      </div>
      <UiButton
        buttonType="black"
        onClick={form.handleSubmit}
        disabled={isLoading}
      >
        {intl.formatMessage({ id: "login" })}
      </UiButton>
    </AuthWrap>
  );
};
