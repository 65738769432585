import {
  WidgetStoryButtonStyle,
  WidgetStatusForCreateWidget,
  DropStatusEnum,
  BackgroundType,
  ContainerType,
  WidgetFeedTitleFont
} from "../../types";
import moment from "moment-timezone";

export const initialValues = {
  title: "",
  subtitle: "",
  type: "",
  background: "",
  site: "",
  status: Object.values(WidgetStatusForCreateWidget)[0],
  startDateDate: null,
  startDateTime: null,
  expirationDateDate: null,
  expirationDateTime: null,
  channels: [],
  terms: "",
  description: "",
  tags: [],
  titleVisibility: false,
  buttonVisibility: false,
  scanRequire: false,
  favoriteIcon: false,
  countdownTime: false,
  fullHeightVisibility: false,
  shareIcon: false,
  exclusive: false,
  feedTitleColor: "#ffffff",
  feedTitleSize: "medium",
  feedTitleFont: WidgetFeedTitleFont.GT_AMERICA,
  feedTitleHighlightColor: "#000000",
  feedAssetsButtonText: "View Details",
  feedAssetsButtonTextColor: "#000000",
  feedAssetsButtonColor: "#ffffff",
  feedAssetsButtonImage: "",
  feedMediaLink: null,
  detailsAssetsButtonText: "",
  detailsAssetsButtonColor: "",
  detailsAssetsButtonTextColor: "",
  detailsAssetsContainerType: ContainerType.LIGHT,

  successAssetsButtonText: "",
  successAssetsButtonColor: "",
  successAssetsButtonTextColor: "",
  successAssetsButtonImage: null,
  successAssetsMediaLink: null,
  promotionsCollaborationImage: "",
  promotionsTitle: "",
  promotionsTitleColor: "",
  promotionsDescription: "",
  promotionsDescriptionColor: "",
  promotionsModalVisibility: true,
  promotionsModalImage: "",
  promotionModalImgLink: null,
  promotionCollaborationImgLink: null,
  promotionsModalTitle: "",
  promotionsModalTitleColor: "",
  promotionsModalBackgroundColor: "",
  promotionsBackgroundColor: "",
  promotionsModalBackgroundImage: "",
  promotionsModalBackgroundImgLink: null,
  thumbnailLockerUrl: null,
  favoriteTitle: "",
  favoriteSiteUrl: "",
  favoriteThumbnailImage: null,
  storyAssetsImage: null,
  storyAuthorName: "",
  storyAuthorNameColor: "",
  storyDescription: "",
  storyAuthorAvatar: null,
  storyAuthorAvatarLink: null,
  storyButtonText: "",
  storyButtonStyle: WidgetStoryButtonStyle.swipeUp,
//   storySwipe: [{
//     assetUrl: "",
//     preview: "",
//     priority: 0,
//     id: Math.random().toString().substr(2),
// }],
  storySwipe: [],
  feedMedia: [],
  id: "",
  isAdd: true,
  campaign: null
};

export const initialValuesDrop = {
  ...initialValues,
  feedBorderColor: "",
  feedDropMediaLink: "",

  detailsHasCountdown: false,
  detailsAssetsButtonText: "Notify Me",
  detailsAssetsButtonColor: "#ffffff",
  detailsAssetsButtonTextColor: "#000000",
  detailsAssetsContainerType: ContainerType.LIGHT,
  // drop
  richContent: "",
  dropName: moment().format('DD/MM/YYYY hh:mm'),
  dropUrl: "",
  dropStatus: DropStatusEnum.AUTOMATIC,
  liveDateDate: null,
  liveDateTime: null,
  hoursBeforeDrop: "",

  hasPinned: false,
  pinnedTitleVisibility: false,
  pinnedHasCountdown: false,
  pinnedTitleColor: "",
  pinnedTitleSize: "medium",
  pinnedTextColor: "",
  pinnedFillColor: "",
  pinnedBackgroundColor: "",
  pinnedMediaUrl: "",

  alertTitleVisibility: false,
  alertHasCountdown: false,
  alertCanBeShared: false,
  alertTitleColor: "#ffffff",
  alertTitleSize: "medium",
  alertTitleHighlightColor: '#000000',
  alertButtonTextColor: "#000000",
  alertButtonColor: "#ffffff",
  alertBorderColor: "",
  alertCountdownTimerColor: "",
  alertMediaUrl: "",

  successTitle: "Bagged It",
  successTitleColor: "#000000",
  successDescription: "Check your inbox for order details and tracking information.",
  successDescriptionColor: "#000000",
  successBackgroundColor: "",
  successMediaUrl: "",

  missedTitle: "",
  missedTitleColor: "",
  missedDescription: "",
  missedDescriptionColor: "",
  missedBackgroundColor: "",
  feedItems: [],
}

export const initialValuesInstantWin = {
  ...initialValues,
  feedDropMediaLink: "",
  detailsAssetsBackgroundType: BackgroundType.COLOR,
  detailsAssetsBackgroundColor: "",
  detailsAssetsWinBackgroundColor: "",
  detailsAssetsMissBackgroundColor: "",
  hasPinned: false,
  pinnedTitleVisibility: false,
  pinnedHasCountdown: false,
  pinnedTitleColor: "",
  pinnedTitleSize: "medium",
  pinnedTextColor: "",
  pinnedFillColor: "",
  pinnedBackgroundColor: "",
  pinnedMediaUrl: "",
}

export const initialValuesLocationBased = {
  ...initialValues,
  feedBorderColor: "",
  feedDropMediaLink: "",

  detailsHasCountdown: false,
  detailsAssetsButtonText: "Notify Me",
  detailsAssetsButtonColor: "#ffffff",
  detailsAssetsButtonTextColor: "#000000",
  detailsAssetsContainerType: ContainerType.LIGHT,

  address: "",
  location: {},
  radius: "",
  locationAlias: "",
  locationSuccessBackgroundColor: "",
  locationButtonPostRedemptionColor: "",
  locationButtonText: "",
  locationButtonTextColor: "",
  locationButtonColor: "",
  locationMediaUrl: "",
  
  successTitle: "Bagged It",
  successTitleColor: "#000000",
  successDescription: "Check your inbox for order details and tracking information.",
  successDescriptionColor: "#000000",
  // successBackgroundColor: "",
  // successMediaUrl: "",
  feedItems: [],
}

export const initialValuesInterest = {
  name: "",
  type: "TRENDING",
  status: "ACTIVE",
  category: "",
  mediaUrl: ""
}

export const initialValuesCampaign = {
  prizeTypes: [],
  prizes: [],
  name: "",
  type: "CAMPAIGN",
  status: "ACTIVE",
  webViewUrl: "",
  offerCodeUrl: "",
  startDateDate: null,
  startDateTime: null,
  expirationDateDate: null,
  expirationDateTime: null,
  category: "Racquet",
  qty: 0,
  hits: '',
  misses: '',
  campaign: {}
}
