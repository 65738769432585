import React from "react";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useTitleSizeSelectStyle } from "./title-size-select.hook";
import { UiButton } from "../../../../components/wilson-ui/buttons";

type TitleSizeVariants = "small" | "medium" | "large";

interface TitleSizeSelectProps {
  label: string;
  value: TitleSizeVariants;
  submit: (value: TitleSizeVariants) => void;
  form: Record<string, any>;
}

export const TitleSizeSelect: React.FC<TitleSizeSelectProps> = ({
  label,
  value,
  submit,
  form,
}) => {
  const classes = useTitleSizeSelectStyle();
  const isHaveError: boolean = form.errors.feedTitleSize && form.submitCount;
  const sizes = ["small", "medium", "large"] as TitleSizeVariants[];
  const errorClass: string = isHaveError ? ` ${classes.errorBorderField}` : "";

  return (
    <FlexBox items="center" gap="16px">
      <span className={classes.label}>{label}</span>
      <FlexBox items="center">
        {sizes.map((size) => (
          <UiButton
            key={size}
            buttonType={value === size ? "black" : "white"}
            onClick={() => submit(size)}
            className={`${classes.button} ${classes[size]}${errorClass}`}
          >
            тТ
          </UiButton>
        ))}
      </FlexBox>
    </FlexBox>
  );
};
