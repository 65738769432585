import React, { FC } from "react";
import { useButtonsItemStyles } from "./buttons-items-style.hook";
import { useIntl } from "react-intl";

interface IProps {
  submit: () => void;
}

export const EditButtonItem: FC<IProps> = ({ submit }) => {
  const classes = useButtonsItemStyles();
  const intl = useIntl();

  return (
    <button className={classes.link} onClick={submit}>
      {intl.formatMessage({ id: "edit" })}
    </button>
  );
};
