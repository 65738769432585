import React, { useContext } from "react";
import { FlexBox } from "../../../wilson-ui/box";
import { UiButton } from "../../../wilson-ui/buttons";
import { UiModal } from "../../../wilson-ui/modals";
import { AuthContext } from "../../../../contexts/auth-context";
import { LOCALES } from "../../../../../I18n/locales";
import { SelectChangeEvent } from "@mui/material/Select";
import { SelectSimple } from "../../../wilson-ui/selects";
import { useLanguage } from "../../../../hooks/use-language-hook";
import { useNavbarModalStyles } from "./navbar-modal-styles.hook";
import { useIntl } from "react-intl";
import moment from "moment-timezone";
import { axiosAuthorized } from "../../../../axios";

interface IProps {
  closeHandler: () => void;
}

export const NavbarModal: React.FC<IProps> = ({ closeHandler }) => {
  const intl = useIntl();
  const { logout } = useContext(AuthContext);
  const { setLanguage, getLanguage } = useLanguage();
  const classes = useNavbarModalStyles();
  const zoneName = moment.tz.guess();
  const zoneOffset = new Date().getTimezoneOffset() / 60;
  const fullZoneName =
    zoneName + (zoneOffset > 0 ? ` GMT+${zoneOffset}` : ` GMT${zoneOffset}`);

  const onLogoutClick = async () => {
    await axiosAuthorized.get(process.env.REACT_APP_API_URL + "auth/logout");
    logout();
  };

  return (
    <UiModal width="500px" closeHandler={closeHandler}>
      <FlexBox content="center" items="center" className={classes.container}>
        <h2>{intl.formatMessage({ id: "settings" })}</h2>
        <FlexBox content="center" items="center" className={classes.fieldBlock}>
          <span className={classes.fieldLabel}>
            {intl.formatMessage({ id: "language" })}:
          </span>
          <SelectSimple
            options={Object.values(LOCALES)}
            name="language"
            value={getLanguage()}
            submit={(e: SelectChangeEvent<string>) => {
              setLanguage(e.target.value);
            }}
          />
        </FlexBox>
        <FlexBox content="center" items="center" className={classes.fieldBlock}>
          <span className={classes.fieldLabel}>
            {intl.formatMessage({ id: "time_zone" })}:
          </span>
          <span className={classes.fieldValue}>{fullZoneName}</span>
        </FlexBox>
        <UiButton buttonType="white" onClick={onLogoutClick}>
          {intl.formatMessage({ id: "logout" })}
        </UiButton>
        <UiButton buttonType="black" onClick={() => closeHandler()}>
          {intl.formatMessage({ id: "exit" })}
        </UiButton>
      </FlexBox>
    </UiModal>
  );
};
