import { makeStyles } from "@material-ui/core";
import { mediaQuery } from "../../components/wilson-ui/constants/media";

export const useCarouselPageStyles = makeStyles(() => ({
  container: {
    gridTemplateColumns: "1fr 800px",
    rowGap: "30px",
    columnGap: "30px",
    [mediaQuery.maxWidth1024]: {
      gridTemplateColumns: "auto !important",
    },
  },
  container__error: {
    textAlign: "center",
  },
}));
