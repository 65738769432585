import React, { FC, useState } from "react";
import { useWinnerItemStyles } from "./winner-item-styles.hook";
import { UiButton } from "../../wilson-ui/buttons";
import { useIntl } from "react-intl";
import { axiosAuthorized } from "../../../axios";
import SubmissionsStore from "../../../store/submissions/submissions.store";
import { ConfirmModal } from "../../../pages/submissions/components/confirm-modal";

interface IWinnerItemContent {
  name: string;
  row: Record<string, any>;
}

const WinnerItemContent: FC<IWinnerItemContent> = ({ name, row }) => {
  const [isModalShow, setIsModalShow] = useState(false);
  const intl = useIntl();

  const toggleModal = () => setIsModalShow(!isModalShow);

  const setWinnerHandler = async (widgetId: string, userId: string) => {
    const sendForm = {
      widgetId: widgetId,
      winners: [userId],
    };

    try {
      await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "promotions/winners",
        sendForm
      );
      SubmissionsStore.setWinner(widgetId, userId);
    } catch (error) {
      console.warn(error);
    }
  };

  const confirmModal = isModalShow && (
    <ConfirmModal
      {...{ toggleModal }}
      submit={() => setWinnerHandler(row.widgetId, row.userId)}
      title={intl.formatMessage({ id: "are_you_sure" })}
    />
  );

  if (!name)
    return (
      <>
        <UiButton onClick={toggleModal} buttonType="black">
          {intl.formatMessage({ id: "assign" })}
        </UiButton>
        {confirmModal}
      </>
    );

  return (
    <UiButton buttonType="white" onClick={toggleModal}>
      {intl.formatMessage({ id: "winner" })}
    </UiButton>
  );
};

interface IProps {
  name: string;
  row: Record<string, any>;
}

export const WinnerItem: FC<IProps> = ({ name, row }) => {
  const classes = useWinnerItemStyles();

  return (
    <div className={classes.container}>
      <WinnerItemContent row={row} name={name} />
    </div>
  );
};
