import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../wilson-ui/constants/colors";

export const useChannelsItemStyles = makeStyles(() => ({
  container: {
    width: "auto",
    minHeight: "25px",
    padding: "0 15px",
    border: "2px solid #000",
    cursor: "pointer",
  },
  wrap: {
    display: "flex",
    width: "100%",
    gap: "10px",
    // flexWrap: "wrap",
    position: "relative",
    overflow: "hidden",
    "& > div > *:not(:first-child)": {
      marginTop: "8px",
    },
  },
  more: {
    cursor: "pointer",
    position: "absolute",
    right: "5px",
    background: baseColors.red,
    color: baseColors.white,
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
}));
