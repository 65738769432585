import { makeAutoObservable } from "mobx";
import { findIndex } from "lodash";
import WidgetsService from "../../services/widgets-service";

export interface ICampaigns {
  length: number;
  data: Array<Record<string, unknown>>;
}

class CampaignsStore {
  campaigns: ICampaigns = { length: 0, data: [] };
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async campaignsRequest(
    row: number,
    page: number,
    order: string,
    sortByName: string,
    term: string
  ) {
    this.isLoading = true;
    const data: Record<string, any> = await WidgetsService.getCampaigns(
      row,
      page,
      order,
      sortByName,
      term
    );
    this.setCampaigns({ length: data.count, data: data.campaigns });
    this.isLoading = false;
  }

  async setCampaignPaused(
    id: string,
    isPaused: boolean
  ): Promise<Record<string, any>> {
    console.log('id', id)
    this.isLoading = true;
    const index = findIndex(this.campaigns.data, (campaign: any) => campaign.id === id);
    if (index >= 0) this.campaigns.data[index].isPaused = isPaused;
    const data = await WidgetsService.setCampaignPaused(id, isPaused);
    this.isLoading = false;
    return data;
  }

  setCampaigns(campaigns: ICampaigns) {
    this.campaigns = campaigns;
  }

  getCampaigns() {
    return this.campaigns;
  }

}

export default new CampaignsStore();
