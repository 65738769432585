import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../../components/wilson-ui/constants/colors";

export const useFeedAssetsModalStyles = makeStyles(() => ({
  button: {
    background: "#fff",
    padding: "8px 17px",
    width: "115px",
    border: "none",
    color: "#000",
    borderRadius: "5px",
    fontWeight: 700,
    fontFamily: "'GT America', sans-serif",
    position: "relative",
    "&::before, &::after": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: baseColors.white,
      position: "absolute",
      width: "30px",
      fontSize: "12px",
      height: "30px",
      background: baseColors.red,
      borderRadius: "50%",
    },
    "&::before": {
      content: "'2'",
      left: "-14px",
      top: "-14px",
    },
    "&::after": {
      content: "'3'",
      left: "73%",
      bottom: "-14px",
    },
  },
}));
