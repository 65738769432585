import { useIntl } from "react-intl";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";
import {
  ITableConfig,
  ITableDragConfig,
} from "../../components/wilson-ui/types/table-config.inteface";

export const widgetsTableConfig = (): ITableConfig => {
  const intl = useIntl();

  return {
    rowColumns: "260px 120px 180px 180px 240px 100px 100px",
    rowColumnsCarousel: "245px 120px 180px 180px 240px 100px 100px",
    columns: [
      {
        name: "title",
        type: "component",
        componentName: "thumbnail",
        title: intl.formatMessage({ id: "title" }),
        filterType: "right",
      },
      {
        name: "type",
        type: "component",
        componentName: "type",
        title: intl.formatMessage({ id: "type" }),
        filterType: "filter",
      },
      {
        type: "component",
        componentName: "data",
        name: "startDate",
        title: intl.formatMessage({ id: "start_date" }),
        filterType: "none",
      },
      {
        type: "component",
        componentName: "data",
        name: "expirationDate",
        title: intl.formatMessage({ id: "expiration_date" }),
        filterType: "none",
      },
      {
        name: "channels",
        type: "component",
        componentName: "channels",
        title: intl.formatMessage({ id: "channels" }),
        filterType: "left",
      },
      {
        name: "edit",
        type: "component",
        componentName: "button",
        title: "",
        filterType: "none",
      },
    ],
  };
};

export const widgetsTableNoDragConfig = (): ITableDragConfig => {
  const intl = useIntl();

  return {
    rowColumnsCarouselDrag: "253px 140px 180px 180px 260px 100px 100px",
    columns: [
      {
        field: "title",
        type: "component",
        componentName: "thumbnail",
        headerName: intl.formatMessage({ id: "title" }),
        filterType: "none",
        width: 260,
        GRID_DETAIL_PANEL_TOGGLE_FIELD,
      },
      {
        field: "type",
        type: "component",
        componentName: "type",
        headerName: intl.formatMessage({ id: "type" }),
        filterType: "filter",
        width: 140,
      },
      {
        type: "component",
        componentName: "data",
        field: "startDate",
        headerName: intl.formatMessage({ id: "start_date" }),
        filterType: "none",
        width: 180,
        sortable: false,
      },
      {
        type: "component",
        componentName: "data",
        field: "expirationDate",
        headerName: intl.formatMessage({ id: "expiration_date" }),
        filterType: "none",
        width: 180,
        sortable: false,
      },
      {
        field: "channels",
        type: "component",
        componentName: "channels",
        headerName: intl.formatMessage({ id: "channels" }),
        filterType: "none",
        width: 240,
        sortable: false,
      },
      {
        type: "component",
        componentName: "entries",
        field: "entries",
        headerName: intl.formatMessage({ id: "entries" }),
        filterType: "none",
        width: 100,
        sortable: false,
      },
      {
        field: "edit",
        type: "component",
        componentName: "button",
        headerName: "",
        filterType: "none",
        width: 200,
        sortable: false,
      },
    ],
  };
};

export const widgetsTableDragConfig = (): ITableDragConfig => {
  const intl = useIntl();

  return {
    rowColumnsCarouselDrag: "350px 160px 140px 260px 120px 100px",
    columns: [
      {
        field: "title",
        type: "component",
        componentName: "thumbnail",
        headerName: intl.formatMessage({ id: "header" }),
        filterType: "none",
        width: 340,
        GRID_DETAIL_PANEL_TOGGLE_FIELD,
      },
      {
        field: "type",
        type: "component",
        componentName: "type",
        headerName: intl.formatMessage({ id: "type" }),
        filterType: "filter",
        width: 160,
      },
      {
        field: "status",
        type: "component",
        componentName: "status",
        headerName: intl.formatMessage({ id: "status" }),
        filterType: "none",
        width: 140,
      },
      {
        field: "channels",
        type: "component",
        componentName: "channels",
        headerName: intl.formatMessage({ id: "channels" }),
        filterType: "none",
        width: 260,
        sortable: false,
      },
      {
        type: "component",
        componentName: "entries",
        field: "entries",
        headerName: intl.formatMessage({ id: "entries" }),
        filterType: "none",
        width: 100,
        sortable: false,
      },
      {
        field: "edit",
        type: "component",
        componentName: "button",
        headerName: "",
        filterType: "none",
        width: 200,
        sortable: false,
      },
    ],
  };
};

export const feedWidgetsTableConfig = (): ITableDragConfig => {
  const intl = useIntl();

  return {
    columns: [
      {
        field: "title",
        type: "component",
        componentName: "thumbnail",
        headerName: intl.formatMessage({ id: "feed_items" }),
        filterType: "none",
        width: 300,
        GRID_DETAIL_PANEL_TOGGLE_FIELD,
      },
      {
        field: "type",
        type: "component",
        componentName: "type",
        headerName: intl.formatMessage({ id: "type" }),
        filterType: "filter",
        width: 120,
      },
      {
        type: "component",
        componentName: "data",
        field: "startDate",
        headerName: intl.formatMessage({ id: "start_date" }),
        filterType: "none",
        width: 200,
        sortable: false,
      },
      {
        type: "component",
        componentName: "data",
        field: "expirationDate",
        headerName: intl.formatMessage({ id: "expiration_date" }),
        filterType: "none",
        width: 200,
        sortable: false,
      },
      {
        type: "component",
        componentName: "toggle",
        field: "isDropExclusive",
        headerName: intl.formatMessage({ id: "drop_exclusive" }),
        filterType: "none",
        width: 150,
        sortable: false,
      },
      {
        type: "component",
        componentName: "actions",
        field: "actions",
        headerName: intl.formatMessage({ id: "actions" }),
        filterType: "none",
        width: 150,
        sortable: false,
      },
      {
        field: "edit",
        type: "component",
        componentName: "data",
        headerName: "",
        filterType: "none",
        width: 10,
        sortable: false,
      },
    ],
  };
};

export const dropResultsTableConfig = (): ITableDragConfig => {
  const intl = useIntl();

  return {
    columns: [
      {
        field: "fullName",
        type: "component",
        componentName: "fullName",
        headerName: intl.formatMessage({ id: "full_name" }),
        filterType: "filter",
        sortable: true,
        width: 220,
      },
      {
        type: "component",
        componentName: "data",
        field: "createdAt",
        headerName: intl.formatMessage({ id: "trigger_buy" }),
        filterType: "none",
        sortable: false,
        width: 200,
      },
      {
        type: "component",
        componentName: "data",
        field: "updateAt",
        headerName: intl.formatMessage({ id: "completed_buy" }),
        filterType: "none",
        sortable: false,
        width: 200,
      },
      {
        type: "component",
        componentName: "status",
        field: "status",
        headerName: intl.formatMessage({ id: "outcome" }),
        filterType: "none",
        sortable: false,
        width: 150,
      },
      {
        field: "edit",
        type: "component",
        componentName: "empty",
        headerName: "",
        filterType: "none",
        width: 1,
        sortable: false,
      },
    ],
  };
};

export const instantResultsTableConfig = (): ITableDragConfig => {
  const intl = useIntl();

  return {
    columns: [
      {
        field: "fullName",
        type: "component",
        componentName: "fullName",
        headerName: intl.formatMessage({ id: "full_name" }),
        filterType: "filter",
        sortable: true,
        width: 220,
      },
      {
        type: "component",
        componentName: "prizeName",
        field: "prize",
        headerName: intl.formatMessage({ id: "prize_name" }),
        filterType: "none",
        sortable: false,
        width: 200,
      },
      {
        type: "component",
        componentName: "promoCode",
        field: "promotionCode",
        headerName: intl.formatMessage({ id: "promo_code" }),
        filterType: "none",
        sortable: false,
        width: 120,
      },
      {
        type: "component",
        componentName: "data",
        field: "createdAt",
        headerName: intl.formatMessage({ id: "date_time" }),
        filterType: "none",
        sortable: false,
        width: 160,
      },
      {
        type: "component",
        componentName: "status",
        field: "status",
        headerName: intl.formatMessage({ id: "status" }),
        filterType: "none",
        sortable: false,
        width: 100,
      },
      {
        field: "edit",
        type: "component",
        componentName: "empty",
        headerName: "",
        filterType: "none",
        width: 1,
        sortable: false,
      },
    ],
  };
};

export const prizesTableConfig = (): ITableDragConfig => {
  const intl = useIntl();

  return {
    columns: [
      {
        type: "component",
        field: "name",
        componentName: "name",
        headerName: intl.formatMessage({ id: "prizes" }),
        filterType: "none",
        width: 300,
        GRID_DETAIL_PANEL_TOGGLE_FIELD,
      },
      {
        type: "component",
        field: "cardColor",
        componentName: "cardColor",
        headerName: intl.formatMessage({ id: "card_color" }),
        filterType: "filter",
        width: 200,
        sortable: false,
      },
      {
        type: "component",
        componentName: "type",
        field: "type",
        headerName: intl.formatMessage({ id: "type" }),
        filterType: "none",
        width: 150,
        sortable: false,
      },
      {
        type: "component",
        componentName: "data",
        field: "expirationDate",
        headerName: intl.formatMessage({ id: "expiration_date" }),
        filterType: "none",
        width: 300,
        sortable: false,
      },
      // {
      //   type: "component",
      //   componentName: "actions",
      //   field: "actions",
      //   headerName: intl.formatMessage({ id: "actions" }),
      //   filterType: "none",
      //   width: 150,
      //   sortable: false,
      // },
    ],
  };
};
