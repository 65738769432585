export const mediaQuery = {
  maxWidth1200: "@media (max-width: 1200px)",
  maxWidth1100: "@media (max-width: 1100px)",
  maxWidth1024: "@media (max-width: 1024px)",
  maxWidth800: "@media (max-width: 800px)",
  maxWidth600: "@media (max-width: 600px)",
  maxWidth550: "@media (max-width: 550px)",
  maxWidth420: "@media (max-width: 420px)",
  minWidth1100: "@media (min-width: 1100px)",
  minWidth1024: "@media (min-width: 1024px)",
  minWidth800: "@media (min-width: 800px)",
};
