import React from "react";
import { isEmpty } from "lodash";
import { FieldArray } from "formik";
import { GeneralSettings } from "./general-settings";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import {
  DropzoneCSV,
  Dropzone,
} from "../../../../components/wilson-ui/dropzone";
import { QuestionIcon } from "../../../../components/icons";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { TinyMCEEditor } from "../../../../components/wilson-ui/EditorTinyMCE";
import { FormikAutocomplete } from "../../../../components/wilson-ui/selects";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { axiosAuthorized } from "../../../../axios";
import { EMPTY_FUNCTION } from "../../../../constants";
import { PrizeType, prizeTypesOptions } from "../../../../enum";
import { Accordion } from "../accordion";
// import { QuestionIcon } from "../../../../../components/icons";
// import { ItemQuestionModal } from "../../modals";
// import image from "../../../../../assets/images/testImage.png";

interface ISettings {
  form: any;
}

export const Settings: React.FC<ISettings> = ({ form }) => {
  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => false;

  const sendDataFile = async (originalname: string, file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "campaigns/offerCodes",
        formData
      );
      if (isEmpty(data.data.codes)) {
        toast(intl.formatMessage({ id: "file_csv_not_contain" }));
        form.setFieldValue(`${originalname}.offerCodeUrl`, "");
        return;
      }

      form.setFieldValue(
        `${originalname}.offerCodeUrl`,
        data.data.offerCodeUrl
      );
      form.setFieldValue(`${originalname}.codes`, data.data.codes);
      form.setFieldValue(`${originalname}.qty`, data.data.codes.length);
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };

  const sendDataImage = async (originalname: string, file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "campaigns/media",
        formData
      );
      form.setFieldValue(`${originalname}.mediaUrl`, data.data.url);
      form.setFieldValue(`${originalname}.mediaLink`, data.data.url);
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };

  // const modal = isModalTrue ? (
  //   <ItemQuestionModal
  //     title={intl.formatMessage({ id: "feed" })}
  //     image={image}
  //     maxHeight="400px"
  //     closeHandler={toggleModal}
  //   />
  // ) : (
  //   <></>
  // );

  const getInitPrize = (type: PrizeType) => {
    switch (type) {
      case PrizeType.PROMOTION: {
        return {
          priority: form.values.prizes.length + 1,
          type: PrizeType.PROMOTION,
          name: "",
          subTitle: "",
          qty: 0,
          prizeUrl: "",
          typeformUrl: " ",
          offerCodeUrl: "",
          mediaUrl: "",
          richText: "",
          redemptionInstructions: "",
        };
      }
      case PrizeType.PHYSICAL: {
        return {
          priority: form.values.prizes.length + 1,
          type: PrizeType.PHYSICAL,
          name: "",
          subTitle: "",
          qty: "",
          prizeUrl: " ",
          offerCodeUrl: " ",
          mediaUrl: "",
          typeformUrl: "",
          richText: "",
          redemptionInstructions: "",
        };
      }
      case PrizeType.DIGITAL: {
        return {
          priority: form.values.prizes.length + 1,
          type: PrizeType.DIGITAL,
          name: "",
          subTitle: "",
          qty: "",
          prizeUrl: " ",
          offerCodeUrl: " ",
          mediaUrl: "",
          typeformUrl: "",
          richText: "",
          redemptionInstructions: "",
        };
      }
      default: {
        return {
          priority: form.values.prizes.length + 1,
          type: PrizeType.PROMOTION,
          name: "",
          subTitle: "",
          qty: 0,
          prizeUrl: "",
          offerCodeUrl: "",
          mediaUrl: "",
          typeformUrl: " ",
          richText: "",
          redemptionInstructions: "",
        };
      }
    }
  };

  const addOnChange = (options: any) => {
    const prizeTypes = options.map((option: any) => ({
      ...option,
      id: option.id,
      noId: !option.id,
    }));
    if (!isEmpty(prizeTypes)) {
      const prizes = prizeTypes.map((option: any, index: number) => {
        if (!option.noId) {
          return {
            ...form.values.prizes.find((prize: any) => prize.id === option.id),
            priority: index + 1,
            noId: false,
          };
        } else
          return {
            ...getInitPrize(option.value),
            id: option.id,
            priority: index + 1,
            noId: true,
          };
      });
      form.setFieldValue("prizeTypes", prizeTypes);
      form.setFieldValue("prizes", prizes);
    } else {
      form.setFieldValue("prizeTypes", []);
      form.setFieldValue("prizes", []);
    }
  };

  return (
    <FlexBox direction="column" gap="70px" className={classes.sectionContainer}>
      <div>
        <Accordion
          className={classes.generalSettingsAccordion}
          summary={
            <FlexBox className={classes.headerContainer}>
              <h3>{intl.formatMessage({ id: "prizes" })}</h3>
              <QuestionIcon submit={toggleModal} />
            </FlexBox>
          }
        >
          <GridBox columns="1fr" cg="30px" rg="30px">
            <FormikAutocomplete
              placeholder="Add Prizes"
              optionLabel="label"
              options={prizeTypesOptions}
              label={intl.formatMessage({ id: "add_prizes" })}
              name="prizeTypes"
              limitTags={3}
              value={form.values.prizeTypes}
              {...{ form }}
              textValue={""}
              addOnChange={addOnChange}
            />
          </GridBox>
        </Accordion>
      </div>
      <GeneralSettings {...{ form }} />
      {form.values.prizes.length ? (
        <FieldArray
          name="prizes"
          render={() => (
            <>
              {form.values.prizes.map((prize: any, index: number) => {
                const optionType = prizeTypesOptions.find(
                  (type) => type.value === prize.type
                );
                return (
                  <div key={index}>
                    <Accordion
                      summary={
                        <FlexBox
                          items="center"
                          gap="10px"
                          className={classes.headerContainer}
                        >
                          <h3>
                            {`${intl.formatMessage({
                              id: "prize",
                            })} # ${prize.priority} - ${intl.formatMessage({
                              id: optionType?.name || "upload_offer_codes",
                            })}`}
                          </h3>
                          <QuestionIcon submit={toggleModal} />
                        </FlexBox>
                      }
                    >
                      <GridBox columns="1fr" cg="30px" rg="30px">
                        <GridBox
                          columns={
                            [PrizeType.PHYSICAL, PrizeType.DIGITAL].includes(
                              prize.type
                            )
                              ? "2fr 1fr 1fr"
                              : "3fr 1fr"
                          }
                          cg="30px"
                          rg="30px"
                        >
                          <div>
                            <FormikField
                              label={intl.formatMessage({
                                id: "prize_name",
                              })}
                              name={`prizes.${index}.name`}
                              submit={form.handleChange}
                              value={form.values.prizes[index].name}
                              {...{ form }}
                              isError={
                                form?.submitCount &&
                                form.errors?.prizes &&
                                form.errors?.prizes[index] &&
                                form.errors?.prizes[index].name
                              }
                              placeholder={intl.formatMessage({
                                id: "add_name",
                              })}
                            />
                            {form?.submitCount &&
                            form.errors?.prizes &&
                            form.errors?.prizes[index] &&
                            form.errors?.prizes[index].name ? (
                              <div className={classes.errorText}>
                                {intl.formatMessage({
                                  id: form.errors?.prizes[
                                    index
                                  ].name.toLowerCase(),
                                })}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <FormikField
                              label={intl.formatMessage({
                                id: "subtitle",
                              })}
                              name={`prizes.${index}.subTitle`}
                              submit={form.handleChange}
                              value={form.values.prizes[index].subTitle}
                              {...{ form }}
                              placeholder={intl.formatMessage({
                                id: "add_subtitle",
                              })}
                            />
                          </div>
                          {[PrizeType.PHYSICAL, PrizeType.DIGITAL].includes(
                            prize.type
                          ) && (
                            <div>
                              <FormikField
                                label={intl.formatMessage({
                                  id: "qty",
                                })}
                                name={`prizes.${index}.qty`}
                                type="number"
                                submit={form.handleChange}
                                value={form.values.prizes[index].qty}
                                {...{ form }}
                                isError={
                                  form?.submitCount &&
                                  form.errors?.prizes &&
                                  form.errors?.prizes[index] &&
                                  form.errors?.prizes[index].qty
                                }
                                placeholder={intl.formatMessage({
                                  id: "qty",
                                })}
                              />
                              {form?.submitCount &&
                              form.errors?.prizes &&
                              form.errors?.prizes[index] &&
                              form.errors?.prizes[index].qty ? (
                                <div className={classes.errorText}>
                                  {intl.formatMessage({
                                    id: form.errors?.prizes[
                                      index
                                    ].qty.toLowerCase(),
                                  })}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </GridBox>
                        <GridBox columns="1fr" cg="30px" rg="30px" items="end">
                          <div>
                            {[PrizeType.PROMOTION].includes(prize.type) ? (
                              <>
                                <FormikField
                                  label={intl.formatMessage({
                                    id: "prize_url",
                                  })}
                                  name={`prizes.${index}.prizeUrl`}
                                  submit={form.handleChange}
                                  value={form.values.prizes[index].prizeUrl}
                                  {...{ form }}
                                  isError={
                                    form?.submitCount &&
                                    form.errors?.prizes &&
                                    form.errors?.prizes[index] &&
                                    form.errors?.prizes[index].prizeUrl
                                  }
                                  placeholder={intl.formatMessage({
                                    id: "prize_url"
                                  })}
                                />
                                {form?.submitCount &&
                                form.errors?.prizes &&
                                form.errors?.prizes[index] &&
                                form.errors?.prizes[index].prizeUrl ? (
                                  <div className={classes.errorText}>
                                    {intl.formatMessage({
                                      id: form.errors?.prizes[
                                        index
                                      ].prizeUrl.toLowerCase(),
                                    })}
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <div>
                                <FormikField
                                  label={intl.formatMessage({
                                    id: "typeform_embed_code_url",
                                  })}
                                  name={`prizes.${index}.typeformUrl`}
                                  submit={form.handleChange}
                                  value={form.values.prizes[index].typeformUrl}
                                  {...{ form }}
                                  isError={
                                    form?.submitCount &&
                                    form.errors?.prizes &&
                                    form.errors?.prizes[index] &&
                                    form.errors?.prizes[index].typeformUrl
                                  }
                                  placeholder={intl.formatMessage({
                                    id: "typeform_embed_code_url",
                                  })}
                                />
                                {form?.submitCount &&
                                form.errors?.prizes &&
                                form.errors?.prizes[index] &&
                                form.errors?.prizes[index].typeformUrl ? (
                                  <div className={classes.errorText}>
                                    {intl.formatMessage({
                                      id: form.errors?.prizes[
                                        index
                                      ].typeformUrl.toLowerCase(),
                                    })}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </GridBox>
                        <GridBox
                          columns={
                            [PrizeType.PROMOTION].includes(prize.type)
                              ? "1fr 1fr"
                              : "1fr"
                          }
                          cg="30px"
                          rg="0px"
                          items="end"
                        >
                          {[PrizeType.PROMOTION].includes(prize.type) && (
                            <FlexBox
                              className={classes.accordionBody}
                              direction="column"
                              gap="30px"
                            >
                              <div>
                                <DropzoneCSV
                                  {...{
                                    form,
                                    sendData: (file) =>
                                      sendDataFile(`prizes.${index}`, file),
                                  }}
                                  isError={
                                    form?.submitCount &&
                                    form.errors?.prizes &&
                                    form.errors?.prizes[index] &&
                                    form.errors?.prizes[index].offerCodeUrl
                                  }
                                  name={`prizes.${index}.offerCodeUrl`}
                                  value={form.values.prizes[index].offerCodeUrl}
                                />
                                {form?.submitCount &&
                                form.errors?.prizes &&
                                form.errors?.prizes[index] &&
                                form.errors?.prizes[index].offerCodeUrl ? (
                                  <div className={classes.errorText}>
                                    {intl.formatMessage({
                                      id: form.errors?.prizes[
                                        index
                                      ].offerCodeUrl.toLowerCase(),
                                    })}
                                  </div>
                                ) : null}
                              </div>
                            </FlexBox>
                          )}
                          <FlexBox
                            className={classes.accordionBody}
                            direction="column"
                            gap="30px"
                          >
                            <div>
                              <Dropzone
                                {...{
                                  form,
                                  sendData: (file) =>
                                    sendDataImage(`prizes.${index}`, file),
                                }}
                                isError={
                                  form?.submitCount &&
                                  form.errors?.prizes &&
                                  form.errors?.prizes[index] &&
                                  form.errors?.prizes[index].mediaUrl
                                }
                                name={`prizes.${index}.mediaUrl`}
                                imagePreview={
                                  form.values.prizes[index].mediaLink
                                }
                              />
                              {form?.submitCount &&
                              form.errors?.prizes &&
                              form.errors?.prizes[index] &&
                              form.errors?.prizes[index].mediaUrl ? (
                                <div className={classes.errorText}>
                                  {intl.formatMessage({
                                    id: form.errors?.prizes[
                                      index
                                    ].mediaUrl.toLowerCase(),
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </FlexBox>
                        </GridBox>
                        <GridBox columns="1fr" cg="30px" rg="30px">
                          <Accordion
                            summary={
                              <FlexBox
                                items="center"
                                gap="10px"
                                className={classes.headerContainer}
                              >
                                <h4>
                                  {intl.formatMessage({ id: "rich_text" })}
                                </h4>
                              </FlexBox>
                            }
                          >
                            <div>
                              <TinyMCEEditor
                                value={form.values.prizes[index].richText}
                                onChange={(value: any) =>
                                  form.setFieldValue(
                                    `prizes.${index}.richText`,
                                    value
                                  )
                                }
                              />
                              {form?.submitCount &&
                              form.errors?.prizes &&
                              form.errors?.prizes[index] &&
                              form.errors?.prizes[index].richText ? (
                                <div className={classes.errorText}>
                                  {intl.formatMessage({
                                    id: form.errors?.prizes[
                                      index
                                    ].richText.toLowerCase(),
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </Accordion>
                          {[PrizeType.PHYSICAL, PrizeType.DIGITAL].includes(
                            prize.type
                          ) ? (
                            <Accordion
                              summary={
                                <FlexBox
                                  items="center"
                                  gap="10px"
                                  className={classes.headerContainer}
                                >
                                  <h4>
                                    {intl.formatMessage({
                                      id: "redemption_instructions",
                                    })}
                                  </h4>
                                </FlexBox>
                              }
                            >
                              <GridBox
                                columns="1fr"
                                cg="30px"
                                rg="30px"
                                items="end"
                              >
                                <div>
                                  <FormikField
                                    placeholder={intl.formatMessage({
                                      id: "redemption_instructions",
                                    })}
                                    name={`prizes.${index}.redemptionInstructions`}
                                    submit={form.handleChange}
                                    value={
                                      form.values.prizes[index]
                                        .redemptionInstructions
                                    }
                                    {...{ form }}
                                    label=""
                                    multiline
                                    variant="outlined"
                                    rows={12}
                                  />
                                </div>
                              </GridBox>
                            </Accordion>
                          ) : null}
                        </GridBox>
                      </GridBox>
                    </Accordion>
                  </div>
                );
              })}
            </>
          )}
        />
      ) : null}
    </FlexBox>
  );
};
