import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { TablePagination } from "@mui/material";
import { useTableStyles } from "./table-styles.hook";
import { TableHeader } from "./components/table-header";
import { TableBody } from "./components/table-body";
import { FlexBox } from "../box";
import { IRowColumn, ITableConfig } from "./components/interface";
import { EMPTY_FUNCTION } from "../../../constants";
import { SearchInput } from "../../../components/wilson-ui/inputs";
import { SortableTableBody } from "./components/table-body/table-body-sortable";

interface IProps {
  config: ITableConfig;
  data: Array<Record<string, any>>;
  components?: Record<string, any>;
  setRowPerPage?: (count: number) => void;
  setPage?: (count: number) => void;
  rowPerPage?: number;
  page?: number;
  length: number;
  disablePagination?: boolean | undefined;
  sortSubmit?: (title: string) => void;
  sortTypeSubmit?: () => void;
  sortable?: boolean;
  onSortEnd?: (index: any) => void;
  searchTable?: boolean;
  setSearch?: (term: string) => void;
  searchValue?: string;
}

export const UiTable: React.FC<IProps> = ({
  config,
  data,
  components,
  setRowPerPage,
  rowPerPage,
  setPage,
  page,
  length,
  disablePagination,
  sortSubmit,
  sortTypeSubmit,
  sortable,
  onSortEnd,
  searchTable,
  setSearch,
  searchValue,
}) => {
  const intl = useIntl();
  const classes = useTableStyles();
  const componentsObject: IRowColumn = {};
  const paginationDisplay = disablePagination ? "none" : "flex";
  const tableGap = disablePagination ? "10px" : "50px";
  const setPageHandler = disablePagination
    ? () => {
        return null;
      }
    : (event: any, count: number) => setPage && setPage(count + 1);

  const tablePage: number =
    !disablePagination && typeof page === "number" ? page - 1 : 1;

  const tableRowsPerPage: number =
    !disablePagination && typeof rowPerPage === "number" ? rowPerPage : 1;

  const setRowPerPageHandler = disablePagination
    ? () => {
        return null;
      }
    : (event: any) =>
        setRowPerPage && setRowPerPage(parseInt(event.target.value));

  const sortSubmitHandler = sortSubmit
    ? sortSubmit
    : () => {
        EMPTY_FUNCTION();
      };
  const sortTypeSubmitHandler = sortTypeSubmit
    ? sortTypeSubmit
    : () => {
        EMPTY_FUNCTION();
      };

  const handleSearch = useCallback(
    (term: string) => {
      setSearch && setSearch(term);
    },
    [setSearch]
  );

  const defaultTableBodyProps = {
    components: components ? components : componentsObject,
    columns: config.columns,
    data,
    rowColumns: config.rowColumns,
    rowColumnsCarousel: config.rowColumnsCarousel || "",
  };

  const tableBodyComponent = sortable ? (
    <SortableTableBody
      {...defaultTableBodyProps}
      dragIconClassName={classes.dragIconClassName}
      onSortEnd={onSortEnd}
    />
  ) : (
    <TableBody {...defaultTableBodyProps} />
  );

  const tableBody =
    data.length > 0 ? (
      tableBodyComponent
    ) : (
      <FlexBox content="center" items="center" className={classes.emptySpan}>
        {intl.formatMessage({ id: "table_is_empty" })}
      </FlexBox>
    );

  return (
    <div className={classes.container} style={{ gap: tableGap }}>
      {searchTable && (
        <FlexBox content="space-between" items="center">
          <SearchInput value={searchValue || ""} submit={handleSearch} />
          <div style={{ color: "#262626" }}>{length} Results</div>
        </FlexBox>
      )}

      <TableHeader
        sortTypeSubmit={sortTypeSubmitHandler}
        sortSubmit={sortSubmitHandler}
        columns={config.columns}
        rowColumns={config.rowColumns}
        marginForDraggable={sortable}
      />

      {tableBody}

      <FlexBox
        items="center"
        gap="10px"
        className={classes.paginationContainer}
        style={{ display: paginationDisplay }}
      >
        <TablePagination
          count={length}
          page={tablePage}
          onPageChange={setPageHandler}
          rowsPerPage={tableRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          onRowsPerPageChange={setRowPerPageHandler}
          labelRowsPerPage={intl.formatMessage({ id: "rows_per_page" })}
        />
      </FlexBox>
    </div>
  );
};

export default UiTable;
