import { makeAutoObservable } from "mobx";
import UsersService from "../../services/users.service";

export interface ITags{
  length: number;
  data: Array<Record<string, unknown>>;
}

class TagsStore {
  tags: ITags= { length: 0, data: [] };
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async tagsRequest(
    row: number,
    page: number,
    term: string,
  ) {
    this.isLoading = true;
    const data: Record<string, any> = await UsersService.getTags(
      row,
      page,
      term,
    );
    this.setTags({ length: data.count, data: data.tags });
    this.isLoading = false;
  }

  setTags(tags: ITags) {
    this.tags = tags;
  }

  getTags() {
    return this.tags;
  }

}

export default new TagsStore();
