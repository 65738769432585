import React, { useState } from "react";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { Dropzone } from "../../../../components/wilson-ui/dropzone";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal } from "../modals";
import { useIntl } from "react-intl";
import { axiosAuthorized } from "../../../../axios";

import image from "../../../../assets/images/favoriteThumbnail.png";
import { EMPTY_FUNCTION } from "../../../../constants";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { Accordion } from "../accordion";

interface IProps {
  form: Record<string, any>;
}

export const FavoriteThumbnail: React.FC<IProps> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);

  const classes = useCreateWidgetsStyles();
  const intl = useIntl();
  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "favorite_thumbnail" })}
      image={image}
      maxHeight="380px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  const sendData = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "widgets/thumbnail",
        formData
      );
      form.setFieldValue("favoriteThumbnailImage", data.data.thumbnail);
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };

  if (form.values.favoriteIcon === false) return null;

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "favorite_thumbnail" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <FormikField
            label={intl.formatMessage({ id: "title" })}
            name="favoriteTitle"
            submit={form.handleChange}
            value={form.values.favoriteTitle}
            {...{ form }}
            placeholder={intl.formatMessage({ id: "add_title" })}
          />
          <FormikField
            label={intl.formatMessage({ id: "website_url" })}
            name="favoriteSiteUrl"
            submit={form.handleChange}
            value={form.values.favoriteSiteUrl}
            {...{ form }}
            placeholder={intl.formatMessage({ id: "enter_website_url" })}
          />
          <FlexBox direction="column" gap="30px">
            <Dropzone
              {...{ form, sendData }}
              name="favoriteThumbnailImage"
              imagePreview={form.values.favouriteThumbnailLink}
            />
          </FlexBox>
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
