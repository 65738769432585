import { Dispatch } from "react";

export const setMediaItemImage = (
  id: string,
  file: string,
  preview: string,
  isLoading: boolean,
  swipeState: Record<string, any>,
  setSwipeState: Dispatch<any>,
  type?: string
): void => {
  if (isLoading) return;
  const newSwipeState = swipeState.map((item: any) => {
    if (item.id === id) {
      item.assetUrl = file;
      item.preview = preview;
      if (type) item.type = type.toUpperCase();
    }

    return item;
  });
  setSwipeState(newSwipeState);
};

export const setMediaItemIsFullScreen = (
  id: string,
  isFullScreen: boolean,
  isLoading: boolean,
  swipeState: Record<string, any>,
  setSwipeState: Dispatch<any>,
): void => {
  if (isLoading) return;
  const newSwipeState = swipeState.map((item: any) => {
    if (item.id === id) {
      item.isFullScreen = isFullScreen;
    }

    return item;
  });
  setSwipeState(newSwipeState);
};
