import { orderBy } from "lodash";
import { parseDateTime } from "../../../../utils/utils";
import { DropStatusEnum } from "../../../../types";
import { WidgetTypeEnum, ContainerTypeEnum } from "../../../../enum";
import { editWidget, sendWidget } from "../../../../actions/widgets";
import { getWithPriority } from "../../../../helpers/widgets/upgrade-widget-to-edit.helper";

export const sendFormHelper = async (
  sendForm: Record<string, any>
): Promise<any> => {
  const sendData: Record<string, any> = {};
  const dropData: Record<string, any> = {};
  const locationBasedData: Record<string, any> = {};
  const storySwipeItems = getWithPriority(sendForm.storySwipe);
  const feedMediaArray = getWithPriority(sendForm.feedMedia).map(
    (item: any) => ({
      mediaUrl: item.assetUrl,
      priority: item.priority,
      isFullScreen: item.isFullScreen
    })
  );
  const feedMediaItems = JSON.stringify(
    getWithPriority(sendForm.feedMedia).map((item: any) => item.assetUrl)
  );

  const startDateTime = parseDateTime(
    sendForm.startDateDate,
    sendForm.startDateTime
  );

  if (sendForm.expirationDateDate) {
    const expirationDateTime = parseDateTime(
      sendForm.expirationDateDate,
      sendForm.expirationDateTime
    );

    sendData.expirationDate = expirationDateTime.date;
    sendData.expirationTime = expirationDateTime.time;
  }

  // console.log("sendForm", sendForm);
  sendData.title = sendForm.title;
  sendData.subTitle = sendForm.subtitle;
  sendData.terms = sendForm.terms;
  sendData.description = sendForm.description;
  sendData.type = sendForm.type.toUpperCase();
  sendData.backgroundColor = sendForm.background;
  sendData.websiteUrl = sendForm.site;
  sendData.titleVisibility = sendForm.titleVisibility;
  sendData.buttonVisibility = sendForm.buttonVisibility;
  sendData.canBeShared = sendForm.shareIcon;
  sendData.canBeLiked = sendForm.favoriteIcon;
  sendData.scanRequire = sendForm.scanRequire;
  sendData.hasCountdown = sendForm.countdownTime;
  sendData.fullHeightVisibility = sendForm.fullHeightVisibility;
  sendData.tagsIds = sendForm.tags.map((item: any) => item.id);

  sendData.feedBorderColor = sendForm?.feedBorderColor || "";
  sendData.feedTitleColor = sendForm.feedTitleColor;
  sendData.feedTitleSize = sendForm.feedTitleSize;
  sendData.feedTitleFont = sendForm.feedTitleFont;
  sendData.feedTitleHighlightColor = sendForm?.feedTitleHighlightColor || "";
  sendData.feedButtonText = sendForm.feedAssetsButtonText;
  sendData.feedButtonColor = sendForm.feedAssetsButtonColor;
  sendData.feedButtonTextColor = sendForm.feedAssetsButtonTextColor;
  sendData.feedMediaUrl =
    sendForm.type.toUpperCase() === WidgetTypeEnum.STORY
      ? sendForm.feedAssetsButtonImage
      : feedMediaItems;
  sendData.feedMediaArray =
    sendForm.type.toUpperCase() === WidgetTypeEnum.STORY
      ? [{ mediaUrl: sendForm.feedAssetsButtonImage, priority: 0 }]
      : feedMediaArray;
  sendData.feedDropMediaUrl =
    sendForm.type.toUpperCase() === WidgetTypeEnum.DROP ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.PROMOTION ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.INSTANT_WIN ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.LOCATION_BASED
      ? sendForm.feedAssetsButtonImage
      : null;

  sendData.detailsHasCountdown = sendForm.detailsHasCountdown || false;
  sendData.detailsButtonText = sendForm.detailsAssetsButtonText;
  sendData.detailsButtonColor = sendForm.detailsAssetsButtonColor;
  sendData.detailsButtonTextColor = sendForm.detailsAssetsButtonTextColor;
  sendData.detailsBackgroundType = sendForm.detailsAssetsBackgroundType;
  sendData.detailsBackgroundColor = sendForm.detailsAssetsBackgroundColor;
  sendData.detailsWinBackgroundColor = sendForm.detailsAssetsWinBackgroundColor;
  sendData.detailsMissBackgroundColor = sendForm.detailsAssetsMissBackgroundColor;
  sendData.detailsContainerType = sendForm.detailsAssetsContainerType;
  sendData.detailsContainerColor =
    sendForm.detailsAssetsContainerType === ContainerTypeEnum.DARK
      ? "#15191D"
      : sendForm.detailsAssetsContainerType === ContainerTypeEnum.LIGHT
      ? "#ffffff"
      : "";

  sendData.favoriteTitle = sendForm.favoriteTitle;
  sendData.favoriteSiteUrl = sendForm.favoriteSiteUrl;
  sendData.thumbnailUrl = sendForm.favoriteThumbnailImage
    ? sendForm.favoriteThumbnailImage
    : "";

  sendData.thumbnailLockerUrl = sendForm.thumbnailLockerUrl
    ? sendForm.thumbnailLockerUrl
    : "";

  sendData.channelIds = sendForm.channels;
  sendData.startDate = startDateTime.date;
  sendData.startTime = startDateTime.time;

  sendData.storyAuthorName = sendForm.storyAuthorName || "";
  sendData.storyAuthorAvatarUrl = sendForm.storyAuthorAvatar || "";
  sendData.storyAuthorNameColor = sendForm.storyAuthorNameColor || "";
  sendData.storyDescription = sendForm.storyDescription || "";
  sendData.storyButtonText = sendForm.storyButtonText || "";
  sendData.storyButtonStyle = sendForm.storyButtonStyle || "";
  sendData.storiesToAdd = storySwipeItems;
  sendData.status = sendForm.status;
  sendData.tagIds = sendForm.tags.map((item: Record<string, any>) => item.id);

  sendData.promotionTitle = sendForm.promotionsTitle || "";
  sendData.promotionTitleColor = sendForm.promotionsTitleColor || "";
  sendData.promotionDescription = sendForm.promotionsDescription || "";
  sendData.promotionDescriptionColor =
    sendForm.promotionsDescriptionColor || "";
  sendData.promotionCollaborationMediaUrl =
    sendForm.type.toUpperCase() === WidgetTypeEnum.STORY ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.DROP ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.PROMOTION ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.INSTANT_WIN ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.LOCATION_BASED
      ? null
      : sendForm.promotionsCollaborationImage
      ? sendForm.promotionsCollaborationImage
      : "";
  sendData.promotionModalMediaUrl =
    sendForm.type.toUpperCase() === WidgetTypeEnum.STORY ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.DROP ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.PROMOTION ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.INSTANT_WIN ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.LOCATION_BASED
      ? null
      : sendForm.promotionsModalImage
      ? sendForm.promotionsModalImage
      : "";
  sendData.promotionBackgroundColor = sendForm.promotionsBackgroundColor || "";
  sendData.promotionModalBackgroundMediaUrl = sendForm.promotionsModalBackgroundImage || "";
  sendData.promotionModalBackgroundColor =
    sendForm.promotionsModalBackgroundColor;
  sendData.promotionModalVisibility = sendForm.promotionsModalVisibility,
  sendData.promotionModalTitle = sendForm.promotionsModalTitle || "";
  sendData.promotionModalTitleColor =
    sendForm.promotionsModalTitleColor;
  sendData.promotionButtonText = sendForm.successAssetsButtonText || "";
  sendData.promotionButtonColor = sendForm.successAssetsButtonColor || "";
  sendData.promotionButtonTextColor =
    sendForm.successAssetsButtonTextColor || "";
  sendData.promotionMediaUrl =
    sendForm.type.toUpperCase() === WidgetTypeEnum.STORY ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.DROP ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.PROMOTION ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.INSTANT_WIN ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.LOCATION_BASED
      ? null
      : sendForm.successAssetsButtonImage
      ? sendForm.successAssetsButtonImage
      : "";
  // instant win
  sendData.hasPinned = sendForm.hasPinned;
  if (sendForm.hasPinned) {
    sendData.pinnedTitleVisibility = sendForm.pinnedTitleVisibility;
    sendData.pinnedHasCountdown = sendForm.pinnedHasCountdown;
    sendData.pinnedTitleColor = sendForm?.pinnedTitleColor || null;
    sendData.pinnedTitleSize = sendForm?.pinnedTitleSize || null;
    sendData.pinnedTextColor = sendForm?.pinnedTextColor || null;
    sendData.pinnedFillColor = sendForm?.pinnedFillColor || null;
    sendData.pinnedBackgroundColor = sendForm?.pinnedBackgroundColor || null;
    sendData.pinnedMediaUrl = sendForm?.pinnedMediaUrl || null;
  } else {
    sendData.pinnedTitleVisibility = false;
    sendData.pinnedHasCountdown = false;
    sendData.pinnedTitleColor = null;
    sendData.pinnedTitleSize = null;
    sendData.pinnedTextColor = null;
    sendData.pinnedFillColor = null;
    sendData.pinnedBackgroundColor = null;
    sendData.pinnedMediaUrl = null;
  }
  sendData.campaignId =
    sendForm.type.toUpperCase() === WidgetTypeEnum.PROMOTION ||
    sendForm.type.toUpperCase() === WidgetTypeEnum.INSTANT_WIN
      ? sendForm.campaign?.id || undefined
      : undefined;

  // drop
  if (sendForm.type === WidgetTypeEnum.DROP) {
    if (sendForm.liveDateDate) {
      const liveDateTime = parseDateTime(
        sendForm.liveDateDate,
        sendForm.liveDateTime
      );

      dropData.liveDate = liveDateTime.date;
      dropData.liveTime = liveDateTime.time;
    }
    dropData.hoursBeforeDrop = sendForm?.hoursBeforeDrop || 0;

    dropData.dropName = sendForm.dropName;
    dropData.hasPinned = sendForm.hasPinned;
    dropData.richContent = sendForm.richContent;
    dropData.dropUrl = sendForm.dropUrl;
    dropData.dropStatus = sendForm.dropStatus || DropStatusEnum.AUTOMATIC;
    if (sendForm.hasPinned) {
      dropData.pinnedTitleVisibility = sendForm.pinnedTitleVisibility;
      dropData.pinnedHasCountdown = sendForm.pinnedHasCountdown;
      dropData.pinnedTitleColor = sendForm?.pinnedTitleColor || null;
      dropData.pinnedTitleSize = sendForm?.pinnedTitleSize || null;
      dropData.pinnedTextColor = sendForm?.pinnedTextColor || null;
      dropData.pinnedFillColor = sendForm?.pinnedFillColor || null;
      dropData.pinnedBackgroundColor = sendForm?.pinnedBackgroundColor || null;
      dropData.pinnedMediaUrl = sendForm?.pinnedMediaUrl || null;
    } else {
      dropData.pinnedTitleVisibility = false;
      dropData.pinnedHasCountdown = false;
      dropData.pinnedTitleColor = null;
      dropData.pinnedTitleSize = null;
      dropData.pinnedTextColor = null;
      dropData.pinnedFillColor = null;
      dropData.pinnedBackgroundColor = null;
      dropData.pinnedMediaUrl = null;
    }
    dropData.alertTitleVisibility = sendForm.alertTitleVisibility;
    dropData.alertHasCountdown = sendForm.alertHasCountdown;
    dropData.alertCanBeShared = sendForm.alertCanBeShared;
    dropData.alertTitleColor = sendForm.alertTitleColor || "";
    dropData.alertTitleSize = sendForm.alertTitleSize;
    dropData.alertTitleHighlightColor = sendForm.alertTitleHighlightColor || "";
    dropData.alertButtonTextColor = sendForm.alertButtonTextColor || "";
    dropData.alertButtonColor = sendForm.alertButtonColor || "";
    dropData.alertBorderColor = sendForm.alertBorderColor || "";
    dropData.alertCountdownTimerColor = sendForm.alertCountdownTimerColor || "";
    dropData.alertMediaUrl = sendForm.alertMediaUrl;
    dropData.detailsHasCountdown = sendForm.detailsHasCountdown;
    dropData.successTitle = sendForm.successTitle || "";
    dropData.successTitleColor = sendForm.successTitleColor || "";
    dropData.successDescription = sendForm.successDescription || "";
    dropData.successDescriptionColor = sendForm.successDescriptionColor || "";
    dropData.successBackgroundColor = sendForm.successBackgroundColor || "";
    dropData.successMediaUrl = sendForm.successMediaUrl || "";
    dropData.missedTitle = sendForm.missedTitle;
    dropData.missedTitleColor = sendForm.missedTitleColor || "";
    dropData.missedDescription = sendForm.missedDescription;
    dropData.missedDescriptionColor = sendForm.missedDescriptionColor || "";
    dropData.missedBackgroundColor = sendForm.missedBackgroundColor || "";
    dropData.feedItems = orderBy(
      sendForm.feedItems || [],
      ["priority", "onPriority"],
      ["asc", "desc"]
    ).map((feedItem: any, index: number) => ({
      id: feedItem.carouselId,
      childId: feedItem.id,
      priority: feedItem.priority || index + 1,
      overrideImageUrl: feedItem?.overrideImageUrl || undefined,
    }));
    sendData.widgetDrop = dropData;
  }

    // location based
    if (sendForm.type === WidgetTypeEnum.LOCATION_BASED) {
      locationBasedData.address = sendForm.address;
      locationBasedData.radius = sendForm.radius;
      locationBasedData.locationAlias = sendForm.locationAlias;
      locationBasedData.location = sendForm.location;
      locationBasedData.city = sendForm.location?.city;
      locationBasedData.country = sendForm.location?.country;
      locationBasedData.state = sendForm.location?.state;
      locationBasedData.street = sendForm.location?.street;
      locationBasedData.postalCode = sendForm.location?.postalCode;
      locationBasedData.lat = sendForm.location?.latitude;
      locationBasedData.lng = sendForm.location?.longitude;

      locationBasedData.locationSuccessBackgroundColor = sendForm.locationSuccessBackgroundColor,
      locationBasedData.locationButtonPostRedemptionColor = sendForm.locationButtonPostRedemptionColor,
      locationBasedData.locationButtonText = sendForm.locationButtonText,
      locationBasedData.locationButtonTextColor = sendForm.locationButtonTextColor,
      locationBasedData.locationButtonColor = sendForm.locationButtonColor,
      locationBasedData.locationMediaUrl = sendForm.locationMediaUrl,

      locationBasedData.detailsHasCountdown = sendForm.detailsHasCountdown;
      locationBasedData.successTitle = sendForm.successTitle || "";
      locationBasedData.successTitleColor = sendForm.successTitleColor || "";
      locationBasedData.successDescription = sendForm.successDescription || "";
      locationBasedData.successDescriptionColor = sendForm.successDescriptionColor || "";
      // locationBasedData.successBackgroundColor = sendForm.successBackgroundColor || "";
      // locationBasedData.successMediaUrl = sendForm.successMediaUrl || "";
      locationBasedData.feedItems = orderBy(
        sendForm.feedItems || [],
        ["priority", "onPriority"],
        ["asc", "desc"]
      ).map((feedItem: any, index: number) => ({
        id: feedItem.carouselId,
        childId: feedItem.id,
        priority: feedItem.priority || index + 1,
        overrideImageUrl: feedItem?.overrideImageUrl || undefined,
      }));
      sendData.widgetLocationBased = locationBasedData;
    }

  sendData.widgetPriority = sendForm?.widgetPriority || undefined;
  sendData.id = sendForm.id;

  if (sendData.id) {
    return editWidget(sendData);
  }
  return sendWidget(sendData);
};
