import { makeStyles } from "@material-ui/core";
import { mediaQuery } from "../../../../components/wilson-ui/constants/media";

export const useGeneralSettingsStyles = makeStyles(() => ({
  bottomContainer: {
    marginTop: "40px",
  },
  radioButtonContainer: {},
  [mediaQuery.maxWidth600]: {
    radioButtonContainer: {
      gridTemplateColumns: "1fr 1fr !important",
    },
  },
  [mediaQuery.maxWidth420]: {
    radioButtonContainer: {
      gridTemplateColumns: "1fr !important",
    },
  },
}));
