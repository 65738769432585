import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from "react";
import { includes } from "lodash";
import { useWidgetsTypeModalStyles } from "./widgets-type-modal-styles.hook";
import CloseIcon from "@mui/icons-material/Close";
import { RadioCheckboxRed } from "../../../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useIntl } from "react-intl";
import { IWidgetTypeItem } from "../../../../types";
import { useChangeSort } from "../../hooks/change-sort";

interface IProps {
  setSortType: Dispatch<SetStateAction<string[]>>;
  toggleModal: () => void;
  sortType: Array<string>;
}

export const WidgetsTypeModal: React.FC<IProps> = ({
  setSortType,
  toggleModal,
  sortType,
}) => {
  const [form, setForm] = useState<Record<string, IWidgetTypeItem>>({
    post: { name: "post", isChecked: true },
    story: { name: "story", isChecked: true },
    "external link": {
      name: "external link",
      isChecked: true,
    },
    carousel: { name: "carousel", isChecked: true },
    drop: { name: "drop", isChecked: true },
    promotion: { name: "promotion", isChecked: true },
    "instant win": { name: "instant win", isChecked: true },
  });
  const intl = useIntl();
  const classes = useWidgetsTypeModalStyles();

  useEffect(() => {
    setForm({
      post: { name: "post", isChecked: !!includes(sortType, "post") },
      story: { name: "story", isChecked: !!includes(sortType, "story") },
      "external link": {
        name: "external link",
        isChecked: !!includes(sortType, "external link"),
      },
      carousel: {
        name: "carousel",
        isChecked: !!includes(sortType, "carousel"),
      },
      drop: {
        name: "drop",
        isChecked: !!includes(sortType, "drop"),
      },
      promotion: {
        name: "promotion",
        isChecked: !!includes(sortType, "promotion"),
      },
      'instant win': {
        name: "instant win",
        isChecked: !!includes(sortType, "instant win"),
      },
    });
  }, [sortType]);

  const changeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    useChangeSort(form, setForm, setSortType, event, value);
  };

  return (
    <div className={classes.container}>
      <CloseIcon className={classes.icon} onClick={toggleModal} />
      <div className={classes.formContainer}>
        <FlexBox direction="column" gap="5px">
          <RadioCheckboxRed
            label={intl.formatMessage({ id: "post" })}
            value={form.post.isChecked}
            submit={changeHandler}
            name={"post"}
          />
          <RadioCheckboxRed
            label={intl.formatMessage({ id: "story" })}
            name={"story"}
            value={form.story.isChecked}
            submit={changeHandler}
          />
          <RadioCheckboxRed
            label={intl.formatMessage({ id: "external_link" })}
            name={"external link"}
            value={form["external link"].isChecked}
            submit={changeHandler}
          />
          <RadioCheckboxRed
            label={intl.formatMessage({ id: "carousel" })}
            name={"carousel"}
            value={form.carousel.isChecked}
            submit={changeHandler}
          />
          <RadioCheckboxRed
            label={intl.formatMessage({ id: "drop" })}
            name={"drop"}
            value={form.drop.isChecked}
            submit={changeHandler}
          />
          <RadioCheckboxRed
            label={intl.formatMessage({ id: "promotion" })}
            name={"promotion"}
            value={form.promotion.isChecked}
            submit={changeHandler}
          />
          <RadioCheckboxRed
            label={intl.formatMessage({ id: "instant_win" })}
            name={"instant win"}
            value={form['instant win'].isChecked}
            submit={changeHandler}
          />
        </FlexBox>

        <FlexBox items="center">
          <h3 className={classes.title}>
            {intl.formatMessage({ id: "type" })}
          </h3>
        </FlexBox>
      </div>
    </div>
  );
};
