export const useTagsOptionsValue = (
  tagsOptions: Array<Record<string, any>>,
  usedValues: Record<string, any>[]
): Record<string, any>[] => {
  const data: Record<string, any>[] = tagsOptions.filter((item: any) => {
    let isUsed = false;
    usedValues.forEach((tag: any) => {
      if (item.title === tag.title) isUsed = true;
    });
    if (!isUsed) return item;
  });

  return data;
};
