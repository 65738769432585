import { FC } from "react";
import { EditButtonItem } from "../buttons-item";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useHistory } from "react-router-dom";
import { useWinnerItemStyles } from "./interest-actins-button-styles.hook";

interface IProps {
  row: Record<string, any>;
  toggleModal: (row: Record<string, any>) => void;
}

export const InterestActionsButton: FC<IProps> = ({ row, toggleModal }) => {
  const history = useHistory();
  const styles = useWinnerItemStyles();

  return (
    <div className={styles.container}>
      <EditButtonItem
        submit={() =>history.push("interests/" + row.id)}
      />
      <DeleteForeverIcon onClick={toggleModal} className={styles.icon} />
    </div>
  );
};
