import qs from "query-string";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import { WidgetTypeEnum } from "../enum";
import { IModifierHTMLVideoElement } from "../types";

export async function hasAudio(video: IModifierHTMLVideoElement) {
  video.volume = 0;

  /**
   * @info
   * need to play a video, for get a value
   * "webkitAudioDecodedByteCount" for detect a sound of video
   * https://stackoverflow.com/questions/21270048/html5-video-how-to-detect-when-there-is-no-audio-track
   *
   * need to make a pause before we play video, another way we get a error in console
   * https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
   */
  video.pause();
  setTimeout(() => video.play(), 150);
  await new Promise((r) => setTimeout(r, 1000));
  video.pause();

  if (typeof video.webkitAudioDecodedByteCount !== "undefined") {
    if (video.webkitAudioDecodedByteCount > 0) {
      // video has audio
      return true;
    } else {
      // video doesn't have audio
      return false;
    }
  } else if (typeof video.mozHasAudio !== "undefined") {
    if (video.mozHasAudio) {
      // video has audio
      return true;
    } else {
      // video doesn't have audio
      return false;
    }
  }
  // can't tell if video has audio
  else return false;
}

export const getThumbnailURL = (imageURL: string, size: string): string => {
  const arr = imageURL?.replace(/\[|\]|"|{|}|\\|\\/g, "") || "";
  const come = arr?.split(",") || [];
  if (!isEmpty(arr)) {
    const result = qs.parseUrl(come[0]);
    const key = result.url.replace(
      "https://custom-labels-console-us-east-1-a8045f7760.s3.amazonaws.com/",
      ""
    );
    return `${process.env.REACT_APP_CDN_URL}/${key}/${size || "480h"}`;
  }
  return "";
};

export const getTypeWidget = (type: string): string => {
  switch (type) {
    case WidgetTypeEnum.POST:
      return "Post";
    case WidgetTypeEnum.STORY:
      return "Story";
    case WidgetTypeEnum.EXTERNAL_LINK:
      return "External Link";
    case WidgetTypeEnum.CAROUSEL:
    case WidgetTypeEnum.FEATURED_CAROUSEL:
      return "Featured Carousel";
    case WidgetTypeEnum.DROP:
      return "Drop";
    case WidgetTypeEnum.PROMOTION:
      return "Promotion";
    case WidgetTypeEnum.INSTANT_WIN:
      return "Instant Win";
    case WidgetTypeEnum.LOCATION_BASED:
      return "Location Based";
  default:
      return "Post";
  }
};

export const parseDateTime = (
  date: string | Date | null, 
  time: Date | string | null
): { date: Date | null, time: string | null } => {
  if(!date || !moment(date).isValid()) return { date: null, time: null };
  const startDate = moment(date).format("YYYY:MM:DD");
  const startTime = moment(time).format("HH:mm");

  const startDateAndTimeObj = {
    data: startDate.split(":").map((item: string) => parseInt(item)),
    time: startTime.split(":").map((item: string) => parseInt(item)),
  };

  const startDateAndTime = new Date(
    startDateAndTimeObj.data[0],
    startDateAndTimeObj.data[1] - 1,
    startDateAndTimeObj.data[2],
    startDateAndTimeObj.time[0],
    startDateAndTimeObj.time[1],
    0
  );
  return ({
    date: startDateAndTime,
    time: startTime
  })
};

export const checkIsImage = (url: string) => {
  /* eslint-disable no-useless-escape */
  return(url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tif|tiff|bmp|webp)(\?(.*))?$/gmi) != null);
};
