import React, { ChangeEvent } from "react";
import { CheckboxRed } from "../../../../components/wilson-ui/checkboxes";
import { FlexBox } from "../../../../components/wilson-ui/box";

interface IProps {
  item: Record<string, any>;
  form: Record<string, any>;
}

export const AdditionChannelsItem: React.FC<IProps> = ({ item, form }) => {
  const isChecked = form.values.channels.includes(item.channelIds[0]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    status: boolean
  ) => {
    if (!status) {
      const newChannelsArray = form.values.channels.filter(
        (element: string) => !item.channelIds.includes(element)
      );
      form.setFieldValue("channels", newChannelsArray);
    } else {
      const newItemToAdd: Array<string> = item.channelIds;
      const newChannelsArray = [...form.values.channels, ...newItemToAdd];
      form.setFieldValue("channels", newChannelsArray);
    }
  };

  return (
    <FlexBox items="center" gap="10px" draggable={true}>
      <CheckboxRed onChange={handleChange} checked={isChecked} />
      <span>{`${item.league}`}</span>
    </FlexBox>
  );
};
