import * as Yup from "yup";
import { COLOR_REGEX, URL_REGEX } from "../../constants";

export const AddWidgetsInstantWinSchema = Yup.object().shape({
  detailsAssetsButtonColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  detailsAssetsButtonTextColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  detailsAssetsBackgroundType: Yup.string().required("Required"),
  detailsAssetsBackgroundColor: Yup.mixed().when(
    ["detailsAssetsBackgroundType"],
    {
      is: "COLOR",
      then: Yup.string()
        .matches(COLOR_REGEX, "must_correspond")
        .required("Required"),
    }
  ),
  detailsAssetsWinBackgroundColor: Yup.mixed().when(
    ["detailsAssetsBackgroundType"],
    {
      is: "COLOR",
      then: Yup.string()
        .matches(COLOR_REGEX, "must_correspond")
        .required("Required"),
    }
  ),
  detailsAssetsMissBackgroundColor: Yup.mixed().when(
    ["detailsAssetsBackgroundType"],
    {
      is: "COLOR",
      then: Yup.string()
        .matches(COLOR_REGEX, "must_correspond")
        .required("Required"),
    }
  ),
  favoriteTitle: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string().required("Required"),
  }),
  favoriteSiteUrl: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string()
      .matches(URL_REGEX, "must_correspond")
      .required("Required"),
  }),
  favoriteThumbnailImage: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.mixed().required("Required"),
  }),
  campaign: Yup.mixed().required("Required"),
  feedMedia: Yup.mixed().when(["detailsAssetsBackgroundType"], {
    is: "IMAGE",
    then: Yup.array().when("description", (description, schema) => {
      return schema.test({
        test: (feedMediaArr: Array<Record<string, any>>) => {
          let isValid = true;
          if (feedMediaArr.length) {
            feedMediaArr.forEach((item: Record<string, any>) => {
              if (!item.assetUrl) {
                isValid = false;
              }
            });
          } else {
            isValid = false;
          }
          return isValid;
        },
      });
    }),
  }),
});
