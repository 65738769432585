import {useIntl} from "react-intl";
import {ITableConfig} from "../../components/wilson-ui/types/table-config.inteface";

export const usersTableConfig = (): ITableConfig => {
    const intl = useIntl();
    const rowColumns = "100px 150px 200px 125px 150px 220px 220px 100px 115px"
    const buttonEditOffset = 0;

    return {
        rowColumns: rowColumns + " 60px",
        columns: [
            {
                name: "id",
                type: "component",
                componentName: "id",
                title: intl.formatMessage({id: "id"}),
                filterType: "data",
            },
            {
                name: "name",
                type: "component",
                componentName: "name",
                title: intl.formatMessage({id: "name"}),
                filterType: "text",
            },
            {
                name: "email",
                type: "component",
                componentName: "email",
                title: intl.formatMessage({id: "email"}),
                filterType: "text",
            },
            {
                name: "location",
                type: "text",
                title: intl.formatMessage({id: "location"}),
                filterType: "text",
            },
            {
                name: "phoneNumber",
                type: "text",
                title: intl.formatMessage({id: "phone"}),
                filterType: "",
            },
            {
                name: "lastLoginAt",
                title: intl.formatMessage({id: "last_login"}),
                filterType: "data",
                type: "component",
                componentName: "data",
            },
            {
                name: "createdAt",
                type: "component",
                componentName: "data",
                title: intl.formatMessage({id: "created_at"}),
                filterType: "data",
            },
            {
                name: "isWhitelisted",
                type: "component",
                style: {position: "sticky", backgroundColor: "white", right: 145 - buttonEditOffset, zIndex: 0},
                componentName: "checkbox",
                title: intl.formatMessage({id: "whitelisted"}),
                filterType: "",
            },
            {
                name: "explicitSms",
                type: "component",
                style: {position: "sticky", backgroundColor: "white", right: 30 - buttonEditOffset, zIndex: 0},
                componentName: "checkbox",
                title: intl.formatMessage({id: "explicitsms"}),
                filterType: "",
            },
            {
                name: "edit",
                type: "component",
                style: {position: "sticky", backgroundColor: "white", right: 0, zIndex: 0},
                componentName: "button",
                title: "",
                filterType: "",
            }
        ],
    };
};
