import React from "react";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useSelectsStyles } from "./selects-styles.hook";
import { DefaultInput } from "../inputs";
import { FlexBox } from "../../../components/wilson-ui/box";
import { parseDateTime } from "../../../utils/utils";

interface IProps {
  form: Record<string, any>;
  value: string | Date;
  date?: Date;
  name: string;
  label?: string;
}

export const FormikTime: React.FC<IProps> = ({ form, date, value, name, label }) => {
  const classes = useSelectsStyles();
  const isHaveError: boolean = form?.errors[name] && form?.submitCount;
  const errorClass: string = isHaveError ? classes.errorFormikDate : "";

  return (
    <FlexBox direction="column" gap="10px">
      {label && <span className={classes.fieldLabelSmall}>{label}</span>}
      <TimePicker
        ampm={false}
        value={value}
        onChange={(newValue: string | Date | null) => {
          const dateTime = date ? parseDateTime(date, newValue): null;
          return form.setFieldValue(name, dateTime?.date || newValue || value);
        }}
        renderInput={(params: Record<string, any>) => (
          <DefaultInput
            className={`${classes.timePicker} ${errorClass} `}
            fullWidth
            variant="standard"
            {...params}
          />
        )}
      />
    </FlexBox>
  );
};
