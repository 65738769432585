import React, {ChangeEventHandler, useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {DefaultInput} from "./index";
import {useInputStyles} from "./inputs-styles.hook";
import {MaterialFieldType} from "../types/material-field.type";
import {InputAdornment} from "@mui/material";
import {useIntl} from "react-intl";

interface IFormikField {
  label?: string;
  name: string;
  submit:
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  value: string;
  form: Record<string, any>;
  variant?: "filled" | "standard" | "outlined" | undefined;
  placeholder?: string;
}

export const FormikVisibleField: React.FC<IFormikField> = ({
                                                             label,
                                                             name,
                                                             submit,
                                                             value,
                                                             variant,
                                                             form,
                                                             placeholder,
                                                           }) => {
  const intl = useIntl()
  const [isVisible, setIsVisible] = useState(false);
  const classes = useInputStyles();
  const isHaveError: boolean = form?.errors[name] && form?.submitCount;
  const error: boolean = form?.touched.name && Boolean(form.errors.name);
  const errorClass = isHaveError ? classes.errorBorderField : "";
  const fieldVariant: MaterialFieldType = variant ?? "standard";
  const visibleIcon = !isVisible ? <VisibilityIcon/> : <VisibilityOffIcon/>;
  const errorText = form.touched[name] && form.errors[name] && form.errors[name]

  return (
    <div>
      <span className={classes.fieldLabel}>{label}</span>
      <DefaultInput
        maxRows={1}
        className={errorClass}
        fullWidth
        multiline
        variant={fieldVariant}
        value={value}
        onChange={submit}
        name={name}
        placeholder={placeholder}
        error={!!error}
        helperText={error}
        InputProps={{
          endAdornment: (
            <InputAdornment
              className={classes.visibleIcon}
              onClick={() => setIsVisible(!isVisible)}
              position="start"
            >
              {visibleIcon}
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.errorText}>

        {
          errorText && intl.formatMessage({
            id: errorText,
          })
        }
      </div>
    </div>
  );
};
