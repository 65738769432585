import {useIntl} from "react-intl";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";
import {ITableDragConfig} from "../../components/wilson-ui/types/table-config.inteface";

export const tagsTableConfig = (): ITableDragConfig => {
    const intl = useIntl();

    return {
        rowColumnsCarouselDrag: "250px 800px 100px",
        columns: [
            {
                field: "name",
                type: "component",
                componentName: "name",
                headerName: intl.formatMessage({id: "name"}),
                width: 250,
                sortable: false,
                filterType: "none",
                GRID_DETAIL_PANEL_TOGGLE_FIELD,
            },
            {
                field: "widgets",
                type: "component",
                componentName: "widgets",
                headerName: intl.formatMessage({id: "feed_widget"}),
                filterType: "none",
                width: 800,
                sortable: false,
            },
            {
                name: "edit",
                type: "component",
                componentName: "button",
                headerName: "",
                filterType: "none",
                width: 100,
                sortable: false,
            },
        ],
    };
};
