import React, { useState, useCallback } from "react";
import { useChannelsItemStyles } from "./channels-item-styles.hook";
import { channelsWrappers } from "../../../constants/channels/channels-wrappers";
import { IChanelUpgradeElement } from "../../../types";
import { ChannelElement } from "./components/channel-element";
import { ChannelMoreButton } from "./components/channel-more-button/channel-more-button";

const UpgradeChannelHandler = (channel: string) => {
  const newItemsArray = Object.values(channelsWrappers).filter(
    (channelWrap: IChanelUpgradeElement) =>
      channelWrap.name.toLowerCase().replace(/\s/g, "") ===
      channel.toLowerCase().replace(/\s/g, "")
  );

  return newItemsArray[0];
};

interface IProps {
  arr: string;
  row?: Record<string, any>;
  handleSelectRow?: (row: Record<any, any> | null, length: number) => void;
}

export const ChannelsItem: React.FC<IProps> = ({
  arr,
  row,
  handleSelectRow,
}) => {
  const [isShowMoreTrue, setIsShowMoreTrue] = useState(false);

  const classes = useChannelsItemStyles();
  const data = arr
    .split(",")
    .map((channel: string) => UpgradeChannelHandler(channel))
    .filter((channel) => !!channel);

  const toggleShowMore = useCallback(() => {
    setIsShowMoreTrue(!isShowMoreTrue);
    handleSelectRow && row && handleSelectRow(row, data.length);
  }, [isShowMoreTrue, handleSelectRow]);

  const moreItemsSpan = data.length > 1 && (
    <ChannelMoreButton
      isShowMoreTrue={isShowMoreTrue}
      length={data.length}
      toggleShowMore={toggleShowMore}
    />
  );

  if (data.length < 1) return <></>;

  return (
    <div className={`${classes.wrap}`}>
      <div>
        {isShowMoreTrue ? (
          data.map((item: IChanelUpgradeElement, index) => (
            <ChannelElement key={index} item={item} />
          ))
        ) : (
          <ChannelElement
            item={{
              background: data[0].background,
              color: data[0].color,
              name: data[0].name,
            }}
          />
        )}
      </div>
      {moreItemsSpan}
    </div>
  );
};
