import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../wilson-ui/constants/colors";

export const useButtonsItemStyles = makeStyles(() => ({
  link: {
    border: "none",
    fontSize: "14px",
    background: "transparent",
    borderBottom: `1px solid ${baseColors.dark}`,
    color: baseColors.dark,
    letterSpacing: "0.5px",
    cursor: "pointer",
    opacity: 0.5,
    transition: "0.7s",
    "&:hover": {
      opacity: 1,
    },
  },
}));
