import * as Yup from "yup";
import { COLOR_REGEX, URL_REGEX } from "../../constants";

export const AddWidgetsPostSchema = Yup.object().shape({
  site: Yup.string(),
  description: Yup.string()
    .min(3, "min_length_3")
    .max(50000, "max_length_50000")
    .required("Required"),
  detailsAssetsButtonText: Yup.string()
    .min(3, "min_length_3")
    .max(50000, "max_length_50000")
    .required("Required"),
  detailsAssetsButtonColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  detailsAssetsButtonTextColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  successAssetsButtonText: Yup.string()
      .min(3, "min_length_3")
      .max(50000, "max_length_50000")
      .required("Required"),
  successAssetsButtonColor: Yup.string()
      .matches(COLOR_REGEX, "must_correspond")
      .required("Required"),
  successAssetsButtonTextColor: Yup.string()
      .matches(COLOR_REGEX, "must_correspond")
      .required("Required"),
  successAssetsButtonImage: Yup.mixed(),
  promotionsTitle: Yup.mixed().required("Required"),
  promotionsTitleColor: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  promotionsDescription: Yup.string().max(500, "max_length_500"),
  promotionsDescriptionColor: Yup.string().matches(
    COLOR_REGEX,
    "must_correspond"
  ),
  promotionsBackgroundColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  promotionsModalImage: Yup.mixed(),
  promotionsModalTitle: Yup.mixed().when(["promotionsModalVisibility"], {
    is: true,
    then: Yup.string()
    .min(5, "min_length_5")
    .max(50, "max_length_50")
    .required("Required"),
  }),
  promotionsModalTitleColor: Yup.mixed().when(["promotionsModalVisibility"], {
    is: true,
    then: Yup.string()
      .matches(COLOR_REGEX, "must_correspond")
      .required("Required"),
  }),
  promotionsModalBackgroundImage: Yup.mixed(),
  promotionsModalBackgroundColor: Yup.mixed().when(["promotionsModalVisibility"], {
    is: true,
    then: Yup.string()
      .matches(COLOR_REGEX, "must_correspond")
      .required("Required"),
  }),
  favoriteTitle: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string().required("Required"),
  }),
  favoriteSiteUrl: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.string()
      .matches(URL_REGEX, "must_correspond")
      .required("Required"),
  }),
  favoriteThumbnailImage: Yup.mixed().when(["favoriteIcon"], {
    is: true,
    then: Yup.mixed().required("Required"),
  }),
  // thumbnailLockerUrl: Yup.string(),
  feedMedia: Yup.array().when("description", (description, schema) => {
    return schema.test({
      test: (feedMediaArr: Array<Record<string, any>>) => {
        let isValid = true;
        if (feedMediaArr.length) {
          feedMediaArr.forEach((item: Record<string, any>) => {
            if (!item.assetUrl) {
              isValid = false;
            }
          });
        } else {
          isValid = false;
        }
        return isValid;
      },
    });
  }),
});
