import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { useIntl } from "react-intl";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import {
  DateRangePickerDay as MuiDateRangePickerDay,
  DateRangePickerDayProps,
} from "@mui/x-date-pickers-pro/DateRangePickerDay";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { UiModal } from "../modal";

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({
    // theme,
    isHighlighting,
    isStartOfHighlighting,
    isEndOfHighlighting,
    outsideCurrentMonth,
  }) => ({
    ...(!outsideCurrentMonth &&
      isHighlighting && {
        borderRadius: 0,
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.common.white,
        // "&:hover, &:focus": {
        //   backgroundColor: theme.palette.primary.dark,
        // },
      }),
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  })
) as React.ComponentType<DateRangePickerDayProps<Dayjs>>;

interface IProps {
  toggle: () => void;
  classes: any;
  onDowload: (dateRange: DateRange<Dayjs>) => any;
}

export const CustomDateRangePickerDay: React.FC<IProps> = ({
  toggle,
  classes,
  onDowload,
}) => {
  const intl = useIntl();
  const [value, setValue] = React.useState<DateRange<Dayjs>>([null, null]);
  const [active, setActive] = React.useState<string>("");

  const renderWeekPickerDay = (
    date: Dayjs,
    dateRangePickerDayProps: DateRangePickerDayProps<Dayjs>
  ) => {
    return <DateRangePickerDay {...dateRangePickerDayProps} />;
  };
  const shortcutsItems: any[] = [
    {
      label: "this_month",
      active: "this_month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "last_month",
      active: "last_month",
      getValue: () => {
        const today = dayjs();
        const prev1 = today.subtract(1, "month");
        return [prev1.startOf("month"), prev1.endOf("month")];
      },
    },
    {
      label: "last_3_months",
      active: "last_3_months",
      getValue: () => {
        const today = dayjs();
        const prev1 = today.subtract(1, "month");
        const prev3 = today.subtract(3, "month");
        return [prev3.startOf("month"), prev1.endOf("month")];
      },
    },
    {
      label: "last_6_months",
      active: "last_6_months",
      getValue: () => {
        const today = dayjs();
        const prev1 = today.subtract(1, "month");
        const prev6 = today.subtract(6, "month");
        return [prev6.startOf("month"), prev1.endOf("month")];
      },
    },
    {
      label: "reset",
      active: "",
      getValue: () => [null, null],
    },
  ];

  return (
    <UiModal width="800px" minHeight="470px" closeHandler={toggle}>
      <div className={classes.header}>
        <h3>{intl.formatMessage({ id: "date_range" })}</h3>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: 1,
          }}
        >
          {shortcutsItems.map((item, index) => (
            <Button
              sx={{
                textTransform: "inherit",
                marginBottom: 2,
                borderColor: '#000',
                color: '#000',
                fontWeight: 700,
                '&: hover': {
                  color: '#fff',
                  backgroundColor: 'rgba(66, 165, 245, 0.6)'
                },
                '&.MuiButton-contained': {
                  color: '#fff',
                }
              }}
              key={index}
              variant={item.label === active ? "contained" : "outlined"}
              onClick={() => {
                setValue(item.getValue());
                setActive(item.active);
              }}
            >
              {intl.formatMessage({ id: item.label })}
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            pt: 1,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              label="date range"
              value={value}
              onChange={(newValue) => setValue(newValue)}
              renderDay={renderWeekPickerDay}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => onDowload(value)}
          sx={{
            textTransform: "inherit",
            marginRight: 1,
            marginTop: 1,
            borderColor: '#000',
            color: '#000',
            fontWeight: 700
          }}
          disabled={!(value[0] && value[1])}
        >
          {intl.formatMessage({ id: "export_date_range" })}
        </Button>
        <Button
          variant="outlined"
          onClick={() => onDowload([null, null])}
          sx={{
            textTransform: "inherit",
            marginRight: 1,
            marginTop: 1,
            borderColor: '#000',
            color: '#000',
            fontWeight: 700
          }}
        >
          {intl.formatMessage({ id: "export_all" })}
        </Button>
      </Box>
    </UiModal>
  );
};
