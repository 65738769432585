import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { FlexBox, GridBox, RadiusBox } from "../../../../components/wilson-ui/box";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { useCarouselItemStyle } from "./carousel-item-style.hook";
import CarouselStore from "../../../../store/carousel/corousel.store";
import { getTypeWidget } from "../../../../utils/utils";
import { DefaultInput } from "../../../../components/wilson-ui/inputs";

interface IProps {
  item: Record<string, any>;
  updateCarouselItemHandler: (id: string, item: Record<string, any>) => any;
  uploadImageCarouselItemHandler: (item: Record<string, any>) => void;
  removeCarouselItemHandler: (id: string) => void;
}

export const CarouselItem: FC<IProps> = ({
  item,
  updateCarouselItemHandler,
  uploadImageCarouselItemHandler,
  removeCarouselItemHandler,
}) => {
  const [isEdit, setIsEdit] = useState<any>({});
  const [carouselTitle, setCarouselTitle] = useState("");
  const classes = useCarouselItemStyle();
  const intl = useIntl();

  const onSubmit = (id: string, title: string, childId: string) => {
    if (!isEdit[childId]) {
      setIsEdit({ [childId]: true });
      setCarouselTitle(title);
    } else {
      if (carouselTitle !== title) {
        CarouselStore.updateItem({
          childId,
          carouselTitle: carouselTitle || ''
        });
        if (id) {
          const result = updateCarouselItemHandler(id, { carouselTitle: carouselTitle || '' });
          if (result) {
            toast.success(intl.formatMessage({ id: "update_title" }));
          }
        }
        setCarouselTitle("");
        setIsEdit({ [id]: false });
      }
    }
  };

  const onChange = (e: any) => {
    setCarouselTitle(e.target.value);
  };
  return (
    <GridBox className={classes.container}>
      <div className={classes.carouselColumn}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            position: "relative",
          }}
        >
          {isEdit[item.childId] ? (
            <DefaultInput
              fullWidth
              variant="outlined"
              value={carouselTitle}
              // onBlur={onBlur}
              onChange={onChange}
              placeholder="Card Color"
            />
          ) : (
            <Box
              sx={{
                width: "160px",
                height: "56px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item.carouselTitle || ""}
            </Box>
          )}
          <Box
            sx={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              background: "#fff",
              border: "1px solid #000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "-5px",
              right: "-5px",
              cursor: "pointer",
            }}
            onClick={() => onSubmit(item?.id, item.carouselTitle, item.childId)}
          >
            {isEdit[item.childId] ? (
              <SaveOutlinedIcon
                fontSize="small"
                sx={{
                  width: "0.8em",
                  height: "0.8em",
                }}
              />
            ) : (
              <ModeEditOutlineOutlinedIcon
                fontSize="small"
                sx={{
                  width: "0.8em",
                  height: "0.8em",
                }}
              />
            )}
          </Box>
        </Box>
      </div>
      <div className={classes.carouselColumn}>{item.title}</div>
      <div className={classes.carouselColumn}>{getTypeWidget(item.type)}</div>
      <FlexBox items="center" content="center" gap="15px">
        <RadiusBox
          width="32px"
          height="32px"
          onClick={() => uploadImageCarouselItemHandler(item)}
        >
          {item?.overrideThumbnailLink || item?.overrideImageLink ? (
            <img
              src={item?.overrideThumbnailLink || item?.overrideImageLink}
              alt="override image"
              className={classes.image}
            />
          ) : (
            <AddPhotoAlternateOutlinedIcon className={classes.icon} />
          )}
        </RadiusBox>
        <RadiusBox
          width="32px"
          height="32px"
          onClick={() => removeCarouselItemHandler(item.id)}
        >
          <DeleteForeverIcon className={classes.removeIcon} />
        </RadiusBox>
      </FlexBox>
      <FlexBox items="center" content="center">
        <DragHandleIcon />
      </FlexBox>
    </GridBox>
  );
};
