import React from "react";
import MDSpinner from "react-md-spinner";
import { FlexBox } from "../box";

interface IProps {
  paddingTop?: string;
}

export const Spiner: React.FC<IProps> = ({ paddingTop }) => {
  const paddingTopValue = paddingTop ? paddingTop : "150px";

  return (
    <FlexBox
      items="center"
      content="center"
      style={{ paddingTop: paddingTopValue }}
    >
      <MDSpinner />
    </FlexBox>
  );
};
