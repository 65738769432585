import React from "react";
import { Table, TableContainer } from "@material-ui/core";
import { useTableBodyStyles } from "./table-body-styles.hook";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { TableRow, TableRowProps } from "../table-row";
import DragAndDropIcon from "../../../../../assets/icons/drag-and-drop.svg";

interface IProps {
  data: Array<Record<string, any>>;
  columns: Array<Record<string, any>>;
  rowColumns: string;
  components: Record<string, any>;
  onSortEnd?: (index: any) => void;
  dragIconClassName?: string;
}

export const SortableTableBody: React.FC<IProps> = ({
  data,
  columns,
  rowColumns,
  components,
  onSortEnd,
  dragIconClassName,
}) => {
  const classes = useTableBodyStyles();

  return (
    <TableContainer>
      <SortableTable
        distance={1}
        data={data}
        columns={columns}
        components={components}
        rowColumns={rowColumns}
        className={classes.container}
        dragIconClassName={dragIconClassName}
        onSortEnd={onSortEnd}
      />
    </TableContainer>
  );
};

interface TableProps extends Omit<TableRowProps, "row"> {
  data: Array<Record<string, any>>;
  dragIconClassName?: string;
}

const SortableTable = SortableContainer(
  ({
    data,
    columns,
    components,
    rowColumns,
    className,
    dragIconClassName,
  }: TableProps) => {
    const columnsWithDraggable = [
      {
        name: "drag",
        componentName: "drag",
        type: "component",
        style: {
          position: "sticky",
          backgroundColor: "white",
          left: 0,
          zIndex: 0,
        },
      },
      ...columns,
    ];

    const componentsWithDraggable = {
      drag: () => (
        <img
          src={DragAndDropIcon}
          className={dragIconClassName || ""}
          alt="drag and drop icon"
        />
      ),
      ...components,
    };

    return (
      <Table>
        {data.map((row: any, index: number) => {
          return (
            <SortableTableRow
              key={index}
              index={index}
              row={row}
              columns={columnsWithDraggable}
              components={componentsWithDraggable}
              className={className}
              rowColumns={`55px ${rowColumns}`}
            />
          );
        })}
      </Table>
    );
  }
);

const SortableTableRow = SortableElement((props: TableRowProps) => (
  <TableRow {...props} />
));
