import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSubmissionsTypeModalStyles } from "./submissions-type-modal-styles.hook";
import { RadioCheckboxRed } from "../../../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { FormikAutocomplete } from "../../../../components/wilson-ui/selects";
import { useIntl } from "react-intl";
import { getWidgetsByString } from "../../../../actions/widgets";
import { useTagsOptionsValue } from "../../hooks";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";

interface IProps {
  toggleModal: () => void;
  titleFilteringArrayForm: Record<string, any>;
  changeTypeHandler: (event: any) => void;
  changeWinnersHandler: (event: any) => void;
  formType: Record<string, any>;
  formWinners: Record<string, any>;
}

export const SubmissionsTypeModal: React.FC<IProps> = ({
  toggleModal,
  titleFilteringArrayForm,
  changeTypeHandler,
  changeWinnersHandler,
  formType,
  formWinners,
}) => {
  const [filterString, setFilterString] = useState("");
  const [tagsOptions, setTagsOptions] = useState([]);

  const intl = useIntl();
  const classes = useSubmissionsTypeModalStyles();

  const tagsOptionsValue: Record<string, any>[] = useTagsOptionsValue(
    tagsOptions,
    titleFilteringArrayForm.values.array
  );

  useEffect(() => {
    const sendData = async () => {
      const data = await getWidgetsByString(filterString);
      setTagsOptions(data);
    };
    sendData();
  }, [filterString]);

  return (
    <div className={classes.container}>
      <CloseIcon className={classes.icon} onClick={toggleModal} />
      <div className={classes.itemsContainer}>
        <GridBox col={2}>
          <FlexBox direction="column" gap="5px">
            <RadioCheckboxRed
              label={intl.formatMessage({ id: "post" })}
              value={!!formType.post}
              submit={changeTypeHandler}
              name={"post"}
            />
            <RadioCheckboxRed
              label={intl.formatMessage({ id: "story" })}
              name={"story"}
              value={!!formType.story}
              submit={changeTypeHandler}
            />
          </FlexBox>

          <FlexBox items="center">
            <h3 className={classes.title}>
              {intl.formatMessage({ id: "widget_type" })}
            </h3>
          </FlexBox>
        </GridBox>

        <GridBox col={2}>
          <FlexBox direction="column" gap="5px">
            <RadioCheckboxRed
              label={intl.formatMessage({ id: "winner" })}
              value={!!formWinners.winner}
              submit={changeWinnersHandler}
              name={"winner"}
            />
            <RadioCheckboxRed
              label={intl.formatMessage({ id: "assign" })}
              name={"assign"}
              value={!!formWinners.assign}
              submit={changeWinnersHandler}
            />
          </FlexBox>

          <FlexBox items="center">
            <h3 className={classes.title}>
              {intl.formatMessage({ id: "winner_type" })}
            </h3>
          </FlexBox>
        </GridBox>

        <div>
          <FormikAutocomplete
            placeholder="Search by widgets"
            optionLabel="title"
            type="white"
            options={tagsOptionsValue}
            name="array"
            value={titleFilteringArrayForm.values.array}
            form={titleFilteringArrayForm}
            textSubmit={setFilterString}
            textValue={filterString}
            variant="outlined"
          />
        </div>
      </div>
    </div>
  );
};
