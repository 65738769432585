import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";

export const useSubmissionsTypeModalStyles = makeStyles(() => ({
  container: {
    position: "fixed",
    width: "400px",
    maxWidth: "80%",
    background: "#FBFCFC",
    border: "1px dashed grey",
    bottom: "20px",
    right: "20px",
    zIndex: 200,
    padding: "20px",
    opacity: 1,
    borderRadius: "10px",
  },
  title: {
    textTransform: "uppercase",
    opacity: 0.5,
  },
  icon: {
    color: baseColors.red,
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
}));
