import { makeStyles } from "@material-ui/core";
import { mediaQuery } from "../../../../components/wilson-ui/constants/media";

export const useHeaderStyles = makeStyles(() => ({
  container: {
    [mediaQuery.maxWidth800]: {
      flexDirection: "column",
      gap: "20px",
    },
  },
  buttons: {
    gap: "10px",
    [mediaQuery.maxWidth550]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));
