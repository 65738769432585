import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../wilson-ui/constants/colors";

export const useNavbarModalStyles = makeStyles(() => ({
  container: {
    padding: "40px",
    flexDirection: "column",
    gap: "30px",
  },
  fieldBlock: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  fieldLabel: {
    fontFamily: "'GT America',  sans-serif",
    width: "140px",
    paddingRight: "10px",
    fontSize: "14px",
    color: baseColors.black,
    fontWeight: 500,
    display: "block",
  },
  fieldValue: {
    width: "100%"
  }
}));
