import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";

export const useSwipeItemStyle = makeStyles(() => ({
  container: {
    width: "93%",
    gridTemplateColumns: "32px 50px auto",
    columnGap: "20px",
  },
  inner: {
    border: "1px solid #d6d6d6",
    borderRadius: "24px",
    backgroundColor: '#fff',
    padding: '16px'
  },
  dragContainer: {
    width: "30px",
    height: "50px",
    borderRadius: '100px',
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid #d6d6d6",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  fileInput: {
    display: "none",
  },
  label: {
    display: "flex",
    borderRadius: "10px",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    border: `1px solid ${baseColors.black}`,
  },
  labelError: {
    border: `1px solid ${baseColors.red}`,
  },
  radioButtonContainer: {
    fontSize: 16,
    marginRight: 15,
  },
}));
