import React from "react";
import { UiButton } from "../../../buttons";
import { useIntl } from "react-intl";

interface IProps {
  isBoth?: boolean;
  isVideo?: boolean;
  isCSV?: boolean;
}
export const DropzoneEmpty: React.FC<IProps> = ({ isBoth, isVideo, isCSV }) => {
  const intl = useIntl();

  return (
    <>
      <div>
        <h5>
          {intl.formatMessage({
            id: isBoth ? "drop_image_video": isVideo ? "drop_video" : isCSV ? "drop_csv": "drop_image",
          })}
        </h5>
      </div>
      <span>{intl.formatMessage({ id: "or" })}</span>
      <UiButton buttonType="rect" type="button">
        {intl.formatMessage({ id: "browse_file" })}
      </UiButton>
    </>
  );
};
