import { makeStyles } from "@material-ui/core";
import { mediaQuery } from "../../../wilson-ui/constants/media";

export const useNavbarBtnStyles = makeStyles(() => ({
  btnWrap: {
    cursor: "pointer",
    [mediaQuery.minWidth1100]: {
      display: "none !important",
    },
  },
  btn: {
    display: "block",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    position: "relative",
  },
  span: {
    "&, &::before, &::after": {
      position: "absolute",
      top: "50%",
      marginTop: "-1px",
      left: "50%",
      marginLeft: "-10px",
      width: "20px",
      height: "2px",
      backgroundColor: "#222",
    },
    "&::after, &::before": {
      content: "'' !important",
      display: "block",
      transition: "1s",
    },
    "&::before": {
      transform: "translateY(-5px)",
    },
    "&::after": {
      transform: "translateY(5px)",
    },
  },
  spanActive: {
    "&::before": {
      transform: "rotate(-35deg)",
      width: "10px",
      transformOrigin: "left top",
    },
    "&::after": {
      transform: "rotate(35deg)",
      width: "10px",
      transformOrigin: "left bottom",
    },
  },
}));
