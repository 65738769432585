import { makeStyles } from "@material-ui/core";
import backgroundModalExport from "../../../../assets/images/backgroundModalExport.png";

export const useDownloadUserCSVdModalStyles = makeStyles(() => ({
  container: {
    fontFamily: "'GT America',  sans-serif",
    width: "100%",
    position: "relative",
  },
  headerInner: {
    width: "480px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",

    position: "absolute",
    top: "140px",
    left: "0",

    padding: "0 24px",
    "& .MuiDateRangePickerInput-root": {
      width: "100% !important"
    }
  },
  header: {
    height: "336px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",

    backgroundImage: `url("${backgroundModalExport}")`,
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "336px",
  },
  headerIcon: {
    position: "absolute",
    top: "32px",
    left: "32px"

  },
  headerTitle: {
    marginBottom: "48px",
    "& h3": {
      fontSize: "20px",
      color: "#212831",
      margin: "5px 0"
    },
    "& h5": {
      fontSize: "14px",
      color: "#475467"
    }
  },
  headerClose: {
    position: "absolute",
    top: "26px",
    right: "26px",
    cursor: "pointer",
  },
  optionsInner: {
    padding: "16px 24px",
    marginTop: "-30px",
    "& li": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid #EAECF0",
      padding: "16px",
      cursor: "pointer",
      "&:not(:last-child)": {
        marginBottom: "12px",
      },
      "&.active": {
        border: "2px solid #CF102D",
      },
      "& .label": {
        padding: "0 12px",
        fontSize: "14px",
        lineHeight: "20px",
        "& p": {
          fontWeight: "bold",
          color: "#344054",
        },
        "& span": {
          color: "#475467",
        }
      }
    }
  }
}));
