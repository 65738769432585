import React, { FC } from "react";

interface IProps {
  name: string;
}

export const NameItem: FC<IProps> = ({ name }) => {
  const value = name
    .split(" ")
    .map((item) => (item.length > 15 ? `${item.slice(0, 15)}...` : item));
  const valueString = value.join(" ");

  return <>{valueString}</>;
};
