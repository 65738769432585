import React from "react";
import { DetailsAssets } from "../details-assets";
import { LockerAssets } from "../locker-assets";
import { FavoriteThumbnail } from "../favorite-thumbnail";

interface IProps {
  form: Record<string, any>;
}

export const SettingPromotion: React.FC<IProps> = ({ form }) => {
  return (
    <>
      <DetailsAssets {...{ form }} />
      <LockerAssets {...{ form }} />
      <FavoriteThumbnail {...{ form }} />
    </>
  );
};
