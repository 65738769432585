import { Dispatch } from "react";
import {
  downloadWidgetsCSV,
  downloadWidgetsFullListCSV,
} from "../../../../actions/widgets";

export const useWidgetsDownloadCurrentCSV = async (
  widgetsId: string[],
  name: string,
  order: string | null,
  sortType: Array<string>,
  setCurrentCSV: Dispatch<any>
): Promise<void> => {
  try {
    const CSVFile = await downloadWidgetsCSV(widgetsId, name, order, sortType);
    setCurrentCSV(CSVFile);
  } catch (error) {
    console.warn(error);
  }
};

export const useWidgetsDownloadCSV = async (
  widgetsId: string[],
  name: string,
  order: string | null,
  sortType: Array<string>,
  setCurrentCSV: Dispatch<any>,
  setFullCSV: Dispatch<any>,
  toggleModal: () => void
): Promise<void> => {
  try {
    const CSVFile = await downloadWidgetsCSV(widgetsId, name, order, sortType);
    const CSVFileFullList = await downloadWidgetsFullListCSV();
    setCurrentCSV(CSVFile);
    setFullCSV(CSVFileFullList);
    toggleModal();
  } catch (error) {
    console.warn(error);
  }
};
