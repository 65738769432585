import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";

export const useFontTypeSelectStyle = makeStyles(() => ({
  label: {
    fontSize: 14,
  },
  button: {
    padding: "2px 2px 4px",
    width: "100px !important",
    height: 35,
    minWidth: "auto",
    borderColor: baseColors.grey,
    borderRadius: 0,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  errorBorderField: {
    borderColor: baseColors.red,
  },
  size: {
    fontSize: 14,
  },
  dark: {

  }
}));
