import React, { useState } from "react";
import { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { Text } from "../../../../components/wilson-ui/text";
import { useSubmissionsHeaderStyles } from "./submissions-header-styles.hook";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { usePageStyles } from "../../../pages-styles.hook";
import { useIntl } from "react-intl";
import { DownloadCsvAsyncModal } from "../../../../components/wilson-ui/modals";
import {
  downloadSubmissionsFullListCSV,
  getSubmissionsCSV,
} from "../../../../actions/submissions";
import { EMPTY_FUNCTION } from "../../../../constants";

interface IProps {
  rows: number;
  page: number;
  sortByName: string;
  order: string;
  length: number;
  filterType: Array<string>;
  filterWinner: Array<string>;
  sortByTitle: Record<string, any>;
}

export const SubmissionsHeader: React.FC<IProps> = ({
  rows,
  page,
  sortByName,
  order,
  length,
  filterType,
  filterWinner,
  sortByTitle,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useSubmissionsHeaderStyles();
  const pageClasses = usePageStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const downloadCSV = async () => {
    try {
      return await getSubmissionsCSV(
        rows,
        page,
        order,
        sortByName,
        filterType,
        filterWinner,
        sortByTitle
      );
    } catch (error) {
      EMPTY_FUNCTION();
      return [[]];
    }
  };

  const downloadCSVAll = async (dateRange: DateRange<Dayjs>) => {
    try {
      return await downloadSubmissionsFullListCSV(parseInt((length).toString()), dateRange);
    } catch (error) {
      EMPTY_FUNCTION();
      return [[]];
    }
    
  };

  const modal = isModalOpen && (
    <DownloadCsvAsyncModal
      toggle={toggleModal}
      downloadCSV={downloadCSV}
      downloadCSVAll={downloadCSVAll}
      currentName="submissions-current-list"
      fullName="submissions-full-list"
    />
  );

  return (
    <FlexBox className={pageClasses.container}>
      <Text className={pageClasses.pageTitle}>
        {intl.formatMessage({ id: "feed_submissions" })}
      </Text>
      <FlexBox className={classes.buttons}>
        <UiButton buttonType="white" disabled={!length} onClick={toggleModal}>
          {intl.formatMessage({ id: "export" })}
        </UiButton>
      </FlexBox>
      {modal}
    </FlexBox>
  );
};
