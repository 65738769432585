import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";

export const useTitleSizeSelectStyle = makeStyles(() => ({
  label: {
    fontSize: 14,
  },
  button: {
    padding: "2px 2px 4px",
    width: "35px !important",
    height: 35,
    minWidth: "auto",
    borderColor: baseColors.grey,
    borderRadius: 0,
    fontWeight: "bold",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  errorBorderField: {
    borderColor: baseColors.red,
  },
  small: {
    fontSize: 11,
  },
  medium: {
    fontSize: 15,
  },
  large: {
    fontSize: 20,
  },
}));
