import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import moment from "moment";
import Box from "@mui/material/Box";
import { campaignsTableConfig } from "./campaigns-table-config";
import { MainLayout } from "../../components";
import { CampaignActionsButton } from "../../components/items/campaign-actions-button";
import { RadioCheckboxRed } from "../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { LayoutWrap } from "../../components/wrappers/layout-wrap/layout-wrap";
import { CampaignsHeader } from "./components/header";
import { UiTableDrag } from "../../components/wilson-ui/table";
import { EMPTY_FUNCTION } from "../../constants";
import { FlexBox } from "../../components/wilson-ui/box";
import { ConfirmModal } from "../submissions/components/confirm-modal";
import CampaignsStore from "../../store/campaigns/campaigns.store";
import { removeCampaigns } from "../../actions/campaigns";
import { CopyItem } from "../../components/items";

const defaultRowPerPage = 10;
const defaultPage = 1;
const defaultSortState = "";
const defaultSortBy = null;
export const CampaignsPage: React.FC = observer(() => {
  const [term, setTerm] = useState<string>("");
  const [rowPerPage, setRowPerPage] = useState<number>(defaultRowPerPage);
  const [page, setPage] = useState<number>(defaultPage);
  const [sortState, setSortState] = useState<string>(defaultSortState);
  const [sortBy, setSortBy] = useState<any>(defaultSortBy);
  const [isModalShow, setIsModalShow] = useState(false);
  const [userChosen, setUserChosen] = useState<string | undefined>();
  const campaigns = CampaignsStore.getCampaigns();
  const toggleModal = (userId?: string) => {
    setIsModalShow(!isModalShow);
    setUserChosen(userId);
  };

  const removeCampaignHandler = async () => {
    if (!userChosen) {
      return;
    }

    CampaignsStore.isLoading = true;
    await removeCampaigns(userChosen);
    setTerm("");
    await CampaignsStore.campaignsRequest(
      rowPerPage,
      page,
      sortBy,
      sortState,
      ""
    );
    CampaignsStore.isLoading = false;
    toggleModal();
  };

  useEffect(() => {
    if (CampaignsStore.isLoading) return;
    CampaignsStore.campaignsRequest(rowPerPage, page, sortBy, sortState, term);
  }, [rowPerPage, page, sortState, sortBy, term]);

  useEffect(() => {
    EMPTY_FUNCTION();
    return () => CampaignsStore.setCampaigns({ data: [], length: 0 });
  }, []);

  const setSearch = (val: string) => {
    setTerm(val);
    CampaignsStore.campaignsRequest(rowPerPage, page, sortBy, sortState, val);
  };

  const setRowPerPageHandler = (val: number) => {
    setPage(1);
    setRowPerPage(val);
  };

  const setSortModel = (field: string, sortOrder: string | null) => {
    setSortBy(sortOrder || null);
    setSortState(field);
  };

  const componentsTable = {
    name: ({ row }: any) => {
      return (
        <CopyItem maxLengthProps={5} row={row} col="id">
          <FlexBox items="center">{row.name}</FlexBox>
        </CopyItem>
      );
    },
    trigger: ({ row }: any) => (
      <Box sx={{ textTransform: "capitalize" }}>{row?.widget?.title || ""}</Box>
    ),
    data: ({ row, col }: any) => (
      <Box>{moment(row[col]).format("MMM DD, YYYY")}</Box>
    ),
    qty: ({ row }: any) => (
      <Box>{Number(row.hits || 0) + Number(row.misses || 0) || Number(row.qty)}</Box>
    ),
    claimed: ({ row }: any) => {
      return <Box>{row.userClaimeds?.length || row.promotionCodes?.length}</Box>;
    },
    status: ({ row }: any) => (
      <Box
        sx={{
          width: "100%",
          display: "inline-block",
        }}
      >
        <Box
          sx={{
            display: "inline-block",
            textTransform: "capitalize",
            border: "2px solid #000",
            padding: "0 8px",
            marginTop: 1,
            marginRight: 1,
            marginBottom: 1,
          }}
        >
          {row?.status?.toLowerCase() || ""}
        </Box>
      </Box>
    ),
    checkbox: ({ row }: any) => (
      <RadioCheckboxRed
        label={""}
        value={row.isPaused}
        submit={() => CampaignsStore.setCampaignPaused(row.id, !row.isPaused)}
        name="isPaused"
      />
    ),
    button: ({ row }: any) => (
      <Box>
        <CampaignActionsButton
          row={row}
          toggleModal={() => toggleModal(row.id)}
        />
      </Box>
    ),
  };

  const Table = (
    <UiTableDrag
      loading={CampaignsStore.isLoading}
      onLoading={(loading: boolean) => (CampaignsStore.isLoading = loading)}
      sortModelInit={
        sortState && sortBy ? [{ field: sortState, sort: sortBy }] : []
      }
      sortTypeSubmit={EMPTY_FUNCTION}
      sortSubmit={setSortModel}
      components={componentsTable}
      componentsTable={componentsTable}
      configDrag={campaignsTableConfig()}
      data={toJS(campaigns.data)}
      disablePagination={!campaigns.length}
      setRowPerPage={setRowPerPageHandler}
      rowPerPage={rowPerPage}
      page={page}
      setPage={setPage}
      length={campaigns.length}
      setRowPriority={EMPTY_FUNCTION}
      setSearch={setSearch}
      searchValue={term}
      searchTable
      rowReordering={false}
    />
  );

  return (
    <MainLayout>
      <CampaignsHeader
        order={sortState}
        page={page}
        rows={rowPerPage}
        length={campaigns.length}
        sortByName={sortBy}
      />
      <LayoutWrap minHeight="600px">{Table}</LayoutWrap>
      {isModalShow && (
        <ConfirmModal
          toggleModal={toggleModal}
          submit={removeCampaignHandler}
          title="Are you sure?"
        />
      )}
    </MainLayout>
  );
});
