import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { sumBy } from 'lodash';
import { useParams, useHistory } from "react-router-dom";
import ROUTING_CONFIG from "../../../../constants/routing-config/routing-config";
import { useAdditionalSettingsStyles } from "./additional-settings-styles.hook";
import { AdditionalItem } from "../additional-item";
import { status, statusValue, categoriesCampaign } from "./contants";
import {
  FormikData,
  FormikSelect,
  FormikTime,
} from "../../../../components/wilson-ui/selects";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { RadioboxRed } from "../../../../components/wilson-ui/checkboxes";
import { CampaignStatus } from "../../../../types";
import { removeCampaigns } from "../../../../actions/campaigns";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import { ConfirmModal } from "../../../submissions/components/confirm-modal";
import CampaignsStore from "../../../../store/campaigns/campaigns.store";
// import { CampaignTypeEnum } from "../../../../enum";

interface IAdditionalSettings {
  form: Record<string, any>;
}

export const AdditionalSettingsCampaign: React.FC<IAdditionalSettings> = ({
  form,
}) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isDeleteCampaignModalShow, setIsDeleteCampaignModalShow] =
    useState(false);
  const toggleModal = () =>
    setIsDeleteCampaignModalShow(!isDeleteCampaignModalShow);
  const [offset, setOffset] = useState<number>(0);
  const classes = useAdditionalSettingsStyles();
  const intl = useIntl();
  const divRef = React.useRef<HTMLDivElement>(null);
  const fixedBlockClass = offset > 80 ? classes.containerOffsetActive : "";
  // const offsetLeft = divRef.current ? divRef.current.offsetLeft : "auto";

  const logit = () => {
    setOffset(window.scrollY);
  };

  const notifyAllField = () => {
    console.log("form.errors", form.errors);
    console.log("form.touched", form.touched);
    const formErrorsArray = Object.values(form.errors);

    if (formErrorsArray.length) {
      toast(intl.formatMessage({ id: "please_fill_in_all_required_fields" }));
      return true;
    }
    form.isSubmitting = false;
  };

  const submitHandler = () => {
    let check: any = false;
    if (form.touched && !form.isValid) check = notifyAllField();
    if (!check) form.handleSubmit();
  };

  const removeCampaignHandler = async (id: string) => {
    CampaignsStore.isLoading = true;
    await removeCampaigns(id);
    CampaignsStore.isLoading = false;
    history.push(ROUTING_CONFIG.campaigns);
    toggleModal();
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  const onSelectStatus = (value: any) => {
    const option = value.target;
    form.setValues({ ...form.values, status: statusValue[option.value] });
  };

  return (
    <div ref={divRef}>
      <div className={`${fixedBlockClass} ${classes.container}`}>
        <AdditionalItem title={intl.formatMessage({ id: "status" })}>
          <FormikSelect
            {...{ form }}
            name="status"
            handleChange={onSelectStatus}
            value={form.values.status ? status[form.values.status] : ""}
            options={Object.values(CampaignStatus)}
            placeholder={intl.formatMessage({ id: "status" })}
          />
        </AdditionalItem>
      </div>
      <div
        className={`${fixedBlockClass} ${classes.container}`}
        // style={{ left: offsetLeft }}
      >
        <h3>{intl.formatMessage({ id: "scheduled" })}</h3>
        <AdditionalItem
          title={intl.formatMessage({ id: "start_expiration_date" })}
        >
          <FlexBox direction="row" gap="10px">
            <FormikData
              {...{ form }}
              time={form.values.startDateTime}
              value={form.values.startDateDate}
              name="startDateDate"
              nameTime="startDateTime"
            />
            <FormikData
              {...{ form }}
              time={form.values.expirationDateTime}
              value={form.values.expirationDateDate}
              name="expirationDateDate"
              nameTime="expirationDateTime"
            />
          </FlexBox>
        </AdditionalItem>
        <AdditionalItem
          title={intl.formatMessage({ id: "start_expiration_time" })}
        >
          <FlexBox direction="row" gap="10px">
            <FormikTime
              {...{ form }}
              date={form.values.startDateDate}
              value={form.values.startDateTime}
              name="startDateTime"
              label={`${intl.formatMessage({
                id: "start_time",
              })}`}
            />
            <FormikTime
              {...{ form }}
              date={form.values.expirationDateDate}
              value={form.values.expirationDateTime}
              name="expirationDateTime"
              label={`${intl.formatMessage({
                id: "expiration_time",
              })}`}
            />
          </FlexBox>
        </AdditionalItem>
      </div>
      <div className={`${fixedBlockClass} ${classes.container}`}>
        <AdditionalItem title={intl.formatMessage({ id: "categories" })}>
          <FlexBox direction="column" gap="10px">
            {categoriesCampaign.map((category: string) => (
              <FlexBox
                key={category}
                items="center"
                gap="10px"
                draggable={true}
              >
                <RadioboxRed
                  onChange={() => form.setFieldValue("category", category)}
                  checked={form.values.category === category}
                />
                <span>{category}</span>
              </FlexBox>
            ))}
          </FlexBox>
        </AdditionalItem>
        {form.errors?.category ? (
          <div className={classes.errorText}>
            {intl.formatMessage({ id: form.errors?.category.toLowerCase() })}
          </div>
        ) : null}
      </div>
      <div className={`${fixedBlockClass} ${classes.container}`}>
        <AdditionalItem title={intl.formatMessage({ id: "hits_misses" })}>
          <FlexBox direction="row" gap="10px">
            <FormikField
              label={`${intl.formatMessage({ id: "hits" })}`}
              name="hits"
              isSmallLabel={true}
              submit={form.handleChange}
              value={sumBy(form.values?.prizes || [], (prize: any) => prize?.qty || 0).toString()}
              {...{ form }}
              type='number'
              disabled={true}
              placeholder={intl.formatMessage({ id: "hits" })}
            />
            <FormikField
              label={`${intl.formatMessage({ id: "misses" })}`}
              name="misses"
              isSmallLabel={true}
              submit={form.handleChange}
              value={form.values.misses}
              {...{ form }}
              type='number'
              placeholder={intl.formatMessage({ id: "misses" })}
            />
          </FlexBox>
        </AdditionalItem>
      </div>
      <GridBox columns="1fr 2fr" cg="10px">
        <UiButton
          type="button"
          buttonType="white"
          onClick={toggleModal}
          disabled={form.isSubmitting || !id}
          className={classes.deleteButton}
        >
          {intl.formatMessage({ id: "delete" })}
        </UiButton>
        <UiButton
          buttonType="black"
          onClick={submitHandler}
          disabled={form.isSubmitting}
          className={classes.saveButton}
        >
          {intl.formatMessage({ id: "save" })}
        </UiButton>
      </GridBox>
      {isDeleteCampaignModalShow && (
        <ConfirmModal
          toggleModal={toggleModal}
          submit={() => removeCampaignHandler(id)}
          title="Are you sure?"
        />
      )}
    </div>
  );
};
