import React, { useState } from "react";
import { useIntl } from "react-intl";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal } from "../modals";
import {
  FormikColorField,
  FormikField,
} from "../../../../components/wilson-ui/inputs";
import { AuthorAvatar } from "../author-avatar";
import { axiosAuthorized } from "../../../../axios";
import { SwipeUpUrl } from "../swipe-up-url";
import { FileType } from "../../../../types";
import image from "../../../../assets/images/storyAssets.png";
import { Accordion } from "../accordion";
import { FavoriteThumbnail } from "../favorite-thumbnail";
import { LockerAssets } from "../locker-assets";

const NAMES = ["Author Avatar", "Author Name", "Swipe to URL"];

interface IProps {
  form: Record<string, any>;
}

export const SettingStory: React.FC<IProps> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);
  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "story_preview" })}
      nameArray={NAMES}
      image={image}
      maxHeight="375px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  const sendData = async (file: FileType) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "widgets/detailsMedia",
        formData
      );
      form.setFieldValue("storyAuthorAvatar", data.data.detailsMedia);
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <>
      <div>
        <Accordion
          summary={
            <FlexBox
              items="center"
              gap="10px"
              className={classes.headerContainer}
            >
              <h3>{intl.formatMessage({ id: "story_assets" })}</h3>
              <QuestionIcon submit={toggleModal} />
            </FlexBox>
          }
        >
          <FlexBox
            className={classes.accordionBody}
            direction="column"
          >
            <FlexBox direction="column" gap="30px">
              <GridBox cg="30px" rg="30px">
                <GridBox columns="1fr 1fr 1fr" cg="30px" rg="30px">
                  <FormikField
                    label={intl.formatMessage({ id: "author_name" })}
                    name="storyAuthorName"
                    submit={form.handleChange}
                    value={form.values.storyAuthorName}
                    {...{ form }}
                    placeholder={intl.formatMessage({ id: "add_author_name" })}
                  />
                  <FormikColorField
                    label={intl.formatMessage({ id: "author_name_color" })}
                    name="storyAuthorNameColor"
                    value={form.values.storyAuthorNameColor}
                    {...{ form }}
                  />
                  <AuthorAvatar
                    sendData={sendData}
                    imagePreview={form.values.storyAuthorAvatarLink}
                  />
                </GridBox>
                <div />
              </GridBox>
            </FlexBox>
            <SwipeUpUrl {...{ form }} />
          </FlexBox>
        </Accordion>
        {modal}
      </div>
      <LockerAssets {...{ form }} />
      <FavoriteThumbnail {...{ form }} />
    </>
  );
};
