import { createContext } from "react";

type loginNoopType = (jwtToken: string) => void;
type logoutNoopType = () => void;

const noop: loginNoopType = () => {
  return undefined;
};

const noopLogout: logoutNoopType = () => {
  return undefined;
};

const defaultTokenValue: any = null;

export const AuthContext = createContext({
  token: defaultTokenValue,
  login: noop,
  logout: noopLogout,
});
