import { axiosAuthorized } from "../../axios";
import { WidgetStatus, WidgetStatusForCreateWidget } from "../../types";

export const downloadWidgetsCSV = async (
  widgetsId: string[],
  fieldName: string,
  order: string | null,
  filteringType: Array<string>
): Promise<Array<Array<any>> | string> => {
  let downloadData: any = [];

  try {
    filteringType = filteringType.map((item) => item.toUpperCase());
    const { data } = await axiosAuthorized.post(
      process.env.REACT_APP_API_URL + `widgets/exportCsv`,
      {
        widgetsId,
        fieldName: fieldName || "widgetPriority",
        order: order?.toUpperCase(),
        filteringType,
      }
    );
    downloadData = data ?? "";
  } catch (error) {
    console.warn(error);
  }

  return downloadData;
};

export const getWidgetsByString = async (
  filterString: string
): Promise<any> => {
  let widgets: Array<Record<string, string>> = [];

  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `widgets/select?filterValue=${filterString}`
    );
    const widgetsData: Array<Record<string, any>> = data.data;
    const testArray = widgetsData.map((item) => item.title);
    const uniq = [...new Set(testArray)];

    widgets = uniq.map((item: string) => {
      return { title: item };
    });
  } catch (error) {
    console.warn(error);
  }

  return widgets;
};

export const getFullWidgetsByString = async (
  filterString: string
): Promise<any> => {
  let widgets: Array<Record<string, string>> = [];

  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `widgets/select?filterValue=${filterString}`
    );
    const widgetsData: Array<Record<string, any>> = data.data;

    widgets = widgetsData;
  } catch (error) {
    console.warn(error);
  }

  return widgets;
};

export const getFullWidgetsFeedByString = async (
  filterString: string,
  isFeed: boolean
): Promise<any> => {
  let widgets: Array<Record<string, string>> = [];

  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `widgets/select?filterValue=${filterString}&isFeed=${isFeed}`
    );
    const widgetsData: Array<Record<string, any>> = data.data;

    widgets = widgetsData;
  } catch (error) {
    console.warn(error);
  }

  return widgets;
};

export const getFullCampaignsByString = async (term: string): Promise<any> => {
  try {
    const data: any = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL +
        `campaigns/all?limit=5&pageNumber=1&term=${term}&isAvailable=true`
    );

    return data.data?.campaigns || [];
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export const setWidgetStoryImage = async (
  file: string | Blob,
  type: string
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  const data: Record<string, any> = await axiosAuthorized.post(
    process.env.REACT_APP_API_URL +
      (type === "video" ? "widgets/storyVideo" : "widgets/story"),
    formData
  );

  return data.data.storyAssetUrl;
};

export const setWidgetFeedMedia = async (
  file: string | Blob,
  type: string
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  const data: Record<string, any> = await axiosAuthorized.post(
    process.env.REACT_APP_API_URL +
      (type === "video" ? "widgets/feedMediaVideo" : "widgets/feedMedia"),
    formData
  );

  return data.data.feedMedia;
};

export const sendWidgetPromotionModalImage = async (
  file: string | Blob
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  let promotionModalImage = "";

  try {
    const data: Record<string, any> = await axiosAuthorized.post(
      process.env.REACT_APP_API_URL + "promotions/promotionCollaborationImage",
      formData
    );

    promotionModalImage = data.data.promotionMedia;
  } catch (e) {
    console.warn(e);
  }

  return promotionModalImage;
};

export const sendWidgetPromotionCollaborationImage = async (
  file: string | Blob
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  let promotionCollaborationImage = "";

  try {
    const data: Record<string, any> = await axiosAuthorized.post(
      process.env.REACT_APP_API_URL + "promotions/promotionCollaborationImage",
      formData
    );

    promotionCollaborationImage = data.data.promotionMedia;
  } catch (e) {
    console.warn(e);
  }

  return promotionCollaborationImage;
};

export const sendWidgetPromotionModalBackgroundImage = async (
  file: string | Blob
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  let promotionImage = "";

  try {
    const data: Record<string, any> = await axiosAuthorized.post(
      process.env.REACT_APP_API_URL + "promotions/promotionModalBackgroundImage",
      formData
    );

    promotionImage = data.data.promotionMedia;
  } catch (e) {
    console.warn(e);
  }

  return promotionImage;
};

export const sendWidgetPromotionImage = async (
  file: string | Blob
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  let promotionImage = "";

  try {
    const data: Record<string, any> = await axiosAuthorized.post(
      process.env.REACT_APP_API_URL + "promotions/promotionImage",
      formData
    );

    promotionImage = data.data.promotionMedia;
  } catch (e) {
    console.warn(e);
  }

  return promotionImage;
};

export const sendOverrideImage = async (
  file: string | Blob,
  type: string
): Promise<Record<string, any>> => {
  const formData = new FormData();
  formData.append("file", file);

  let resutl = null;

  try {
    const data: Record<string, any> = await axiosAuthorized.post(
      process.env.REACT_APP_API_URL +
        (type === "video" ? "widgets/overrideVideo" : "widgets/overrideImage"),
      formData
    );

    resutl = data.data;
  } catch (e) {
    console.warn(e);
  }

  return resutl;
};

export const getChannels = async (): Promise<Array<Record<string, any>>> => {
  let channels: Array<Record<string, any>> = [];

  try {
    const data: Record<string, any> = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL + "channels/v2"
    );
    channels = data.data;
  } catch (error) {
    console.warn(error);
  }

  return channels;
};

export const downloadWidgetsFullListCSV = async (): Promise<string> => {
  let downloadData: any = [];

  try {
    const { data } = await axiosAuthorized.post(
      process.env.REACT_APP_API_URL + "widgets/exportCsv",
      {
        fieldName: "title",
        order: "ASC",
      }
    );
    downloadData = data ?? "";
  } catch (error) {
    console.warn(error);
  }

  return downloadData;
};

export const getWidget = async (id: string): Promise<Record<string, any>> => {
  let widget = {};

  try {
    const data = await axiosAuthorized.get(
      process.env.REACT_APP_API_URL + `widgets/${id}/cms/v2`
    );
    widget = data.data;
  } catch (error) {
    console.warn(error);
  }

  return widget;
};

export const removeWidget = async (id: string): Promise<boolean> => {
  let isRemove;

  try {
    await axiosAuthorized.delete(
      process.env.REACT_APP_API_URL + `widgets/${id}`
    );
    isRemove = true;
  } catch (error) {
    console.warn(error);
    isRemove = false;
  }

  return isRemove;
};

export const editWidget = async (
  widget: Record<string, any>
): Promise<boolean> => {
  let isEdited = false;

  try {
    await axiosAuthorized.patch(
      process.env.REACT_APP_API_URL + "widgets/" + widget.id + "/v2",
      {
        ...widget,
        status:
          widget.status === WidgetStatus.hidden
            ? WidgetStatus.hidden
            : widget.status === WidgetStatusForCreateWidget.onTheBench ||
              widget.status === WidgetStatus.onTheBench
            ? WidgetStatus.onTheBench
            : WidgetStatus.itsAGameTime,
      }
    );
    isEdited = true;
  } catch (error) {
    console.warn(error);
  }

  return isEdited;
};

export const sendWidget = async (
  widget: Record<string, any>
): Promise<boolean> => {
  let isAdded;

  try {
    await axiosAuthorized.post(
      process.env.REACT_APP_API_URL + "widgets/post/v2",
      {
        ...widget,
        status:
          widget.status === WidgetStatus.hidden
            ? WidgetStatus.hidden
            : widget.status === WidgetStatusForCreateWidget.onTheBench ||
              widget.status === WidgetStatus.onTheBench
            ? WidgetStatus.onTheBench
            : WidgetStatus.itsAGameTime,
      }
    );
    isAdded = true;
  } catch (error) {
    isAdded = false;
    console.warn(error);
  }

  return isAdded;
};

export const setWidgetsPriority = async (
  widgets: Array<Record<string, any>>,
  pageNumber: number,
  widgetsPerPage: number
): Promise<boolean> => {
  const defaultIndex = (pageNumber - 1) * widgetsPerPage + 1;
  const newWidgetsData = widgets.map(({ id }, index) => ({
    id,
    widgetPriority: defaultIndex + index,
  }));
  let isChanged;

  try {
    await axiosAuthorized.put(
      process.env.REACT_APP_API_URL + "widgets/priority",
      { widgets: newWidgetsData }
    );
    isChanged = true;
  } catch (error) {
    isChanged = false;
    console.warn(error);
  }

  return isChanged;
};

export const sendCarouselData = async (
  carouselId: string,
  data: Array<Record<string, any>>,
  status: string,
  title: string
): Promise<void> => {
  const newData = data.map((item, index) => {
    return {
      ...item,
      id: item?.carouselId,
      carouselId: undefined,
      childId: item.id,
      ["priority"]: index,
    };
  });
  await axiosAuthorized.put(
    process.env.REACT_APP_API_URL + "widgets/carousel/" + carouselId + "/v2",
    {
      widgetsToAdd: newData,
      status,
      title,
    }
  );
};
