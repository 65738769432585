export const status: any = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

export const statusValue: any = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE'
}

export const categories: any = [
  'Baseball',
  'Basketball',
  'Fastpitch Softball',
  'Football',
  'Golf',
  'Padel',
  'Soccer',
  'Sportswear',
  'Tennis',
  'Volleyball',
];
