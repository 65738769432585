import React from "react";
import { FormikField } from "../../../../../components/wilson-ui/inputs";
import { FlexBox, GridBox } from "../../../../../components/wilson-ui/box";
import { useCreateWidgetsStyles } from "../../../create-widgets-style.hook";
import { useIntl } from "react-intl";
import { QuestionIcon } from "../../../../../components/icons";
// import { ItemQuestionModal } from "../../modals";
// import image from "../../../../../assets/images/testImage.png";
import { Accordion } from "../../accordion";

interface IGeneralSettings {
  form: Record<string, any>;
}

export const GeneralSettings: React.FC<IGeneralSettings> = ({ form }) => {
  // const [isModalTrue, setIsModalTrue] = useState<boolean>(false);
  const createWidgetClasses = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => false;

  // const modal = isModalTrue ? (
  //   <ItemQuestionModal
  //     title={intl.formatMessage({ id: "feed" })}
  //     image={image}
  //     maxHeight="400px"
  //     closeHandler={toggleModal}
  //   />
  // ) : (
  //   <></>
  // );

  return (
    <div>
      <Accordion
        className={createWidgetClasses.generalSettingsAccordion}
        summary={
          <FlexBox className={createWidgetClasses.headerContainer}>
            <h3>{intl.formatMessage({ id: "general_setting" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox columns="1fr" cg="30px" rg="30px" items="end">
            <FormikField
              label={`${intl.formatMessage({ id: "name" })}*`}
              name="name"
              submit={form.handleChange}
              value={form.values.name}
              {...{ form }}
              placeholder={intl.formatMessage({ id: "campaign_name" })}
            />
          </GridBox>
          {/* <GridBox columns="1fr" cg="30px" rg="30px" items="end">
            <FormikField
              label={intl.formatMessage({ id: "website_url" })}
              name="webViewUrl"
              submit={form.handleChange}
              value={form.values.webViewUrl}
              {...{ form }}
              placeholder={intl.formatMessage({ id: "enter_website_url" })}
            />
          </GridBox> */}
        </GridBox>
      </Accordion>
      {/* {modal} */}
    </div>
  );
};
