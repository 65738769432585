import { makeStyles } from "@material-ui/core";
// import { baseColors } from "../../../../wilson-ui/constants/colors";

export const useChannelMoreButtonStyle = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  more: {
    width: "24px",
    height: "24px",
    border: "2px solid #000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
