import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { DefaultInput } from "./index";
import { useInputStyles } from "./inputs-styles.hook";
import { MaterialFieldType } from "../types/material-field.type";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  fontWeight: "bold",
  border: "2px solid #000",
  borderRadius: 0,
  lineHeight: 1.5,
  backgroundColor: "#fff",
  color: "#000",
  marginBottom: "4px",
  cursor: "pointer",
  fontFamily: "GT America, sans-serif",
  "&:hover": {
    backgroundColor: "#eee",
    border: "2px solid #000",
  },
});
interface ISearchInput {
  label?: string;
  name?: string;
  value: string;
  setLoading?: (isLoading: boolean) => void;
  submit: (term: string) => void;
  variant?: "filled" | "standard" | "outlined" | undefined;
  multiline?: boolean;
}

export const SearchInput: React.FC<ISearchInput> = ({
  label,
  name,
  value,
  submit,
  setLoading,
  variant,
  multiline,
}) => {
  const [term, setTerm] = useState(value);
  const [threadTimeOut, setThreadTimeOut] = useState(0);
  const classes = useInputStyles();
  const fieldVariant: MaterialFieldType = variant ? variant : "standard";
  const onLoadingTable = (isLoading: boolean) => {
    setLoading && setLoading(isLoading);
  };
  const onChange = (evt: any) => {
    onLoadingTable(true);
    const searValue = evt.target.value;
    if (searValue === term) {
      return;
    }
    setTerm(searValue);
    callSearch(searValue);
  };

  const onSubmit = () => {
    submit(term);
  };
  const callSearch = (term: string) => {
    if (threadTimeOut) {
      clearTimeout(threadTimeOut);
    }
    const timer: any = setTimeout(function () {
      submit(term);
      onLoadingTable(false);
    }, 1000);
    setThreadTimeOut(timer);
  };

  const onKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      submit(term);
      if (threadTimeOut) {
        clearTimeout(threadTimeOut);
      }
    }
  };

  return (
    <div style={{ width: "30%" }}>
      {label && <span className={classes.fieldLabel}>{label}</span>}
      <DefaultInput
        multiline={multiline}
        fullWidth
        variant={fieldVariant}
        value={term}
        onChange={onChange}
        name={name}
        placeholder="Search"
        onKeyDown={onKeyDown}
        InputProps={{
          endAdornment: (
            <BootstrapButton variant="outlined" onClick={onSubmit}>
              Search
            </BootstrapButton>
          ),
        }}
      />
    </div>
  );
};
