import React, { useState } from "react";
import { FormikColorField } from "../../../../components/wilson-ui/inputs/formik-color-field";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { RadioCheckboxRed } from "../../../../components/wilson-ui/checkboxes/radio-checkbox-red";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { useIntl } from "react-intl";
import { DropzoneImageMP4 } from "../../../../components/wilson-ui/dropzone";
import { axiosAuthorized } from "../../../../axios";
import { QuestionIcon } from "../../../../components/icons";
import { ItemQuestionModal } from "../modals";
import image from "../../../../assets/images/testImage.png";
import { Accordion } from "../accordion";
import { TitleSizeSelect } from "../title-size-select";
import { EMPTY_FUNCTION } from "../../../../constants";

interface IGeneralSettings {
  form: Record<string, any>;
}

export const AlertAssets: React.FC<IGeneralSettings> = ({ form }) => {
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);
  const classes = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const sendData = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "widgets/fullscreenAlertMedia",
        formData
      );

      form.setFieldValue("alertMediaUrl", data.data.alertMedia);
      form.setFieldValue("alertMediaLink", data.data.alertMedia);
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };

  const modal = isModalTrue ? (
    <ItemQuestionModal
      title={intl.formatMessage({ id: "fullscreen_alert" })}
      image={image}
      maxHeight="400px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  return (
    <div>
      <Accordion
        summary={
          <FlexBox
            items="center"
            gap="10px"
            className={classes.headerContainer}
          >
            <h3>{intl.formatMessage({ id: "fullscreen_alert_assets" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox
            columns="1fr 1fr 1fr"
            rg="15px"
            cg="25px"
            className={classes.radioButtonContainer}
          >
            <RadioCheckboxRed
              value={form.values.alertTitleVisibility}
              label={intl.formatMessage({
                id: "title_visibility",
              })}
              submit={(event, value) =>
                form.setFieldValue("alertTitleVisibility", value)
              }
              name="alertTitleVisibility"
            />
            <RadioCheckboxRed
              value={form.values.alertHasCountdown}
              label={intl.formatMessage({ id: "countdown_time" })}
              submit={(event, value) =>
                form.setFieldValue("alertHasCountdown", value)
              }
              name="alertHasCountdown"
            />
            <RadioCheckboxRed
              value={form.values.alertCanBeShared}
              label={intl.formatMessage({ id: "share_icon" })}
              labelFirst={true}
              submit={(event, value) =>
                form.setFieldValue("alertCanBeShared", value)
              }
              name="alertCanBeShared"
            />
          </GridBox>
          <GridBox columns="1fr 1fr 1fr" cg="30px" rg="30px" items="center">
            <FormikColorField
              label={intl.formatMessage({ id: "title_color" })}
              name="alertTitleColor"
              value={form.values.alertTitleColor}
              {...{ form }}
            />
            <TitleSizeSelect
              label="Title Size"
              value={form.values.alertTitleSize}
              submit={(value) => form.setFieldValue("alertTitleSize", value)}
              {...{ form }}
            />
            <FormikColorField
              label={intl.formatMessage({ id: "title_highlight_color" })}
              name="alertTitleHighlightColor"
              value={form.values.alertTitleHighlightColor}
              {...{ form }}
            />
          </GridBox>
          <GridBox columns="2fr 2fr" cg="30px" rg="30px">
            <FormikColorField
              label={intl.formatMessage({ id: "button_text_color" })}
              name="alertButtonTextColor"
              value={form.values.alertButtonTextColor}
              {...{ form }}
            />
            <FormikColorField
              label={intl.formatMessage({ id: "button_fill_color" })}
              name="alertButtonColor"
              value={form.values.alertButtonColor}
              {...{ form }}
            />
          </GridBox>
          <GridBox columns="2fr 2fr" cg="30px" rg="30px">
            <FormikColorField
              label={intl.formatMessage({ id: "border_color" })}
              name="alertBorderColor"
              value={form.values.alertBorderColor}
              {...{ form }}
            />
            <FormikColorField
              label={intl.formatMessage({ id: "timer_color" })}
              name="alertCountdownTimerColor"
              value={form.values.alertCountdownTimerColor}
              {...{ form }}
            />
          </GridBox>
          <GridBox columns="1fr" cg="30px" rg="30px">
            <DropzoneImageMP4
              {...{ form, sendData }}
              name="alertMediaUrl"
              filePreview={form.values.alertMediaLink}
            />
          </GridBox>
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
