import React, { FC } from "react";
import { GridBox } from "../../../../components/wilson-ui/box";
import { useCarouselListHeaderStyle } from "./carousel-list-header-style.hook";

export const CarouselListHeader: FC = () => {
  const classes = useCarouselListHeaderStyle();

  return (
    <GridBox className={classes.container}>
      <div className={classes.carouselColumn}>Title</div>
      <div className={classes.carouselColumn}>Feed Widget</div>
      <div className={classes.carouselColumn}>Widget Type</div>
    </GridBox>
  );
};
