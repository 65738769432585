import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormikField } from "../../../../components/wilson-ui/inputs";
import { useIntl } from "react-intl";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { FormikColorField } from "../../../../components/wilson-ui/inputs";
import { useAddCarouselStyles } from "./setting-carousel.hook";
import { Text } from "../../../../components/wilson-ui/text";
import { WidgetStatus } from "../../../../types";
import { FormikSelect } from "../../../../components/wilson-ui/selects";
import { UiButton } from "../../../../components/wilson-ui/buttons";
import {
  createNewCarouseRequest,
  editCarouseRequest,
} from "../../../../actions/carousel";
// import { toast } from "react-toastify";
// import CarouselStore from "../../../../store/carousel/corousel.store";

interface IProps {
  form: Record<string, any>;
  activeCarousel?: Record<string, any> | null;
}

export const SettingCarousel: React.FC<IProps> = ({ form, activeCarousel }) => {
  // const notify = (text: string) => toast(text);
  const history = useHistory();

  const intl = useIntl();
  const formInitialValues = {
    title: activeCarousel?.title || "",
    titleColor: activeCarousel?.titleColor || "",
    backgroundColor: activeCarousel?.backgroundColor || "",
    status: activeCarousel?.status || Object.values(WidgetStatus)[0],
    id: activeCarousel?.id || null,
  };

  const carouselForm = useFormik({
    initialValues: formInitialValues,
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Required"),
    }),
    onSubmit: (values) =>
      activeCarousel ? editCarouselHandler(values) : sendFormHandler(values),
  });

  const sendFormHandler = async (values: Record<string, any>) => {
    const isCreated = await createNewCarouseRequest(values);
    history.push("/create-carousel/" + isCreated.id);

    // if (isCreated) {
    //   await CarouselStore.getCarouselsDataRequest(page);
    // } else {
    //   notify("some error");
    // }
  };

  const editCarouselHandler = async (values: Record<string, any>) => {
    await editCarouseRequest(values);

    // if (isEdited) {
    //   await CarouselStore.getCarouselsDataRequest(page);
    //   CarouselStore.resetCarouselData();
    // } else {
    //   notify("some error");
    // }
  };

  const classes = useAddCarouselStyles();

  const submitButtonText = !activeCarousel
    ? intl.formatMessage({ id: "add" })
    : intl.formatMessage({ id: "save" });

  const titleText = !activeCarousel
    ? intl.formatMessage({ id: "add_carousel" })
    : intl.formatMessage({ id: "edit_carousel" });

  return (
    <div className={classes.container}>
      <GridBox rg="30px">
        <Text fontSize={"32px"}>{titleText}</Text>

        <FormikField
          label={intl.formatMessage({ id: "title" })}
          name="title"
          submit={carouselForm.handleChange}
          placeholder={intl.formatMessage({ id: "title" })}
          value={carouselForm.values.title}
          form={carouselForm}
        />

        <GridBox columns="1fr 1fr" cg="30px" rg="30px">
          <FormikColorField
            label={intl.formatMessage({ id: "title_color" })}
            name="titleColor"
            value={carouselForm.values.titleColor}
            form={carouselForm}
          />
          <FormikColorField
            label={intl.formatMessage({ id: "background_color" })}
            name="backgroundColor"
            value={carouselForm.values.backgroundColor}
            form={carouselForm}
          />
        </GridBox>

        <FormikSelect
          form={carouselForm}
          name="status"
          value={carouselForm.values.status}
          options={Object.values(WidgetStatus)}
          placeholder={intl.formatMessage({ id: "set_status" })}
        />

        <FlexBox content="flex-end" cg="20px">
          <UiButton
            buttonType="white"
            style={{ marginRight: 10 }}
            onClick={() => form.setFieldValue("type", "")}
          >
            {intl.formatMessage({ id: "cancel" })}
          </UiButton>
          <UiButton
            buttonType="black"
            onClick={() => carouselForm.submitForm()}
          >
            {submitButtonText}
          </UiButton>
        </FlexBox>
      </GridBox>
    </div>
  );
};
