import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { useIntl } from "react-intl";
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import {
  FormikField,
  FormikColorField
} from "../../../../components/wilson-ui/inputs";
import { useGeneralSettingsStyles } from "./location-settings-styles.hook";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { Dropzone } from "../../../../components/wilson-ui/dropzone";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { axiosAuthorized } from "../../../../axios";
import { QuestionIcon } from "../../../../components/icons";
import { LocationQuestionModal } from "../modals";
import image1 from "../../../../assets/images/locationInfoAssets1.png";
import image2 from "../../../../assets/images/locationInfoAssets2.png";
import { Accordion } from "../accordion";
import { EMPTY_FUNCTION } from "../../../../constants";
import { placeToAddress } from "../../../../helpers/widgets/place.helper"

interface IGeneralSettings {
  form: Record<string, any>;
}

export const LocationSettings: React.FC<IGeneralSettings> = ({ form }) => {
  const inputRef = useRef(null);
  const [location, setLocation] = useState<Record<any, string>>();
  const [isModalTrue, setIsModalTrue] = useState<boolean>(false);
  const [threadTimeOut, setThreadTimeOut] = useState<any>();

  const classes = useGeneralSettingsStyles();
  const createWidgetClasses = useCreateWidgetsStyles();
  const intl = useIntl();

  const toggleModal = () => setIsModalTrue(!isModalTrue);

  const modal = isModalTrue ? (
    <LocationQuestionModal
      title={intl.formatMessage({ id: "location_info" })}
      image1={image1}
      image2={image2}
      maxHeight="400px"
      closeHandler={toggleModal}
    />
  ) : (
    <></>
  );

  const sendData = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const data: Record<string, any> = await axiosAuthorized.post(
        process.env.REACT_APP_API_URL + "widgets/locationMedia",
        formData
      );
      form.setFieldValue("locationMediaUrl", data.data.locationMedia);
    } catch (e) {
      EMPTY_FUNCTION();
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      (inputRef.current as any).name = "address"; // Set name attribute
    }
    setLocation({
      address: form.values.address,
      location: form.values.location,
      lat: form.values.lat,
      lng: form.values.lng
    });
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (threadTimeOut) {
      clearTimeout(threadTimeOut);
    }
    setThreadTimeOut(
      setTimeout(() => {
        if (location?.address !== value ) {
          form.setFieldValue("address", value);
          form.setFieldValue("location", undefined);
          form.setFieldValue("lat", undefined);
          form.setFieldValue("lng", undefined);
        } else {
          form.setFieldValue("address", location?.address);
          form.setFieldValue("location", location?.location);
          form.setFieldValue("lat", location?.lat);
          form.setFieldValue("lng", location?.lng);
        }
      }, 1000)
    );
  };

  const errorAddress =
    (form?.submitCount && form.errors.address) ||
    (form.touched.address && form.values.address && (form.errors.lat || form.errors.lng));

  return (
    <div>
      <Accordion
        className={createWidgetClasses.generalSettingsAccordion}
        summary={
          <FlexBox className={createWidgetClasses.headerContainer}>
            <h3>{intl.formatMessage({ id: "location_info" })}</h3>
            <QuestionIcon submit={toggleModal} />
          </FlexBox>
        }
      >
        <GridBox cg="30px" rg="30px">
          <GridBox
            columns="2fr 2fr"
            cg="30px"
            rg="30px"
            items="end"
            className={`${classes.containerAddressInput} ${
              errorAddress ? classes.containerAddressInputError : ""
            }`}
          >
            <div>
              <h4>{intl.formatMessage({ id: "address" })}</h4>
              <ReactGoogleAutocomplete
                ref={inputRef}
                apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                options={{
                  types: [],
                  componentRestrictions: { country: ["us", "in", "vn"] },
                }}
                onPlaceSelected={(place) => {
                  const location = placeToAddress(place);
                  form.setFieldValue("address", place.formatted_address);
                  form.setFieldValue("location", location);
                  form.setFieldValue("lat", location?.latitude);
                  form.setFieldValue("lng", location?.longitude);
                }}
                placeholder="Enter your address"
                onBlur={form.handleBlur}
                onChange={handleChange}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  return false;
                }}
                inputAutocompleteValue={form.values.address}
                defaultValue={form.values.address}
              />
              <div className={classes.errorText}>
                {errorAddress
                  ? intl.formatMessage({
                      id:
                        form?.submitCount && form.errors.address
                          ? form.errors.address
                          : form.errors.lat || form.errors.lng,
                    })
                  : ""}
              </div>
            </div>
            <FormikField
              label={`${intl.formatMessage({ id: "radius" })}`}
              name="radius"
              type="number"
              submit={form.handleChange}
              value={form.values.radius}
              {...{ form }}
              placeholder={intl.formatMessage({ id: "add_radius" })}
            />
          </GridBox>
          <GridBox columns="1fr" cg="30px" rg="30px" items="end">
            <FormikField
              label={`${intl.formatMessage({ id: "location_alias" })}`}
              name="locationAlias"
              submit={form.handleChange}
              value={form.values.locationAlias}
              {...{ form }}
              placeholder={intl.formatMessage({ id: "add_location_alias" })}
            />
          </GridBox>
          <GridBox rg="30px" cg="30px" columns="1fr 1fr 1fr">
            <FormikField
              label={intl.formatMessage({ id: "button_primary" })}
              name="locationButtonText"
              submit={form.handleChange}
              value={form.values.locationButtonText}
              {...{ form }}
              placeholder={intl.formatMessage({ id: "add_button_text" })}
            />
            <FormikColorField
              label={intl.formatMessage({ id: "button_color" })}
              name="locationButtonColor"
              value={form.values.locationButtonColor}
              {...{ form }}
            />
            <FormikColorField
              label={intl.formatMessage({ id: "button_text_color" })}
              name="locationButtonTextColor"
              value={form.values.locationButtonTextColor}
              {...{ form }}
            />
          </GridBox>
          <GridBox rg="30px" cg="30px" columns="1fr 1fr">
            <FormikColorField
              label={intl.formatMessage({ id: "success_background_color" })}
              name="locationSuccessBackgroundColor"
              value={form.values.locationSuccessBackgroundColor}
              {...{ form }}
            />
            <FormikColorField
              label={intl.formatMessage({ id: "post_redemption_button_color" })}
              name="locationButtonPostRedemptionColor"
              value={form.values.locationButtonPostRedemptionColor}
              {...{ form }}
            />
          </GridBox>
          <Dropzone
            {...{ form, sendData }}
            name="locationMediaUrl"
            imagePreview={form.values.locationMediaLink}
          />
        </GridBox>
      </Accordion>
      {modal}
    </div>
  );
};
