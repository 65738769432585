import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: pink[600],
    '&:hover': {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: pink[600],
  },
}));

interface IProps {
  value: boolean;
  onChange: (payload: Record<string, any>) => void;
}

export const MaterialSwitch: React.FC<IProps> = ({
  value,
  onChange,
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleOnChange = () => {
    onChange({ isDropExclusive: !checked });
    setChecked(!checked);
  }
  return (
      <PinkSwitch
        onChange={handleOnChange}
        checked={checked}
      />
  );
};
