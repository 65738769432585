import { makeStyles } from "@material-ui/core";

export const useModalStyle = makeStyles(() => ({
  wrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: " rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: "0",
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 1000,
    padding: "20px",
  },
  container: {
    background: "#f3f3f3",
    width: "100%",
    maxWidth: "680px",
    minHeight: "400px",
    maxHeight: "80vh",
    overflowY: "scroll",
    borderRadius: "12px",
    padding: "24px",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));
