export enum WidgetTypeEnum {
  POST = "POST",
  STORY = "STORY",
  EXTERNAL_LINK = "EXTERNAL LINK",
  CAROUSEL = "CAROUSEL",
  FEATURED_CAROUSEL = "FEATURED CAROUSEL",
  DROP = "DROP",
  PROMOTION = "PROMOTION",
  INSTANT_WIN = 'INSTANT WIN',
  LOCATION_BASED = 'LOCATION BASED',
}

export enum InterestTypeEnum {
  OTHER = 'OTHER',
  TRENDING = 'TRENDING',
}

export const InterestTypeOptions = ['Trending', 'Other'];

export const InterestTypeValue: any = {
  Trending: 'TRENDING',
  Other: 'OTHER'
};

export const InterestValueType: any = {
  TRENDING: 'Trending',
  OTHER: 'Other'
};

export enum CampaignTypeEnum {
  CAMPAIGN = 'CAMPAIGN'
}

export const CampaignTypeOptions = ['Campaign'];

export const CampaignTypeValue: any = {
  Campaign: 'CAMPAIGN'
};

export const CampaignValueType: any = {
  CAMPAIGN: 'Campaign'
};

export enum BackgroundTypeEnum {
  COLOR = "COLOR",
  IMAGE = "IMAGE",
}

export enum ContainerTypeEnum {
  LIGHT = "LIGHT",
  DARK = "DARK",
}

export enum ContainerTypeValuesEnum {
  LIGHT = "Light",
  DARK = "Dark",
}

export enum PrizeType {
  PROMOTION = 'PROMOTION',
  PHYSICAL = 'PHYSICAL',
  DIGITAL = 'DIGITAL'
}

export const prizeTypesOptions = [
  { label: 'Promotion Code', value: 'PROMOTION', name: 'upload_offer_codes', noId: true },
  { label: 'Physical Good', value: 'PHYSICAL', name: 'physical_good', noId: true },
  // { label: 'Digital Good', value: 'DIGITAL', name: 'digital_good', noId: true },
  // { label: 'Experience', value: 'DIGITAL', name: 'digital_good' },
]

export enum WidgetFeedTitleFont {
  GT_AMERICA = 'GT America',
  ARGENT_CF = 'Argent CF',
}
