import React from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useRoutes } from "./routes";
import { useAuth } from "./hooks";
import { AuthContext } from "./contexts/auth-context";
import { LOCALES } from "../I18n/locales";
import { messages } from "../I18n/messages";
import { useLanguage } from "./hooks/use-language-hook";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import {ToastContainer} from "react-toastify";

export const App = observer(() => {
  const { login, logout, token, isFirstRenderEnd } = useAuth();
  const routes = useRoutes(!!token, isFirstRenderEnd);
  const { getLanguage } = useLanguage();
  const chosenLanguage = getLanguage();
  const locale = chosenLanguage ? chosenLanguage : LOCALES.ENGLISH;

  return (
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale="en"
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthContext.Provider
            value={{
              token,
              logout,
              login,
            }}
          >
            <BrowserRouter>{routes}</BrowserRouter>
            <ToastContainer
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
            />
          </AuthContext.Provider>
        </LocalizationProvider>
      </MuiPickersUtilsProvider>
    </IntlProvider>
  );
});
