import React, { useState } from "react";
import { useNavBarStyles } from "./navbar-styles.hook";
import { NavbarList } from "./components/navbar-list";
import image from "../../assets/images/wilson_logo-red.png";
import { MenuBtn } from "./components/navbar-btn";
import { MobileMenu } from "./components/mobile-menu";
import { FlexBox } from "../wilson-ui/box";
import { NavbarModal } from "./components/navbar-modal";
import SettingsIcon from "@mui/icons-material/Settings";

export const Navbar: React.FC = () => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const classes = useNavBarStyles();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const modal = isModalOpen ? (
    <NavbarModal closeHandler={toggleModal} />
  ) : (
    <></>
  );

  return (
    <>
      <MobileMenu isMobileMenuActive={isMobileMenuActive} />
      <div className={classes.container}>
        <div>
          <img src={image} alt="wilson logo" />
        </div>
        <FlexBox items="center" gap="30px">
          <div className={classes.list}>
            <NavbarList />
          </div>
          <SettingsIcon onClick={toggleModal} className={classes.icon} />
          <div className={classes.button}>
            <MenuBtn
              isActive={isMobileMenuActive}
              setIsMobileActiveHandler={() =>
                setIsMobileMenuActive(!isMobileMenuActive)
              }
            />
          </div>
        </FlexBox>
      </div>
      {modal}
    </>
  );
};
