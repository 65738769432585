import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../../components/wilson-ui/constants/colors";

export const useItemQuestionFooterStyles = makeStyles(() => ({
  container: {
    borderTop: `1px solid ${baseColors.black}`,
    padding: "0px 20px",
  },
  itemContainer: {
    width: "35px",
    height: "35px",
    background: "#000",
    color: "#fff",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
}));
