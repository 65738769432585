import React from "react";
import { makeStyles } from "@material-ui/core";
import { baseColors } from "../constants/colors/base-colors";

const useStyles = makeStyles(() => ({
  button: {
    padding: "14px 23px",
    // borderRadius: "25px",
    fontFamily: "'GT America bold', sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    minWidth: "177px",
    cursor: "pointer",
    transition: "0.7s",
    border: "none",
  },
  black: {
    background: baseColors.black,
    color: baseColors.white,
    "&:hover": {
      background: baseColors.darkGreen,
    },
  },
  white: {
    background: baseColors.white,
    color: baseColors.black,
    border: `2px solid ${baseColors.black}`,
    "&:hover": {
      background: baseColors.grey,
    },
  },
  rect: {
    borderRadius: "0px",
    background: "transparent",
    border: "1px solid " + baseColors.black,
    color: baseColors.black,
    "&:hover": {
      background: baseColors.black,
      color: baseColors.white,
    },
  },
}));

interface IProps {
  onClick?: () => void;
  type?: "button" | "reset" | 'submit';
  buttonType: "button" | "black" | "white" | "rect";
  width?: string;
  disabled?: boolean;
  className?: string;
  style?: Record<string, any>;
}

const UiButton: React.FC<IProps> = ({
  children,
  buttonType,
  onClick,
  width,
  disabled,
  style,
  className = "",
  type
}) => {
  const classes = useStyles();
  const buttonWidth = width ? width : "auto";
  const opacity = disabled ? 0.5 : 1;

  return (
    <button
      type={type || "submit"}
      style={{ width: buttonWidth, opacity: opacity, ...style }}
      className={`${classes.button} ${classes[buttonType]} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export { UiButton };
