import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../constants/colors";

export const useTableBodyStyles = makeStyles(() => ({
  container: {
    display: "grid",
    alignItems: "center",
    borderBottom: `1px solid ${baseColors.grey}`,
    "& td": {
      borderBottom: "none",
    },
  },
}));
