import { axiosAuthorized } from "../axios";
import {getCarouselsArray} from "../actions/carousel";

const CarouselService = {
  async getCarousel(carouselId: string): Promise<any> {
    try {
      const data: Record<string, any> = await axiosAuthorized.get(
        process.env.REACT_APP_API_URL + "widgets/carousel/" + carouselId + "/v2"
      );
      const sortData = {
        ...data.data,
        childWidgets: data.data.childWidgets.map((item: any) => ({
          ...item,
          id: item?.carouselId,
          carouselId: undefined,
          childId: item.id,
        })),
      };
      return sortData;
    } catch (error) {
      throw new Error(error.response.data.message || error.message);
    }
  },

  async getCarouselsArray(page: number): Promise<any> {
    try {
      const data: Record<string, any> = await getCarouselsArray(page)

      return data;
    } catch (error) {
      console.warn(error)
    }
  },


};


export default CarouselService;
