import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FlexBox, GridBox } from "../../../../../components/wilson-ui/box";
import { itemQuestionModalStyles } from "./item-question-modal-styles.hook";
import { ItemQuestionFooter } from "../item-question-footer";

import { UiModal } from "../../../../../components/wilson-ui/modals";

interface IProps {
  title: string;
  nameArray?: Array<string>;
  image1: string;
  image2: string;
  maxHeight: string;
  closeHandler: () => void;
}

export const LocationQuestionModal: React.FC<IProps> = ({
  image1,
  image2,
  title,
  nameArray,
  closeHandler,
}) => {
  const [tab, setTab] = useState("Instructions");
  const classes = itemQuestionModalStyles();
  const titleArray: Array<string> = nameArray ? nameArray : [];
  const rows = nameArray ? "60px auto 60px" : "60px auto";

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newValue: "Instructions" | "Map"
  ) => {
    setTab(newValue);
  };
  return (
    <UiModal width="500px" isWhiteBackground={true} closeHandler={closeHandler}>
      <GridBox rows={rows}>
        <FlexBox className={classes.header}>
          <h4>{title}</h4>
        </FlexBox>
        <TabContext value={tab}>
          <Box>
            <TabList
              onChange={handleChangeTab}
              textColor="secondary"
              indicatorColor="secondary"
              classes={{ root: classes.tabsRoot }}
              aria-label="lab API tabs example"
            >
              <Tab
                label="Instructions"
                value="Instructions"
                classes={{ root: classes.tabRoot }}
              />
              <Tab
                label="Map"
                value="Map"
                classes={{ root: classes.tabRoot }}
              />
            </TabList>
            <TabPanel value="Instructions" classes={{ root: classes.tabPanel }}>
              <FlexBox content="center" className={classes.content}>
                <img src={image2} style={{ width: "100%" }} />
              </FlexBox>
            </TabPanel>
            <TabPanel value="Map" classes={{ root: classes.tabPanel }}>
              <FlexBox content="center" className={classes.content}>
                <img src={image1} style={{ width: "100%" }} />
              </FlexBox>
            </TabPanel>
          </Box>
        </TabContext>
        <ItemQuestionFooter titleArray={titleArray} />
      </GridBox>
    </UiModal>
  );
};
