import React, { useState, useEffect } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { MainLayout } from "../../components";
import { FlexBox, GridBox } from "../../components/wilson-ui/box";
import { AdditionalSettingsCampaign } from "./components/addition-settings-campaign";
import { Settings } from "./components/setting-campaign";
import { AddCampaignSchema } from "./add-campaigns-schema";
import { useCreateWidgetsStyles } from "./create-widgets-style.hook";
import ROUTING_CONFIG from "../../constants/routing-config/routing-config";
import { initialValuesCampaign } from "./initeal-values";
import { sendFormHelper } from "./helpers/send-campaign";
// import { CampaignTypeEnum } from "../../enum";
import { UpgradeCampaignToEditHelper } from "../../helpers/widgets/upgrade-widget-to-edit.helper";
import { Spiner } from "../../components/wilson-ui/spiner";
import { getCampaign } from "../../actions/campaigns";
import { usePageStyles } from "../pages-styles.hook";

export const CreateCampaignsPage: React.FC = () => {
  const intl = useIntl();
  const [currentCampaign, setCurrentCampaign] = useState({ name: "" });
  const [isFound, setIsFound] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstRenderEnd, setIsFirstRenderEnd] = useState(false);
  const classes = useCreateWidgetsStyles();
  const classesTitle = usePageStyles();
  const history = useHistory();
  const location: Record<string, any> = useLocation();

  const form = useFormik({
    initialValues: initialValuesCampaign,
    validationSchema: AddCampaignSchema,
    onSubmit: (_, { setSubmitting }) => {
      sendFormHandler({ setSubmitting });
    },
  });

  useEffect(() => {
    getCampaignHandler();
  }, []);

  useEffect(() => {
    if (isFirstRenderEnd) {
      form.setValues(form.values);
    } else {
      setIsFirstRenderEnd(true);
    }
  }, [form.values.type]);

  const notify = (isExists: boolean) =>
    toast(
      intl.formatMessage({
        id: isExists ? "error_campaign_exists" : "something_is_wrong",
      })
    );

  const sendFormHandler = async ({ setSubmitting }: any) => {
    const payload: any = { ...form.values };
    const id =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (id && form.values.name === currentCampaign?.name) {
      payload.name = undefined;
    }
    const res = await sendFormHelper(payload);
    const data = res.data;
    if (data.campaign) {
      history.push(ROUTING_CONFIG.campaigns);
    } else if (data.isExists) {
      notify(data.isExists);
      setSubmitting(false);
    } else notify(false);
  };

  const getCampaignHandler = async () => {
    const id =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (id) {
      const data = await getCampaign(id);

      if (data.id) {
        const campaignToSet: any = UpgradeCampaignToEditHelper(data);
        setCurrentCampaign(campaignToSet);
        form.setValues(campaignToSet);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsFound(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const body = isFound ? (
    isLoading ? (
      <Spiner />
    ) : (
      <FormikProvider value={form}>
        <Form>
          <GridBox columns="auto 312px" className={classes.container} cg="50px">
            <div>
              <h2 className={classesTitle.pageTitle}>
                {intl.formatMessage({ id: "campaigns_details" })}
              </h2>
              <Settings {...{ form }} />
            </div>
            <AdditionalSettingsCampaign {...{ form }} />
          </GridBox>
        </Form>
      </FormikProvider>
    )
  ) : (
    <FlexBox items="center" content="center">
      <h3 className={classes.errorTitle}>
        {intl.formatMessage({ id: "campaign_not_found" })}
      </h3>
    </FlexBox>
  );

  return <MainLayout>{body}</MainLayout>;
};
