import React from "react";
import { useIntl } from "react-intl";
import { GeneralSettings } from "../general-settings";
import { GeneralSettingsDrop } from "../general-settings-drop";
import { GeneralSettingsInstantWin} from "../general-settings-instant-win";
import { LocationSettings } from "../location-settings";
import { GeneralSettingsLocationBased } from "../general-settings-location-based";
import { FormikSelect } from "../../../../components/wilson-ui/selects";
import { SettingExternalLink } from "../settings-external_link";
import { SettingPost } from "../settings-post";
import { SettingPromotion } from "../settings-promotion";
import { SettingInstantWin } from "../settings-instant_win";
import { SettingStory } from "../setting-story";
import { SettingDrop } from "../setting-drop";
import { SettingLocationBased } from "../setting-location-based";
import { FeedAssets } from "../feed-assets";
import { schemas } from "../../create-widgets-schema-options";
import { SettingCarousel } from "../carousel/setting-carousel";
import { FlexBox, GridBox } from "../../../../components/wilson-ui/box";
import { WidgetTypeEnum } from "../../../../enum";
import { initialValues, initialValuesDrop, initialValuesInstantWin, initialValuesLocationBased } from "../../initeal-values";
import { useCreateWidgetsStyles } from "../../create-widgets-style.hook";
import { QuestionIcon } from "../../../../components/icons";
interface ISettings {
  form: Record<string, any>;
}

export const Settings: React.FC<ISettings> = ({ form }) => {
  const createWidgetClasses = useCreateWidgetsStyles();
  const intl = useIntl();
  const typeOptions = schemas.map((item) => item.name);
  const onSelectType = (value: any) => {
    const option = value.target;
    if (option.value === WidgetTypeEnum.DROP) {
      form.setValues({
        ...form.values,
        ...initialValuesDrop,
        type: WidgetTypeEnum.DROP,
      });
    } else if (option.value === WidgetTypeEnum.INSTANT_WIN) {
      form.setValues({
        ...form.values,
        ...initialValuesInstantWin,
        type: WidgetTypeEnum.INSTANT_WIN,
      });
    } else if (option.value === WidgetTypeEnum.LOCATION_BASED) {
      form.setValues({
        ...form.values,
        ...initialValuesLocationBased,
        type: WidgetTypeEnum.LOCATION_BASED,
      });
    } else {
      form.setValues({ ...form.values, ...initialValues, type: option.value });
    }
  };

  const toggleModal = () => {
    return;
  };

  const renderAdditionalSettings = () => {
    switch (form.values.type.toUpperCase()) {
      case WidgetTypeEnum.EXTERNAL_LINK:
        return <SettingExternalLink {...{ form }} />;
      case WidgetTypeEnum.POST:
        return <SettingPost {...{ form }} />;
      case WidgetTypeEnum.STORY:
        return <SettingStory {...{ form }} />;
      case WidgetTypeEnum.DROP:
        return <SettingDrop {...{ form }} />;
      case WidgetTypeEnum.PROMOTION:
        return <SettingPromotion {...{ form }} />;
      case WidgetTypeEnum.INSTANT_WIN:
        return <SettingInstantWin {...{ form }} />;
      case WidgetTypeEnum.LOCATION_BASED:
        return <SettingLocationBased {...{ form }} />;
      default:
        return <></>;
    }
  };

  const renderGeneralSetting = () => {
    switch (form.values.type.toUpperCase()) {
      case WidgetTypeEnum.DROP:
        return (
          <>
            <GeneralSettingsDrop {...{ form }} />
            <FeedAssets {...{ form }} />
          </>
        );
      case WidgetTypeEnum.INSTANT_WIN:
        return (
          <>
            <GeneralSettingsInstantWin {...{ form }} />
            <FeedAssets {...{ form }} />
          </>
        );
      case WidgetTypeEnum.LOCATION_BASED:
        return (
          <>
            <LocationSettings {...{ form }} />
            <GeneralSettingsLocationBased {...{ form }} />
            <FeedAssets {...{ form }} />
          </>
        );
      default:
        return (
          <>
            <GeneralSettings {...{ form }} />
            <FeedAssets {...{ form }} />
          </>
        );
    }
  };

  const renderForm = () => {
    switch (form.values.type.toUpperCase()) {
      case WidgetTypeEnum.FEATURED_CAROUSEL:
        return <SettingCarousel {...{ form }} />;
      default:
        return (
          <>
          {renderGeneralSetting()}
          {renderAdditionalSettings()}
        </>
        );
    }
  }

  return (
    <FlexBox
      direction="column"
      gap="70px"
      className={createWidgetClasses.sectionContainer}
    >
      <div style={{ padding: "0 16px" }}>
        <FlexBox
          items="center"
          gap="10px"
          marginBottom="30px"
          className={createWidgetClasses.headerContainer}
        >
          <h3>
            <span>{intl.formatMessage({ id: "widget" })}</span>
            <span>{intl.formatMessage({ id: "type" })}</span>
          </h3>
          <QuestionIcon submit={toggleModal} />
        </FlexBox>
        <GridBox columns="1fr 1fr" cg="30px" rg="30px">
          <FormikSelect
            {...{ form }}
            name="type"
            value={form.values.type}
            options={typeOptions}
            label={intl.formatMessage({ id: "type" })}
            placeholder={intl.formatMessage({ id: "select_a_type" })}
            type="outlined"
            showIcon={true}
            handleChange={onSelectType}
          />
        </GridBox>
      </div>
      {renderForm()}
    </FlexBox>
  );
};
