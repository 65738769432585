import { useIntl } from "react-intl";
import { ITableConfig } from "../../components/wilson-ui/types/table-config.inteface";

export const submissionsTableConfig = (): ITableConfig => {
  const intl = useIntl();

  return {
    rowColumns: "200px 200px 200px 190px 180px 230px",
    columns: [
      {
        name: "name",
        type: "text",
        title: intl.formatMessage({ id: "name" }),
        filterType: "text",
      },
      {
        name: "email",
        type: "component",
        componentName: "email",
        title: intl.formatMessage({ id: "email_address" }),
        filterType: "text",
      },
      {
        name: "title",
        type: "text",
        title: intl.formatMessage({ id: "feed_widget_title" }),
        filterType: "filter",
      },
      {
        name: "createdAt",
        type: "component",
        componentName: 'data',
        title: intl.formatMessage({ id: "submission_date" }),
        filterType: "data",
      },
      {
        name: "type",
        type: "text",
        title: intl.formatMessage({ id: "feed_widget_type" }),
        filterType: "filter",
      },
      {
        name: "winner",
        type: "component",
        componentName: "winner",
        title: intl.formatMessage({ id: "winner" }),
        filterType: "filter",
      },
    ],
  };
};
