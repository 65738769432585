import { ChangeEvent, Dispatch } from "react";
import { IWidgetTypeItem } from "../../../../types";

export const useChangeSort = (
  form: Record<string, any>,
  setForm: Dispatch<any>,
  setSortType: Dispatch<any>,
  event: ChangeEvent<HTMLInputElement>,
  value: boolean
): void => {
  setForm({
    ...form,
    [event.target.name]: { ...form[event.target.name], isChecked: value },
  });
  const newArray = Object.values(form)
    .filter((item: IWidgetTypeItem) =>
      item.name === event.target.name ? !item.isChecked : item.isChecked
    )
    .map((item: IWidgetTypeItem) => item.name);
  setSortType(newArray);
};
