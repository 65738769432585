import { makeStyles } from "@material-ui/core";

export const useWidgetsPageStyles = makeStyles(() => ({
  resetButton: {
    marginBottom: 20,
  },
  tabsRoot: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#CF102D !important",
    },
  },
  tabRoot: {
    "&.Mui-selected": {
      fontWeight: "bold",
      color: "#CF102D !important",
    },
  },
  tabPanel: {
    padding: "24px 0px !important",
  },
}));
