import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const Text = styled(Typography)<{
  fontColor?: string;
  fontSize?: string;
}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "32px")};
  color: ${(props) => props.fontColor && props.fontColor};
`;
