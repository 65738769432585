import React from "react";
import { useMobileMenuStyles } from "./mobile-menu-styles.hook";
import image from "../../../../assets/images/wilson_logo-red.png";
import { NavbarList } from "../navbar-list";
import { FlexBox } from "../../../wilson-ui/box";

interface IProps {
  isMobileMenuActive: boolean;
}

export const MobileMenu: React.FC<IProps> = ({ isMobileMenuActive }) => {
  const classes = useMobileMenuStyles();

  const menuPosition = isMobileMenuActive ? "0%" : "-100%";

  return (
    <div
      className={classes.container}
      style={{ transform: `translateX(${menuPosition})` }}
    >
      <FlexBox content="center">
        <img src={image} alt="wilson logo" />
      </FlexBox>
      <div>
        <NavbarList />
      </div>
    </div>
  );
};
