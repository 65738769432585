import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../constants/colors/base-colors";

export const useTableHeaderStyles = makeStyles(() => ({
  row: {
    display: "grid",
    borderBottom: "1px solid",
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
    "& h4": {
      marginRight: "10px",
      fontFamily: "'GT America', sans-serif",
      color: baseColors.black,
    },
    "& th": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  },
  column: {
    "& .MuiTableCell-root": {
      display: "flex",
      borderBottom: "none",
      "& h4": {
        marginRight: "10px",
      },
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
      },
      "& .MuiBox-root": {
        cursor: "pointer",
      },
    },
    transition: "1s",
    "&:hover": {
      opacity: 0.5,
    },
  },
}));
