import { Dispatch } from "react";

export const setSwipeField = (
  id: string,
  swipeUrl: string,
  hasSound: boolean,
  buttonStyle: string,
  imageMap: string,
  swipeState: Record<string, any>,
  setSwipeState: Dispatch<any>
): void => {
  const newSwipeState = swipeState.map((item: any) => {
    if (item.id === id) {
      item.swipeUrl = swipeUrl;
      item.imageMap = imageMap;
      if(buttonStyle) item.buttonStyle = buttonStyle;
      if(typeof hasSound === 'boolean') item.hasSound = hasSound;
    }
    return item;
  });
  setSwipeState(newSwipeState);
};
