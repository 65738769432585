import React, { ChangeEvent, useEffect } from "react";
import Switch from "@mui/material/Switch";

import { FlexBox } from "../box";
import { useCheckBoxesStyles } from "./checkboxes-styles.hook";

interface IProps {
  label: string;
  labelFirst?: boolean;
  value: boolean;
  submit: (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  name: string;
  disabled?: boolean;
  form?: Record<string, any>;
}

export const RadioCheckboxRed: React.FC<IProps> = ({
  label,
  labelFirst,
  value,
  submit,
  name,
  disabled,
  form,
}) => {
  const classes = useCheckBoxesStyles();
  const activeClass = value && classes.redSwitchActive;

  useEffect(() => {
    if (disabled && form) {
      form.setFieldValue(name, false);
    }
  }, [disabled]);

  return (
    <FlexBox items="center" gap="8px">
      <Switch
        onChange={submit}
        className={`${classes.redSwitch} ${activeClass}`}
        checked={value}
        name={name}
        disabled={disabled}
      />
      <span style={{ order: labelFirst ? -1 : 2 }}>{label}</span>
    </FlexBox>
  );
};
