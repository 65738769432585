import React, { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FlexBox } from "../../../../components/wilson-ui/box";

import { useAdditionalItemsStyles } from "./additional-item-styles.hook";

interface IAdditionalItem {
  title: string;
  children: React.ReactElement;
}

export const AdditionalItem: React.FC<IAdditionalItem> = ({
  title,
  children,
}) => {
  const [isContentActive, setIsContentActive] = useState<boolean>(true);
  const classes = useAdditionalItemsStyles();

  const contentHeight: string = isContentActive ? "1000px" : "0px";
  const buttonPosition: string = isContentActive
    ? "rotateX(0deg)"
    : "rotateX(180deg)";

  const toggleContentIsActive = () => setIsContentActive(!isContentActive);

  return (
    <div style={{ marginBottom: '15px' }}>
      <div className={classes.wrap} onClick={toggleContentIsActive}>
        {title && (
          <FlexBox content="space-between">
            <h4>{title}</h4>
            <div className={classes.icon} style={{ transform: buttonPosition }}>
              <KeyboardArrowUpIcon />
            </div>
          </FlexBox>
        )}
      </div>
      <div className={classes.container} style={{ maxHeight: contentHeight }}>
        {children}
      </div>
    </div>
  );
};
