import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../../../components/wilson-ui/constants/colors";

export const useMediaItemStyle = makeStyles(() => ({
  container: {
    gridTemplateColumns: "60px 200px 60px",
    columnGap: "15px",
    alignItems: "center",
  },
  dragContainer: {
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  fileInput: {
    display: "none",
  },
  label: {
    display: "flex",
    borderRadius: "10px",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    border: `1px solid ${baseColors.black}`,
  },
  labelError: {
    border: `1px solid ${baseColors.red}`,
  },
}));
