import * as Yup from "yup";
import moment from "moment-timezone";
import { COLOR_REGEX } from "../../constants";

export const AddWidgetsSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "min_length_3")
    .max(65, "max_length_65")
    .required("Required"),
  subtitle: Yup.string().max(50, "Max length 50"),
  type: Yup.string().required("Required"),
  background: Yup.string().matches(COLOR_REGEX, "must_correspond"),
  status: Yup.string().required("Required"),
  startDateDate: Yup.date().required("Required"),
  startDateTime: Yup.date().required("Required"),
  expirationDateDate: Yup.date()
    .required("Required")
    .when("startDateDate", (startDateDate, schema) => {
      return schema.test({
        test: (expirationDateDate: string) => {
          const expirationDateDateVal = moment(
            moment(expirationDateDate).format("MM/DD/YYYY")
          ).valueOf();
          const startDateDateVal = moment(
            moment(startDateDate).format("MM/DD/YYYY")
          ).valueOf();
          return (
            !expirationDateDate ||
            (!!expirationDateDate && expirationDateDateVal >= startDateDateVal)
          );
        },
      });
    }),
  expirationDateTime: Yup.date()
    .required("Required")
    .when("startDateTime", (startDateTime, schema) => {
      return schema.test({
        test: (expirationDateTime: string) => {
          const expirationDateDateVal = moment(
            moment(expirationDateTime).format("MM/DD/YYYY HH:mm")
          ).valueOf();
          const startDateDateVal = moment(
            moment(startDateTime).format("MM/DD/YYYY HH:mm")
          ).valueOf();
          return (
            !expirationDateTime || 
            (!!expirationDateTime && expirationDateDateVal >= startDateDateVal)
          );
        },
      });
    }),
  tags: Yup.array().min(1, "min_length_1"),
  feedAssetsButtonText: Yup.string()
    .min(3, "min_length_3")
    .max(50, "max_length_50")
    .required("Required"),
  feedAssetsButtonColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  feedAssetsButtonTextColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  feedTitleColor: Yup.string()
    .matches(COLOR_REGEX, "must_correspond")
    .required("Required"),
  feedTitleSize: Yup.string().required("Required"),
});
