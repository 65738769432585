import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSelectsStyles } from "./selects-styles.hook";
import { OutlinedInput, Input } from "@mui/material";

interface IProps {
  form: Record<string, any>;
  options: Array<string>;
  name: string;
  value: string;
  label?: string;
  placeholder: string;
  background?: string;
  type?: "outlined";
  showIcon?: boolean;
  handleChange?: (value: any) => void;
}

export const FormikSelect: React.FC<IProps> = ({
  form,
  options,
  name,
  value,
  label,
  placeholder,
  background,
  type,
  showIcon,
  handleChange,
}) => {
  const classes = useSelectsStyles();
  const isHaveError: boolean = form?.errors[name] && form?.submitCount;
  const marginClass: string = !label ? classes.selectMarginTop : "";
  const errorClass: string = isHaveError ? classes.errorSelect : "";
  const placeholderValue = placeholder ? placeholder : "";
  const bgValue = background ?? "transparent";
  const inputType = type ? <OutlinedInput /> : <Input />;
  const disableIconClass = !showIcon && classes.formikSelectWithoutIcon;

  return (
    <div>
      <FormControl
        fullWidth
        variant="standard"
        className={`${classes.formikSelect} ${marginClass} ${errorClass} ${disableIconClass}`}
        style={{ background: bgValue }}
      >
        <Select
          displayEmpty
          fullWidth
          value={value}
          onChange={handleChange ? handleChange: form.handleChange}
          name={name}
          input={inputType}
          renderValue={(selected: string) => {
            if (!selected || selected.length === 0) {
              return <>{placeholderValue}</>;
            }

            return <>{value}</>;
          }}
        >
          {options.map((item: string) => (
            <MenuItem
              className={classes.selectMenuItem}
              key={Math.random().toString().substr(2)}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
