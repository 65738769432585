import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { userInterestsTableConfig } from "./users-interests-table-config";
import { MainLayout } from "../../components";
import { InterestActionsButton } from "../../components/items/interest-actions-button";
import { LayoutWrap } from "../../components/wrappers/layout-wrap/layout-wrap";
import { UserInterestsHeader } from "./components/header";
import { UiTableDrag } from "../../components/wilson-ui/table";
import { EMPTY_FUNCTION } from "../../constants";
import { FlexBox } from "../../components/wilson-ui/box";
import { ConfirmModal } from "../submissions/components/confirm-modal";
import { useInterestsPageStyles } from "./interests-page-styles.hook";
import UserInterestsStore from "../../store/userInterests/userInterests.store";
import {
  removeUserInterests,
  updatePriorityHelper,
} from "../../actions/usersInterests";

const types: any = {
  OTHER: "Other",
  TRENDING: "Trending",
};

enum InterestTabLabel {
  ALL = "All Interests",
  OTHER = "Other",
  TRENDING = "Trending",
}

enum InterestTabValue {
  All = "ALL",
  Other = "OTHER",
  Trending = "TRENDING",
}

const defaultRowPerPage = 10;
const defaultPage = 1;
const defaultSortState = "name";
const defaultSortBy = "asc";
export const UserInterestsPage: React.FC = observer(() => {
  const [tab, setTab] = React.useState(InterestTabValue.All);
  const [term, setTerm] = useState<string>("");
  const [rowPerPage, setRowPerPage] = useState<number>(defaultRowPerPage);
  const [page, setPage] = useState<number>(defaultPage);
  const [sortState, setSortState] = useState<string>(defaultSortState);
  const [sortBy, setSortBy] = useState<any>(defaultSortBy);
  const [isModalShow, setIsModalShow] = useState(false);
  const [userChosen, setUserChosen] = useState<string | undefined>();
  const userInterests = UserInterestsStore.getUserInterests();
  const interestStyles = useInterestsPageStyles();
  const toggleModal = (userId?: string) => {
    setIsModalShow(!isModalShow);
    setUserChosen(userId);
  };

  const removeUserInterestHandler = async () => {
    if (!userChosen) {
      return;
    }

    UserInterestsStore.isLoading = true;
    await removeUserInterests(userChosen);
    setTerm("");
    await UserInterestsStore.usersRequest(
      rowPerPage,
      page,
      sortBy,
      sortState,
      "",
      tab
    );
    UserInterestsStore.isLoading = false;
    toggleModal();
  };

  useEffect(() => {
    if (UserInterestsStore.isLoading) return;
    UserInterestsStore.usersRequest(
      rowPerPage,
      page,
      sortBy,
      sortState,
      term,
      tab
    );
  }, [rowPerPage, page, sortState, sortBy, term, tab]);

  useEffect(() => {
    EMPTY_FUNCTION();
    return () => UserInterestsStore.setUserInterests({ data: [], length: 0 });
  }, []);

  const setSearch = (val: string) => {
    setTerm(val);
    switch (tab) {
      case InterestTabValue.All:
        setRowPerPage(10);
        setSortBy("asc");
        setSortState("name");
        break;
      case InterestTabValue.Trending:
      case InterestTabValue.Other:
        setRowPerPage(100);
        setSortBy("asc");
        setSortState("priority");
        break;
      default: {
        setRowPerPage(10);
        setSortBy("asc");
        setSortState("name");
      }
    }
  };

  const setRowPerPageHandler = (val: number) => {
    setPage(1);
    setRowPerPage(val);
  };

  const setSortModel = (field: string, sortOrder: string | null) => {
    setSortBy(sortOrder || null);
    setSortState(field);
  };

  const componentsTable = {
    name: ({ row }: any) => {
      return (
        <FlexBox items="center">
          <Avatar variant="square" src={row.mediaUrl} sx={{ marginRight: 2 }} />
          {row.name}
        </FlexBox>
      );
    },
    type: ({ row }: any) => (
      <Box sx={{ textTransform: "capitalize" }}>{types[row.type]}</Box>
    ),
    category: ({ row }: any) => (
      <Box
        sx={{
          width: "100%",
          display: "inline-block",
        }}
      >
        <Box
          sx={{
            display: "inline-block",
            textTransform: "capitalize",
            border: "2px solid #000",
            padding: "0 8px",
            marginTop: 1,
            marginRight: 1,
            marginBottom: 1,
          }}
        >
          {row.category}
        </Box>
      </Box>
    ),
    button: ({ row }: any) => (
      <Box>
        <InterestActionsButton
          row={row}
          toggleModal={() => toggleModal(row.id)}
        />
      </Box>
    ),
  };

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newValue: InterestTabValue
  ) => {
    setTab(newValue);
    setTerm("");
    switch (newValue) {
      case InterestTabValue.All:
        setRowPerPage(10);
        setSortBy("asc");
        setSortState("name");
        break;
      case InterestTabValue.Trending:
      case InterestTabValue.Other:
        setRowPerPage(100);
        setSortBy("asc");
        setSortState("priority");
        break;
      default: {
        setRowPerPage(10);
        setSortBy("asc");
        setSortState("name");
      }
    }
  };

  const setRowPriorityHandler = async (payload: Array<Record<string, any>>) => {
    await updatePriorityHelper(
      payload.map((u) => ({
        ...u,
        priority: u.widgetPriority,
        widgetPriority: undefined,
      }))
    );
  };

  const Table = (
    <UiTableDrag
      loading={UserInterestsStore.isLoading}
      onLoading={(loading: boolean) => (UserInterestsStore.isLoading = loading)}
      sortModelInit={
        sortState && sortBy ? [{ field: sortState, sort: sortBy }] : []
      }
      sortTypeSubmit={EMPTY_FUNCTION}
      sortSubmit={setSortModel}
      components={componentsTable}
      componentsTable={componentsTable}
      configDrag={userInterestsTableConfig()}
      data={toJS(userInterests.data)}
      disablePagination={!userInterests.length}
      setRowPerPage={setRowPerPageHandler}
      rowPerPage={rowPerPage}
      page={page}
      setPage={setPage}
      length={userInterests.length}
      setSearch={setSearch}
      searchValue={term}
      searchTable
      setRowPriority={setRowPriorityHandler}
      rowReordering={tab !== InterestTabValue.All}
    />
  );

  return (
    <MainLayout>
      <UserInterestsHeader
        order={sortBy}
        page={page}
        rows={rowPerPage}
        length={userInterests.length}
        sortByName={sortState}
      />
      <LayoutWrap minHeight="600px">
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangeTab}
              textColor="secondary"
              indicatorColor="secondary"
              classes={{ root: interestStyles.tabsRoot }}
              aria-label="lab API tabs example"
            >
              <Tab
                label={InterestTabLabel.ALL}
                value={InterestTabValue.All}
                classes={{ root: interestStyles.tabRoot }}
              />
              <Tab
                label={InterestTabLabel.TRENDING}
                value={InterestTabValue.Trending}
                classes={{ root: interestStyles.tabRoot }}
              />
              <Tab
                label={InterestTabLabel.OTHER}
                value={InterestTabValue.Other}
                classes={{ root: interestStyles.tabRoot }}
              />
            </TabList>
          </Box>
          <TabPanel
            value={InterestTabValue.All}
            classes={{ root: interestStyles.tabPanel }}
          >
            {Table}
          </TabPanel>
          <TabPanel
            value={InterestTabValue.Trending}
            classes={{ root: interestStyles.tabPanel }}
          >
            {Table}
          </TabPanel>
          <TabPanel
            value={InterestTabValue.Other}
            classes={{ root: interestStyles.tabPanel }}
          >
            {Table}
          </TabPanel>
        </TabContext>
      </LayoutWrap>
      {isModalShow && (
        <ConfirmModal
          toggleModal={toggleModal}
          submit={removeUserInterestHandler}
          title="Are you sure?"
        />
      )}
    </MainLayout>
  );
});
