import { makeStyles } from "@material-ui/core";
import { baseColors } from "../../wilson-ui/constants/colors";

export const useCopyItemsStyle = makeStyles(() => ({
  container: {
    "& label": {
      cursor: "pointer",
    },
    "& span": {
      cursor: "pointer",
      opacity: 0,
      padding: "8px 20px",
      position: "absolute",
      background: baseColors.black,
      color: baseColors.white,
      borderRadius: "2px",
      transform: "translate(-30px, 20px)",
      transition: "0.5s",
      display: "none",
      gap: "7px",
      alignItems: "center",
      zIndex: 10,
    },
    "&:hover": {
      "& span": {
        opacity: 1,
        display: "flex",
      },
    },
  },
  none: {
    display: "none",
  },
  blur: {
    position: "fixed",
    top: "0",
    left: "0",
    overflow: "hidden",
    height: "100vh",
    width: "100vw",
    background: "rgb(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
}));
