import { makeStyles } from "@material-ui/core";
import { mediaQuery } from "../../../../components/wilson-ui/constants/media";

export const useAdditionalSettingsStyles = makeStyles(() => ({
  container: {
    border: "1px solid rgb(238, 238, 238)",
    padding: "20px 10px",
    marginBottom: '15px',
    "& h3": {
      fontWeight: 900,
      fontSize: "24px",
      textTransform: "uppercase",
      fontFamily: "'GT America Bold', sans-serif",
      marginBottom: '10px',
    },
  },
  containerOffsetActive: {
    [mediaQuery.minWidth1024]: {
      // position: "fixed",
      // top: "80px",
      width: "312px",
      // right: "0px",
    },
  },
  deleteButton: {
    width: "auto",
    minWidth: "auto",
    borderRadius: 4,
  },
  saveButton: {
    width: "auto",
    minWidth: "auto",
    borderRadius: 4,
    padding: "14px 59px",
  },
  errorText: {
    fontFamily: "'GT America',  sans-serif",
    fontSize: "12px",
    color: '#CF102D',
    fontWeight: 500,
    display: "block",
    marginTop: "5px",
  },
}));
