import { makeStyles } from "@material-ui/core";

export const useCarouselsPageStyles = makeStyles(() => ({
  removeIcon: {
    color: 'red',
    cursor: 'pointer',
    opacity: 0.7,
    transition: '0.5s',
    '&:hover': {
      opacity: 1
    }
  },
}));
