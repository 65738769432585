import React from "react";

import { useNavbarBtnStyles } from "./navbar-btn-styles.hook";

interface IProps {
  setIsMobileActiveHandler: () => void;
  isActive: boolean;
}

export const MenuBtn: React.FC<IProps> = ({
  setIsMobileActiveHandler,
  isActive,
}) => {
  const classes = useNavbarBtnStyles();
  const activeClass = isActive ? classes.spanActive : "";

  return (
    <div onClick={setIsMobileActiveHandler} className={classes.btnWrap}>
      <span className={classes.btn}>
        <span className={`${classes.span} ${activeClass}`} />
      </span>
    </div>
  );
};
